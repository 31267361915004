import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import NavbarLPComponent from "../../components/LandingPage/NavbarLPComponent";
import FooterComponent from "../../components/FooterComponent";

import "./signuppage.scss";
class SignUpPage extends Component {
  render() {
    return (
      <div>
        <NavbarLPComponent />
        <div className="mt-64 p-5">
          <div className="row text-center">
            <div className="col-md-12">
              <h1>Sign Up for Gussy</h1>
              <h6>Everyone deserves a beautiful home.</h6>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-4 offset-md-4">
              <div className="login-form">
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="Name" />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="Email Address" />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Control type="password" placeholder="Password" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Sign Up
                  </Button>
                </Form>
                <p>
                  Already have an account? &nbsp;
                  <a href="https://app.gussy.design/">Sign In</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}

export default SignUpPage;
