import React, { useState } from 'react';

export default function AdditionalFrill(props) {
  /* eslint-disable react/prop-types */
  const { frill } = props;
  const {
    description,
    discountedPrice,
    frillDetails,
    frillImage,
    hasPriceStartLabel,
    isMirrored,
    title,
    price,
  } = frill;

  const [isMoreDetailsExpanded, setMoreDetailsExpanded] = useState(false);

  const containerClass = 'row';
  const containerClassToggle = isMirrored ? `${containerClass} flex-row-reverse` : containerClass;

  const additionalFrillsTextClass = `text-md-${
    isMirrored ? 'right' : 'left'
  } col-md-6 text-center mt-40 mb-40`;

  const hasMoreDetails = !!frillDetails?.length;

  function handleMoreDetailsClick() {
    setMoreDetailsExpanded(!isMoreDetailsExpanded);
  }

  const priceStartLabel = hasPriceStartLabel && (
    <span className="italic-callout">Price starts at</span>
  );

  const detailList =
    hasMoreDetails &&
    isMoreDetailsExpanded &&
    frillDetails.map((detail) => {
      return <li key={detail}>{detail}</li>;
    });

  const moreDetails = hasMoreDetails && (
    <>
      {
        // eslint-disable-next-line react/button-has-type
        <button
          className="obsidian btntext-13-sb accordion-arrow more-details-btn btn-link"
          onClick={handleMoreDetailsClick}
        >
          More Details
        </button>
      }
      <ul className="mt-3 projects-list text-left body-1-reg pricing-list">{detailList}</ul>
    </>
  );

  return (
    <div className="p-5 container">
      <div className={containerClassToggle}>
        <div className="col-md-6 order-last order-md-0">
          <img alt={title} className="mb-2 mb-md-0" height="267px" src={frillImage} width="100%" />
        </div>
        <div className={additionalFrillsTextClass}>
          <div className="3d-rendering">
            <h3 className="vulcanite">{title}</h3>
            {priceStartLabel}
            <div>
              <p>
                <span className="body-3-sb petra-font mt-n-5">
                  <sup className="body-1-reg petra-font">PHP </sup>
                  {discountedPrice}
                </span>
                <span className="strikethrough smoke body-1-sb ml-3">PHP {price}</span>
              </p>
            </div>
            <p className="vulcanite mt-10">{description}</p>
          </div>
          {moreDetails}
        </div>
      </div>
    </div>
  );
}
