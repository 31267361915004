import React from "react";
import { Form } from "react-bootstrap";
import Fade from "react-reveal/Fade";

import { CITIES, REGIONS } from "../../../config/constants.js";
import {
  sortObjEntriesByName,
  sortObjEntriesByFullname,
} from "../../../utils/helpers";

function StyleQuizComponent(props) {
  const {
    name,
    city,
    region,
    onChangeCity,
    onChangeName,
    onChangeRegion,
    changeDisabledState,
  } = props;

  React.useEffect(() => {
    if (name.trim() && region !== "none" && city !== "none") {
      changeDisabledState(false);
    } else {
      changeDisabledState(true);
    }
  }, [name, region, city, changeDisabledState]);

  const renderCityOptions = () => {
    return Object.entries(CITIES)
      .sort(sortObjEntriesByName)
      .filter(([id, val]) => val.regionID === region)
      .map(([id, val]) => {
        return (
          <option value={id} key={id}>
            {val.name}
          </option>
        );
      });
  };

  const renderRegionsOption = () => {
    return Object.entries(REGIONS)
      .sort(sortObjEntriesByFullname)
      .map(([id, value]) => {
        return (
          <option value={id} key={id}>
            {value.fullname}
          </option>
        );
      });
  };
  return (
    <div>
      <div className="mt-75 container container-wide">
        <div className="row d-flex justify-content-center">
          <div className="col-md-5 sq-center">
            <Fade left>
              <h3>Tell us about yourself</h3>
              <p>We'd love to know you.</p>
            </Fade>
          </div>
          <div className="col-md-5 sq-center">
            <Fade delay={300}>
              <Form className="mt-md-5">
                <Form.Group controlId="formName" className="pt-5">
                  <Form.Label className="btntext-13-sb">Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={onChangeName}
                    placeholder="Type your name"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="btntext-13-sb">Region</Form.Label>
                  <Form.Control
                    as="select"
                    value={region}
                    onChange={onChangeRegion}
                  >
                    <option value="none">Select region</option>
                    {renderRegionsOption()}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="btntext-13-sb">City</Form.Label>
                  <Form.Control
                    as="select"
                    value={city}
                    onChange={onChangeCity}
                    disabled={renderCityOptions().length === 0}
                  >
                    <option value="none">Select city</option>
                    {renderCityOptions()}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StyleQuizComponent;
