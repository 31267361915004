import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SaleTag from '../SaleTag';
import placeholderImage from '../../../assets/img/about/gussy-footer.png';
import { toMoney } from '../../../utils/helpers';

import './productthumbnail.scss';

import SkeletonElement from '../../SkeletonComponents/SkeletonElements';

function ProductThumbnailComponent(props) {
  const {
    discount,
    src,
    price,
    isOnSale,
    salePrice,
    name,
    id,
    isWallpaper,
    categoryId,
    productTypeId,
    wallpaperUnit,
  } = props;
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkImage();
  }, []);

  const checkImage = () => {
    if (!src) {
      setImage(placeholderImage);
      setIsLoading(false);
      return;
    }
    var img = new Image();
    img.onload = () => {
      setImage(
        `https://res.cloudinary.com/symphco/image/fetch/q_100,f_auto,w_150,fl_lossy/${encodeURIComponent(
          src,
        )}`,
      );
      setIsLoading(false);
    };
    img.onerror = () => {
      setImage(src);
      setIsLoading(false);
    };
    img.src = `https://res.cloudinary.com/symphco/image/fetch/q_100,f_auto,w_150,fl_lossy/${encodeURIComponent(
      src,
    )}`;
  };

  const displayPrice = () => {
    if (isOnSale) {
      return (
        <p className="product-price">
          {toMoney(salePrice)}
          {isWallpaper(categoryId, productTypeId, wallpaperUnit).toLowerCase()}
          <br />
          <span className="original-price">
            {toMoney(price)}
            {isWallpaper(categoryId, productTypeId, wallpaperUnit).toLowerCase()}
          </span>
          <span className="discount">{discount}%</span>
        </p>
      );
    } else {
      return (
        <p className="product-price">
          {toMoney(price)}
          {isWallpaper(categoryId, productTypeId, wallpaperUnit).toLowerCase()}
        </p>
      );
    }
  };

  const renderImage = isLoading ? (
    <div className="product-image-div">
      <SkeletonElement type="product-img" />
    </div>
  ) : (
    <div className="product-image-div">
      <div
        className="product-image"
        style={{
          backgroundImage: `url("${image}")`,
        }}
      />
    </div>
  );

  const showSaleTag = isOnSale ? <SaleTag /> : <> </>;
  return (
    <div className="product-thumbnail">
      <Link to={`/shop/item-details?id=${id}`} className="product-link">
        {renderImage}
        <h5 className="product-title">{name}</h5>
        {displayPrice()}
      </Link>
      {showSaleTag}
    </div>
  );
}

export default ProductThumbnailComponent;
