import React, { useCallback, useEffect, useState, useContext } from 'react';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import queryString from 'query-string';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { ArrowLeftSquare, ArrowRightSquare } from 'react-bootstrap-icons';
import InnerImageZoom from 'react-inner-image-zoom';
import Box from '@material-ui/core/Box';
import Fade from 'react-reveal/Fade';
import CookieConsent from 'react-cookie-consent';
import { useSwipeable } from 'react-swipeable';
import { withRouter } from 'react-router';
import { Parser } from 'html-to-react';
import Swal from 'sweetalert2';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

import NavbarLPComponent from '../../LandingPage/NavbarLPComponent';
import FooterComponent from '../../FooterComponent/';
import RelatedProductsComponent from '../RelatedProducts';
import CheckoutLoginModal from '../CheckoutLoginModal';
import ShopNav from '../ShopNav';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import placeholderImage from '../../../assets/img/about/gussy-footer.png';

import routes from '../../../config/routes';
import { LEFT, NEXT, PREV, PRODUCT_TYPE } from '../../../config/constants.js';
import { fetchProductDetails, fetchCartIds, fetchMerchantLogo } from '../../../queries/shop';
import { dbFirebase, authFirebase, functionsFirebase } from '../../../components/Firebase';
import { httpsCallable } from 'firebase/functions';
import { update, ref } from 'firebase/database';
import { fetchProductTypeName } from '../../../queries/shop';
import { toMoney, cloudinaryfy } from '../../../utils/helpers';
import { AuthContext, CartCountContext } from '../../../context';

import './productdetails.scss';

function ProductDetailsComponent(props) {
  const { location, history } = props;
  const params = queryString.parse(location.search);
  const authContext = useContext(AuthContext);
  const [productDetails, setProductDetails] = useState({});
  const [productType, setProductType] = useState('');
  const [isFetching, setIsFetching] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cartIds, setCartIds] = useState({});
  const [merchantLogo, setMerchantLogo] = useState('');
  const [image, setImage] = useState();
  const [imageOffsetPosition, setImageOffsetPosiotion] = useState({ left: '0px' });

  const cartCountContext = useContext(CartCountContext);
  const wasAddedToCart = params.id in cartIds;
  const isOutOfStock = productDetails?.status !== 'ACTIVE' || productDetails?.stocksRemaining <= 0;
  const stocksRemaining = isOutOfStock ? 'No stocks available' : `${productDetails?.stocksRemaining} stock${productDetails?.stocksRemaining > 1 ? 's' : ''} remaining`

  const toggleModal = () => setIsOpen(!isOpen);

  const productImageUrls = productDetails?.imgUrls?.map((imgUrl) => cloudinaryfy(imgUrl));

  const init = useCallback(async () => {
    setCartIds(await fetchCartIds());
    const prod = await fetchProductDetails(params.id);
    if (!prod) {
      Swal.fire({
        title: 'Oops!',
        text: 'This product does not exist.',
        icon: 'error',
        confirmButtonColor: '#D27455',
        confirmButtonText: 'Back to shop',
      }).then(() => {
        history.push({
          pathname: routes.SHOP,
          state: { fromProductDetails: true },
        });
      });
    } else {
      setProductDetails(prod);
      setProductType(await fetchProductTypeName(prod.productType));
      setIsFetching(false);
    }

    httpsCallable(
      functionsFirebase,
      'incrementProductPopularity',
    )({
      productKey: params.id,
    }).catch((err) => console.error(err));
  }, [params.id]);

  useEffect(() => {
    init();
    window.scrollTo(0, 0);
  }, [init]);

  useEffect(() => {
    if (wasAddedToCart) {
      handleAddToCart();
    }
  }, [quantity]);

  useEffect(() => {
    if (wasAddedToCart) {
      setQuantity(cartIds[params.id]);
    }
  }, [cartIds]);

  useEffect(() => {
    if (productDetails.user) {
      getMerchantLogo();
    }
  }, [productDetails.user]);

  const wallpaperUnit =
    productType === PRODUCT_TYPE.WALLPAPER_MURAL
      ? ` / ${productDetails.wallpaperUnit || 'sqm'}`
      : '';

  const getMerchantLogo = async () => {
    const url = await fetchMerchantLogo(`merchant-logo/${Object.keys(productDetails.user)[0]}`);
    checkImage(url, 300, setMerchantLogo);
  };

  const onClickAddToCart = () => {
    const clicked = true;
    handleAddToCart(clicked);
  };

  const onClickBuyNow = () => {
    const clicked = true;
    const buyNow = true;
    handleAddToCart(clicked, buyNow);
  };

  const handleAddToCart = async (clicked, buyNow) => {
    if (authFirebase.currentUser) {
      await update(ref(dbFirebase, `cart/${authFirebase.currentUser.uid}`), {
        [params.id]: quantity,
      });
    } else {
      const cartItems = JSON.parse(localStorage.getItem('cartItems')) || {};
      cartItems[params.id] = quantity;
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }

    if (clicked) {
      if (buyNow) {
        if (!wasAddedToCart) {
          cartCountContext.incCount();
        }
        if (authContext.isLoggedIn) {
          history.push(routes.CHECKOUT);
        } else {
          toggleModal();
        }
      } else {
        cartCountContext.incCount();
        setCartIds({ ...cartIds, [params.id]: quantity });
      }
    }
  };

  function handleNavigatePrev() {
    handleImageNavigation(PREV);
  }

  function handleNavigateNext() {
    handleImageNavigation(NEXT);
  }

  function handleSwiping({ deltaX }) {
    setImageOffsetPosiotion({ left: `${deltaX}px` });
  }

  function handleOnSwiped({ dir }) {
    dir === LEFT ? handleNavigateNext() : handleNavigatePrev();
    setImageOffsetPosiotion({ left: '0px' });
  }

  function handleImageNavigation(direction) {
    const imageUrlsLen = productImageUrls.length;
    const currentSelectedImageIndex = productImageUrls.indexOf(image);

    const isNext = direction === NEXT;
    const isPrev = direction === PREV;

    const hasNoPrev = currentSelectedImageIndex === 0 && isPrev;
    const hasNoNext = currentSelectedImageIndex === imageUrlsLen - 1 && isNext;

    if (hasNoPrev || hasNoNext) return;

    const newIndex = isNext ? currentSelectedImageIndex + 1 : currentSelectedImageIndex - 1;
    const newSelectedImage = productImageUrls[newIndex];
    setImage(newSelectedImage);
  }

  const swipeHandlers = useSwipeable({
    delta: 10,
    onSwiping: handleSwiping,
    onSwiped: handleOnSwiped,
    preventDefaultTouchmoveEvent: false,
    rotationAngle: 0,
    trackTouch: true,
    trackMouse: false,
  });

  const handleQuantityChange = (e) => {
    const { value } = e.target;
    const { name } = e.currentTarget;
    if (name === 'inc') {
      setQuantity(quantity + 1);
    } else if (name === 'dec') {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    } else if (value) {
      setQuantity(parseInt(value));
    }
  };

  useEffect(() => {
    if (productDetails.imgUrl) {
      setImage(cloudinaryfy(productDetails.imgUrl));
    }
  }, [productDetails]);

  const checkImage = (imgUrl, size, setter) => {
    var img = new Image();
    img.onload = () => setter(cloudinaryfy(imgUrl, size, 100));
    img.onerror = () => setter(imgUrl);
    img.src = cloudinaryfy(imgUrl, 50, 100);
  };

  const cartButtons = () => {
    if (!isOutOfStock) {
      return (
        <>
          <div className='d-flex my-4 align-items-center'>
            <InputGroup className="increment-input">
              <InputGroup.Prepend>
                <Button
                  variant="link"
                  size="sm"
                  onClick={handleQuantityChange}
                  name="dec"
                  disabled={isOutOfStock}
                >
                  <RemoveIcon style={{ fontSize: 20 }} />
                </Button>
              </InputGroup.Prepend>
              <FormControl
                aria-describedby="quantity"
                size="sm"
                type="number"
                onChange={handleQuantityChange}
                value={quantity}
                disabled={isOutOfStock}
              />
              <InputGroup.Append>
                <Button
                  variant="link"
                  size="sm"
                  onClick={handleQuantityChange}
                  name="inc"
                  disabled={isOutOfStock}
                >
                  <AddIcon style={{ fontSize: 20 }} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
            {productDetails?.stocks === 'LIMITED' && <span className='stocks-remaining-label'>{stocksRemaining}</span>}
          </div>
          <div className="d-flex mb-4">
            <Button
              className="w-100 mr-1 btn-outline-petra"
              onClick={onClickAddToCart}
              disabled={wasAddedToCart || isOutOfStock}
            >
              {wasAddedToCart ? 'Added to cart' : 'Add to Cart'}
            </Button>
            <Button className="w-100 ml-1 btn-petra" onClick={onClickBuyNow} disabled={isOutOfStock}>
              Buy Now
            </Button>
          </div>
        </>
      );
    }
  };

  const getDescripion = () => {
    if (productDetails.descriptionQuill) {
      const html = new QuillDeltaToHtmlConverter(productDetails.descriptionQuill.ops).convert();
      const htmlToReactParser = new Parser();

      return <div>{htmlToReactParser.parse(html)}</div>;
    } else if (productDetails.description) {
      return productDetails.description.split('\n').map((item, i) => (
        <React.Fragment key={i}>
          {item}
          <br />
        </React.Fragment>
      ));
    } else {
      return '';
    }
  };

  const renderImages = productImageUrls?.map((imgUrl) => {
    const isSelectedImage = image === imgUrl;
    const selectedImageClass = `product-image ${isSelectedImage ? 'active' : ''}`;
    return (
      <div
        key={imgUrl}
        onClick={() => setImage(imgUrl)}
        className={selectedImageClass}
        style={{
          backgroundImage: `url('${imgUrl}')`,
        }}
      ></div>
    );
  });

  const displayPrice = () => {
    if (productDetails.isOnSale) {
      return (
        <p className="product-detail-price">
          {toMoney(productDetails.salePrice)}
          {wallpaperUnit.toLowerCase()}
          <br />
          <span className="original-price">
            {toMoney(productDetails.price)}
            {wallpaperUnit.toLowerCase()}
          </span>
          <span className="discount">{productDetails.saleDiscount || 0}%</span>
        </p>
      );
    } else {
      return (
        <p className="product-detail-price">
          {toMoney(productDetails.price)}
          {wallpaperUnit.toLowerCase()}
        </p>
      );
    }
  };

  const hasManyImage = productImageUrls?.length > 1;
  const productImage = hasManyImage && (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <ArrowLeftSquare cursor="pointer" onClick={handleNavigatePrev} size={20} />
      <div className="images-container">{renderImages.length > 1 ? renderImages : ''}</div>
      <ArrowRightSquare cursor="pointer" onClick={handleNavigateNext} size={20} />
    </Box>
  );

  const backToShop = () => {
    history.push({
      pathname: routes.SHOP,
      state: { fromProductDetails: true },
    });
  };

  return (
    <>
      <div className="no-focus-outline">
        <NavbarLPComponent />
        <CookieConsent
          location="bottom"
          buttonText="I Agree"
          style={{ background: '#33332B' }}
          buttonStyle={{
            background: '#d1755b',
            padding: '5px 20px',
            color: '#ffffff',
            fontSize: '13px',
          }}
        >
          This website uses cookies to enhance the user experience.{' '}
          <a href="/terms-of-use#cookies" className="cookies-link">
            Learn More.
          </a>
        </CookieConsent>
        <ShopNav />
        <CheckoutLoginModal isOpen={isOpen} toggleModal={toggleModal} />
        {!isFetching ? (
          <Fade cascade>
            <div className="container mt-2 pt-5">
              <div className="row justify-content-center">
                <div className="col-12">
                  <Button onClick={() => backToShop()} variant="link" className="btn-back">
                    <ArrowBackIosIcon style={{ fontSize: 12 }} /> Back to Shop
                  </Button>
                </div>
                <div
                  className="col-12 col-md-10 col-lg-7 px-md-5 stick-md-top text-center"
                  style={{ height: 'fit-content' }}
                >
                  <div
                    {...swipeHandlers}
                    className="product-image-container"
                    style={imageOffsetPosition}
                  >
                    <InnerImageZoom
                      className="img-fluid mb-3 px-lg-5 product-image-zoom"
                      src={productDetails.imgUrl ? image : placeholderImage}
                    />
                  </div>
                  {productImage}
                </div>
                <div className="col-12 col-lg-4">
                  <img src={merchantLogo} alt="" className="mb-1 product-merchant-logo" />
                  <h3 className="product-detail-title">{productDetails.name}</h3>
                  <div className="price-container">
                    {displayPrice()}
                    {isOutOfStock && <p className="out-of-stock">OUT OF STOCK</p>}
                  </div>
                  {cartButtons()}
                  <div className="mb-3 body-1-reg">{getDescripion()}</div>

                  <div className="mb-3">
                    <p className="body-1-sb mb-0">Color</p>
                    <p className="body-1-reg">{productDetails.colors}</p>
                  </div>

                  <div className="mb-3">
                    <p className="body-1-sb mb-0">Material</p>
                    <p className="body-1-reg">{productDetails.material}</p>
                  </div>

                  <div className="mb-3">
                    <p className="body-1-sb mb-0">Dimensions</p>
                    <p className="body-1-reg">
                      Width: {productDetails.width} cm
                      <br />
                      Depth: {productDetails.depth} cm
                      <br />
                      Height: {productDetails.height} cm
                      <br />
                    </p>
                  </div>
                  {+productDetails.weight !== 0 && (
                    <div className="mb-3">
                      <p className="body-1-sb mb-0">Weight</p>
                      <p className="body-1-reg">{productDetails.weight} kg</p>
                    </div>
                  )}
                </div>
              </div>
              <RelatedProductsComponent product={productDetails} />
            </div>
            <FooterComponent />
          </Fade>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

export default withRouter(ProductDetailsComponent);
