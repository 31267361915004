import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./productTypesModal.scss";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import AllFurnitureIcon from "../../../assets/img/icons/all-furniture-icon.svg";
import { getProductTypeImages } from "../../../queries/shop";

const ProductTypesModal = (props) => {
  const { categoryKey, categoryName, productTypes, show, toggleModal } = props;
  const [productTypeImages, setProductTypeImages] = useState({});
  const [isDoneFetching, setIsDoneFetching] = useState(false);
  const productTypeKeys = Object.keys(productTypes);
  const productTypeValues = Object.values(productTypes);
  useEffect(() => {
    getImages();
  }, [productTypes]);

  const getImages = async () => {
    setIsDoneFetching(false);
    const images = await getProductTypeImages(productTypeKeys);
    setProductTypeImages(images);
    setIsDoneFetching(true);
  };

  const productTypesCol = useMemo(() => {
    if (productTypeValues.length > 0 && isDoneFetching) {
      return productTypeValues.map((prodType) => {
        return (
          <Col className="prodtype-col" xs={6} key={prodType.key}>
            <Link
              key={prodType.key}
              onClick={toggleModal}
              to={{ pathname: `/shop/${categoryKey}/${prodType.key}` }}
            >
              <Card>
                <div className="prodtype-img-div">
                  <img
                    src={productTypeImages[prodType.key]}
                    alt={prodType.name}
                  />
                </div>
                <Card.Body className="text-center prodtype-card-text-div">
                  <Card.Text>{prodType.name}</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        );
      });
    }
  }, [productTypeValues, productTypeImages, isDoneFetching]);

  return (
    <Modal show={show} className="filter-modal">
      <Modal.Body>
        <Button
          className="modal-back mb-20"
          onClick={toggleModal}
          variant="link"
        >
          <ArrowBackIosIcon style={{ fontSize: 20 }} /> Back
        </Button>
        <h3>{categoryName}</h3>
        <Row className="prodtype-row">
          {isDoneFetching && (
            <Col className="prodtype-col" xs={6}>
              <Link
                key={categoryKey}
                onClick={toggleModal}
                to={{ pathname: `/shop/${categoryKey}` }}
              >
                <Card>
                  <div className="prodtype-img-div">
                    <img src={AllFurnitureIcon} alt="All Furniture" />
                  </div>
                  <Card.Body className="text-center prodtype-card-text-div">
                    <Card.Text>All {categoryName}</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          )}
          {productTypesCol}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ProductTypesModal;
