import React, { useEffect, useState } from 'react';
import ReactCompareImage from 'react-compare-image';

import { fetchHomePage } from '../../../queries/contentfulCMS';
import './beforeafterlpcomponent.scss';

function BeforeAfterLPComponent() {
  const [homePage, setHomePage] = useState([]);

  const getHomePage = async () => {
    const data = await fetchHomePage();
    setHomePage(data);
  };

  useEffect(() => {
    getHomePage();
  }, []);

  return homePage.map((val) => {
    const { introText, schematicInterior, actualInterior } = val;
    return (
      <div className="container-fluid" key={introText}>
        <div className="row align-items-center">
          <div className="col text-center my-5 my-lg-0">
            <div className=" px-lg-5 py-lg-3">
              <h2 className="f-cormorant f-medium smmt-70 mt-4 f60">{introText}</h2>
            </div>
          </div>
          <div className="col-lg-6 ba-compare pl-0 pr-0">
            <ReactCompareImage
              hover
              className="compare-slider"
              leftImage={schematicInterior.file.url}
              leftImageCss={{ height: '500px', objectFit: 'cover' }}
              rightImage={actualInterior.file.url}
              rightImageCss={{ height: '500px', objectFit: 'cover' }}
            />
          </div>
        </div>
      </div>
    );
  });
}

export default BeforeAfterLPComponent;
