import React from 'react';
import { Link } from 'react-router-dom';

import '../../App.scss';

function ChoosePackageComponent() {
  return (
    <div className="ready-section bg-petra">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center pt-4 pb-5">
            <h3 className="white-font f-bold pt-1 mb-4">Ready to get started?</h3>
            <Link className="btn-white btntext-13-sb mb-0 obsidian-font btn-padding" to="/pricing">
              Choose a Package
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChoosePackageComponent;
