import React from 'react';
import CookieConsent from 'react-cookie-consent';

import FooterComponent from '../../components/FooterComponent';
import NavbarLPComponent from '../../components/LandingPage/NavbarLPComponent';

import './locationspage.scss';

function LocationsPage() {
  return (
    <div>
      <NavbarLPComponent />
      <CookieConsent
        buttonStyle={{
          background: '#d1755b',
          padding: '5px 20px',
          color: '#ffffff',
          fontSize: '13px',
        }}
        buttonText="I Agree"
        location="bottom"
        style={{ background: '#33332B' }}
      >
        This website uses cookies to enhance the user experience.{' '}
        <a className="cookies-link" href="/terms-of-use#cookies">
          Learn More.
        </a>
      </CookieConsent>
      <div className="mt-64 p-5 text-center">
        <h1>Locations</h1>
      </div>
      <FooterComponent />
    </div>
  );
}

export default LocationsPage;
