import {
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { LOGIN_PROVIDER } from "../../../config/constants";
import { socialMediaLogos } from "../../../config/images";
import { AuthContext } from "../../../context";
import {
  authFirebase,
  FacebookProvider,
  functionsFirebase,
  GoogleProvider,
} from "../../Firebase";

function CheckoutLoginModal(props) {
  const { isOpen, toggleModal, packageType } = props;
  const { register, handleSubmit, errors } = useForm();

  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const ErrMsg = ({ msg = "Required" }) => (
    <Form.Text className="text-error body-1-reg">{msg}</Form.Text>
  );

  const onSignUpSubmit = async (data) => {
    const { email, password, mobilePhone, firstName, lastName } = data;
    let providers = [];
    if (!authContext.isLoggedIn) {
      providers = await fetchSignInMethodsForEmail(authFirebase, email);
    }

    if (providers.length === 0) {
      Swal.fire({
        title: "Creating your account",
        text: "Please wait",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        icon: "info",
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      await authContext
        .signUp(
          email,
          null,
          null,
          null,
          password,
          null,
          mobilePhone,
          firstName,
          lastName,
        )
        .then(() => {
          Swal.fire("Account successfully created!", "", "success");
          toggleModal();
          if (packageType) {
            history.push(`/checkout-package?id=${packageType}`);
          } else {
            history.push("/checkout");
          }
        })
        .catch((error) => {
          Swal.fire("Something went wrong", "", "error");
        });
    } else {
      Swal.fire(
        "Email taken!",
        "Please use a different email address",
        "warning",
      );
    }
  };

  const onLoginSubmit = async (data) => {
    const { email, password } = data;
    await signInWithEmailAndPassword(authFirebase, email, password)
      .then(() => {
        toggleModal();
        if (packageType) {
          history.push(`/checkout-package?id=${packageType}`);
        } else {
          history.push("/checkout");
        }
      })
      .catch((error) =>
        Swal.fire("Invalid email and/or password.", "", "error"),
      );
  };

  const onForgotPasswordSubmit = async (data) => {
    const { email } = data;
    Swal.fire({
      title: "Please wait",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      icon: "info",
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await httpsCallable(
      functionsFirebase,
      "sendPasswordResetEmail",
    )({ email });

    if (res.data?.errorInfo?.code === "auth/user-not-found") {
      Swal.fire("No user found with this email address", "", "error");
    } else if (res.data?.code !== 500) {
      Swal.fire("Reset password email sent!", "", "success");
    } else {
      Swal.fire(res.data?.msg, "", "error");
    }
  };

  const signUpThirdParty = async (method) => {
    let provider;
    switch (method) {
      case LOGIN_PROVIDER.GOOGLE:
        provider = GoogleProvider;
        break;
      case LOGIN_PROVIDER.FACEBOOK:
        provider = FacebookProvider;
        break;
      default:
        break;
    }

    await signInWithPopup(authFirebase, provider)
      .then(async (res) => {
        await httpsCallable(
          functionsFirebase,
          "getSignInToken",
        )({
          uid: res.user.uid,
        });

        if (packageType) {
          history.push(`/checkout-package?id=${packageType}`);
        } else {
          history.push("/checkout");
        }
      })
      .catch((error) => {
        Swal.fire("Something went wrong", error.message, "error");
        toggleModal();
        signOut(authFirebase);
      });
  };

  const onChangeDisplay = () => setIsLogin(!isLogin);

  const onForgotPasswordClick = () => {
    if (isForgotPassword) {
      setIsLogin(true);
    }

    setIsForgotPassword(!isForgotPassword);
  };

  const signUpModalContent = (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="modal-header text-center">
            <h4>Create an Account</h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button
          className="mt-10 google-btn login-btn"
          onClick={() => signUpThirdParty(LOGIN_PROVIDER.GOOGLE)}
          type="submit"
          variant="primary"
        >
          <img
            src={socialMediaLogos.googleLogo}
            alt={LOGIN_PROVIDER.GOOGLE}
            className="login-btn-logo"
          />
          Sign in with Google
        </Button>
        <Button
          className="mt-20 facebook-btn login-btn"
          onClick={() => signUpThirdParty(LOGIN_PROVIDER.FACEBOOK)}
          type="submit"
          variant="primary"
        >
          <img
            src={socialMediaLogos.fbLogo}
            alt={LOGIN_PROVIDER.FACEBOOK}
            className="login-btn-logo"
          />
          Sign in with Facebook
        </Button>
        <div className="login-divider mt-30">or</div>
        <Form onSubmit={handleSubmit(onSignUpSubmit)} className="mt-30">
          <Form.Group>
            <Form.Label>First name</Form.Label>
            <Form.Control
              name="firstName"
              ref={register({ required: true })}
              type="text"
            />
            {errors.firstName && <ErrMsg />}
          </Form.Group>

          <Form.Group>
            <Form.Label>Last name</Form.Label>
            <Form.Control
              name="lastName"
              ref={register({ required: true })}
              type="text"
            />
            {errors.lastName && <ErrMsg />}
          </Form.Group>

          <Form.Group>
            <Form.Label>Mobile Phone</Form.Label>
            <Form.Control
              name="mobilePhone"
              ref={register({ required: true })}
              type="text"
            />
            {errors.mobilePhone && <ErrMsg />}
          </Form.Group>

          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              name="email"
              ref={register({ required: true })}
              type="email"
            />
            {errors.email && <ErrMsg />}
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password"
              ref={register({
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9]{6,}$/i,
                  message: "Must be at least 6 characters",
                },
              })}
              type="password"
            />
            {errors.password && <ErrMsg msg={errors.password.message} />}
          </Form.Group>

          <div className="w-100 text-center">
            <span className="notice">
              By signing up, you agree to Gussy's&nbsp;
              <a href="/terms-of-use" target="_blank">
                Terms of Service
              </a>{" "}
              &amp;&nbsp;
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </span>
          </div>
          <Button
            variant="primary"
            className="mt-3 btn-petra login-btn"
            type="submit"
          >
            Create Account
          </Button>
        </Form>
        <div className="d-flex justify-content-center mt-50">
          <span className="body-1-reg">
            Already have an account?
            <button className="register-btn" onClick={onChangeDisplay}>
              Log In
            </button>
          </span>
        </div>
      </Modal.Body>
    </>
  );
  const loginModalContent = (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="modal-header text-center">
            <h4>Login to your account</h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button
          className="mt-10 google-btn login-btn"
          type="submit"
          onClick={() => signUpThirdParty(LOGIN_PROVIDER.GOOGLE)}
          variant="primary"
        >
          <img
            src={socialMediaLogos.googleLogo}
            alt={LOGIN_PROVIDER.GOOGLE}
            className="login-btn-logo"
          />
          Log in with Google
        </Button>
        <Button
          className="mt-20 facebook-btn login-btn"
          type="submit"
          variant="primary"
          onClick={() => signUpThirdParty(LOGIN_PROVIDER.FACEBOOK)}
        >
          <img
            src={socialMediaLogos.fbLogo}
            alt={LOGIN_PROVIDER.FACEBOOK}
            className="login-btn-logo"
          />
          Log in with Facebook
        </Button>
        <div className="login-divider mt-30">or</div>
        <Form onSubmit={handleSubmit(onLoginSubmit)} className="mt-30">
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              name="email"
              ref={register({ required: true })}
              type="email"
            />
            {errors.email && <ErrMsg />}
          </Form.Group>

          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password"
              ref={register({ required: true })}
              type="password"
            />
            {errors.password && <ErrMsg />}
          </Form.Group>

          <div className="d-flex justify-content-end">
            <span className="body-1-reg">
              <button
                className="register-btn"
                type="button"
                onClick={onForgotPasswordClick}
              >
                Forgot Password?
              </button>
            </span>
          </div>
          <Button
            variant="primary"
            className="mt-3 btn-petra login-btn"
            type="submit"
          >
            Login
          </Button>
        </Form>
        <div className="d-flex justify-content-center mt-50">
          <span className="body-1-reg">
            Don’t have an account?
            <button className="register-btn" onClick={onChangeDisplay}>
              Create an Account
            </button>
          </span>
        </div>
      </Modal.Body>
    </>
  );

  const forgotPasswordContent = (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="modal-header text-center">
            <h4>Forgot Password?</h4>
            <span className="body-1-reg">
              We will send you an email to reset your password.
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onForgotPasswordSubmit)} className="mt-20">
          <Form.Group>
            <Form.Label>Enter email</Form.Label>
            <Form.Control
              name="email"
              ref={register({ required: true })}
              type="email"
            />
            {errors.email && <ErrMsg />}
          </Form.Group>
          <Button
            variant="primary"
            className="mt-3 btn-petra login-btn"
            type="submit"
          >
            Send Email
          </Button>
        </Form>
        <div className="d-flex justify-content-center mt-50">
          <span className="body-1-reg">
            <button className="register-btn" onClick={onForgotPasswordClick}>
              Go Back to Login
            </button>
          </span>
        </div>
      </Modal.Body>
    </>
  );

  const renderDisplay = useCallback(() => {
    if (isForgotPassword) {
      return forgotPasswordContent;
    } else if (isLogin) {
      return loginModalContent;
    } else {
      return signUpModalContent;
    }
  }, [
    forgotPasswordContent,
    isForgotPassword,
    isLogin,
    loginModalContent,
    signUpModalContent,
  ]);

  useEffect(() => {
    renderDisplay();
  }, [isLogin, renderDisplay]);

  return (
    <Modal
      centered
      className="checkout-login-modal"
      onHide={toggleModal}
      show={isOpen}
    >
      {renderDisplay()}
    </Modal>
  );
}

export default CheckoutLoginModal;
