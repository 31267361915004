import React, { useContext, useState, useEffect } from 'react';
import { Accordion, Button, Badge } from 'react-bootstrap';

import { GUSSY_REDIRECT_URL } from '../../config/constants';
import { AuthContext } from '../../context';
import { fetchPricingPackages, fetchHomePage } from '../../queries/contentfulCMS';
import { getLastWord, isEven } from '../../utils/helpers';
import CheckoutLoginModal from '../ShopComponents/CheckoutLoginModal';

import './pricingpackagecomponent.scss';
import '../../App.scss';

function PricingPackageComponent(props) {
  // eslint-disable-next-line react/prop-types
  const { isLandingPage } = props;
  const authContext = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [packageType, setPackageType] = useState(false);
  const [pricingPackages, setPricingPackages] = useState([]);
  const [homePage, setHomePage] = useState([]);

  const imageStyles = ['col-lg-8 package-3-images', 'col-lg-8 package-2-images order-lg-1'];

  const getHomePage = async () => {
    const data = await fetchHomePage();
    setHomePage(data);
  };

  const getPricingPackages = async () => {
    const data = await fetchPricingPackages();
    setPricingPackages(data);
  };

  useEffect(() => {
    getHomePage();
    getPricingPackages();
  }, []);

  const toggleModal = () => setIsOpen(!isOpen);

  const handleClickPackage = async ({ target }) => {
    const { name: type } = target;
    const { getToken, isLoggedIn, user } = authContext;

    if (isLoggedIn) {
      const token = await getToken(user.id);
      window.location.href = `${GUSSY_REDIRECT_URL}/client-timeline.html?package=${type}&token=${token}`;
    } else {
      toggleModal();
      setPackageType(type);
    }
  };

  const largeColumnSizeLimit = 3;

  const landingPagePricingPackageList =
    pricingPackages &&
    pricingPackages.map((val) => {
      const { packageImage, packageName, packageDescription, packagePrice } = val;
      return (
        <div
          className={`col-md-4 col-lg-${
            pricingPackages.length > largeColumnSizeLimit ? '3' : '4'
          } col-sm-12 text-center mb-50`}
          key={packageName}
        >
          <div className="pricing-page-card">
            <div className="pricing-img-div mb-4">
              <img alt={packageName} className="pricing-img" src={packageImage.file.url} />
            </div>
            <h4>{packageName}</h4>
            <p className="body-1-reg">{packageDescription}</p>
            <div className="mb-20 mt-30">
              <p className="body-1-m copper-font text-uppercase mb-0">
                {val?.packageHighlight ?? ''}
              </p>
              <p className="price body-3-reg mbn-10">
                <sup className="body-2-reg">PHP</sup> {packagePrice}
              </p>
            </div>
            <Button
              className="btn-petra pricing-component btntext-13-sb p-12-30"
              name={`PACKAGE-${getLastWord(val.packageName)}`}
              onClick={handleClickPackage}
            >
              {`Get Your ${packageName}`}
            </Button>
            <Accordion className="accordion-styled text-left mt-30">
              <Accordion.Toggle
                as={Button}
                className="obsidian f-bold f-13 accordion-arrow"
                eventKey="0"
                variant="link"
              >
                More Details
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <ul className="mt-30 projects-list text-left body-1-reg pricing-list">
                  {val.packageDetailsList.map((detail) => (
                    <li key={detail}>{detail}</li>
                  ))}
                </ul>
              </Accordion.Collapse>
            </Accordion>
          </div>
        </div>
      );
    });

  const pricingPackageList =
    pricingPackages &&
    pricingPackages.map((val, index) => {
      const {
        packageImagePricingpage,
        packageHighlight,
        packageName,
        packagePrice,
        packageDescriptionPricingpage,
      } = val;
      const pricingPageImgURLS = packageImagePricingpage.map((item) => item.fields.file.url);

      return (
        <section className={isEven(index) ? 'bg-gray9 p-4 mt-40' : ''} key={packageName}>
          <div className={`container ${isEven(index) ? 'pricing-even' : 'p-4 pricing-odd mt-4'}`}>
            <div
              className={`row justify-content-center align-items-start ${
                isEven(index) ? 'mt-40 mb-30' : ''
              }`}
            >
              <div
                className={`col-lg-4 col-md-8 text-center ${
                  isEven(index) ? 'text-lg-left order-lg-2' : 'text-lg-right col-sm-12'
                }`}
              >
                <Badge className={packageHighlight ? 'text-uppercase badge-secondary' : ''}>
                  {val?.packageHighlight ?? ''}
                </Badge>
                <h3 className="mb-0">{packageName}</h3>
                <p className="body-3-sb petra-font mt-n-5">
                  <sup className="body-1-reg petra-font">PHP </sup>
                  {packagePrice}
                </p>
                <p className="f14">{packageDescriptionPricingpage}</p>
                <Button
                  className="btn-petra pricing-component btntext-13-sb p-12-30 smmb-20"
                  name={`PACKAGE-${getLastWord(packageName)}`}
                  onClick={handleClickPackage}
                >
                  {`Get Your ${packageName}`}
                </Button>
                <Accordion
                  className={`text-center mt-3 ${isEven(index) ? 'text-lg-left' : 'text-lg-right'}`}
                >
                  <Accordion.Toggle
                    as={Button}
                    className="obsidian f-bold f-13 accordion-arrow"
                    eventKey="0"
                    variant="link"
                  >
                    More Details
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <ul className="mt-3 projects-list text-left body-1-reg pricing-list">
                      {val.packageDetailsList.map((detail) => (
                        <li key={detail}>{detail}</li>
                      ))}
                    </ul>
                  </Accordion.Collapse>
                </Accordion>
              </div>
              <div className={index === 0 ? 'package-1-images' : imageStyles[index % 2]}>
                {pricingPageImgURLS.length === 2 &&
                  pricingPageImgURLS.map((url, idx) => (
                    <img
                      alt={packageName}
                      className={`img-fluid image-${idx + 1} mt-2 mt-lg-0`}
                      key={`${packageName}Image`}
                      src={url}
                    />
                  ))}
              </div>
            </div>
          </div>
        </section>
      );
    });

  const forLandingPage = (
    <>
      <div className="container container-wide">
        <div className="row justify-content-center">{landingPagePricingPackageList}</div>
      </div>

      {homePage.map((val) => {
        const { cartLogo, textBanner, textDescription, bannerButton } = val;
        return (
          <section className="bg-gray9 py-5" key={textDescription}>
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-10 offset-md-1 text-center">
                  <img alt="cart" className="cart-lp mb-3" src={cartLogo.file.url} />
                  <h2 className="f-semibold f40 vulcanite">{textBanner}</h2>
                  <p className="body-1-reg f20">{textDescription}</p>
                  <Button className="btn-dark p-15-30 btntext-13-sb" href="/shop">
                    {bannerButton}
                  </Button>
                </div>
              </div>
            </div>
          </section>
        );
      })}
    </>
  );

  return (
    <>
      <CheckoutLoginModal isOpen={isOpen} packageType={packageType} toggleModal={toggleModal} />
      {isLandingPage ? forLandingPage : pricingPackageList}
    </>
  );
}

export default PricingPackageComponent;
