import React from "react";
import { Form } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import FacebookIcon from "../../../assets/img/facebook-icon.png";

function StyleQuiz7Component(props) {
  const {
    signUpThirdParty,
    handleSubmit,
    emailValue,
    handleEmailChange,
    passwordValue,
    handlePasswordChange,
    confirmPassword,
    onChangeConfirmPassword,
  } = props;

  return (
    <div>
      <div className="mt-75 container container-wide">
        <div className="row d-flex justify-content-center">
          <div className="col-md-5 sq-center">
            <Fade left>
              <h3>Create your account</h3>
              <p>Provide your email and password to create an account.</p>
            </Fade>
          </div>
          <div className="col-md-5 sq-center">
            <Fade delay={300}>
              <Form onSubmit={handleSubmit} className="mt-md-5">
                <Form.Group className="pt-5">
                  <Form.Label className="btntext-13-sb">Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type your email"
                    value={emailValue}
                    onChange={handleEmailChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="btntext-13-sb">Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Create a password"
                    value={passwordValue}
                    onChange={handlePasswordChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="btntext-13-sb">
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={onChangeConfirmPassword}
                  />
                </Form.Group>

                <div className="d-flex justify-content-end mt-5">
                  <input
                    value="Create my account"
                    type="submit"
                    className="btn-dark p-13-55 btntext-13-sb"
                  />
                </div>
              </Form>
              <div
                className="d-flex flex-column text-center"
                style={{ width: 250, marginLeft: "auto" }}
              >
                <span className="lbl-or"> Or sign up via </span>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-light btn-3d-party"
                    onClick={() => signUpThirdParty("GOOGLE")}
                  >
                    <img
                      style={{ width: 30 }}
                      alt="Google sign-in"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                    />
                  </button>
                  <button
                    className="btn btn-light btn-3d-party"
                    onClick={() => signUpThirdParty("FACEBOOK")}
                  >
                    <img
                      style={{ width: 30 }}
                      alt="Facebook sign-in"
                      src={FacebookIcon}
                    />
                  </button>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StyleQuiz7Component;
