import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { GUSSY_REDIRECT_URL } from '../../config/constants';
import { AuthContext } from '../../context';

import './readycomponent.scss';
import '../../App.scss';

function ReadyComponent() {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const onClickStyleQuiz = async () => {
    if (authContext.user?.hasStyleQuiz) {
      const token = await authContext.getToken(authContext.user.id);
      window.location.href = `${GUSSY_REDIRECT_URL}/client-timeline.html?token=${token}`;
    } else {
      history.push(`/style-quiz`);
    }
  };
  return (
    <div className="ready-section bg-petra">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center pt-4 pb-5">
            <h3 className="white-font f-bold pt-1">Ready to get started?</h3>
            <p className="body-1-reg white-font mb-4">
              Take our free style quiz to get matched with a designer.
            </p>
            <Button
              className="btn-white btntext-13-sb mb-0 obsidian-font btn-padding"
              onClick={onClickStyleQuiz}
            >
              Find Your Style
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReadyComponent;
