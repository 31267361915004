import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import './contactuspage.scss';

import HelmetComponent from '../../components/HelmetComponent';
import LayoutComponent from '../../components/LayoutComponent';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

function ContactUsPage(props) {
  // eslint-disable-next-line react/prop-types
  const { history } = props;
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    modeOfContact: '',
    message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...state }),
    })
      .then(() => {
        e.preventDefault();
        // eslint-disable-next-line react/prop-types
        history.push('/success');
      })
      // eslint-disable-next-line no-alert
      .catch((error) => alert(error));
  };

  const handleChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const { firstName, lastName, email, contactNumber, modeOfContact, message } = state;

  return (
    <LayoutComponent>
      <HelmetComponent description="Send us your details and we’ll reach out!" title="Contact" />
      <Fade>
        <div className="mt-64 py-5 container">
          <div className="row text-center mb-20">
            <div className="col-lg-5 col-md-7 text-left">
              <h2 className="page-title">Get In Touch</h2>
              <p>
                Send us your details and we’ll reach out! You can also schedule a call through our{' '}
                <a
                  className="petra"
                  href="https://calendly.com/gussydesign/consultation"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Calendly
                </a>
                .
              </p>
            </div>
          </div>

          <Form name="contact" onSubmit={handleSubmit}>
            <input name="form-name" type="hidden" value="contact" />
            <Row>
              <Col lg={3} md={4}>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    required
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={firstName}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col lg={3} md={4}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    required
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={lastName}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg={3} md={4}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    required
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col lg={3} md={4}>
                <FormGroup>
                  <Label for="contactNumber">Contact Number</Label>
                  <Input
                    required
                    id="contactNumber"
                    name="contactNumber"
                    type="text"
                    value={contactNumber}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={8}>
                <FormGroup tag="fieldset">
                  <Label>How would you want us to contact you?</Label>
                  <FormGroup check>
                    <Label check>
                      <Input
                        required
                        defaultValue={modeOfContact === 'Email'}
                        name="modeOfContact"
                        type="radio"
                        value="Email"
                        onChange={handleChange}
                      />{' '}
                      Email
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        defaultValue={modeOfContact === 'Call'}
                        name="modeOfContact"
                        type="radio"
                        value="Call"
                        onChange={handleChange}
                      />{' '}
                      Call
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            {modeOfContact === 'Call' && (
              <Row>
                <Col lg={6} md={8}>
                  <a
                    className="schedule-info"
                    href="https://calendly.com/gussydesign/consultation"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="schedule-details">
                      <h6>Set a schedule</h6>
                      <p>
                        Schedule when you would like us to contact you at:{' '}
                        <strong className="petra">
                          https://calendly.com/gussydesign/consultation
                        </strong>
                      </p>
                    </div>
                  </a>
                </Col>
              </Row>
            )}

            <Row>
              <Col lg={6} md={8}>
                <FormGroup>
                  <Label for="message">Message</Label>
                  <Input
                    required
                    id="message"
                    name="message"
                    type="textarea"
                    value={message}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Button
              className="btn-petra btntext-13-sb p-13-55 mt-2 btn btn-primary"
              type="submit"
              variant="primary"
            >
              Submit
            </Button>
          </Form>
        </div>
      </Fade>
    </LayoutComponent>
  );
}

export default ContactUsPage;
