import React from 'react';
import Helmet from 'react-helmet';

import defaultImg from '../../assets/img/landing/banner-bg.jpg';

// eslint-disable-next-line react/prop-types
const HelmetComponent = ({ title, description, image }) => {
  const metaDescription =
    description ||
    'Our licensed designers make sure you get the best. Check our curated furniture and decor marketplace to create the designer home you deserve.';
  const metaTitle = `Gussy - ${title || 'Online Home Styling and Shop PH'}`;
  const metaImage = image || defaultImg;

  return (
    <Helmet title={metaTitle}>
      <meta charSet="utf-8" />
      <meta content="ie=edge" httpEquiv="x-ua-compatible" />
      <meta content="width=device-width, initial-scale=1, shrink-to-fit=no" name="viewport" />
      <meta content={metaDescription} name="description" />
      <meta content="en_US" property="og:locale" />
      <meta content="website" property="og:type" />
      <meta content={metaTitle} property="og:title" />
      <meta content={metaDescription} property="og:description" />
      <meta content={metaImage} property="og:image" />
      <meta content={metaTitle} name="twitter:title" />
      <meta content={metaDescription} name="twitter:description" />
      <meta content={metaImage} name="twitter:image" />
      <meta content="Gussy" property="og:site_name" />
    </Helmet>
  );
};

export default HelmetComponent;
