import React, { useState } from "react";
import { CartCountContext } from "../context";
import { fetchCartIds } from "../queries/shop";

import { authFirebase } from "../components/Firebase";

const CartCountProvider = (props) => {
  const [cartCount, setCartCount] = useState(0);

  return (
    <CartCountContext.Provider
      value={{
        count: cartCount,
        setCount: (count) => setCartCount(count),
        incCount: () => setCartCount(cartCount + 1),
        decCount: () => setCartCount(cartCount - 1),
        initCount: async () => {
          let ids = {};
          if (authFirebase.currentUser) {
            ids = await fetchCartIds();
          } else {
            ids = JSON.parse(localStorage.getItem("cartItems"));
          }
          setCartCount(Object.keys(ids || {}).length);
        },
      }}
    >
      {props.children}
    </CartCountContext.Provider>
  );
};

export default CartCountProvider;
