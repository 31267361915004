import React from 'react';

import HelmetComponent from '../../components/HelmetComponent';
import BannerLPComponent from '../../components/LandingPage/BannerLPComponent';
import BeforeAfterLPComponent from '../../components/LandingPage/BeforeAfterLPComponent';
import CarouselLPComponent from '../../components/LandingPage/CarouselLPComponent';
import ReviewBannerComponent from '../../components/LandingPage/ReviewBannerComponent';
import StepsLPComponent from '../../components/LandingPage/StepsLPComponent';
import LayoutComponent from '../../components/LayoutComponent';
import PricingPackageComponent from '../../components/PricingPackageComponent';

import './landingpage.scss';

function LandingPage() {
  return (
    <LayoutComponent className="no-focus-outline">
      <HelmetComponent
        description="Create a designer home in a few days."
        title="Online Home Styling &amp; Shop"
      />
      <CarouselLPComponent />
      <BeforeAfterLPComponent />
      <StepsLPComponent />
      <PricingPackageComponent isLandingPage />
      <BannerLPComponent />
      <ReviewBannerComponent />
    </LayoutComponent>
  );
}

export default LandingPage;
