import parse from "html-react-parser";
import React, { useState } from "react";

import { Accordion, Card, InputGroup, FormControl } from "react-bootstrap";

import down from "../../assets/img/icons/arrow-down.svg";

function FaqsComponent() {
    const edesignitems = [
        {
            id: 1,
            faq: "This is the first time I heard of e-design, can you explain to me the process.",
            arrow: down,
            body:
                "<p>Steps to gussying up your home:</p>" +
                "<ol>" +
                "<li>Choose among the design packages we are offering, you can see them <a href='/pricing' target='_blank'>here</a>.</li>" +
                "<li>Choose additional frills which are additional services to top up your package.</li>" +
                "<li>Sign up and create an account with password prior to check out.</li>" +
                "<li>Confirm your account by clicking the link sent via email.</li>" +
                "<li>Pay for the chosen package and top ups (if any).</li>" +
                "<li>Once paid, you are directed towards our GussyApp where you will need to take a style quiz if you haven’t taken it already.  But if you’ve completed the style quiz, you may go straight to answering our detailed questionnaire.</li>" +
                "<li>After the questionnaire is completed, you will receive an email from your designer.</li>" +
                "<li>Your designer will then start designing your space using the GussyApp</li>" +
                "</ol>",
        },
        {
            id: 2,
            faq: "What is the GussyApp?",
            arrow: down,
            body:
                "<p>GussyApp is our proprietary web-based software developed by Gussy  to provide a fast and convenient designing and shopping experience for you.</p>" +
                "<p>Through the GussyApp, your designers will be able to create 2D schematic designs using on-stock items with just a simple drag and drop. All items seen in your 2D schematic along with several alternatives are  automatically saved in your shopping cart where you have an option to tick or untick items you wish to purchase.</p>",
        },
        {
            id: 3,
            faq: "Can I use the GussyApp or is it only my designers who can use this?",
            arrow: down,
            body:
                "<p>For those who have availed of our design packages, you will be able to use the following GussyApp features:   fill up a questionnaire, view your designer’s submitted designs for your space, communicate with your designer on your feedback to the designs and check-out recommended products on your shopping basket.</p>" +
                "<p>However, for the 2D schematic collage builder, only your designer has access to this.  This is how your designer will be able to create your designs.  Should you wish to create your own designs using the GussyApp, we have available subscription packages <a href='/pricing' target='_blank'>here</a>.</p>",
        },
        {
            id: 4,
            faq: "What makes us different from furniture stores that offer free design?",
            arrow: down,
            body: "<p>With our pool of merchants, Gussy provides you with a range of curated choices, styles and across different price points to fit your budget.  You will not be limited to purchasing from one brand only. It’s the fastest and most convenient way to create your dream home!",
        },
        {
            id: 5,
            faq: "My home is still being constructed/ turnover will be in a few months, is it too soon to start purchasing a design package?",
            arrow: down,
            body: "<p>The entire design process can take a minimum of 2 weeks to a maximum of 2 months depending on how long you and your designer can finalize the design.  With this timeline in mind, it would be best to start purchasing a package just a few weeks before turnover to make sure that the items our designer recommends will still be on stock.",
        },
        {
            id: 6,
            faq: "What package should I consider?",
            arrow: down,
            body:
                "<p>It depends on how much assistance you need to gussy up your space. We have three packages to choose from along with supplemental services to cater to your unique requirements.  You can learn more about them <a href='/pricing' target='_blank'>here</a>." +
                "<p>Should you not wish to work with a designer anymore, you may avail of a subscription package for our fun 2D Schematic collage builder called the <a href='https://app.gussy.design/' target='_blank'>GussyApp</a> and create designs yourself.",
        },
        {
            id: 7,
            faq: "What rooms can I have designed?",
            arrow: down,
            body:
                "<p>Since Gussy focuses on fast and affordable design, we only provide schematic designs which entail styling and staging through furniture and accessories. We cannot design rooms that will require construction-related activities such as tiling, ceiling and electrical works. Should you wish to have a more extensive design for your space, you may contact our traditional design sister company, Empire Designs <a href='https://empiredesigns.ph/' target='_blank'>here.</a>" +
                "<p>With Gussy’s extensive furniture, lighting, wallpaper and accessories we can design all rooms except kitchens and toilets.",
        },
        {
            id: 8,
            faq: "Who will be my designer?",
            arrow: down,
            body: "<p>You will be matched with a designer based on the results of your style quiz.  You need not worry as our pool of designers have been trained in the industry for more than a decade and are all licensed Interior Designers.</p>",
        },
        {
            id: 9,
            faq: "When do I get to talk to my designer?",
            arrow: down,
            body:
                "<p>You may schedule a call at any given point of the designing stage whether you wish to discuss certain items on the questionnaire or to further discuss the designs submitted by your designer.  Please note that listed in packages are the number of calls included.</p>" +
                "<p>For Gussied Style Advice, a 20-minute call with your designer is not included in the package.</p>" +
                "<p>For Gussied Up Essentials and Premium, we would suggest to reserve these calls at the styling stage when all your furniture pieces have arrived, this will help you and the designer assess how the design was executed and if there are any other things needed to complete the look.</p>" +
                "<p>For the most part, coordination with your designer can be done either via email or through the GussyApp which has an extensive questionnaire for you to be able to take your time in detailing your requirements as well as a comments section for you to immediately give your feedback to your designer.</p>",
        },
        {
            id: 10,
            faq: "How much will an e-design project cost me?",
            arrow: down,
            body:
                "<p>The cost of the design packages are fixed and straightforward as seen <a href='/pricing' target='_blank'>here</a>.  But for the actual cost of furniture and accessories we shall be recommending, we can give estimates as to how much the total project will cost once we are able to asses your needs through your answers in the GussyApp questionnaire.</p>" +
                "<p>You may also give us your budget and we shall let you know what can fit within it.</p>",
        },
        {
            id: 11,
            faq: "Can I take the style quiz even before paying for a package?",
            arrow: down,
            body: "<p>Yes you may!  Our super fun style quiz is there for everyone to learn more about their unique style.</p>",
        },
        {
            id: 12,
            faq: "Do I need to take a style quiz and questionnaire?",
            arrow: down,
            body:
                "<p>Yes we would definitely need you to answer this.  Gussy’s aim is to provide a custom design made especially for you taking both the style quiz and questionnaire will help us do just that.</p>" +
                "<p>Think of it as a getting to know you phase. Styles and tastes are unique; and yours would be top of mind when designing your space.</p>",
        },
        {
            id: 13,
            faq: "What happens if I don’t agree with the quiz results?",
            arrow: down,
            body: "<p>No problem here! Maybe we just need more time to get to know you better. Feel free to send us pictures or links to your Pinterest / Houzz boards or simply email us additional things you think we’ll need to know so we can understand your needs better.</p>",
        },
        {
            id: 14,
            faq: "Will my designer visit the site and do actual measurements?",
            arrow: down,
            body:
                "<p>Gussy does not offer traditional interior design services this means that the designer will not do any site visits to verify the site dimensions.  We will therefore ask you to send us your layout and site photos and we will rely on the dimensions you will provide.  Don’t worry, we can walk you through how to do this.</p>" +
                "<p>Should you wish to have a more extensive design for your space that includes construction-related activities, you may contact our traditional design sister company, Empire Designs <a href='https://empiredesigns.ph/' target='_blank'>here</a>.</p>",
        },
        {
            id: 15,
            faq: "Once the design is submitted to me, can I still have this revised?",
            arrow: down,
            body:
                "<p>The number of revisions are set for each package.</p>" +
                "<p>Comments can be placed on the GussyApp itself so you can point out what you want revised. We also give two (2) additional options for key furniture pieces so you can explore looks and decide which best furniture you like.</p>" +
                "<p>For Gussied Up Essentials and Premium packages, you may also schedule a call with our designers for a quick chat on your thoughts on the submitted design.</p>",
        },
        {
            id: 16,
            faq: "How long will the design process take?",
            arrow: down,
            body:
                "<p>Once you have completed the style quiz and questionnaire, our designers will immediately start designing your space.  Expect an initial design within 7-10 business days.  After which, we shall wait for your feedback and once we’ve seen your comments, revisions can be completed within 3-5 business days.</p>" +
                "<p>The entire process can take a minimum of 2 weeks to a maximum of 2 months, depending on your approval.</p>",
        },
        {
            id: 17,
            faq: "Is there an expiry to my package?",
            arrow: down,
            body:
                "<p>Your package will expire after 6 months of purchase whether used or not. This is enough time for you and your designers to collaborate on your designs.  The different concept boards and options submitted by your designer will be saved on your account indefinitely and so you can still look back on these but can no longer have revisions made even if you may still have revision credits left once the package expires.</p>" +
                "<p>Gussy aims for a fast and convenient experience for you and so we encourage you not to purchase a package if you are not yet ready to have your space designed.</p>",
        },
        {
            id: 18,
            faq: "What happens next once I approve the design?",
            arrow: down,
            body:
                "<p>Once design is approved, your designs will be saved in your account indefinitely.  All items your designer recommends automatically are listed in your shopping cart.  This means that you no longer need to search for each furniture piece in our Shop.  You may select the items you wish to purchase and uncheck any items you don’t need for now. Click check-out and pay.  It's as simple and easy as that!</p>" +
                "<p>You can then sit back and wait for all the items you purchased to arrive on your doorstep and get ready to show off your newly gussied up space!</p>" +
                "<p>For those with remaining calls in their package, you may schedule an online styling call with your designer where you can walk her through the space once all your items have arrived and she can further recommend and fix the items in your space.</p>",
        },
        {
            id: 19,
            faq: "Do I need to purchase the furniture recommended to me by my designer right away?",
            arrow: down,
            body:
                "<p>You may purchase the items anytime during the designing process or after the design is completed.  However, we do not guarantee its availability and pricing by then.  Some pieces are fast moving and so we encourage you to immediately purchase once you find a certain piece that suits your space.</p>" +
                "<p>In case the item is out of stock or discontinued, please refer to the other options we have recommended as alternatives.</p>" +
                "<p>We can also re-specify and recommend something similar to what you had picked ONLY if you have remaining revisions in your package.  Otherwise, you may have to search our SHOP yourself basing it on our designs submitted.</p>",
        },
        {
            id: 20,
            faq: "Can I have certain pieces reserved?",
            arrow: down,
            body: "<p>Unfortunately, we cannot reserve any items for now.</p>",
        },
    ];

    const shoppingitems = [
        {
            id: 21,
            faq: "Can I purchase items on your site without purchasing a design package?",
            arrow: down,
            body:
                "<p>Yes you can!  You may shop directly without the assistance of a designer through our <a href='/shop' target='_blank'>SHOP</a>.</p>" +
                "<p>You may also avail of a subscription package for our fun 2D Schematic collage builder called the GussyApp and create designs yourself.</p>",
        },
        {
            id: 22,
            faq: "Do you have readily available furniture and accessory looks I can choose from?",
            arrow: down,
            body:
                "<p>Yes! We constantly create pre-designed “Get That Look” packages at no additional cost seen in our <a href='https://www.instagram.com/gussy.design/' target='_blank'>Instagram</a> account. </p>" +
                "<p>We have also pre-filtered each item in the SHOP so you may easily find a style, color or price range you are looking for. </p>",
        },
        {
            id: 23,
            faq: "What are your payment options?  Do you offer installment or COD?",
            arrow: down,
            body:
                "<p>Payments are made easy through our various payment options.  You may pay via Credit card/Debit card,  Online Bank Transfer,  Over the Counter Bank Payment as well as Paymaya, Coins.ph and Grabpay. We accept all major Visa and Mastercard credit cards and for Online bank transfers you may transfer using any of the following online banks- BPI, BDO, Metrobank, Chinabank, RCBC, Landbank, Maybank, Unionbank, Robinsons Bank.   Over the counter payment is available for BPI and BDO banks online.</p>" +
                "<p>Unfortunately, COD and installment options are not available for now.</p>",
        },
        {
            id: 24,
            faq: "What areas do you service?",
            arrow: down,
            body: "<p>We can deliver all over the country.</p>",
        },
        {
            id: 25,
            faq: "How much are delivery/shipping fees and how long will my orders arrive?",
            arrow: down,
            body:
                "<p>Lead times and delivery/shipping fees depend on your order. Deliver fees are quoted upon check-out. Some merchants offer free delivery for a minimum purchase.</p>" +
                "<p>For lead times, your project manager will inform you of lead times and target delivery dates per item.  Please expect different delivery schedules for each item especially if you purchased items from several merchants.</p>" +
                "<p>For Metro Manila and Greater Manila Area, expect delivery within 10-14 business days.</p>" +
                "<p>For Provincial Areas and Vismin, expect a quotation within 3 business days for Shipping fees and Delivery Schedules prior to check-out and payment.</p>",
        },
        {
            id: 26,
            faq: "Do you accept returns and exchanges?",
            arrow: down,
            body:
                "<p>All products that have been paid are considered a final sale.</p>" +
                "<p>We only accept returns if the items delivered are not the same as what was ordered, are damaged,  or may have missing items which were listed in the product description. You will need to inspect each item upon delivery. You may authorize someone to check for you if you can’t be present during delivery.</p>" +
                "<p>Please refer to our returns and exchange policy for more details <a href='/terms-of-use#returnpolicy'>here</a>.</p>",
        },
        {
            id: 27,
            faq: "Do you sell furniture for customization?",
            arrow: down,
            body: "<p>Most of the items at Gussy are on-hand and as is. However, we do have a few pieces for custom orders. Custom options and lead times will be stated in the product description.</p>",
        },
        {
            id: 28,
            faq: "Is installation included?",
            arrow: down,
            body: "<p>Our merchants provide installation services for wallpaper and certain lighting fixtures only.  You will be able to check out installation inclusions on the product description.</p>",
        },
    ];

    const [edesign, setEdesign] = useState(edesignitems);
    const [shopping, setShopping] = useState(shoppingitems);

    const doesItemIncludeSearchWord = (item, e) =>
        item.faq.toLowerCase().includes(e.target.value.toLowerCase());

    const handleSearch = (e) => {
        setEdesign(
            edesignitems.filter((item) =>
                item.faq
                    .toLowerCase()
                    .includes(`${e.target.value}`.toLowerCase()),
            ),
        );
        setShopping(
            shoppingitems.filter((item) =>
                item.faq
                    .toLowerCase()
                    .includes(`${e.target.value}`.toLowerCase()),
            ),
        );
    };

    function DisplayAccordion(content) {
        return content.map((item) => (
            <Card className="card-faqs" key={item.id}>
                <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                    {item.faq}{" "}
                    <img
                        alt="up"
                        className="faq-arrow"
                        src={item.arrow}
                        width="24px"
                    />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={item.id}>
                    <Card.Body>{parse(item.body)} </Card.Body>
                </Accordion.Collapse>
            </Card>
        ));
    }

    return (
        <div>
            <div className="mb-50">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    className="search-icon"
                                    id="basic-addon1"
                                >
                                    <i className="fa fa-search icon" />{" "}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-describedby="basic-addon1"
                                aria-label="Search by keyword"
                                className="search-input"
                                placeholder="Search by keyword"
                                onChange={handleSearch}
                            />
                        </InputGroup>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <Accordion>
                            {edesign.length > 0 && (
                                <h4 className="mt-4 mb-3 petra">E-Design</h4>
                            )}
                            {DisplayAccordion(edesign)}

                            {shopping.length > 0 && (
                                <h4 className="mt-4 mb-3 petra">Shopping</h4>
                            )}
                            {DisplayAccordion(shopping)}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaqsComponent;
