/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Button } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Carousel from 'react-multi-carousel';
import chunk from 'lodash.chunk';
import 'react-multi-carousel/lib/styles.css';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import ProductThumbnail from '../../components/ShopComponents/ProductThumbnailComponent';

import { fetchBySearchTags, fetchGetThatLookByKey, fetchProductDetails } from '../../queries/shop';

import HelmetComponent from '../../components/HelmetComponent';
import LayoutComponent from '../../components/LayoutComponent';
import ShopNav from '../../components/ShopComponents/ShopNav';
import { shuffleProducts } from '../../utils/helpers';

import SkeletonElement from '../../components/SkeletonComponents/SkeletonElements';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function ShopGetThatLookPage(props) {
  const pageSize = 20;
  const { history, location, match } = props;
  const lookId = match.params.id;
  const [products, setProducts] = useState([]);
  const [productsTotal, setProductsTotal] = useState([]);
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [isDoneFetchingProducts, setIsDoneFetchingProducts] = useState(false);
  const [isDoneFetchingRelatedProducts, setIsDoneFetchingRelatedProducts] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedItemsTags, setRelatedItemsTags] = useState([]);
  const [description, setDescription] = useState('');
  const [imgUrls, setImgUrls] = useState({});
  const [name, setName] = useState('');
  const [items, setItems] = useState({});
  const [video, setVideo] = useState('');
  const [isChanged, setisChanged] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const pageNum = location.pathname.split('/')[3]
    ? parseInt(location.pathname.split('/')[3], 10)
    : 1;

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  useEffect(() => {
    getProducts();
    getRelatedProducts();
  }, [items, relatedItemsTags]);

  useEffect(() => {
    if (isDoneFetchingProducts && isDoneFetchingRelatedProducts) {
      setIsLoading(false);
      chunkProducts();
    }
  }, [isDoneFetchingProducts, isDoneFetchingRelatedProducts]);

  const setData = ({
    description: thisDescription,
    imgUrls: thisImgUrls,
    items: thisItems,
    name: thisName,
    relatedItems: thisRelatedItems,
    videoUrl: thisVideoUrl,
  }) => {
    setDescription(thisDescription);
    setImgUrls(thisImgUrls);
    setName(thisName);
    setItems(thisItems);
    setVideo(thisVideoUrl);

    const tags = thisRelatedItems.split(',').map((el) => el.trim());
    setRelatedItemsTags(tags);
  };
  const getData = async () => {
    if (location.state && location.state.data) {
      setData(location.state.data);
    } else {
      const data = await fetchGetThatLookByKey(lookId);
      setData(data);
    }
  };

  const backToShop = () => {
    history.push({
      pathname: '/shop-home',
    });
  };

  const getProducts = async () => {
    const promises = Object.keys(items).map(async (item) => {
      const prod = await fetchProductDetails(item);
      return prod;
    });

    Promise.all(promises).then((fetchedProducts) => {
      setIsDoneFetchingProducts(false);
      setProducts(fetchedProducts);
      setIsDoneFetchingProducts(true);
    });
  };

  const getRelatedProducts = async () => {
    if (relatedItemsTags.length > 0) {
      const fetched = await fetchBySearchTags(relatedItemsTags);
      shuffleProducts(fetched);
      setRelatedProducts(fetched);
      setIsDoneFetchingRelatedProducts(true);
    }
  };

  const chunkProducts = () => {
    const allProducts = products.concat(relatedProducts);
    const filteredAllProducts = removeNullProducts(allProducts);
    const chunkedProds = chunk(filteredAllProducts, pageSize);
    setProductsTotal(allProducts.length);
    setPaginatedProducts(chunkedProds);
  };

  const removeNullProducts = (productList) => {
    return productList.filter((product) => product !== null);
  };

  const getThatLookImages = Object.values(imgUrls).map((imgUrl, i) => (
    <div className="get-that-look-img-div" key={i}>
      <img src={imgUrl.imgUrl} alt={name} />
    </div>
  ));
  function playVideo() {
    setisChanged((current) => !current);
  }
  function pauseVideo() {
    setisChanged((current) => !current);
  }
  const videoDisplay = (
    <div
      className="d-flex flex-column flex-md-row align-items-center justify-content-center"
      style={{ height: '100%' }}
    >
      <ReactPlayer
        url={video}
        config={{
          youtube: {
            playerVars: { modestbranding: 1 },
          },
        }}
        height="315px"
        style={{ minWidth: '50%', maxWidth: '100%' }}
        controls
        onPlay={playVideo}
        onPause={pauseVideo}
      />
    </div>
  );
  const carouselItems = video ? [videoDisplay, ...getThatLookImages] : getThatLookImages;
  const getThatLookCarousel = (
    <Carousel
      autoPlay={isChanged}
      arrows
      draggable
      infinite
      autoPlaySpeed={5000}
      className="mb-5"
      responsive={responsive}
    >
      {carouselItems}
    </Carousel>
  );
  const renderProducts = () => {
    if (paginatedProducts.length > 0) {
      const paginatedProductList = paginatedProducts[pageNum - 1];

      return paginatedProductList
        .filter((product) => product.active)
        .map((product) => {
          return (
            <div className="col-6 col-md-6 col-lg-3" key={product.id}>
              <ProductThumbnail
                src={product.imgUrl}
                isOnSale={product.isOnSale}
                salePrice={product.salePrice}
                price={product.price}
                name={product.name}
                id={product.id}
                productTypeId={product.productType}
                categoryId={product.categoryId}
                wallpaperUnit={product.wallpaperUnit}
                isWallpaper={() => ''}
                discount={product.saleDiscount || 0}
              />
            </div>
          );
        });
    }
  };

  const renderProductsPagination = () => {
    if (isDoneFetchingProducts && isDoneFetchingRelatedProducts) {
      return (
        <>
          {renderProducts()}
          {renderPagination()}
        </>
      );
    }
  };

  const onChangePagination = (n) => {
    const element = document.getElementById('productsDiv');
    window.scrollTo(0, element.offsetTop - 80);
    history.push(`/shop-look/${lookId}/${n}`);
  };

  const renderPagination = () => {
    return (
      <div className="col-12 d-flex justify-content-center">
        <Pagination
          activePage={pageNum}
          onChange={onChangePagination}
          itemsCountPerPage={20}
          totalItemsCount={productsTotal - 1}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    );
  };

  const navlist = isLoading ? (
    <div className="shop-nav d-flex">
      <SkeletonElement type="navlist" />
    </div>
  ) : (
    <ShopNav />
  );

  const backBtn = isLoading ? (
    <SkeletonElement type="back-to-shop" />
  ) : (
    <div className="col-12">
      <Button onClick={() => backToShop()} variant="link" className="btn-back">
        <ArrowBackIosIcon style={{ fontSize: 12 }} /> Back to Shop Home
      </Button>
    </div>
  );

  const carouselDisplay = isLoading ? (
    <div
      className="react-multi-carousel-list  mb-5 container"
      style={{ maxWidth: '510px', minWidth: '50%' }}
    >
      <SkeletonElement type="video-link" />{' '}
    </div>
  ) : (
    getThatLookCarousel
  );

  const contentCarousel = isLoading ? (
    <>
      <div align="center" className="mt-20 ">
        <SkeletonElement type="h4-size" />
      </div>

      <div>
        <SkeletonElement type="text-paragraph" />
        <SkeletonElement type="text-paragraph" />
        <SkeletonElement type="text-paragraph" />
      </div>
    </>
  ) : (
    <>
      {' '}
      <h2>{name}</h2>
      <p style={{ whiteSpace: 'pre-wrap' }}>{description}</p>
    </>
  );

  const productTitle = isLoading ? (
    <div className="mt-20">
      {' '}
      <SkeletonElement type="etc" /> <SkeletonElement type="etc" /> <SkeletonElement type="etc" />
    </div>
  ) : (
    <h3>Products</h3>
  );

  return (
    <LayoutComponent>
      <HelmetComponent
        title="Shop | Get that Look"
        description="We have partnered with a wide array of global and local brands to give you the convenience of online home shopping."
      />
      {navlist}
      <div className="mt-2 pt-5">
        <div className="container">
          {backBtn}
          {carouselDisplay}
          <div className="p-3 text-center w-100 d-flex justify-content-center">
            <div className="get-that-look-desc">{contentCarousel}</div>
          </div>
          <div className="mt-3" id="productsDiv">
            {productTitle}
            <div className="row pl-0 pl-md-4">{renderProductsPagination()}</div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
}

export default ShopGetThatLookPage;
