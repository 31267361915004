import React from 'react';

import './picture.scss';

const Picture = (props) => {
  // eslint-disable-next-line react/prop-types
  const { images, alt, classNames } = props; // receives object of images with different image formats, check constants > imgFormats for formats

  // eslint-disable-next-line react/prop-types
  const { avif, webp, jpg } = images;
  return (
    <picture>
      {avif && <source srcSet={`${avif}`} type="image/avif" />}
      {webp && <source srcSet={webp} type="image/webp" />}
      <img alt={alt} className={classNames} src={jpg} />
    </picture>
  );
};

export default Picture;
