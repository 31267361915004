import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Fade from 'react-reveal/Fade';

import sqPackage1 from '../../assets/img/pricing/package-1.png';
import sqPackage2 from '../../assets/img/pricing/package-2.png';
import sqPackage3 from '../../assets/img/pricing/package-3.png';
import sqPackage4 from '../../assets/img/pricing/package-4.png';
import sqPackage5 from '../../assets/img/pricing/package-5.png';
import FooterComponent from '../../components/FooterComponent';
import HelmetComponent from '../../components/HelmetComponent';
import NavbarLPComponent from '../../components/LandingPage/NavbarLPComponent';
import PricingPackageComponent from '../../components/PricingPackageComponent';
import ReadyComponent from '../../components/ReadyComponent';
import { ADDITIONAL_FRILL_LIST } from '../../config/content';
import { cloudinaryImagesFromAssets } from '../../utils/helpers';

import AdditionalFrill from './components/AdditionalFrill';

import './pricingpage.scss';

function PricingPage() {
  const additionalFrills = ADDITIONAL_FRILL_LIST.map((frill) => {
    return <AdditionalFrill frill={frill} key={frill.title} />;
  });

  return (
    <>
      <HelmetComponent
        description="Transform your space in just a few clicks. Gussy provides new ways for you to collaborate with designers through a purely online process."
        title="Pricing &amp; Packages"
      />
      <Fade cascade>
        <NavbarLPComponent />
        <CookieConsent
          buttonStyle={{
            background: '#d1755b',
            padding: '5px 20px',
            color: '#ffffff',
            fontSize: '13px',
          }}
          buttonText="I Agree"
          location="bottom"
          style={{ background: '#33332B' }}
        >
          This website uses cookies to enhance the user experience.{' '}
          <a className="cookies-link" href="/terms-of-use#cookies">
            Learn More.
          </a>
        </CookieConsent>
        <div className="mt-64 pt-5 container">
          <div className="row text-center justify-content-center">
            <div className="col-md-10">
              <h2 className="page-title mb-3">Pricing &amp; Packages</h2>
              <p className="body-1-reg">
                <strong>Big</strong> and <strong>small</strong> spaces can be gussied up. Gussy will
                assign you a dedicated designer and project manager to help you turn your vision
                into reality. Get a concept board or styling advice depending on how complex your
                project is. Then, purchase your items based on a curated shopping list.
              </p>
            </div>
          </div>
        </div>
        <PricingPackageComponent isLandingPage={false} />
      </Fade>
      <div className="container pricing-packages">
        <div className="row text-center">
          <div className="col-12">
            <h2 className="mb-50">Every package includes:</h2>
          </div>
        </div>
        <div className="row full-bg-lightpetra align-items-center mb-3 mb-md-2 mb-lg-0 packages-cards">
          <div className="col-xs-12 text-center col-md-4 offset-md-1 sm-order-1">
            <img
              alt="Dream Team"
              className="mb-4 mb-md-0"
              src={cloudinaryImagesFromAssets(sqPackage1)}
              width="100%"
            />
          </div>
          <div className="col-md-6 text-center text-sm-left sm-order-2">
            <h3 className="petra-font mb-5">Access to a Dream Team</h3>
            <p className="f16 body-1-reg mb-4">
              We do not use bots or algorithms - your home will be handled by trained professionals,
              stylists, and known licensed designers who’ve been in the industry for more than a
              decade.
            </p>
          </div>
        </div>
        <div className="row align-items-center mb-3 mb-md-4 mb-lg-0 packages-section packages-cards">
          <div className="col-md-6 offset-md-1 text-center text-sm-right sm-order-2">
            <h3 className="petra-font mb-5">A Personalized Design</h3>
            <p className="f16 body-1-reg mb-4">
              Your home should be a reflection of your unique taste and personality. We’re here to
              recreate your personality into your living spaces.
            </p>
          </div>
          <div className="col-xs-12 text-center col-md-4 offset-md-1 sm-order-1">
            <img
              alt="Personalized Design"
              className="mb-4 mb-md-0"
              src={cloudinaryImagesFromAssets(sqPackage2)}
              width="100%"
            />
          </div>
        </div>
        <div className="row right-bg-lightpetra align-items-center mb-3 mb-md-2 mb-lg-0 packages-cards">
          <div className="col-xs-12 text-center col-md-4 offset-md-1 sm-order-1">
            <img
              alt="Easy Ordering Process"
              className="mb-4 mb-md-0"
              src={cloudinaryImagesFromAssets(sqPackage3)}
              width="100%"
            />
          </div>
          <div className="col-md-6 text-center text-sm-left sm-order-2">
            <h3 className="petra-font mb-5">An Easy Ordering Process</h3>
            <p className="f16 body-1-reg mb-4">
              We’ve partnered with brands all over the country to give you well-curated furniture
              and furnishings you can conveniently shop direct from the shopping list provided.
            </p>
          </div>
        </div>
        <div className="row align-items-center mb-3 mb-md-2 mb-lg-0 packages-section packages-cards">
          <div className="col-md-6 offset-md-1 text-center text-sm-right sm-order-2">
            <h3 className="petra-font mb-5">Purchase Anytime</h3>
            <p className="f16 body-1-reg mb-4">
              Not ready to shop but just need the design ready? No problem! Your final designs will
              be saved and you can access your account anytime you wish to start purchasing your
              designer’s recommendations.
            </p>
          </div>
          <div className="col-xs-12 text-center col-md-4 offset-md-1 sm-order-1">
            <img
              alt="Purchase Anytime"
              className="mb-4 mb-md-0"
              src={cloudinaryImagesFromAssets(sqPackage4)}
              width="100%"
            />
          </div>
        </div>
        <div className="row left-bg-lightpetra align-items-center mb-3 mb-md-2 mb-lg-0 packages-cards">
          <div className="col-xs-12 text-center col-md-4 offset-md-1 sm-order-1">
            <img
              alt="Friendly Support Team"
              className="mb-4 mb-md-0"
              src={cloudinaryImagesFromAssets(sqPackage5)}
              width="100%"
            />
          </div>
          <div className="col-md-6 text-center text-sm-left sm-order-2">
            <h3 className="petra-font mb-5">Our Friendly Support Team</h3>
            <p className="f16 body-1-reg mb-4">
              Do you have any concerns or questions? Just send us an email and our project managers
              will make sure you are well taken care of.
            </p>
          </div>
        </div>
      </div>
      <Fade cascade>
        <section className="bg-gray9 py-5">
          <div className="container">
            <div className="row text-center">
              <div className="col-12 pt-md-3">
                <h2>Additional Frills:</h2>
              </div>
            </div>
          </div>
          {additionalFrills}
          <div className="container text-center mb-md-2 pt-4">
            <div className="row">
              <span className="italic-callout col-md-12 text-center mb-30">
                *Living outside Metro Manila? Send us a message for a quotation.
              </span>
              <div className="col-md-12 text-center body-1-reg">
                For more information, <br />
                <a className="brand-orange f-semibold" href="/contact-us">
                  Contact Us
                </a>{' '}
                or view our{' '}
                <a className="brand-orange f-semibold" href="/faqs">
                  FAQs
                </a>
              </div>
            </div>
          </div>
        </section>
        <ReadyComponent />
        <FooterComponent />
      </Fade>
    </>
  );
}

export default PricingPage;
