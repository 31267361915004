import React from 'react';
import { Button } from 'react-bootstrap';

function FooterSQComponent(props) {
  // eslint-disable-next-line react/prop-types
  const { next, disabled } = props;

  return (
    <div className="container container-wide text-center">
      <div className="mt-100 mb-100 d-flex bd-highlight justify-content-end">
        <div className="bd-highlight">
          <Button className="btn-dark p-13-55 btntext-13-sb" disabled={disabled} onClick={next}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FooterSQComponent;
