import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import queryString from 'query-string';

import NavbarLPComponent from '../../../components/LandingPage/NavbarLPComponent';
import FooterComponent from '../../../components/FooterComponent';
import { clearCart, fetchPayment } from '../../../queries/shop';
import { CartCountContext } from '../../../context';
import { functionsFirebase } from '../../../components/Firebase';
import { httpsCallable } from 'firebase/functions';
import { GUSSY_REDIRECT_URL } from '../../../config/constants';

import './../successpage.scss';

function SuccessCheckoutPage(props) {
  const [display, setDisplay] = useState();
  const [showContinue, setShowContinue] = useState(false);
  const { location } = props;
  const params = queryString.parse(location.search);
  const cartCountContext = useContext(CartCountContext);

  useEffect(() => {
    init();
    window.scrollTo(0, 0);
  }, []);

  const init = async () => {
    if (params.quoted) {
      clearCart(params.uid);
      cartCountContext.setCount(0);
      const tmp = quoted(params.order_id);
      setDisplay(tmp);
      return;
    }
    if (params.provider === 'PAYMAYA') {
      if (params.type === 'PROJECT_PURCHASE_SHOP') {
        const userData = await httpsCallable(functionsFirebase, 'fetchUser')({ id: params.uid });
        if (!userData.stylequiz && !userData.hasProject) {
          setDisplay(newProjectPurchased);
          setShowContinue(true);
        } else if (userData.stylequiz) {
          const token = await httpsCallable(
            functionsFirebase,
            'getSignInToken',
          )({
            uid: params.uid,
          });
          window.location.href = `${GUSSY_REDIRECT_URL}/client-projects.html?token=${token.data}`;
        }
      } else if (params.type === 'PRODUCT_PURCHASE_SHOP') {
        setShowContinue(true);
        const payment = await fetchPayment(params.trans_id);
        if (payment) {
          clearCart(params.uid);
          cartCountContext.setCount(0);
          const layout = shopPurchased(payment.orderRefNo);
          setDisplay(layout);
        }
      }
    } else if (params.provider === 'DRAGONPAY') {
      if (params.type === 'PROJECT_PURCHASE_SHOP') {
        const payment = await fetchPayment(params.trans_id);
        const userData = await httpsCallable(
          functionsFirebase,
          'fetchUser',
        )({
          id: payment.clientId,
        });

        if (!userData.stylequiz && !userData.hasProject) {
          setDisplay(newProjectPurchased);
          setShowContinue(true);
        } else if (userData.stylequiz) {
          const token = await httpsCallable(
            functionsFirebase,
            'getSignInToken',
          )({
            uid: payment.clientId,
          });
          window.location.href = `${GUSSY_REDIRECT_URL}/client-projects.html?token=${token.data}`;
        }
      } else if (params.type === 'PRODUCT_PURCHASE_SHOP') {
        setShowContinue(true);
        clearCart(params.uid);
        cartCountContext.setCount(0);
        const layout = shopPurchased(params.order_id);
        setDisplay(layout);
      }
    }
  };
  const newProjectPurchased = (
    <>
      <p>We've sent you an email to verify your email address</p>
    </>
  );

  const shopPurchased = (orderId) => (
    <>
      <p>
        Your order # is: <strong>{orderId}</strong>
      </p>
      <p>We’ll email you an order confirmation with details of your purchase.</p>
    </>
  );

  const quoted = (orderId) => (
    <>
      <p>
        Your order # is: <strong>{orderId}</strong>
      </p>
      <p>
        Please expect an email within 2-3 business days for the quotation of your items with its
        corresponding shipping fees including a link on how to settle payment. Thank you!
      </p>
    </>
  );
  return (
    <div>
      <NavbarLPComponent />
      <div className="mt-100 p-5 text-center success-page">
        <div className="container">
          <Row className="flex-column align-items-center">
            <Col lg={6}>
              <h3 className="mb-3">
                {params.quoted ? 'Order Received!' : 'Thank you for your purchase!'}
              </h3>
            </Col>
            <Col lg={4}>{display}</Col>

            {showContinue && (
              <Link
                to="/shop"
                variant="primary"
                className="btn-petra btntext-13-sb p-13-55 mt-2 btn btn-primary"
              >
                Continue Shopping
              </Link>
            )}
          </Row>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default SuccessCheckoutPage;
