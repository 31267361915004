import React, { useState, useEffect, Fragment } from 'react';
import ReactMarkdown from 'react-markdown';

import { fetchHomePage } from '../../../queries/contentfulCMS';
import './stepslpcomponent.scss';

function StepsLPComponent() {
  const [homePage, setHomePage] = useState([]);

  const getHomePage = async () => {
    const data = await fetchHomePage();
    setHomePage(data);
  };

  useEffect(() => {
    getHomePage();
  }, []);

  const renderColumn = (columnNumber, fileURL, title, description) => (
    <div className="col-lg-3 col-md-6 mb-40">
      <img alt="design quiz" className="w-100 mb-30" src={fileURL} />
      <h3 className="petra f-bodoni">{columnNumber}.</h3>
      <p className="body-1-sb mb-5">{title}</p>
      <p className="body-1-reg">{description}</p>
    </div>
  );

  return homePage.map((val) => {
    const {
      stepsLogo,
      stepsText,
      step1,
      step1Title,
      step1Description,
      step2,
      step2Title,
      step2Description,
      step3,
      step3Title,
      step3Description,
      step4,
      step4Title,
      step4Description,
      featuredPhoto,
      featuredText,
    } = val;
    return (
      <Fragment key={stepsText}>
        <div className="container-fluid bg-gray9">
          <h2 className="f-bold steps-header pt-5">
            <img alt="logo" className="landing-logo" src={stepsLogo.file.url} />
            {stepsText}
          </h2>
          <div className="row mt-40">
            {renderColumn('1', step1.file.url, step1Title, step1Description)}
            {renderColumn('2', step2.file.url, step2Title, step2Description)}
            {renderColumn('3', step3.file.url, step3Title, step3Description)}
            {renderColumn('4', step4.file.url, step4Title, step4Description)}
          </div>
        </div>
        <div className="container-fluid">
          <div className="row mb-5 d-flex align-items-center">
            <div className="col-lg-6 pl-0 pr-0">
              <img alt="banner" className="smmt-20" src={featuredPhoto.file.url} width="100%" />
            </div>
            <div className="col-lg-4 col-md-10 offset-lg-1 text-center m-auto">
              <h2 className="f-cormorant f-medium smmt-70 mt-4">
                <span>
                  <ReactMarkdown>{featuredText}</ReactMarkdown>
                </span>
              </h2>
            </div>
          </div>
        </div>
      </Fragment>
    );
  });
}

export default StepsLPComponent;
