import { functionsFirebase } from '../components/Firebase';
import { httpsCallable } from 'firebase/functions';
import {
  PAYMAYA_SUCCESS_REDIRECT_URL,
  PAYMAYA_FALIURE_REDIRECT_URL,
  PAYMAYA_CANCEL_REDIRECT_URL,
} from '../config/constants';

export const checkoutDragonPay = (
  txnid,
  amount,
  ccy,
  description,
  email,
  param1 = '',
  param2 = '',
) => {
  var getDragonPayKeys = httpsCallable(functionsFirebase, 'getDragonPayKeys');
  getDragonPayKeys({
    txnid,
    amount,
    ccy,
    description,
    email,
  }).then((result) => {
    const { digest, id } = result.data;
    const dragonpayURL = new URL(process.env.REACT_APP_DRAGONPAY_URL);

    dragonpayURL.search = new URLSearchParams({
      merchantid: id,
      txnid,
      amount,
      ccy,
      description,
      email,
      param1,
      param2,
      digest,
    });

    window.location.href = dragonpayURL.toString();
  });
};

export const checkoutPaymaya = (
  items,
  txnid,
  amount,
  deliveryFee,
  totalAmount,
  type,
  dbRefKey,
  uid,
  discount = 0,
) => {
  const checkoutObject = {
    totalAmount: {
      value: totalAmount,
      currency: 'PHP',
      details: {
        discount,
        shippingFee: deliveryFee,
        subtotal: amount,
      },
    },
    items,
    redirectUrl: {
      success: `${PAYMAYA_SUCCESS_REDIRECT_URL}&db_ref_key=${dbRefKey}&type=${type}&trans_id=${txnid}&uid=${uid}&provider=PAYMAYA`,
      failure: `${PAYMAYA_FALIURE_REDIRECT_URL}&db_ref_key=${dbRefKey}&type=${type}&trans_id=${txnid}&uid=${uid}&provider=PAYMAYA`,
      cancel: `${PAYMAYA_CANCEL_REDIRECT_URL}&db_ref_key=${dbRefKey}&type=${type}&trans_id=${txnid}&uid=${uid}&provider=PAYMAYA`,
    },
    requestReferenceNumber: txnid,
    metadata: {
      uid,
      dbRefKey,
      type,
      gussy: true,
    },
  };
  httpsCallable(
    functionsFirebase,
    'getPaymayaUrl',
  )({ test: true, checkoutObject }).then((res) => {
    window.location.href = res.data.redirectUrl;
  });
};
