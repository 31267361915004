import React from 'react';
import CookieConsent from 'react-cookie-consent';

import FooterComponent from '../FooterComponent';
import NavbarLPComponent from '../LandingPage/NavbarLPComponent';

// eslint-disable-next-line react/prop-types
function LayoutComponent({ children }) {
  return (
    <>
      <NavbarLPComponent />
      <CookieConsent
        buttonStyle={{
          background: '#d1755b',
          padding: '5px 20px',
          color: '#ffffff',
          fontSize: '13px',
        }}
        buttonText="I Agree"
        location="bottom"
        style={{ background: '#33332B' }}
      >
        This website uses cookies to enhance the user experience.{' '}
        <a className="cookies-link" href="/terms-of-use#cookies">
          Learn More.
        </a>
      </CookieConsent>
      {children}
      <FooterComponent />
    </>
  );
}

export default LayoutComponent;
