import React from 'react';

import FooterComponent from '../../components/FooterComponent';
import NavbarLPComponent from '../../components/LandingPage/NavbarLPComponent';

import './notfoundpage.scss';

function AboutPage() {
  return (
    <div>
      <NavbarLPComponent />
      <div className="mt-64 p-5 text-center not-found">
        <h1>Fiddlesticks.</h1>
        <h6>
          We can&apos;t seem to find the page you&apos;re looking for. Let’s get you back on track:
        </h6>
      </div>
      <FooterComponent />
    </div>
  );
}

export default AboutPage;
