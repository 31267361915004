export const PAYAMAYA_PUBLIC_KEY = 'pk-NCLk7JeDbX1m22ZRMDYO9bEPowNWT5J4aNIKIbcTy2a';

// checking typeof window is needed to run sitemap-generator.js
export const PAYMAYA_SUCCESS_REDIRECT_URL =
  typeof window !== 'undefined' ? `${window.location.origin}/success-checkout?status=S` : '';
export const PAYMAYA_FALIURE_REDIRECT_URL =
  typeof window !== 'undefined' ? `${window.location.origin}/failed-payment?status=F` : '';
export const PAYMAYA_CANCEL_REDIRECT_URL =
  typeof window !== 'undefined' ? `${window.location.origin}/cancelled-payment?status=C` : '';

export const GUSSY_REDIRECT_URL = process.env.REACT_APP_PROD_URL;

export const GUSSY_NETLIFY_URL = 'https://gussy-website.netlify.app';

export const PROJECT_MATCHING = 'matching';
export const PROJECT_PURCHASING = 'purchasing';
export const PROJECT_DOING = 'doing';
export const PROJECT_FOR_REVIEW = 'for review';
export const PROJECT_SENT_TO_CLIENT = 'sent to client';
export const PROJECT_DONE = 'done';
export const PROJECT_DESIGN = 'design';
export const PROJECT_CONSULTATION = 'consultation';
export const PROJECT_PENDING = 'payment pending';
export const WALLPAPER_IDS = ['-MA475wOY1pFyXriNSXg', '-MF-7oTTiX2G43h3iaaC'];

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

export const ACTIVE_CAPITALIZED = 'Active';

// Sort By Filters
export const DEFAULT = 'DEFAULT';
export const L_TO_H = 'L_TO_H';
export const H_TO_L = 'H-TO-L';
export const SALE = 'SALE';
export const NEW = 'NEW';

export const FILTERS = {
  PRODUCT_TYPE: {},
  PRICE: {},
  WIDTH: {},
  DEPTH: {},
  HEIGHT: {},
  COLOR: {},
  SALE: false,
  STYLE: {},
  MERCHANT: {},
  NEW: false,
  SEARCH: '',
  CATEGORY_ID: '',
  PRODUCT_TYPE_ID: '',
};

export const LOGIN_PROVIDER = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
};

export const URL_NAV_PARAMS = {
  PAGE: 'page_no',
  SORT: 'sort',
  LIMIT: 'limit',
  SEARCH: 'search',
  BRAND: 'brand',
  COLOR: 'color',
  STYLE: 'style',
  WIDTH_MIN: 'width_min',
  WIDTH_MAX: 'width_max',
  HEIGHT_MIN: 'height_min',
  HEIGHT_MAX: 'height_max',
  DEPTH_MIN: 'depth_min',
  DEPTH_MAX: 'depth_max',
  PRICE_MIN: 'price_min',
  PRICE_MAX: 'price_max',
};

export const COLOR_FILTERS = [
  'beige',
  'black',
  'blue',
  'brown',
  'gold',
  'green',
  'grey',
  'navy',
  'orange',
  'pink',
  'purple',
  'red',
  'yellow',
  'silver',
  'white',
  'others',
];

export const STYLE_FILTERS = ['boho', 'bold', 'minimalist', 'modern', 'rustic', 'transitional'];

export const PACKAGES = {
  'PACKAGE-ADVICE': {
    price: 7999,
    id: 'PACKAGE-ADVICE',
    name: 'Gussied Style Advice',
  },
  'PACKAGE-ESSENTIALS': {
    price: 19999,
    id: 'PACKAGE-ESSENTIALS',
    name: 'Gussied Style Essentials',
  },
  'PACKAGE-PREMIUM': {
    price: 34999,
    id: 'PACKAGE-PREMIUM',
    name: 'Gussied Style Premium',
  },
};

export const REGIONS = {
  'PH-00': { name: 'NCR', fullname: 'National Capital Region' },
  'PH-01': { name: 'Region I', fullname: 'Ilocos' },
  'PH-02': { name: 'Region II', fullname: 'Cagayan Valley' },
  'PH-03': { name: 'Region III', fullname: 'Central Luzon' },
  'PH-40': { name: 'Region IV-A', fullname: 'Calabarzon' },
  'PH-41': { name: 'Region IV-B', fullname: 'Mimaropa' },
  'PH-05': { name: 'Region V', fullname: 'Bicol' },
  'PH-06': { name: 'Region VI', fullname: 'Western Visayas' },
  'PH-07': { name: 'Region VII', fullname: 'Central Visayas' },
  'PH-08': { name: 'Region VII', fullname: 'Eastern Visayas' },
  'PH-09': { name: 'Region IX', fullname: 'Zamboanga Peninsula' },
  'PH-10': { name: 'Region X', fullname: 'Northern Mindanao' },
  'PH-11': { name: 'Region XI', fullname: 'Davao' },
  'PH-12': { name: 'Region XII', fullname: 'Soccsksargen' },
  'PH-13': { name: 'Region XIII', fullname: 'Caraga' },
  'PH-14': { name: 'ARMM', fullname: 'Autonomous Region in Muslim Mindanao' },
  'PH-15': { name: 'CAR', fullname: 'Cordillera Administrative Region' },
};

export const PROVINCES = {
  'PH-00-A': {
    name: 'Metro Manila - Caloocan',
    regionID: 'PH-00',
  },
  'PH-00-B': {
    name: 'Metro Manila - Las Pinas',
    regionID: 'PH-00',
  },
  'PH-00-C': {
    name: 'Metro Manila - Makati',
    regionID: 'PH-00',
  },
  'PH-00-D': {
    name: 'Metro Manila - Malabon',
    regionID: 'PH-00',
  },
  'PH-00-E': {
    name: 'Metro Manila - Mandaluyong',
    regionID: 'PH-00',
  },
  'PH-00-F': {
    name: 'Metro Manila - Manila',
    regionID: 'PH-00',
  },
  'PH-00-G': {
    name: 'Metro Manila - Marikina',
    regionID: 'PH-00',
  },
  'PH-00-H': {
    name: 'Metro Manila - Muntinlupa',
    regionID: 'PH-00',
  },
  'PH-00-I': {
    name: 'Metro Manila - Navotas',
    regionID: 'PH-00',
  },
  'PH-00-J': {
    name: 'Metro Manila - Paranaque',
    regionID: 'PH-00',
  },
  'PH-00-K': {
    name: 'Metro Manila - Pasay',
    regionID: 'PH-00',
  },
  'PH-00-L': {
    name: 'Metro Manila - Pasig',
    regionID: 'PH-00',
  },
  'PH-00-M': {
    name: 'Metro Manila - Quezon City',
    regionID: 'PH-00',
  },
  'PH-00-N': {
    name: 'Metro Manila - San Juan',
    regionID: 'PH-00',
  },
  'PH-00-O': {
    name: 'Metro Manila - Taguig',
    regionID: 'PH-00',
  },
  'PH-00-P': {
    name: 'Metro Manila - Valenzuela',
    regionID: 'PH-00',
  },
  'PH-13-A': {
    name: 'Agusan del Norte',
    regionID: 'PH-13',
  },
  'PH-13-B': {
    name: 'Agusan del Sur',
    regionID: 'PH-13',
  },
  'PH-05-A': {
    name: 'Albay',
    regionID: 'PH-05',
  },
  'PH-09-A': {
    name: 'Basilan',
    regionID: 'PH-09',
  },
  'PH-03-A': {
    name: 'Bataan',
    regionID: 'PH-03',
  },
  'PH-40-A': {
    name: 'Batangas',
    regionID: 'PH-40',
  },
  'PH-15-A': {
    name: 'Benguet',
    regionID: 'PH-15',
  },
  'PH-07-A': {
    name: 'Bohol',
    regionID: 'PH-07',
  },
  'PH-10-A': {
    name: 'Bukidnon',
    regionID: 'PH-10',
  },
  'PH-03-B': {
    name: 'Bulacan',
    regionID: 'PH-03',
  },
  'PH-02-A': {
    name: 'Cagayan',
    regionID: 'PH-02',
  },
  'PH-05-B': {
    name: 'Camarines Sur',
    regionID: 'PH-05',
  },
  'PH-06-A': {
    name: 'Capiz',
    regionID: 'PH-06',
  },
  'PH-40-B': {
    name: 'Cavite',
    regionID: 'PH-40',
  },
  'PH-07-B': {
    name: 'Cebu',
    regionID: 'PH-07',
  },
  'PH-12-A': {
    name: 'Cotabato (North)',
    regionID: 'PH-12',
  },
  'PH-11-A': {
    name: 'Davao del Norte',
    regionID: 'PH-11',
  },
  'PH-11-B': {
    name: 'Davao del Sur',
    regionID: 'PH-11',
  },
  'PH-11-C': {
    name: 'Davao Oriental',
    regionID: 'PH-11',
  },
  'PH-08-A': {
    name: 'Eastern Samar',
    regionID: 'PH-08',
  },
  'PH-01-A': {
    name: 'Ilocos Norte',
    regionID: 'PH-01',
  },
  'PH-01-B': {
    name: 'Ilocos Sur',
    regionID: 'PH-01',
  },
  'PH-06-B': {
    name: 'Iloilo',
    regionID: 'PH-06',
  },
  'PH-02-B': {
    name: 'Isabela',
    regionID: 'PH-02',
  },
  'PH-15-B': {
    name: 'Kalinga',
    regionID: 'PH-15',
  },
  'PH-01-C': {
    name: 'La Union',
    regionID: 'PH-01',
  },
  'PH-40-C': {
    name: 'Laguna',
    regionID: 'PH-40',
  },
  'PH-10-B': {
    name: 'Lanao del Norte',
    regionID: 'PH-10',
  },
  'PH-14-A': {
    name: 'Lanao del Sur',
    regionID: 'PH-14',
  },
  'PH-08-B': {
    name: 'Leyte',
    regionID: 'PH-08',
  },
  'PH-12-B': {
    name: 'Maguindanao',
    regionID: 'PH-12',
  },
  'PH-05-C': {
    name: 'Masbate',
    regionID: 'PH-05',
  },
  'PH-10-C': {
    name: 'Misamis Occidental',
    regionID: 'PH-10',
  },
  'PH-10-D': {
    name: 'Misamis Oriental',
    regionID: 'PH-10',
  },
  'PH-06-C': {
    name: 'Negros Occidental',
    regionID: 'PH-06',
  },
  'PH-07-C': {
    name: 'Negros Oriental',
    regionID: 'PH-07',
  },
  'PH-03-C': {
    name: 'Nueva Ecija',
    regionID: 'PH-03',
  },
  'PH-41-A': {
    name: 'Oriental Mindoro',
    regionID: 'PH-41',
  },
  'PH-41-B': {
    name: 'Palawan',
    regionID: 'PH-41',
  },
  'PH-03-D': {
    name: 'Pampanga',
    regionID: 'PH-03',
  },
  'PH-01-D': {
    name: 'Pangasinan',
    regionID: 'PH-01',
  },
  'PH-40-D': {
    name: 'Quezon',
    regionID: 'PH-40',
  },
  'PH-40-E': {
    name: 'Rizal',
    regionID: 'PH-40',
  },
  'PH-08-C': {
    name: 'Samar (Western)',
    regionID: 'PH-08',
  },
  'PH-05-D': {
    name: 'Sorsogon',
    regionID: 'PH-05',
  },
  'PH-12-C': {
    name: 'South Cotabato',
    regionID: 'PH-12',
  },
  'PH-08-D': {
    name: 'Southern Leyte',
    regionID: 'PH-08',
  },
  'PH-12-D': {
    name: 'Sultan Kudarat',
    regionID: 'PH-12',
  },
  'PH-13-C': {
    name: 'Surigao del Norte',
    regionID: 'PH-13',
  },
  'PH-13-D': {
    name: 'Surigao del Sur',
    regionID: 'PH-13',
  },
  'PH-03-E': {
    name: 'Tarlac',
    regionID: 'PH-03',
  },
  'PH-03-F': {
    name: 'Zambales',
    regionID: 'PH-03',
  },
  'PH-09-B': {
    name: 'Zamboanga del Norte',
    regionID: 'PH-09',
  },
  'PH-09-C': {
    name: 'Zamboanga del Sur',
    regionID: 'PH-09',
  },
  'PH-15-C': {
    name: 'Abra',
    regionID: 'PH-15',
  },
  'PH-15-D': {
    name: 'Apayao',
    regionID: 'PH-15',
  },
  'PH-15-E': {
    name: 'Ifugao',
    regionID: 'PH-15',
  },
  'PH-15-F': {
    name: 'Mountain Province',
    regionID: 'PH-15',
  },
  'PH-02-C': {
    name: 'Batanes',
    regionID: 'PH-02',
  },
  'PH-02-D': {
    name: 'Nueva Vizcaya',
    regionID: 'PH-02',
  },
  'PH-02-E': {
    name: 'Quirino',
    regionID: 'PH-02',
  },
  'PH-03-G': {
    name: 'Aurora',
    regionID: 'PH-03',
  },
  'PH-41-C': {
    name: 'Marinduque',
    regionID: 'PH-41',
  },
  'PH-41-D': {
    name: 'Occidental Mindoro',
    regionID: 'PH-41',
  },
  'PH-41-E': {
    name: 'Romblon',
    regionID: 'PH-41',
  },
  'PH-05-E': {
    name: 'Camarines Norte',
    regionID: 'PH-05',
  },
  'PH-05-F': {
    name: 'Catanduanes',
    regionID: 'PH-05',
  },
  'PH-06-D': {
    name: 'Aklan',
    regionID: 'PH-06',
  },
  'PH-06-E': {
    name: 'Antique',
    regionID: 'PH-06',
  },
  'PH-06-F': {
    name: 'Guimaras',
    regionID: 'PH-06',
  },
  'PH-07-D': {
    name: 'Siquijor',
    regionID: 'PH-07',
  },
  'PH-08-E': {
    name: 'Biliran',
    regionID: 'PH-08',
  },
  'PH-08-F': {
    name: 'Northern Samar',
    regionID: 'PH-08',
  },
  'PH-08-G': {
    name: 'Samar',
    regionID: 'PH-08',
  },
  'PH-09-D': {
    name: 'Zamboanga Sibugay',
    regionID: 'PH-09',
  },
  'PH-10-E': {
    name: 'Camiguin',
    regionID: 'PH-10',
  },
  'PH-11-D': {
    name: 'Compostela Valley',
    regionID: 'PH-11',
  },
  'PH-11-E': {
    name: 'Davao Occidental',
    regionID: 'PH-11',
  },
  'PH-12-E': {
    name: 'Cotabato',
    regionID: 'PH-12',
  },
  'PH-12-F': {
    name: 'Sarangani',
    regionID: 'PH-12',
  },
  'PH-13-E': {
    name: 'Dinagat Islands',
    regionID: 'PH-13',
  },
  'PH-14-B': {
    name: 'Sulu',
    regionID: 'PH-14',
  },
  'PH-14-C': {
    name: 'Tawi-tawi',
    regionID: 'PH-14',
  },
};
export const CITIES = {
  'PH-00-A-ZAP': {
    name: 'Caloocan',
    provinceID: 'PH-00-A',
    regionID: 'PH-00',
  },
  'PH-00-B-LPQ': {
    name: 'Las Pinas',
    provinceID: 'PH-00-B',
    regionID: 'PH-00',
  },
  'PH-00-C-UXD': {
    name: 'Makati',
    provinceID: 'PH-00-C',
    regionID: 'PH-00',
  },
  'PH-00-D-PQS': {
    name: 'Malabon',
    provinceID: 'PH-00-D',
    regionID: 'PH-00',
  },
  'PH-00-E-UHO': {
    name: 'Mandaluyong',
    provinceID: 'PH-00-E',
    regionID: 'PH-00',
  },
  'PH-00-F-PBE': {
    name: 'Manila',
    provinceID: 'PH-00-F',
    regionID: 'PH-00',
  },
  'PH-00-G-UWR': {
    name: 'Marikina',
    provinceID: 'PH-00-G',
    regionID: 'PH-00',
  },
  'PH-00-H-NJU': {
    name: 'Muntinlupa',
    provinceID: 'PH-00-H',
    regionID: 'PH-00',
  },
  'PH-00-I-XOV': {
    name: 'Navotas',
    provinceID: 'PH-00-I',
    regionID: 'PH-00',
  },
  'PH-00-J-MCW': {
    name: 'Paranaque',
    provinceID: 'PH-00-J',
    regionID: 'PH-00',
  },
  'PH-00-K-SWJ': {
    name: 'Pasay',
    provinceID: 'PH-00-K',
    regionID: 'PH-00',
  },
  'PH-00-L-VTL': {
    name: 'Pasig',
    provinceID: 'PH-00-L',
    regionID: 'PH-00',
  },
  'PH-00-M-UKJ': {
    name: 'Quezon City',
    provinceID: 'PH-00-M',
    regionID: 'PH-00',
  },
  'PH-00-N-AHN': {
    name: 'San Juan',
    provinceID: 'PH-00-N',
    regionID: 'PH-00',
  },
  'PH-00-O-SIX': {
    name: 'Taguig',
    provinceID: 'PH-00-O',
    regionID: 'PH-00',
  },
  'PH-00-P-BBY': {
    name: 'Valenzuela',
    provinceID: 'PH-00-P',
    regionID: 'PH-00',
  },
  'PH-13-B-NWJ': {
    name: 'Butuan',
    provinceID: 'PH-13-B',
    regionID: 'PH-13',
  },
  'PH-13-B-VII': {
    name: 'Cabadbaran',
    provinceID: 'PH-13-B',
    regionID: 'PH-13',
  },
  'PH-13-B-GCG': {
    name: 'Bayugan',
    provinceID: 'PH-13-B',
    regionID: 'PH-13',
  },
  'PH-05-A-QSW': {
    name: 'Legazpi',
    provinceID: 'PH-05-A',
    regionID: 'PH-05',
  },
  'PH-05-A-XNU': {
    name: 'Ligao',
    provinceID: 'PH-05-A',
    regionID: 'PH-05',
  },
  'PH-05-A-PEZ': {
    name: 'Tabaco',
    provinceID: 'PH-05-A',
    regionID: 'PH-05',
  },
  'PH-09-A-LRR': {
    name: 'Isabela',
    provinceID: 'PH-09-A',
    regionID: 'PH-09',
  },
  'PH-09-A-NBP': {
    name: 'Lamitan',
    provinceID: 'PH-09-A',
    regionID: 'PH-09',
  },
  'PH-03-A-BFK': {
    name: 'Balanga',
    provinceID: 'PH-03-A',
    regionID: 'PH-03',
  },
  'PH-40-A-WZG': {
    name: 'Batangas City',
    provinceID: 'PH-40-A',
    regionID: 'PH-40',
  },
  'PH-40-A-HEO': {
    name: 'Lipa',
    provinceID: 'PH-40-A',
    regionID: 'PH-40',
  },
  'PH-40-A-POH': {
    name: 'Tanauan',
    provinceID: 'PH-40-A',
    regionID: 'PH-40',
  },
  'PH-15-A-EMZ': {
    name: 'Baguio',
    provinceID: 'PH-15-A',
    regionID: 'PH-15',
  },
  'PH-07-A-SPV': {
    name: 'Tagbilaran',
    provinceID: 'PH-07-A',
    regionID: 'PH-07',
  },
  'PH-10-A-KTU': {
    name: 'Malaybalay',
    provinceID: 'PH-10-A',
    regionID: 'PH-10',
  },
  'PH-10-A-MXK': {
    name: 'Valencia',
    provinceID: 'PH-10-A',
    regionID: 'PH-10',
  },
  'PH-03-B-WZL': {
    name: 'Malolos',
    provinceID: 'PH-03-B',
    regionID: 'PH-03',
  },
  'PH-03-B-NZJ': {
    name: 'Meycauayan',
    provinceID: 'PH-03-B',
    regionID: 'PH-03',
  },
  'PH-03-B-BIB': {
    name: 'San Jose del Monte',
    provinceID: 'PH-03-B',
    regionID: 'PH-03',
  },
  'PH-02-A-TQU': {
    name: 'Tuguegarao',
    provinceID: 'PH-02-A',
    regionID: 'PH-02',
  },
  'PH-05-B-RMW': {
    name: 'Iriga',
    provinceID: 'PH-05-B',
    regionID: 'PH-05',
  },
  'PH-05-B-GQS': {
    name: 'Naga',
    provinceID: 'PH-05-B',
    regionID: 'PH-05',
  },
  'PH-06-A-OCP': {
    name: 'Roxas',
    provinceID: 'PH-06-A',
    regionID: 'PH-06',
  },
  'PH-40-B-LRB': {
    name: 'Bacoor',
    provinceID: 'PH-40-B',
    regionID: 'PH-40',
  },
  'PH-40-B-ADY': {
    name: 'Cavite City',
    provinceID: 'PH-40-B',
    regionID: 'PH-40',
  },
  'PH-40-B-KBX': {
    name: 'Dasmarinas',
    provinceID: 'PH-40-B',
    regionID: 'PH-40',
  },
  'PH-40-B-PMD': {
    name: 'Imus',
    provinceID: 'PH-40-B',
    regionID: 'PH-40',
  },
  'PH-40-B-DPH': {
    name: 'Tagaytay',
    provinceID: 'PH-40-B',
    regionID: 'PH-40',
  },
  'PH-40-B-QYF': {
    name: 'Trece Martires',
    provinceID: 'PH-40-B',
    regionID: 'PH-40',
  },
  'PH-07-B-PVT': {
    name: 'Bogo',
    provinceID: 'PH-07-B',
    regionID: 'PH-07',
  },
  'PH-07-B-XED': {
    name: 'Carcar',
    provinceID: 'PH-07-B',
    regionID: 'PH-07',
  },
  'PH-07-B-QUJ': {
    name: 'Cebu City',
    provinceID: 'PH-07-B',
    regionID: 'PH-07',
  },
  'PH-07-B-HRY': {
    name: 'Danao',
    provinceID: 'PH-07-B',
    regionID: 'PH-07',
  },
  'PH-07-B-MYF': {
    name: 'Lapu-Lapu',
    provinceID: 'PH-07-B',
    regionID: 'PH-07',
  },
  'PH-07-B-STA': {
    name: 'Mandaue',
    provinceID: 'PH-07-B',
    regionID: 'PH-07',
  },
  'PH-07-B-QTA': {
    name: 'Naga',
    provinceID: 'PH-07-B',
    regionID: 'PH-07',
  },
  'PH-07-B-ETB': {
    name: 'Talisay',
    provinceID: 'PH-07-B',
    regionID: 'PH-07',
  },
  'PH-07-B-WUS': {
    name: 'Toledo',
    provinceID: 'PH-07-B',
    regionID: 'PH-07',
  },
  'PH-12-A-VNQ': {
    name: 'Kidapawan',
    provinceID: 'PH-12-A',
    regionID: 'PH-12',
  },
  'PH-11-A-NMR': {
    name: 'Panabo',
    provinceID: 'PH-11-A',
    regionID: 'PH-11',
  },
  'PH-11-A-VBW': {
    name: 'Samal',
    provinceID: 'PH-11-A',
    regionID: 'PH-11',
  },
  'PH-11-A-OBV': {
    name: 'Tagum',
    provinceID: 'PH-11-A',
    regionID: 'PH-11',
  },
  'PH-11-B-VEG': {
    name: 'Davao City',
    provinceID: 'PH-11-B',
    regionID: 'PH-11',
  },
  'PH-11-B-HWD': {
    name: 'Digos',
    provinceID: 'PH-11-B',
    regionID: 'PH-11',
  },
  'PH-11-C-ERI': {
    name: 'Mati',
    provinceID: 'PH-11-C',
    regionID: 'PH-11',
  },
  'PH-08-A-FEJ': {
    name: 'Borongan',
    provinceID: 'PH-08-A',
    regionID: 'PH-08',
  },
  'PH-01-A-QEM': {
    name: 'Batac',
    provinceID: 'PH-01-A',
    regionID: 'PH-01',
  },
  'PH-01-A-CZZ': {
    name: 'Laoag',
    provinceID: 'PH-01-A',
    regionID: 'PH-01',
  },
  'PH-01-B-BTE': {
    name: 'Candon',
    provinceID: 'PH-01-B',
    regionID: 'PH-01',
  },
  'PH-01-B-NRJ': {
    name: 'Vigan',
    provinceID: 'PH-01-B',
    regionID: 'PH-01',
  },
  'PH-06-B-KRN': {
    name: 'Iloilo City',
    provinceID: 'PH-06-B',
    regionID: 'PH-06',
  },
  'PH-06-B-PQJ': {
    name: 'Passi',
    provinceID: 'PH-06-B',
    regionID: 'PH-06',
  },
  'PH-02-B-ZEE': {
    name: 'Cauayan',
    provinceID: 'PH-02-B',
    regionID: 'PH-02',
  },
  'PH-02-B-YXO': {
    name: 'Ilagan',
    provinceID: 'PH-02-B',
    regionID: 'PH-02',
  },
  'PH-02-B-UEX': {
    name: 'Santiago',
    provinceID: 'PH-02-B',
    regionID: 'PH-02',
  },
  'PH-15-B-TCN': {
    name: 'Tabuk',
    provinceID: 'PH-15-B',
    regionID: 'PH-15',
  },
  'PH-01-C-VOV': {
    name: 'San Fernando',
    provinceID: 'PH-01-C',
    regionID: 'PH-01',
  },
  'PH-40-C-OHB': {
    name: 'Binan',
    provinceID: 'PH-40-C',
    regionID: 'PH-40',
  },
  'PH-40-C-PSJ': {
    name: 'Cabuyao',
    provinceID: 'PH-40-C',
    regionID: 'PH-40',
  },
  'PH-40-C-RCE': {
    name: 'Calamba',
    provinceID: 'PH-40-C',
    regionID: 'PH-40',
  },
  'PH-40-C-ACY': {
    name: 'San Pablo',
    provinceID: 'PH-40-C',
    regionID: 'PH-40',
  },
  'PH-40-C-RCF': {
    name: 'Santa Rosa',
    provinceID: 'PH-40-C',
    regionID: 'PH-40',
  },
  'PH-40-C-DLY': {
    name: 'San Pedro',
    provinceID: 'PH-40-C',
    regionID: 'PH-40',
  },
  'PH-10-B-APQ': {
    name: 'Iligan',
    provinceID: 'PH-10-B',
    regionID: 'PH-10',
  },
  'PH-14-A-KMS': {
    name: 'Marawi',
    provinceID: 'PH-14-A',
    regionID: 'PH-14',
  },
  'PH-08-B-JJP': {
    name: 'Baybay',
    provinceID: 'PH-08-B',
    regionID: 'PH-08',
  },
  'PH-08-B-VQJ': {
    name: 'Ormoc',
    provinceID: 'PH-08-B',
    regionID: 'PH-08',
  },
  'PH-08-B-PCQ': {
    name: 'Tacloban',
    provinceID: 'PH-08-B',
    regionID: 'PH-08',
  },
  'PH-12-B-NDH': {
    name: 'Cotabato City',
    provinceID: 'PH-12-B',
    regionID: 'PH-12',
  },
  'PH-05-C-UVZ': {
    name: 'Masbate City',
    provinceID: 'PH-05-C',
    regionID: 'PH-05',
  },
  'PH-10-C-NVT': {
    name: 'Oroquieta',
    provinceID: 'PH-10-C',
    regionID: 'PH-10',
  },
  'PH-10-C-GBZ': {
    name: 'Ozamiz',
    provinceID: 'PH-10-C',
    regionID: 'PH-10',
  },
  'PH-10-C-XZK': {
    name: 'Tangub',
    provinceID: 'PH-10-C',
    regionID: 'PH-10',
  },
  'PH-10-D-VAC': {
    name: 'Cagayan de Oro',
    provinceID: 'PH-10-D',
    regionID: 'PH-10',
  },
  'PH-10-D-JVY': {
    name: 'El Salvador',
    provinceID: 'PH-10-D',
    regionID: 'PH-10',
  },
  'PH-10-D-EGT': {
    name: 'Gingoog',
    provinceID: 'PH-10-D',
    regionID: 'PH-10',
  },
  'PH-06-C-KCU': {
    name: 'Bacolod',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-QYR': {
    name: 'Bago',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-FKX': {
    name: 'Cadiz',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-OON': {
    name: 'Escalante',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-QJF': {
    name: 'Himamaylan',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-CBV': {
    name: 'Kabankalan',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-JWG': {
    name: 'La Carlota',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-BYO': {
    name: 'Sagay',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-FJI': {
    name: 'San Carlos',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-FEN': {
    name: 'Silay',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-SQJ': {
    name: 'Sipalay',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-JKA': {
    name: 'Talisay',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-06-C-NVG': {
    name: 'Victorias',
    provinceID: 'PH-06-C',
    regionID: 'PH-06',
  },
  'PH-07-C-JED': {
    name: 'Bais',
    provinceID: 'PH-07-C',
    regionID: 'PH-07',
  },
  'PH-07-C-OZR': {
    name: 'Bayawan',
    provinceID: 'PH-07-C',
    regionID: 'PH-07',
  },
  'PH-07-C-ZMW': {
    name: 'Canlaon',
    provinceID: 'PH-07-C',
    regionID: 'PH-07',
  },
  'PH-07-C-LCG': {
    name: 'Dumaguete',
    provinceID: 'PH-07-C',
    regionID: 'PH-07',
  },
  'PH-07-C-SSM': {
    name: 'Guihulngan',
    provinceID: 'PH-07-C',
    regionID: 'PH-07',
  },
  'PH-07-C-CED': {
    name: 'Tanjay',
    provinceID: 'PH-07-C',
    regionID: 'PH-07',
  },
  'PH-03-C-GHE': {
    name: 'Cabanatuan',
    provinceID: 'PH-03-C',
    regionID: 'PH-03',
  },
  'PH-03-C-MZY': {
    name: 'Gapan',
    provinceID: 'PH-03-C',
    regionID: 'PH-03',
  },
  'PH-03-C-WIZ': {
    name: 'Munoz',
    provinceID: 'PH-03-C',
    regionID: 'PH-03',
  },
  'PH-03-C-BJE': {
    name: 'Palayan',
    provinceID: 'PH-03-C',
    regionID: 'PH-03',
  },
  'PH-03-C-WHL': {
    name: 'San Jose',
    provinceID: 'PH-03-C',
    regionID: 'PH-03',
  },
  'PH-41-A-FOH': {
    name: 'Calapan',
    provinceID: 'PH-41-A',
    regionID: 'PH-41',
  },
  'PH-41-B-XDY': {
    name: 'Puerto Princesa',
    provinceID: 'PH-41-B',
    regionID: 'PH-41',
  },
  'PH-03-D-JID': {
    name: 'Angeles',
    provinceID: 'PH-03-D',
    regionID: 'PH-03',
  },
  'PH-03-D-ALW': {
    name: 'Mabalacat',
    provinceID: 'PH-03-D',
    regionID: 'PH-03',
  },
  'PH-03-D-TYR': {
    name: 'San Fernando',
    provinceID: 'PH-03-D',
    regionID: 'PH-03',
  },
  'PH-01-D-WMR': {
    name: 'Alaminos',
    provinceID: 'PH-01-D',
    regionID: 'PH-01',
  },
  'PH-01-D-PXI': {
    name: 'Dagupan',
    provinceID: 'PH-01-D',
    regionID: 'PH-01',
  },
  'PH-01-D-GTQ': {
    name: 'San Carlos',
    provinceID: 'PH-01-D',
    regionID: 'PH-01',
  },
  'PH-01-D-RFB': {
    name: 'Urdaneta',
    provinceID: 'PH-01-D',
    regionID: 'PH-01',
  },
  'PH-40-D-FEI': {
    name: 'Lucena',
    provinceID: 'PH-40-D',
    regionID: 'PH-40',
  },
  'PH-40-D-RHQ': {
    name: 'Tayabas',
    provinceID: 'PH-40-D',
    regionID: 'PH-40',
  },
  'PH-40-E-VUG': {
    name: 'Antipolo',
    provinceID: 'PH-40-E',
    regionID: 'PH-40',
  },
  'PH-08-C-YEK': {
    name: 'Calbayog',
    provinceID: 'PH-08-C',
    regionID: 'PH-08',
  },
  'PH-08-C-YSC': {
    name: 'Catbalogan',
    provinceID: 'PH-08-C',
    regionID: 'PH-08',
  },
  'PH-05-D-RES': {
    name: 'Sorsogon City',
    provinceID: 'PH-05-D',
    regionID: 'PH-05',
  },
  'PH-12-C-HCD': {
    name: 'General Santos',
    provinceID: 'PH-12-C',
    regionID: 'PH-12',
  },
  'PH-12-C-NHM': {
    name: 'Koronadal',
    provinceID: 'PH-12-C',
    regionID: 'PH-12',
  },
  'PH-08-D-RNF': {
    name: 'Maasin',
    provinceID: 'PH-08-D',
    regionID: 'PH-08',
  },
  'PH-12-D-BGV': {
    name: 'Tacurong',
    provinceID: 'PH-12-D',
    regionID: 'PH-12',
  },
  'PH-13-C-GBB': {
    name: 'Surigao City',
    provinceID: 'PH-13-C',
    regionID: 'PH-13',
  },
  'PH-13-D-VEE': {
    name: 'Bislig',
    provinceID: 'PH-13-D',
    regionID: 'PH-13',
  },
  'PH-13-D-RHO': {
    name: 'Tandag',
    provinceID: 'PH-13-D',
    regionID: 'PH-13',
  },
  'PH-03-E-GFW': {
    name: 'Tarlac City',
    provinceID: 'PH-03-E',
    regionID: 'PH-03',
  },
  'PH-03-F-RJL': {
    name: 'Olongapo',
    provinceID: 'PH-03-F',
    regionID: 'PH-03',
  },
  'PH-09-B-ROY': {
    name: 'Dapitan',
    provinceID: 'PH-09-B',
    regionID: 'PH-09',
  },
  'PH-09-B-BOX': {
    name: 'Dipolog',
    provinceID: 'PH-09-B',
    regionID: 'PH-09',
  },
  'PH-09-C-MQV': {
    name: 'Pagadian',
    provinceID: 'PH-09-C',
    regionID: 'PH-09',
  },
  'PH-09-C-UYK': {
    name: 'Zamboanga City',
    provinceID: 'PH-09-C',
    regionID: 'PH-09',
  },
  'PH-15-C-VAR': {
    name: 'Bangued',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-OXG': {
    name: 'Boliney',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-ETC': {
    name: 'Bucay',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-ZGS': {
    name: 'Bucloc',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-ORV': {
    name: 'Daguioman',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-HEU': {
    name: 'Danglas',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-RRX': {
    name: 'Dolores',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-HBP': {
    name: 'La Paz',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-BFV': {
    name: 'Lacub',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-IWJ': {
    name: 'Lagangilang',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-FDX': {
    name: 'Lagayan',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-AWM': {
    name: 'Langiden',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-NZI': {
    name: 'Licuan-Baay',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-OZX': {
    name: 'Luba',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-KVP': {
    name: 'Malibcong',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-LBY': {
    name: 'Manabo',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-PWX': {
    name: 'Peñarrubia',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-BWT': {
    name: 'Pidigan',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-HUH': {
    name: 'Pilar',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-NCD': {
    name: 'Sallapadan',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-RZT': {
    name: 'San Isidro',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-YDZ': {
    name: 'San Juan',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-OCJ': {
    name: 'San Quintin',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-CZG': {
    name: 'Tayum',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-BVC': {
    name: 'Tineg',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-EWU': {
    name: 'Tubo',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-C-MVN': {
    name: 'Villaviciosa',
    regionID: 'PH-15',
    provinceID: 'PH-15-C',
  },
  'PH-15-D-UEQ': {
    name: 'Calanasan',
    regionID: 'PH-15',
    provinceID: 'PH-15-D',
  },
  'PH-15-D-PKC': {
    name: 'Conner',
    regionID: 'PH-15',
    provinceID: 'PH-15-D',
  },
  'PH-15-D-TEU': {
    name: 'Flora',
    regionID: 'PH-15',
    provinceID: 'PH-15-D',
  },
  'PH-15-D-ICC': {
    name: 'Kabugao',
    regionID: 'PH-15',
    provinceID: 'PH-15-D',
  },
  'PH-15-D-IIN': {
    name: 'Luna',
    regionID: 'PH-15',
    provinceID: 'PH-15-D',
  },
  'PH-15-D-PAX': {
    name: 'Pudtol',
    regionID: 'PH-15',
    provinceID: 'PH-15-D',
  },
  'PH-15-D-YXG': {
    name: 'Santa Marcela',
    regionID: 'PH-15',
    provinceID: 'PH-15-D',
  },
  'PH-15-A-PVZ': {
    name: 'Atok',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-YHS': {
    name: 'Bakun',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-JOK': {
    name: 'Bokod',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-YXD': {
    name: 'Buguias',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-GBO': {
    name: 'Itogon',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-GME': {
    name: 'Kabayan',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-UNZ': {
    name: 'Kapangan',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-GWD': {
    name: 'Kibungan',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-RQO': {
    name: 'La Trinidad',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-TGE': {
    name: 'Mankayan',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-MLU': {
    name: 'Sablan',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-XNM': {
    name: 'Tuba',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-A-SFA': {
    name: 'Tublay',
    regionID: 'PH-15',
    provinceID: 'PH-15-A',
  },
  'PH-15-E-KLP': {
    name: 'Aguinaldo',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-E-FWO': {
    name: 'Alfonso Lista',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-E-GEH': {
    name: 'Asipulo',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-E-PUC': {
    name: 'Banaue',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-E-ABL': {
    name: 'Hingyon',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-E-NTE': {
    name: 'Hungduan',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-E-GKQ': {
    name: 'Kiangan',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-E-CDB': {
    name: 'Lagawe',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-E-QSX': {
    name: 'Lamut',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-E-ZAU': {
    name: 'Mayoyao',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-E-DSE': {
    name: 'Tinoc',
    regionID: 'PH-15',
    provinceID: 'PH-15-E',
  },
  'PH-15-B-MNI': {
    name: 'Balbalan',
    regionID: 'PH-15',
    provinceID: 'PH-15-B',
  },
  'PH-15-B-BDU': {
    name: 'Lubuagan',
    regionID: 'PH-15',
    provinceID: 'PH-15-B',
  },
  'PH-15-B-CNR': {
    name: 'Pasil',
    regionID: 'PH-15',
    provinceID: 'PH-15-B',
  },
  'PH-15-B-MRE': {
    name: 'Pinukpuk',
    regionID: 'PH-15',
    provinceID: 'PH-15-B',
  },
  'PH-15-B-BKI': {
    name: 'Rizal',
    regionID: 'PH-15',
    provinceID: 'PH-15-B',
  },
  'PH-15-B-EQO': {
    name: 'Tanudan',
    regionID: 'PH-15',
    provinceID: 'PH-15-B',
  },
  'PH-15-B-LOQ': {
    name: 'Tinglayan',
    regionID: 'PH-15',
    provinceID: 'PH-15-B',
  },
  'PH-15-F-WGG': {
    name: 'Barlig',
    regionID: 'PH-15',
    provinceID: 'PH-15-F',
  },
  'PH-15-F-COG': {
    name: 'Bauko',
    regionID: 'PH-15',
    provinceID: 'PH-15-F',
  },
  'PH-15-F-HDB': {
    name: 'Besao',
    regionID: 'PH-15',
    provinceID: 'PH-15-F',
  },
  'PH-15-F-RNH': {
    name: 'Bontoc',
    regionID: 'PH-15',
    provinceID: 'PH-15-F',
  },
  'PH-15-F-MGF': {
    name: 'Natonin',
    regionID: 'PH-15',
    provinceID: 'PH-15-F',
  },
  'PH-15-F-NOQ': {
    name: 'Paracelis',
    regionID: 'PH-15',
    provinceID: 'PH-15-F',
  },
  'PH-15-F-YOF': {
    name: 'Sabangan',
    regionID: 'PH-15',
    provinceID: 'PH-15-F',
  },
  'PH-15-F-JGD': {
    name: 'Sadanga',
    regionID: 'PH-15',
    provinceID: 'PH-15-F',
  },
  'PH-15-F-PSY': {
    name: 'Sagada',
    regionID: 'PH-15',
    provinceID: 'PH-15-F',
  },
  'PH-15-F-TCQ': {
    name: 'Tadian',
    regionID: 'PH-15',
    provinceID: 'PH-15-F',
  },
  'PH-01-A-EGU': {
    name: 'Adams',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-VAN': {
    name: 'Bacarra',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-LHT': {
    name: 'Badoc',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-NIL': {
    name: 'Bangui',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-PDT': {
    name: 'Banna',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-KYJ': {
    name: 'Burgos',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-BID': {
    name: 'Carasi',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-FUB': {
    name: 'Currimao',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-YYK': {
    name: 'Dingras',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-QFX': {
    name: 'Dumalneg',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-IPH': {
    name: 'Marcos',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-IZF': {
    name: 'Nueva Era',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-ERF': {
    name: 'Pagudpud',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-YDC': {
    name: 'Paoay',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-IJC': {
    name: 'Pasuquin',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-ITC': {
    name: 'Piddig',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-CBC': {
    name: 'Pinili',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-SDU': {
    name: 'San Nicolas',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-MDQ': {
    name: 'Sarrat',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-BTA': {
    name: 'Solsona',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-A-LOX': {
    name: 'Vintar',
    regionID: 'PH-01',
    provinceID: 'PH-01-A',
  },
  'PH-01-B-VEG': {
    name: 'Alilem',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-CZG': {
    name: 'Banayoyo',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-OPX': {
    name: 'Bantay',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-IXE': {
    name: 'Burgos',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-KNX': {
    name: 'Cabugao',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-KYJ': {
    name: 'Caoayan',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-FUW': {
    name: 'Cervantes',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-HJH': {
    name: 'Galimuyod',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-QBK': {
    name: 'Gregorio Del Pilar',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-ISI': {
    name: 'Lidlidda',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-UHE': {
    name: 'Magsingal',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-ZYA': {
    name: 'Nagbukel',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-VPG': {
    name: 'Narvacan',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-KUY': {
    name: 'Quirino',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-WPS': {
    name: 'Salcedo',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-LUJ': {
    name: 'San Emilio',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-OBY': {
    name: 'San Esteban',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-VIN': {
    name: 'San Ildefonso',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-WGE': {
    name: 'San Juan',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-TJP': {
    name: 'San Vicente',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-JYS': {
    name: 'Santa',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-UOS': {
    name: 'Santa Catalina',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-DCI': {
    name: 'Santa Cruz',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-FLQ': {
    name: 'Santa Lucia',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-ZHR': {
    name: 'Santa Maria',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-TCO': {
    name: 'Santiago',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-OAA': {
    name: 'Santo Domingo',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-LOX': {
    name: 'Sigay',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-CRH': {
    name: 'Sinait',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-YPU': {
    name: 'Sugpon',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-JDZ': {
    name: 'Suyo',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-B-RYV': {
    name: 'Tagudin',
    regionID: 'PH-01',
    provinceID: 'PH-01-B',
  },
  'PH-01-C-GBA': {
    name: 'Agoo',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-ZXB': {
    name: 'Aringay',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-FPH': {
    name: 'Bacnotan',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-MOS': {
    name: 'Bagulin',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-NVP': {
    name: 'Balaoan',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-MXF': {
    name: 'Bangar',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-CUQ': {
    name: 'Bauang',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-MNU': {
    name: 'Burgos',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-DUC': {
    name: 'Caba',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-LNO': {
    name: 'Luna',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-LSJ': {
    name: 'Naguilian',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-GHW': {
    name: 'Pugo',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-DHW': {
    name: 'Rosario',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-RFM': {
    name: 'San Gabriel',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-YWJ': {
    name: 'San Juan',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-ULY': {
    name: 'Santo Tomas',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-EGO': {
    name: 'Santol',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-TCX': {
    name: 'Sudipen',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-C-SIY': {
    name: 'Tubao',
    regionID: 'PH-01',
    provinceID: 'PH-01-C',
  },
  'PH-01-D-JUS': {
    name: 'Agno',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-HMI': {
    name: 'Aguilar',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-CEZ': {
    name: 'Alcala',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-RLG': {
    name: 'Anda',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-OSL': {
    name: 'Asingan',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-DNQ': {
    name: 'Balungao',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-CYT': {
    name: 'Bani',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-NCJ': {
    name: 'Basista',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-TPR': {
    name: 'Bautista',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-BXX': {
    name: 'Bayambang',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-UTY': {
    name: 'Binalonan',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-HIW': {
    name: 'Binmaley',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-IJG': {
    name: 'Bolinao',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-OSB': {
    name: 'Bugallon',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-JCR': {
    name: 'Burgos',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-ACT': {
    name: 'Calasiao',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-ZTQ': {
    name: 'Dasol',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-OGY': {
    name: 'Infanta',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-EXD': {
    name: 'Labrador',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-SEA': {
    name: 'Laoac',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-CST': {
    name: 'Lingayen',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-NQL': {
    name: 'Mabini',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-FSY': {
    name: 'Malasiqui',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-EAL': {
    name: 'Manaoag',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-MQZ': {
    name: 'Mangaldan',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-YVB': {
    name: 'Mangatarem',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-YOJ': {
    name: 'Mapandan',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-QOQ': {
    name: 'Natividad',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-VMN': {
    name: 'Pozzorubio',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-BAM': {
    name: 'Rosales',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-RSU': {
    name: 'San Fabian',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-XTN': {
    name: 'San Jacinto',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-TXO': {
    name: 'San Manuel',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-CGI': {
    name: 'San Nicolas',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-YKJ': {
    name: 'San Quintin',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-KAM': {
    name: 'Santa Barbara',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-VWS': {
    name: 'Santa Maria',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-VHT': {
    name: 'Santo Tomas',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-MAP': {
    name: 'Sison',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-LZE': {
    name: 'Sual',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-MGW': {
    name: 'Tayug',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-PYK': {
    name: 'Umingan',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-KMX': {
    name: 'Urbiztondo',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-01-D-QEJ': {
    name: 'Villasis',
    regionID: 'PH-01',
    provinceID: 'PH-01-D',
  },
  'PH-02-C-JRC': {
    name: 'Basco',
    regionID: 'PH-02',
    provinceID: 'PH-02-C',
  },
  'PH-02-C-KCI': {
    name: 'Itbayat',
    regionID: 'PH-02',
    provinceID: 'PH-02-C',
  },
  'PH-02-C-VYZ': {
    name: 'Ivana',
    regionID: 'PH-02',
    provinceID: 'PH-02-C',
  },
  'PH-02-C-ZWE': {
    name: 'Mahatao',
    regionID: 'PH-02',
    provinceID: 'PH-02-C',
  },
  'PH-02-C-LUP': {
    name: 'Sabtang',
    regionID: 'PH-02',
    provinceID: 'PH-02-C',
  },
  'PH-02-C-MFE': {
    name: 'Uyugan',
    regionID: 'PH-02',
    provinceID: 'PH-02-C',
  },
  'PH-02-A-TZD': {
    name: 'Abulug',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-WXE': {
    name: 'Alcala',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-EEN': {
    name: 'Allacapan',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-ECL': {
    name: 'Amulung',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-TQO': {
    name: 'Aparri',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-UFM': {
    name: 'Baggao',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-MDM': {
    name: 'Ballesteros',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-XLE': {
    name: 'Buguey',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-YMV': {
    name: 'Calayan',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-ZKV': {
    name: 'Camalaniugan',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-GJE': {
    name: 'Claveria',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-JJC': {
    name: 'Enrile',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-IRO': {
    name: 'Gattaran',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-GRB': {
    name: 'Gonzaga',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-RDV': {
    name: 'Iguig',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-RAA': {
    name: 'Lal-lo',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-HFT': {
    name: 'Lasam',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-NIJ': {
    name: 'Pamplona',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-ACH': {
    name: 'Peñablanca',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-DLN': {
    name: 'Piat',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-CUM': {
    name: 'Rizal',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-XNF': {
    name: 'Sanchez-Mira',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-AGB': {
    name: 'Santa Ana',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-JNA': {
    name: 'Santa Praxedes',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-ONU': {
    name: 'Santa Teresita',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-IAH': {
    name: 'Santo Niño',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-THF': {
    name: 'Solana',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-A-SOZ': {
    name: 'Tuao',
    regionID: 'PH-02',
    provinceID: 'PH-02-A',
  },
  'PH-02-B-ICK': {
    name: 'Alicia',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-ZFF': {
    name: 'Angadanan',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-RYU': {
    name: 'Aurora',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-EDT': {
    name: 'Benito Soliven',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-KMR': {
    name: 'Burgos',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-SFO': {
    name: 'Cabagan',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-NUN': {
    name: 'Cabatuan',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-BUH': {
    name: 'Cordon',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-AWN': {
    name: 'Delfin Albano',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-QXK': {
    name: 'Dinapigue',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-BZM': {
    name: 'Divilacan',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-YEZ': {
    name: 'Echague',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-IKR': {
    name: 'Gamu',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-GKG': {
    name: 'Jones',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-WQM': {
    name: 'Luna',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-ABH': {
    name: 'Maconacon',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-BWC': {
    name: 'Mallig',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-TKT': {
    name: 'Naguilian',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-KSW': {
    name: 'Palanan',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-TBM': {
    name: 'Quezon',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-NDE': {
    name: 'Quirino',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-WMF': {
    name: 'Ramon',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-NOZ': {
    name: 'Reina Mercedes',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-NLL': {
    name: 'Roxas',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-PBE': {
    name: 'San Agustin',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-GTE': {
    name: 'San Guillermo',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-BCT': {
    name: 'San Isidro',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-XRU': {
    name: 'San Manuel',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-WTD': {
    name: 'San Mariano',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-IOB': {
    name: 'San Mateo',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-VSM': {
    name: 'San Pablo',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-RUU': {
    name: 'Santa Maria',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-VSR': {
    name: 'Santo Tomas',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-B-KUD': {
    name: 'Tumauini',
    regionID: 'PH-02',
    provinceID: 'PH-02-B',
  },
  'PH-02-D-CQO': {
    name: 'Alfonso Castaneda',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-TCY': {
    name: 'Ambaguio',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-MZL': {
    name: 'Aritao',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-JHB': {
    name: 'Bagabag',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-KFX': {
    name: 'Bambang',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-TVV': {
    name: 'Bayombong',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-OUA': {
    name: 'Diadi',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-OFM': {
    name: 'Dupax del Norte',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-NNO': {
    name: 'Dupax del Sur',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-TYO': {
    name: 'Kasibu',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-YWI': {
    name: 'Kayapa',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-JDD': {
    name: 'Quezon',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-ZEA': {
    name: 'Santa Fe',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-ANY': {
    name: 'Solano',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-D-BHJ': {
    name: 'Villaverde',
    regionID: 'PH-02',
    provinceID: 'PH-02-D',
  },
  'PH-02-E-DOK': {
    name: 'Aglipay',
    regionID: 'PH-02',
    provinceID: 'PH-02-E',
  },
  'PH-02-E-PNV': {
    name: 'Cabarroguis',
    regionID: 'PH-02',
    provinceID: 'PH-02-E',
  },
  'PH-02-E-JEN': {
    name: 'Diffun',
    regionID: 'PH-02',
    provinceID: 'PH-02-E',
  },
  'PH-02-E-KJO': {
    name: 'Maddela',
    regionID: 'PH-02',
    provinceID: 'PH-02-E',
  },
  'PH-02-E-TVI': {
    name: 'Nagtipunan',
    regionID: 'PH-02',
    provinceID: 'PH-02-E',
  },
  'PH-02-E-WAA': {
    name: 'Saguday',
    regionID: 'PH-02',
    provinceID: 'PH-02-E',
  },
  'PH-03-G-TQI': {
    name: 'Baler',
    regionID: 'PH-03',
    provinceID: 'PH-03-G',
  },
  'PH-03-G-IAK': {
    name: 'Casiguran',
    regionID: 'PH-03',
    provinceID: 'PH-03-G',
  },
  'PH-03-G-SJV': {
    name: 'Dilasag',
    regionID: 'PH-03',
    provinceID: 'PH-03-G',
  },
  'PH-03-G-QFC': {
    name: 'Dinalungan',
    regionID: 'PH-03',
    provinceID: 'PH-03-G',
  },
  'PH-03-G-HSQ': {
    name: 'Dingalan',
    regionID: 'PH-03',
    provinceID: 'PH-03-G',
  },
  'PH-03-G-KJL': {
    name: 'Dipaculao',
    regionID: 'PH-03',
    provinceID: 'PH-03-G',
  },
  'PH-03-G-FIG': {
    name: 'Maria Aurora',
    regionID: 'PH-03',
    provinceID: 'PH-03-G',
  },
  'PH-03-G-QBC': {
    name: 'San Luis',
    regionID: 'PH-03',
    provinceID: 'PH-03-G',
  },
  'PH-03-A-OSG': {
    name: 'Abucay',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-A-GFD': {
    name: 'Bagac',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-A-ZFQ': {
    name: 'Dinalupihan',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-A-PAK': {
    name: 'Hermosa',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-A-VHH': {
    name: 'Limay',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-A-GEF': {
    name: 'Mariveles',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-A-RNF': {
    name: 'Morong',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-A-WNF': {
    name: 'Orani',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-A-IYW': {
    name: 'Orion',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-A-IDK': {
    name: 'Pilar',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-A-SCT': {
    name: 'Samal',
    regionID: 'PH-03',
    provinceID: 'PH-03-A',
  },
  'PH-03-B-YZF': {
    name: 'Angat',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-FWA': {
    name: 'Balagtas',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-AME': {
    name: 'Baliuag',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-SOD': {
    name: 'Bocaue',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-BIV': {
    name: 'Bulakan',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-RLK': {
    name: 'Bustos',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-JCP': {
    name: 'Calumpit',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-WGA': {
    name: 'Doña Remedios Trinidad',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-HEH': {
    name: 'Guiguinto',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-WNU': {
    name: 'Hagonoy',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-MMT': {
    name: 'Marilao',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-TLN': {
    name: 'Norzagaray',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-RIM': {
    name: 'Obando',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-OXQ': {
    name: 'Pandi',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-KLQ': {
    name: 'Paombong',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-ZVZ': {
    name: 'Plaridel',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-NHN': {
    name: 'Pulilan',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-PXJ': {
    name: 'San Ildefonso',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-URM': {
    name: 'San Miguel',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-PIH': {
    name: 'San Rafael',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-B-LVZ': {
    name: 'Santa Maria',
    regionID: 'PH-03',
    provinceID: 'PH-03-B',
  },
  'PH-03-C-FDU': {
    name: 'Aliaga',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-TJB': {
    name: 'Bongabon',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-ZSD': {
    name: 'Cabiao',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-UQL': {
    name: 'Carranglan',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-OTS': {
    name: 'Cuyapo',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-LNH': {
    name: 'Gabaldon',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-JHD': {
    name: 'General Mamerto Natividad',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-YFE': {
    name: 'General Tinio',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-QVY': {
    name: 'Guimba',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-CGM': {
    name: 'Jaen',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-OYV': {
    name: 'Laur',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-RCS': {
    name: 'Licab',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-DTA': {
    name: 'Llanera',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-SDQ': {
    name: 'Lupao',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-GLR': {
    name: 'Muñoz',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-BLV': {
    name: 'Nampicuan',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-ZKM': {
    name: 'Pantabangan',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-VTT': {
    name: 'Peñaranda',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-HZC': {
    name: 'Quezon',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-ZUI': {
    name: 'Rizal',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-DEN': {
    name: 'San Antonio',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-NGK': {
    name: 'San Isidro',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-JJL': {
    name: 'San Leonardo',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-SFE': {
    name: 'Santa Rosa',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-AGL': {
    name: 'Santo Domingo',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-BIZ': {
    name: 'Talavera',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-GNP': {
    name: 'Talugtug',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-C-OKT': {
    name: 'Zaragoza',
    regionID: 'PH-03',
    provinceID: 'PH-03-C',
  },
  'PH-03-D-ZMK': {
    name: 'Apalit',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-SKR': {
    name: 'Arayat',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-QUZ': {
    name: 'Bacolor',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-LSK': {
    name: 'Candaba',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-DHK': {
    name: 'Floridablanca',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-PHJ': {
    name: 'Guagua',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-AGO': {
    name: 'Lubao',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-XTH': {
    name: 'Macabebe',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-PDD': {
    name: 'Magalang',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-FPM': {
    name: 'Masantol',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-SZD': {
    name: 'Mexico',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-XDQ': {
    name: 'Minalin',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-CHC': {
    name: 'Porac',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-LKQ': {
    name: 'San Luis',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-ALC': {
    name: 'San Simon',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-LYH': {
    name: 'Santa Ana',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-EBE': {
    name: 'Santa Rita',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-PGH': {
    name: 'Santo Tomas',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-D-WHJ': {
    name: 'Sasmuan',
    regionID: 'PH-03',
    provinceID: 'PH-03-D',
  },
  'PH-03-E-PCI': {
    name: 'Anao',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-VXC': {
    name: 'Bamban',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-JOK': {
    name: 'Camiling',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-OEO': {
    name: 'Capas',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-TGY': {
    name: 'Concepcion',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-TOO': {
    name: 'Gerona',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-BSM': {
    name: 'La Paz',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-OMY': {
    name: 'Mayantoc',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-XRA': {
    name: 'Moncada',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-MYX': {
    name: 'Paniqui',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-MIA': {
    name: 'Pura',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-PTC': {
    name: 'Ramos',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-OJE': {
    name: 'San Clemente',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-BXF': {
    name: 'San Jose',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-FSW': {
    name: 'San Manuel',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-ASF': {
    name: 'Santa Ignacia',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-OSY': {
    name: 'Tarlac',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-E-YSF': {
    name: 'Victoria',
    regionID: 'PH-03',
    provinceID: 'PH-03-E',
  },
  'PH-03-F-EXF': {
    name: 'Botolan',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-RXX': {
    name: 'Cabangan',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-AMH': {
    name: 'Candelaria',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-VHP': {
    name: 'Castillejos',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-YGO': {
    name: 'Iba',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-QPW': {
    name: 'Masinloc',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-CYK': {
    name: 'Palauig',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-NTS': {
    name: 'San Antonio',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-COP': {
    name: 'San Felipe',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-NTT': {
    name: 'San Marcelino',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-PFO': {
    name: 'San Narciso',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-PYP': {
    name: 'Santa Cruz',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-03-F-AVH': {
    name: 'Subic',
    regionID: 'PH-03',
    provinceID: 'PH-03-F',
  },
  'PH-40-A-JZT': {
    name: 'Agoncillo',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-AJH': {
    name: 'Alitagtag',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-HRI': {
    name: 'Balayan',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-UXG': {
    name: 'Balete',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-LID': {
    name: 'Batangas',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-GGC': {
    name: 'Bauan',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-LZX': {
    name: 'Calaca',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-ZXA': {
    name: 'Calatagan',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-PEV': {
    name: 'Cuenca',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-LZF': {
    name: 'Ibaan',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-ARW': {
    name: 'Laurel',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-MVX': {
    name: 'Lemery',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-DIU': {
    name: 'Lian',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-NMP': {
    name: 'Lobo',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-EMO': {
    name: 'Mabini',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-IDF': {
    name: 'Malvar',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-IRZ': {
    name: 'Mataasnakahoy',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-OER': {
    name: 'Nasugbu',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-CHU': {
    name: 'Padre Garcia',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-OLO': {
    name: 'Rosario',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-KTJ': {
    name: 'San Jose',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-FAO': {
    name: 'San Juan',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-FCQ': {
    name: 'San Luis',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-DIJ': {
    name: 'San Nicolas',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-AKJ': {
    name: 'San Pascual',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-AZU': {
    name: 'Santa Teresita',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-WFH': {
    name: 'Santo Tomas',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-JPB': {
    name: 'Taal',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-IMZ': {
    name: 'Talisay',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-TPF': {
    name: 'Taysan',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-ZKV': {
    name: 'Tingloy',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-A-JMH': {
    name: 'Tuy',
    regionID: 'PH-40',
    provinceID: 'PH-40-A',
  },
  'PH-40-B-VZE': {
    name: 'Alfonso',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-LCC': {
    name: 'Amadeo',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-DXV': {
    name: 'Carmona',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-CCJ': {
    name: 'Cavite',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-JGT': {
    name: 'Dasmariñas',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-BRP': {
    name: 'General Mariano Alvarez',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-FIL': {
    name: 'General Emilio Aguinaldo',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-QCT': {
    name: 'General Trias',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-QIR': {
    name: 'Indang',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-HHQ': {
    name: 'Kawit',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-UKO': {
    name: 'Magallanes',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-SVE': {
    name: 'Maragondon',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-IRG': {
    name: 'Mendez',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-TJC': {
    name: 'Naic',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-QHA': {
    name: 'Noveleta',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-PDO': {
    name: 'Rosario',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-IKD': {
    name: 'Silang',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-LKL': {
    name: 'Tanza',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-B-ZZU': {
    name: 'Ternate',
    regionID: 'PH-40',
    provinceID: 'PH-40-B',
  },
  'PH-40-C-WNA': {
    name: 'Alaminos',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-LYW': {
    name: 'Bay',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-YCS': {
    name: 'Biñan',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-IMU': {
    name: 'Calauan',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-VCQ': {
    name: 'Cavinti',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-RDT': {
    name: 'Famy',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-HZG': {
    name: 'Kalayaan',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-RKK': {
    name: 'Liliw',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-NCP': {
    name: 'Los Baños',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-FDH': {
    name: 'Luisiana',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-QOR': {
    name: 'Lumban',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-MVR': {
    name: 'Mabitac',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-JHF': {
    name: 'Magdalena',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-TVJ': {
    name: 'Majayjay',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-LEL': {
    name: 'Nagcarlan',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-UZZ': {
    name: 'Paete',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-SPV': {
    name: 'Pagsanjan',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-ECO': {
    name: 'Pakil',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-CLF': {
    name: 'Pangil',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-DEU': {
    name: 'Pila',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-LZF': {
    name: 'Rizal',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-NPZ': {
    name: 'Santa Cruz',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-QIE': {
    name: 'Santa Maria',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-KVB': {
    name: 'Siniloan',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-C-BVF': {
    name: 'Victoria',
    regionID: 'PH-40',
    provinceID: 'PH-40-C',
  },
  'PH-40-D-PFL': {
    name: 'Agdangan',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-TQK': {
    name: 'Alabat',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-FQB': {
    name: 'Atimonan',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-DHF': {
    name: 'Buenavista',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-QNW': {
    name: 'Burdeos',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-EMY': {
    name: 'Calauag',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-CTZ': {
    name: 'Candelaria',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-RCL': {
    name: 'Catanauan',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-LNO': {
    name: 'Dolores',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-TOD': {
    name: 'General Luna',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-UIZ': {
    name: 'General Nakar',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-IXA': {
    name: 'Guinayangan',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-AFW': {
    name: 'Gumaca',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-JLF': {
    name: 'Infanta',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-YLY': {
    name: 'Jomalig',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-ZKP': {
    name: 'Lopez',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-RBW': {
    name: 'Lucban',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-VTX': {
    name: 'Macalelon',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-OCA': {
    name: 'Mauban',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-GRT': {
    name: 'Mulanay',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-GZX': {
    name: 'Padre Burgos',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-XCR': {
    name: 'Pagbilao',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-DEE': {
    name: 'Panukulan',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-YPP': {
    name: 'Patnanungan',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-WRB': {
    name: 'Perez',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-RUW': {
    name: 'Pitogo',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-PWA': {
    name: 'Plaridel',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-XBP': {
    name: 'Polillo',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-YXG': {
    name: 'Quezon',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-EAL': {
    name: 'Real',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-HAW': {
    name: 'Sampaloc',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-VLX': {
    name: 'San Andres',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-ENZ': {
    name: 'San Antonio',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-OXT': {
    name: 'San Francisco',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-GTY': {
    name: 'San Narciso',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-FHD': {
    name: 'Sariaya',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-ITX': {
    name: 'Tagkawayan',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-KRA': {
    name: 'Tiaong',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-D-ONB': {
    name: 'Unisan',
    regionID: 'PH-40',
    provinceID: 'PH-40-D',
  },
  'PH-40-E-MWN': {
    name: 'Angono',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-NFJ': {
    name: 'Baras',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-PST': {
    name: 'Binangonan',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-JVC': {
    name: 'Cainta',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-SHR': {
    name: 'Cardona',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-QLF': {
    name: 'Jalajala',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-CTF': {
    name: 'Morong',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-MQA': {
    name: 'Pililla',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-TNB': {
    name: 'Rodriguez',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-YON': {
    name: 'San Mateo',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-SWQ': {
    name: 'Tanay',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-EAY': {
    name: 'Taytay',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-40-E-OGX': {
    name: 'Teresa',
    regionID: 'PH-40',
    provinceID: 'PH-40-E',
  },
  'PH-41-C-UXK': {
    name: 'Boac',
    regionID: 'PH-41',
    provinceID: 'PH-41-C',
  },
  'PH-41-C-YAN': {
    name: 'Buenavista',
    regionID: 'PH-41',
    provinceID: 'PH-41-C',
  },
  'PH-41-C-CRQ': {
    name: 'Gasan',
    regionID: 'PH-41',
    provinceID: 'PH-41-C',
  },
  'PH-41-C-PKX': {
    name: 'Mogpog',
    regionID: 'PH-41',
    provinceID: 'PH-41-C',
  },
  'PH-41-C-LNU': {
    name: 'Santa Cruz',
    regionID: 'PH-41',
    provinceID: 'PH-41-C',
  },
  'PH-41-C-GMO': {
    name: 'Torrijos',
    regionID: 'PH-41',
    provinceID: 'PH-41-C',
  },
  'PH-41-D-IGQ': {
    name: 'Abra de Ilog',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-D-DKQ': {
    name: 'Calintaan',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-D-UNK': {
    name: 'Looc',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-D-PFJ': {
    name: 'Lubang',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-D-CBG': {
    name: 'Magsaysay',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-D-DSE': {
    name: 'Mamburao',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-D-LTW': {
    name: 'Paluan',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-D-JXD': {
    name: 'Rizal',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-D-KQP': {
    name: 'Sablayan',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-D-VXM': {
    name: 'San Jose',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-D-VWU': {
    name: 'Santa Cruz',
    regionID: 'PH-41',
    provinceID: 'PH-41-D',
  },
  'PH-41-A-HRL': {
    name: 'Baco',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-QFD': {
    name: 'Bansud',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-JIR': {
    name: 'Bongabong',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-MDQ': {
    name: 'Bulalacao',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-QDB': {
    name: 'Gloria',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-CPV': {
    name: 'Mansalay',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-CMI': {
    name: 'Naujan',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-YAY': {
    name: 'Pinamalayan',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-OOX': {
    name: 'Pola',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-IYE': {
    name: 'Puerto Galera',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-BMN': {
    name: 'Roxas',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-KEG': {
    name: 'San Teodoro',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-NME': {
    name: 'Socorro',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-A-FJU': {
    name: 'Victoria',
    regionID: 'PH-41',
    provinceID: 'PH-41-A',
  },
  'PH-41-B-IQM': {
    name: 'Aborlan',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-VSA': {
    name: 'Agutaya',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-JDY': {
    name: 'Araceli',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-LXP': {
    name: 'Balabac',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-GCS': {
    name: 'Bataraza',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-YNO': {
    name: "Brooke's Point",
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-XGF': {
    name: 'Busuanga',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-UEX': {
    name: 'Cagayancillo',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-SGD': {
    name: 'Coron',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-MYO': {
    name: 'Culion',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-QJY': {
    name: 'Cuyo',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-OWV': {
    name: 'Dumaran',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-OMX': {
    name: 'El Nido',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-RLV': {
    name: 'Kalayaan',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-EEV': {
    name: 'Linapacan',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-BFT': {
    name: 'Magsaysay',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-GUM': {
    name: 'Narra',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-PKW': {
    name: 'Quezon',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-UHQ': {
    name: 'Rizal',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-CAK': {
    name: 'Roxas',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-VAO': {
    name: 'San Vicente',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-JUQ': {
    name: 'Sofronio Española',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-B-JAQ': {
    name: 'Taytay',
    regionID: 'PH-41',
    provinceID: 'PH-41-B',
  },
  'PH-41-E-ZAB': {
    name: 'Alcantara',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-HLS': {
    name: 'Banton',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-LVQ': {
    name: 'Cajidiocan',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-XLZ': {
    name: 'Calatrava',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-CDU': {
    name: 'Concepcion',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-CRD': {
    name: 'Corcuera',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-NHV': {
    name: 'Ferrol',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-RXQ': {
    name: 'Looc',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-KWU': {
    name: 'Magdiwang',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-UZG': {
    name: 'Odiongan',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-ENN': {
    name: 'Romblon',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-RLX': {
    name: 'San Agustin',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-TUR': {
    name: 'San Andres',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-XOB': {
    name: 'San Fernando',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-RCR': {
    name: 'San Jose',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-DZO': {
    name: 'Santa Fe',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-41-E-AXV': {
    name: 'Santa Maria',
    regionID: 'PH-41',
    provinceID: 'PH-41-E',
  },
  'PH-05-A-DXY': {
    name: 'Bacacay',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-XAY': {
    name: 'Camalig',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-WDM': {
    name: 'Daraga',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-KML': {
    name: 'Guinobatan',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-QRS': {
    name: 'Jovellar',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-GAO': {
    name: 'Libon',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-QSN': {
    name: 'Malilipot',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-ZPT': {
    name: 'Malinao',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-MIS': {
    name: 'Manito',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-IQZ': {
    name: 'Oas',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-IOQ': {
    name: 'Pio Duran',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-IJI': {
    name: 'Polangui',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-FOR': {
    name: 'Rapu-Rapu',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-KRS': {
    name: 'Santo Domingo',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-A-EBS': {
    name: 'Tiwi',
    regionID: 'PH-05',
    provinceID: 'PH-05-A',
  },
  'PH-05-E-XEU': {
    name: 'Basud',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-XXY': {
    name: 'Capalonga',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-WSE': {
    name: 'Daet',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-LZN': {
    name: 'Jose Panganiban',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-HNY': {
    name: 'Labo',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-LBP': {
    name: 'Mercedes',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-CBO': {
    name: 'Paracale',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-STV': {
    name: 'San Lorenzo Ruiz',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-MRO': {
    name: 'San Vicente',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-UVQ': {
    name: 'Santa Elena',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-KYD': {
    name: 'Talisay',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-E-GDJ': {
    name: 'Vinzons',
    regionID: 'PH-05',
    provinceID: 'PH-05-E',
  },
  'PH-05-B-GIG': {
    name: 'Baao',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-JNQ': {
    name: 'Balatan',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-BIG': {
    name: 'Bato',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-DVH': {
    name: 'Bombon',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-TBP': {
    name: 'Buhi',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-OFT': {
    name: 'Bula',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-LYL': {
    name: 'Cabusao',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-QJF': {
    name: 'Calabanga',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-PQB': {
    name: 'Camaligan',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-WSU': {
    name: 'Canaman',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-JSW': {
    name: 'Caramoan',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-MST': {
    name: 'Del Gallego',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-SWW': {
    name: 'Gainza',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-PDD': {
    name: 'Garchitorena',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-VDS': {
    name: 'Goa',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-SHP': {
    name: 'Lagonoy',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-ONR': {
    name: 'Libmanan',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-KSR': {
    name: 'Lupi',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-HQK': {
    name: 'Magarao',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-LYW': {
    name: 'Milaor',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-ACF': {
    name: 'Minalabac',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-WCA': {
    name: 'Nabua',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-VMO': {
    name: 'Ocampo',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-ETF': {
    name: 'Pamplona',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-YCL': {
    name: 'Pasacao',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-SAN': {
    name: 'Pili',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-HHU': {
    name: 'Presentacion',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-KBK': {
    name: 'Ragay',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-QFC': {
    name: 'Sagñay',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-UTO': {
    name: 'San Fernando',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-YQG': {
    name: 'San Jose',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-ZRX': {
    name: 'Sipocot',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-LKF': {
    name: 'Siruma',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-RPL': {
    name: 'Tigaon',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-B-FUH': {
    name: 'Tinambac',
    regionID: 'PH-05',
    provinceID: 'PH-05-B',
  },
  'PH-05-F-GNR': {
    name: 'Bagamanoc',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-F-NQW': {
    name: 'Baras',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-F-WLU': {
    name: 'Bato',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-F-ZEI': {
    name: 'Caramoran',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-F-ACD': {
    name: 'Gigmoto',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-F-AXJ': {
    name: 'Pandan',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-F-QXU': {
    name: 'Panganiban',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-F-HDM': {
    name: 'San Andres',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-F-URT': {
    name: 'San Miguel',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-F-FSD': {
    name: 'Viga',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-F-EFE': {
    name: 'Virac',
    regionID: 'PH-05',
    provinceID: 'PH-05-F',
  },
  'PH-05-C-PIA': {
    name: 'Aroroy',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-WRQ': {
    name: 'Baleno',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-PUQ': {
    name: 'Balud',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-IPS': {
    name: 'Batuan',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-RMQ': {
    name: 'Cataingan',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-OHF': {
    name: 'Cawayan',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-QGO': {
    name: 'Claveria',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-NTT': {
    name: 'Dimasalang',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-SVG': {
    name: 'Esperanza',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-PIG': {
    name: 'Mandaon',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-JIU': {
    name: 'Masbate',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-BTR': {
    name: 'Milagros',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-QXZ': {
    name: 'Mobo',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-OAB': {
    name: 'Monreal',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-ULE': {
    name: 'Palanas',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-KWE': {
    name: 'Pio V. Corpuz',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-HLX': {
    name: 'Placer',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-VRI': {
    name: 'San Fernando',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-FWK': {
    name: 'San Jacinto',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-DYX': {
    name: 'San Pascual',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-C-ADR': {
    name: 'Uson',
    regionID: 'PH-05',
    provinceID: 'PH-05-C',
  },
  'PH-05-D-KAE': {
    name: 'Barcelona',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-CTK': {
    name: 'Bulan',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-WCY': {
    name: 'Bulusan',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-BXC': {
    name: 'Casiguran',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-MYZ': {
    name: 'Castilla',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-LUK': {
    name: 'Donsol',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-AQX': {
    name: 'Gubat',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-SNK': {
    name: 'Irosin',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-IQU': {
    name: 'Juban',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-RLG': {
    name: 'Magallanes',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-YEY': {
    name: 'Matnog',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-OPB': {
    name: 'Pilar',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-WRQ': {
    name: 'Prieto Diaz',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-AKV': {
    name: 'Santa Magdalena',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-05-D-HLH': {
    name: 'Sorsogon',
    regionID: 'PH-05',
    provinceID: 'PH-05-D',
  },
  'PH-06-D-MZO': {
    name: 'Altavas',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-NFE': {
    name: 'Balete',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-XLD': {
    name: 'Banga',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-OSO': {
    name: 'Batan',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-ZFR': {
    name: 'Buruanga',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-JVE': {
    name: 'Ibajay',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-EXL': {
    name: 'Kalibo',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-SHL': {
    name: 'Lezo',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-OIU': {
    name: 'Libacao',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-KDQ': {
    name: 'Madalag',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-FSL': {
    name: 'Makato',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-WCG': {
    name: 'Malay',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-AIJ': {
    name: 'Malinao',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-KPL': {
    name: 'Nabas',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-BDU': {
    name: 'New Washington',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-LVC': {
    name: 'Numancia',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-D-CEB': {
    name: 'Tangalan',
    regionID: 'PH-06',
    provinceID: 'PH-06-D',
  },
  'PH-06-E-LDF': {
    name: 'Anini-y',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-XTB': {
    name: 'Barbaza',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-BCS': {
    name: 'Belison',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-CLR': {
    name: 'Bugasong',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-ICW': {
    name: 'Caluya',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-ENH': {
    name: 'Culasi',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-ILJ': {
    name: 'Hamtic',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-BXK': {
    name: 'Laua-an',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-YKA': {
    name: 'Libertad',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-MDF': {
    name: 'Pandan',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-DOO': {
    name: 'Patnongon',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-CUD': {
    name: 'San Jose',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-DNU': {
    name: 'San Remigio',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-ORO': {
    name: 'Sebaste',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-ULK': {
    name: 'Sibalom',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-SIY': {
    name: 'Tibiao',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-EYU': {
    name: 'Tobias Fornier',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-E-WZB': {
    name: 'Valderrama',
    regionID: 'PH-06',
    provinceID: 'PH-06-E',
  },
  'PH-06-A-BNE': {
    name: 'Cuartero',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-NUB': {
    name: 'Dao',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-TKP': {
    name: 'Dumalag',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-LXG': {
    name: 'Dumarao',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-NAG': {
    name: 'Ivisan',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-GTI': {
    name: 'Jamindan',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-DEM': {
    name: 'Maayon',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-YOY': {
    name: 'Mambusao',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-ZKT': {
    name: 'Panay',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-TCR': {
    name: 'Panitan',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-IDO': {
    name: 'Pilar',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-PSV': {
    name: 'Pontevedra',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-YNZ': {
    name: 'President Roxas',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-JWB': {
    name: 'Sapian',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-LSK': {
    name: 'Sigma',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-A-DYA': {
    name: 'Tapaz',
    regionID: 'PH-06',
    provinceID: 'PH-06-A',
  },
  'PH-06-F-HBK': {
    name: 'Buenavista',
    regionID: 'PH-06',
    provinceID: 'PH-06-F',
  },
  'PH-06-F-LNP': {
    name: 'Jordan',
    regionID: 'PH-06',
    provinceID: 'PH-06-F',
  },
  'PH-06-F-AKF': {
    name: 'Nueva Valencia',
    regionID: 'PH-06',
    provinceID: 'PH-06-F',
  },
  'PH-06-F-NCD': {
    name: 'San Lorenzo',
    regionID: 'PH-06',
    provinceID: 'PH-06-F',
  },
  'PH-06-F-SWR': {
    name: 'Sibunag',
    regionID: 'PH-06',
    provinceID: 'PH-06-F',
  },
  'PH-06-B-AMF': {
    name: 'Ajuy',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-OBA': {
    name: 'Alimodian',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-LAW': {
    name: 'Anilao',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-SHG': {
    name: 'Badiangan',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-BLR': {
    name: 'Balasan',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-MZK': {
    name: 'Banate',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-BLQ': {
    name: 'Barotac Nuevo',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-SNU': {
    name: 'Barotac Viejo',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-ORS': {
    name: 'Batad',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-HCK': {
    name: 'Bingawan',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-UBZ': {
    name: 'Cabatuan',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-TPG': {
    name: 'Calinog',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-MHI': {
    name: 'Carles',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-XAD': {
    name: 'Concepcion',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-VPL': {
    name: 'Dingle',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-RUR': {
    name: 'Dueñas',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-IHH': {
    name: 'Dumangas',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-CVA': {
    name: 'Estancia',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-MZX': {
    name: 'Guimbal',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-GEG': {
    name: 'Igbaras',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-HEV': {
    name: 'Iloilo',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-KQN': {
    name: 'Janiuay',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-VRM': {
    name: 'Lambunao',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-QOW': {
    name: 'Leganes',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-DKH': {
    name: 'Lemery',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-GRP': {
    name: 'Leon',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-XZG': {
    name: 'Maasin',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-PVN': {
    name: 'Miagao',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-ATT': {
    name: 'Mina',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-CVY': {
    name: 'New Lucena',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-EHH': {
    name: 'Oton',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-OYF': {
    name: 'Pavia',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-NJO': {
    name: 'Pototan',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-GKZ': {
    name: 'San Dionisio',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-KNM': {
    name: 'San Enrique',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-VCM': {
    name: 'San Joaquin',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-IVV': {
    name: 'San Miguel',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-UHJ': {
    name: 'San Rafael',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-AWB': {
    name: 'Santa Barbara',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-ZJG': {
    name: 'Sara',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-XZW': {
    name: 'Tigbauan',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-LDN': {
    name: 'Tubungan',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-B-DSG': {
    name: 'Zarraga',
    regionID: 'PH-06',
    provinceID: 'PH-06-B',
  },
  'PH-06-C-FXP': {
    name: 'Binalbagan',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-EXK': {
    name: 'Calatrava',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-OMF': {
    name: 'Candoni',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-NLE': {
    name: 'Cauayan',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-OYV': {
    name: 'Enrique B. Magalona',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-PTH': {
    name: 'Hinigaran',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-BHZ': {
    name: 'Hinoba-an',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-FIM': {
    name: 'Ilog',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-TIT': {
    name: 'Isabela',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-IFS': {
    name: 'La Castellana',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-CFA': {
    name: 'Manapla',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-XAF': {
    name: 'Moises Padilla',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-OLK': {
    name: 'Murcia',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-AID': {
    name: 'Pontevedra',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-LTR': {
    name: 'Pulupandan',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-DBF': {
    name: 'Salvador Benedicto',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-GYT': {
    name: 'San Enrique',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-QYT': {
    name: 'Toboso',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-06-C-ZIV': {
    name: 'Valladolid',
    regionID: 'PH-06',
    provinceID: 'PH-06-C',
  },
  'PH-07-A-PFV': {
    name: 'Alburquerque',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-GML': {
    name: 'Alicia',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-GAF': {
    name: 'Anda',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-KBY': {
    name: 'Antequera',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-YZZ': {
    name: 'Baclayon',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-LGB': {
    name: 'Balilihan',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-PIT': {
    name: 'Batuan',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-LVS': {
    name: 'Bien Unido',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-LXK': {
    name: 'Bilar',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-REA': {
    name: 'Buenavista',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-WHD': {
    name: 'Calape',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-QQQ': {
    name: 'Candijay',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-PQT': {
    name: 'Carmen',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-LPW': {
    name: 'Catigbian',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-OFN': {
    name: 'Clarin',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-WDU': {
    name: 'Corella',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-UXJ': {
    name: 'Cortes',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-PBD': {
    name: 'Dagohoy',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-YAT': {
    name: 'Danao',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-BMJ': {
    name: 'Dauis',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-BHJ': {
    name: 'Dimiao',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-ZSP': {
    name: 'Duero',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-OMV': {
    name: 'Garcia Hernandez',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-CUA': {
    name: 'Getafe',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-SYF': {
    name: 'Guindulman',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-BPH': {
    name: 'Inabanga',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-FEJ': {
    name: 'Jagna',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-YFL': {
    name: 'Lila',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-SHK': {
    name: 'Loay',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-ZZF': {
    name: 'Loboc',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-QIT': {
    name: 'Loon',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-GRO': {
    name: 'Mabini',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-ITI': {
    name: 'Maribojoc',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-CGH': {
    name: 'Panglao',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-QRQ': {
    name: 'Pilar',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-HUN': {
    name: 'President Carlos P. Garcia',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-TSO': {
    name: 'Sagbayan',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-QIR': {
    name: 'San Isidro',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-NGC': {
    name: 'San Miguel',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-UVB': {
    name: 'Sevilla',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-LAO': {
    name: 'Sierra Bullones',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-ITH': {
    name: 'Sikatuna',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-HVN': {
    name: 'Talibon',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-YPU': {
    name: 'Trinidad',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-KWE': {
    name: 'Tubigon',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-OWL': {
    name: 'Ubay',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-A-GGT': {
    name: 'Valencia',
    regionID: 'PH-07',
    provinceID: 'PH-07-A',
  },
  'PH-07-B-UNO': {
    name: 'Alcantara',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-ZPT': {
    name: 'Alcoy',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-YAC': {
    name: 'Alegria',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-LWT': {
    name: 'Aloguinsan',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-TAT': {
    name: 'Argao',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-QJD': {
    name: 'Asturias',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-FKE': {
    name: 'Badian',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-OPQ': {
    name: 'Balamban',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-TZA': {
    name: 'Bantayan',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-UIP': {
    name: 'Barili',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-GWR': {
    name: 'Boljoon',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-EXV': {
    name: 'Borbon',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-OVA': {
    name: 'Carmen',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-JWD': {
    name: 'Catmon',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-TSB': {
    name: 'Cebu',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-XSR': {
    name: 'Compostela',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-VVL': {
    name: 'Consolacion',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-AKL': {
    name: 'Cordoba',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-YWX': {
    name: 'Daanbantayan',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-ITO': {
    name: 'Dalaguete',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-RTQ': {
    name: 'Dumanjug',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-SWN': {
    name: 'Ginatilan',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-DZE': {
    name: 'Liloan',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-YJG': {
    name: 'Madridejos',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-BBS': {
    name: 'Malabuyoc',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-FYV': {
    name: 'Medellin',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-LAF': {
    name: 'Minglanilla',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-TJA': {
    name: 'Moalboal',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-BSD': {
    name: 'Oslob',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-QJT': {
    name: 'Pilar',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-NCN': {
    name: 'Pinamungajan',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-SPO': {
    name: 'Poro',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-ZUX': {
    name: 'Ronda',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-DQK': {
    name: 'Samboan',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-EXA': {
    name: 'San Fernando',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-AWN': {
    name: 'San Francisco',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-EZW': {
    name: 'San Remigio',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-HVP': {
    name: 'Santa Fe',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-UHN': {
    name: 'Santander',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-MLJ': {
    name: 'Sibonga',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-EYA': {
    name: 'Sogod',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-TLQ': {
    name: 'Tabogon',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-HVI': {
    name: 'Tabuelan',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-THH': {
    name: 'Tuburan',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-B-CJC': {
    name: 'Tudela',
    regionID: 'PH-07',
    provinceID: 'PH-07-B',
  },
  'PH-07-C-SVS': {
    name: 'Amlan',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-DTW': {
    name: 'Ayungon',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-ZFH': {
    name: 'Bacong',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-APE': {
    name: 'Basay',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-DFY': {
    name: 'Bindoy',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-YDR': {
    name: 'Dauin',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-ZWR': {
    name: 'Jimalalud',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-JQQ': {
    name: 'La Libertad',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-QUL': {
    name: 'Mabinay',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-OGD': {
    name: 'Manjuyod',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-UXN': {
    name: 'Pamplona',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-VMJ': {
    name: 'San Jose',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-QIG': {
    name: 'Santa Catalina',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-AEI': {
    name: 'Siaton',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-ZKB': {
    name: 'Sibulan',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-WTD': {
    name: 'Tayasan',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-XAI': {
    name: 'Valencia',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-ICP': {
    name: 'Vallehermoso',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-C-MIS': {
    name: 'Zamboanguita',
    regionID: 'PH-07',
    provinceID: 'PH-07-C',
  },
  'PH-07-D-FEI': {
    name: 'Enrique Villanueva',
    regionID: 'PH-07',
    provinceID: 'PH-07-D',
  },
  'PH-07-D-JRF': {
    name: 'Larena',
    regionID: 'PH-07',
    provinceID: 'PH-07-D',
  },
  'PH-07-D-WUV': {
    name: 'Lazi',
    regionID: 'PH-07',
    provinceID: 'PH-07-D',
  },
  'PH-07-D-SZL': {
    name: 'Maria',
    regionID: 'PH-07',
    provinceID: 'PH-07-D',
  },
  'PH-07-D-ZDU': {
    name: 'Siquijor',
    regionID: 'PH-07',
    provinceID: 'PH-07-D',
  },
  'PH-08-E-JCG': {
    name: 'Almeria',
    regionID: 'PH-08',
    provinceID: 'PH-08-E',
  },
  'PH-08-E-FUT': {
    name: 'Biliran',
    regionID: 'PH-08',
    provinceID: 'PH-08-E',
  },
  'PH-08-E-BIQ': {
    name: 'Cabucgayan',
    regionID: 'PH-08',
    provinceID: 'PH-08-E',
  },
  'PH-08-E-DQO': {
    name: 'Caibiran',
    regionID: 'PH-08',
    provinceID: 'PH-08-E',
  },
  'PH-08-E-SNO': {
    name: 'Culaba',
    regionID: 'PH-08',
    provinceID: 'PH-08-E',
  },
  'PH-08-E-MXP': {
    name: 'Kawayan',
    regionID: 'PH-08',
    provinceID: 'PH-08-E',
  },
  'PH-08-E-MFN': {
    name: 'Maripipi',
    regionID: 'PH-08',
    provinceID: 'PH-08-E',
  },
  'PH-08-E-CVJ': {
    name: 'Naval',
    regionID: 'PH-08',
    provinceID: 'PH-08-E',
  },
  'PH-08-A-KBF': {
    name: 'Arteche',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-FAE': {
    name: 'Balangiga',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-IHA': {
    name: 'Balangkayan',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-CAN': {
    name: 'Can-avid',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-CXM': {
    name: 'Dolores',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-JNE': {
    name: 'General MacArthur',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-TOF': {
    name: 'Giporlos',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-HAA': {
    name: 'Guiuan',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-ART': {
    name: 'Hernani',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-JWG': {
    name: 'Jipapad',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-HXY': {
    name: 'Lawaan',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-QOE': {
    name: 'Llorente',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-WJF': {
    name: 'Maslog',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-KSS': {
    name: 'Maydolong',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-QTF': {
    name: 'Mercedes',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-NDV': {
    name: 'Oras',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-BDE': {
    name: 'Quinapondan',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-QWS': {
    name: 'Salcedo',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-RUS': {
    name: 'San Julian',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-LSV': {
    name: 'San Policarpo',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-HAP': {
    name: 'Sulat',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-A-XXQ': {
    name: 'Taft',
    regionID: 'PH-08',
    provinceID: 'PH-08-A',
  },
  'PH-08-B-HET': {
    name: 'Abuyog',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-IWJ': {
    name: 'Alangalang',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-MHF': {
    name: 'Albuera',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-TNL': {
    name: 'Babatngon',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-PXT': {
    name: 'Barugo',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-YUA': {
    name: 'Bato',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-AAQ': {
    name: 'Burauen',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-QEK': {
    name: 'Calubian',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-WST': {
    name: 'Capoocan',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-QNP': {
    name: 'Carigara',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-PUK': {
    name: 'Dagami',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-QOW': {
    name: 'Dulag',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-ZAD': {
    name: 'Hilongos',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-IUX': {
    name: 'Hindang',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-QMB': {
    name: 'Inopacan',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-BNF': {
    name: 'Isabel',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-NOE': {
    name: 'Jaro',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-AQZ': {
    name: 'Javier',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-PIU': {
    name: 'Julita',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-PMW': {
    name: 'Kananga',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-SJQ': {
    name: 'La Paz',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-ROJ': {
    name: 'Leyte',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-XME': {
    name: 'MacArthur',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-UAM': {
    name: 'Mahaplag',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-YPZ': {
    name: 'Matag-ob',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-RGT': {
    name: 'Matalom',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-KOV': {
    name: 'Mayorga',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-DMJ': {
    name: 'Merida',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-NUT': {
    name: 'Palo',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-VPC': {
    name: 'Palompon',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-TNZ': {
    name: 'Pastrana',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-YIM': {
    name: 'San Isidro',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-QMV': {
    name: 'San Miguel',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-VDE': {
    name: 'Santa Fe',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-HZM': {
    name: 'Tabango',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-XGQ': {
    name: 'Tabontabon',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-SXO': {
    name: 'Tanauan',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-HOU': {
    name: 'Tolosa',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-CGZ': {
    name: 'Tunga',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-B-MIG': {
    name: 'Villaba',
    regionID: 'PH-08',
    provinceID: 'PH-08-B',
  },
  'PH-08-F-SZH': {
    name: 'Allen',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-APY': {
    name: 'Biri',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-UJN': {
    name: 'Bobon',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-EFL': {
    name: 'Capul',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-ETC': {
    name: 'Pambujan',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-UUE': {
    name: 'Catubig',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-GCN': {
    name: 'Gamay',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-KUS': {
    name: 'Laoang',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-BUR': {
    name: 'Lapinig',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-UWC': {
    name: 'Las Navas',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-KOK': {
    name: 'Lavezares',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-JIC': {
    name: 'Lope de Vega',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-FJI': {
    name: 'Mapanas',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-QUK': {
    name: 'Mondragon',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-YGJ': {
    name: 'Palapag',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-HXD': {
    name: 'Rosario',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-YWA': {
    name: 'San Antonio',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-UUV': {
    name: 'San Isidro',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-CCS': {
    name: 'San Jose',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-IEJ': {
    name: 'San Roque',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-MDS': {
    name: 'San Vicente',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-NYN': {
    name: 'Silvino Lobos',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-F-BBH': {
    name: 'Victoria',
    regionID: 'PH-08',
    provinceID: 'PH-08-F',
  },
  'PH-08-G-SSK': {
    name: 'Almagro',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-LLX': {
    name: 'Basey',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-UJW': {
    name: 'Calbayog',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-OZZ': {
    name: 'Calbiga',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-YTF': {
    name: 'Catbalogan',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-IUS': {
    name: 'Daram',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-DXG': {
    name: 'Gandara',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-NXW': {
    name: 'Hinabangan',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-EPK': {
    name: 'Jiabong',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-SHU': {
    name: 'Marabut',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-PZF': {
    name: 'Matuguinao',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-GWS': {
    name: 'Motiong',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-RCY': {
    name: 'Pagsanghan',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-JAI': {
    name: 'Paranas',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-IWS': {
    name: 'Pinabacdao',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-MVH': {
    name: 'San Jorge',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-PAO': {
    name: 'San Jose de Buan',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-OWI': {
    name: 'San Sebastian',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-JGR': {
    name: 'Santa Margarita',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-UMN': {
    name: 'Santa Rita',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-VNA': {
    name: 'Santo Niño',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-PRG': {
    name: 'Tagapul-an',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-ITZ': {
    name: 'Talalora',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-NCE': {
    name: 'Tarangnan',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-IIJ': {
    name: 'Villareal',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-G-JKR': {
    name: 'Zumarraga',
    regionID: 'PH-08',
    provinceID: 'PH-08-G',
  },
  'PH-08-D-PCQ': {
    name: 'Anahawan',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-GJL': {
    name: 'Bontoc',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-KSL': {
    name: 'Hinunangan',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-WGE': {
    name: 'Hinundayan',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-MPJ': {
    name: 'Libagon',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-AEF': {
    name: 'Liloan',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-YCR': {
    name: 'Limasawa',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-CMP': {
    name: 'Macrohon',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-MEY': {
    name: 'Malitbog',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-GDX': {
    name: 'Padre Burgos',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-HEY': {
    name: 'Pintuyan',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-FQJ': {
    name: 'Saint Bernard',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-ICA': {
    name: 'San Francisco',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-SAI': {
    name: 'San Juan',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-PBR': {
    name: 'San Ricardo',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-KHK': {
    name: 'Silago',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-BCT': {
    name: 'Sogod',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-08-D-OXX': {
    name: 'Tomas Oppus',
    regionID: 'PH-08',
    provinceID: 'PH-08-D',
  },
  'PH-09-B-JGU': {
    name: 'Baliguian',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-CAE': {
    name: 'Godod',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-VSV': {
    name: 'Gutalac',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-NDD': {
    name: 'Jose Dalman',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-UDX': {
    name: 'Kalawit',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-VRS': {
    name: 'Katipunan',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-EAF': {
    name: 'La Libertad',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-ISV': {
    name: 'Labason',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-CMS': {
    name: 'Leon B. Postigo',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-IRE': {
    name: 'Liloy',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-WGX': {
    name: 'Manukan',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-MUI': {
    name: 'Mutia',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-MWP': {
    name: 'Piñan',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-VZH': {
    name: 'Polanco',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-GZJ': {
    name: 'President Manuel A. Roxas',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-PJD': {
    name: 'Rizal',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-WJG': {
    name: 'Salug',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-QZL': {
    name: 'Sergio Osmeña Sr.',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-RAF': {
    name: 'Siayan',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-HAP': {
    name: 'Sibuco',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-CEH': {
    name: 'Sibutad',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-KOD': {
    name: 'Sindangan',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-EJN': {
    name: 'Siocon',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-LBN': {
    name: 'Sirawai',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-B-VKI': {
    name: 'Tampilisan',
    regionID: 'PH-09',
    provinceID: 'PH-09-B',
  },
  'PH-09-C-RQI': {
    name: 'Aurora',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-NKL': {
    name: 'Bayog',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-PMR': {
    name: 'Dimataling',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-LNU': {
    name: 'Dinas',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-RHK': {
    name: 'Dumalinao',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-ZMK': {
    name: 'Dumingag',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-SSY': {
    name: 'Guipos',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-INT': {
    name: 'Josefina',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-VNH': {
    name: 'Kumalarang',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-JPM': {
    name: 'Labangan',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-NYO': {
    name: 'Lakewood',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-PPF': {
    name: 'Lapuyan',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-LUW': {
    name: 'Mahayag',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-FIH': {
    name: 'Margosatubig',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-GPL': {
    name: 'Midsalip',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-JNK': {
    name: 'Molave',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-ROS': {
    name: 'Pitogo',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-LPX': {
    name: 'Ramon Magsaysay',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-OYJ': {
    name: 'San Miguel',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-IHW': {
    name: 'San Pablo',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-YMH': {
    name: 'Sominot',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-HIO': {
    name: 'Tabina',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-PRP': {
    name: 'Tambulig',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-JZK': {
    name: 'Tigbao',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-LEJ': {
    name: 'Tukuran',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-RAL': {
    name: 'Vincenzo A. Sagun',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-C-XWU': {
    name: 'Zamboanga',
    regionID: 'PH-09',
    provinceID: 'PH-09-C',
  },
  'PH-09-D-JLY': {
    name: 'Alicia',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-OYQ': {
    name: 'Buug',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-CQP': {
    name: 'Diplahan',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-CVT': {
    name: 'Imelda',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-UUN': {
    name: 'Ipil',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-RBT': {
    name: 'Kabasalan',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-USQ': {
    name: 'Mabuhay',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-XSP': {
    name: 'Malangas',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-VHT': {
    name: 'Naga',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-IHA': {
    name: 'Olutanga',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-KPH': {
    name: 'Payao',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-YUM': {
    name: 'Roseller Lim',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-QGU': {
    name: 'Siay',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-EQR': {
    name: 'Talusan',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-JNZ': {
    name: 'Titay',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-09-D-WDB': {
    name: 'Tungawan',
    regionID: 'PH-09',
    provinceID: 'PH-09-D',
  },
  'PH-10-A-LXZ': {
    name: 'Baungon',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-VRC': {
    name: 'Cabanglasan',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-PRE': {
    name: 'Damulog',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-KJM': {
    name: 'Dangcagan',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-UIF': {
    name: 'Don Carlos',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-TEQ': {
    name: 'Impasugong',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-QFF': {
    name: 'Kadingilan',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-DMO': {
    name: 'Kalilangan',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-EUM': {
    name: 'Kibawe',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-CRX': {
    name: 'Kitaotao',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-ZMR': {
    name: 'Lantapan',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-VTO': {
    name: 'Libona',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-BOY': {
    name: 'Malitbog',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-DNN': {
    name: 'Manolo Fortich',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-PTH': {
    name: 'Maramag',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-WDG': {
    name: 'Pangantucan',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-AXD': {
    name: 'Quezon',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-PSE': {
    name: 'San Fernando',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-HTX': {
    name: 'Sumilao',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-A-FAD': {
    name: 'Talakag',
    regionID: 'PH-10',
    provinceID: 'PH-10-A',
  },
  'PH-10-E-HGN': {
    name: 'Catarman',
    regionID: 'PH-10',
    provinceID: 'PH-10-E',
  },
  'PH-10-E-TQE': {
    name: 'Guinsiliban',
    regionID: 'PH-10',
    provinceID: 'PH-10-E',
  },
  'PH-10-E-NVH': {
    name: 'Mahinog',
    regionID: 'PH-10',
    provinceID: 'PH-10-E',
  },
  'PH-10-E-KBT': {
    name: 'Mambajao',
    regionID: 'PH-10',
    provinceID: 'PH-10-E',
  },
  'PH-10-E-IOZ': {
    name: 'Sagay',
    regionID: 'PH-10',
    provinceID: 'PH-10-E',
  },
  'PH-10-B-VBZ': {
    name: 'Bacolod',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-FBD': {
    name: 'Baloi',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-AOD': {
    name: 'Baroy',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-JRJ': {
    name: 'Kapatagan',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-BUI': {
    name: 'Kauswagan',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-MEX': {
    name: 'Kolambugan',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-OMS': {
    name: 'Lala',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-ZME': {
    name: 'Linamon',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-TFH': {
    name: 'Magsaysay',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-HWA': {
    name: 'Maigo',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-KPA': {
    name: 'Matungao',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-XEC': {
    name: 'Munai',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-TXR': {
    name: 'Nunungan',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-DMO': {
    name: 'Pantao Ragat',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-GIP': {
    name: 'Pantar',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-VBA': {
    name: 'Poona Piagapo',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-SIZ': {
    name: 'Salvador',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-KJS': {
    name: 'Sapad',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-ALW': {
    name: 'Sultan Naga Dimaporo',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-XPQ': {
    name: 'Tagoloan',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-CMW': {
    name: 'Tangcal',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-B-DYU': {
    name: 'Tubod',
    regionID: 'PH-10',
    provinceID: 'PH-10-B',
  },
  'PH-10-C-NST': {
    name: 'Aloran',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-YTZ': {
    name: 'Baliangao',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-VHF': {
    name: 'Bonifacio',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-SVK': {
    name: 'Calamba',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-PWV': {
    name: 'Clarin',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-FIE': {
    name: 'Concepcion',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-WWQ': {
    name: 'Don Victoriano Chiongbian',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-LFF': {
    name: 'Jimenez',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-UWL': {
    name: 'Lopez Jaena',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-LIP': {
    name: 'Panaon',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-UGD': {
    name: 'Plaridel',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-DIQ': {
    name: 'Sapang Dalaga',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-ZKT': {
    name: 'Sinacaban',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-C-XQC': {
    name: 'Tudela',
    regionID: 'PH-10',
    provinceID: 'PH-10-C',
  },
  'PH-10-D-NNS': {
    name: 'Alubijid',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-ZGQ': {
    name: 'Balingasag',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-NIP': {
    name: 'Balingoan',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-CPI': {
    name: 'Binuangan',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-AGV': {
    name: 'Claveria',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-CQM': {
    name: 'Gitagum',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-CQQ': {
    name: 'Initao',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-FJL': {
    name: 'Jasaan',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-CSR': {
    name: 'Kinoguitan',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-MBH': {
    name: 'Lagonglong',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-JAR': {
    name: 'Laguindingan',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-ABF': {
    name: 'Libertad',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-CKV': {
    name: 'Lugait',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-WWV': {
    name: 'Magsaysay',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-MKA': {
    name: 'Manticao',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-GBD': {
    name: 'Medina',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-DZO': {
    name: 'Naawan',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-QUM': {
    name: 'Opol',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-FQT': {
    name: 'Salay',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-SOT': {
    name: 'Sugbongcogon',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-GDW': {
    name: 'Tagoloan',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-UOK': {
    name: 'Talisayan',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-10-D-KRA': {
    name: 'Villanueva',
    regionID: 'PH-10',
    provinceID: 'PH-10-D',
  },
  'PH-11-D-MXR': {
    name: 'Compostela',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-D-XUX': {
    name: 'Laak',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-D-EHJ': {
    name: 'Mabini',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-D-OQA': {
    name: 'Maco',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-D-LCC': {
    name: 'Maragusan',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-D-JQO': {
    name: 'Mawab',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-D-DNW': {
    name: 'Monkayo',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-D-CPH': {
    name: 'Montevista',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-D-RCZ': {
    name: 'Nabunturan',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-D-SIK': {
    name: 'New Bataan',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-D-SIJ': {
    name: 'Pantukan',
    regionID: 'PH-11',
    provinceID: 'PH-11-D',
  },
  'PH-11-A-BRM': {
    name: 'Asuncion',
    regionID: 'PH-11',
    provinceID: 'PH-11-A',
  },
  'PH-11-A-HRL': {
    name: 'Braulio E. Dujali',
    regionID: 'PH-11',
    provinceID: 'PH-11-A',
  },
  'PH-11-A-FOC': {
    name: 'Carmen',
    regionID: 'PH-11',
    provinceID: 'PH-11-A',
  },
  'PH-11-A-DMD': {
    name: 'Kapalong',
    regionID: 'PH-11',
    provinceID: 'PH-11-A',
  },
  'PH-11-A-YWE': {
    name: 'New Corella',
    regionID: 'PH-11',
    provinceID: 'PH-11-A',
  },
  'PH-11-A-VUK': {
    name: 'San Isidro',
    regionID: 'PH-11',
    provinceID: 'PH-11-A',
  },
  'PH-11-A-TGG': {
    name: 'Santo Tomas',
    regionID: 'PH-11',
    provinceID: 'PH-11-A',
  },
  'PH-11-A-HZM': {
    name: 'Talaingod',
    regionID: 'PH-11',
    provinceID: 'PH-11-A',
  },
  'PH-11-B-VCB': {
    name: 'Bansalan',
    regionID: 'PH-11',
    provinceID: 'PH-11-B',
  },
  'PH-11-B-CVF': {
    name: 'Davao',
    regionID: 'PH-11',
    provinceID: 'PH-11-B',
  },
  'PH-11-B-UOC': {
    name: 'Hagonoy',
    regionID: 'PH-11',
    provinceID: 'PH-11-B',
  },
  'PH-11-B-HIF': {
    name: 'Kiblawan',
    regionID: 'PH-11',
    provinceID: 'PH-11-B',
  },
  'PH-11-B-BVL': {
    name: 'Magsaysay',
    regionID: 'PH-11',
    provinceID: 'PH-11-B',
  },
  'PH-11-B-RXE': {
    name: 'Malalag',
    regionID: 'PH-11',
    provinceID: 'PH-11-B',
  },
  'PH-11-B-JVV': {
    name: 'Matanao',
    regionID: 'PH-11',
    provinceID: 'PH-11-B',
  },
  'PH-11-B-TAK': {
    name: 'Padada',
    regionID: 'PH-11',
    provinceID: 'PH-11-B',
  },
  'PH-11-B-CPB': {
    name: 'Santa Cruz',
    regionID: 'PH-11',
    provinceID: 'PH-11-B',
  },
  'PH-11-B-XIH': {
    name: 'Sulop',
    regionID: 'PH-11',
    provinceID: 'PH-11-B',
  },
  'PH-11-E-GGM': {
    name: 'Don Marcelino',
    regionID: 'PH-11',
    provinceID: 'PH-11-E',
  },
  'PH-11-E-IVG': {
    name: 'Jose Abad Santos',
    regionID: 'PH-11',
    provinceID: 'PH-11-E',
  },
  'PH-11-E-EYW': {
    name: 'Malita',
    regionID: 'PH-11',
    provinceID: 'PH-11-E',
  },
  'PH-11-E-XLQ': {
    name: 'Santa Maria',
    regionID: 'PH-11',
    provinceID: 'PH-11-E',
  },
  'PH-11-E-VCY': {
    name: 'Sarangani',
    regionID: 'PH-11',
    provinceID: 'PH-11-E',
  },
  'PH-11-C-JAQ': {
    name: 'Baganga',
    regionID: 'PH-11',
    provinceID: 'PH-11-C',
  },
  'PH-11-C-DZZ': {
    name: 'Banaybanay',
    regionID: 'PH-11',
    provinceID: 'PH-11-C',
  },
  'PH-11-C-KZY': {
    name: 'Boston',
    regionID: 'PH-11',
    provinceID: 'PH-11-C',
  },
  'PH-11-C-EGD': {
    name: 'Caraga',
    regionID: 'PH-11',
    provinceID: 'PH-11-C',
  },
  'PH-11-C-HQV': {
    name: 'Cateel',
    regionID: 'PH-11',
    provinceID: 'PH-11-C',
  },
  'PH-11-C-NJL': {
    name: 'Governor Generoso',
    regionID: 'PH-11',
    provinceID: 'PH-11-C',
  },
  'PH-11-C-WMO': {
    name: 'Lupon',
    regionID: 'PH-11',
    provinceID: 'PH-11-C',
  },
  'PH-11-C-OCI': {
    name: 'Manay',
    regionID: 'PH-11',
    provinceID: 'PH-11-C',
  },
  'PH-11-C-UFT': {
    name: 'San Isidro',
    regionID: 'PH-11',
    provinceID: 'PH-11-C',
  },
  'PH-11-C-TIF': {
    name: 'Tarragona',
    regionID: 'PH-11',
    provinceID: 'PH-11-C',
  },
  'PH-12-E-RWF': {
    name: 'Alamada',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-NEQ': {
    name: 'Aleosan',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-ISC': {
    name: 'Antipas',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-EDD': {
    name: 'Arakan',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-RRO': {
    name: 'Banisilan',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-XDJ': {
    name: 'Carmen',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-CQR': {
    name: 'Kabacan',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-RBV': {
    name: 'Kidapawan',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-QAN': {
    name: 'Libungan',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-GSE': {
    name: "M'lang",
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-HJZ': {
    name: 'Magpet',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-QFS': {
    name: 'Makilala',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-TIV': {
    name: 'Matalam',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-LIV': {
    name: 'Midsayap',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-IMB': {
    name: 'Pigcawayan',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-NXL': {
    name: 'Pikit',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-BZO': {
    name: 'President Roxas',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-E-QUS': {
    name: 'Tulunan',
    regionID: 'PH-12',
    provinceID: 'PH-12-E',
  },
  'PH-12-F-BGU': {
    name: 'Alabel',
    regionID: 'PH-12',
    provinceID: 'PH-12-F',
  },
  'PH-12-F-ZHC': {
    name: 'Glan',
    regionID: 'PH-12',
    provinceID: 'PH-12-F',
  },
  'PH-12-F-YAT': {
    name: 'Kiamba',
    regionID: 'PH-12',
    provinceID: 'PH-12-F',
  },
  'PH-12-F-SUI': {
    name: 'Maasim',
    regionID: 'PH-12',
    provinceID: 'PH-12-F',
  },
  'PH-12-F-HYK': {
    name: 'Maitum',
    regionID: 'PH-12',
    provinceID: 'PH-12-F',
  },
  'PH-12-F-VYC': {
    name: 'Malapatan',
    regionID: 'PH-12',
    provinceID: 'PH-12-F',
  },
  'PH-12-F-JDA': {
    name: 'Malungon',
    regionID: 'PH-12',
    provinceID: 'PH-12-F',
  },
  'PH-12-C-GSA': {
    name: 'Banga',
    regionID: 'PH-12',
    provinceID: 'PH-12-C',
  },
  'PH-12-C-PEA': {
    name: 'Lake Sebu',
    regionID: 'PH-12',
    provinceID: 'PH-12-C',
  },
  'PH-12-C-YAK': {
    name: 'Norala',
    regionID: 'PH-12',
    provinceID: 'PH-12-C',
  },
  'PH-12-C-VAI': {
    name: 'Polomolok',
    regionID: 'PH-12',
    provinceID: 'PH-12-C',
  },
  'PH-12-C-YQG': {
    name: 'Santo Niño',
    regionID: 'PH-12',
    provinceID: 'PH-12-C',
  },
  'PH-12-C-JPX': {
    name: 'Surallah',
    regionID: 'PH-12',
    provinceID: 'PH-12-C',
  },
  'PH-12-C-EGF': {
    name: "T'boli",
    regionID: 'PH-12',
    provinceID: 'PH-12-C',
  },
  'PH-12-C-NKI': {
    name: 'Tampakan',
    regionID: 'PH-12',
    provinceID: 'PH-12-C',
  },
  'PH-12-C-MER': {
    name: 'Tantangan',
    regionID: 'PH-12',
    provinceID: 'PH-12-C',
  },
  'PH-12-C-NGH': {
    name: 'Tupi',
    regionID: 'PH-12',
    provinceID: 'PH-12-C',
  },
  'PH-12-D-PZT': {
    name: 'Bagumbayan',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-12-D-WKK': {
    name: 'Columbio',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-12-D-RJH': {
    name: 'Esperanza',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-12-D-DOB': {
    name: 'Isulan',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-12-D-YZS': {
    name: 'Kalamansig',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-12-D-VOT': {
    name: 'Lambayong',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-12-D-ZBM': {
    name: 'Lebak',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-12-D-DNI': {
    name: 'Lutayan',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-12-D-XJB': {
    name: 'Palimbang',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-12-D-VLQ': {
    name: 'President Quirino',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-12-D-UEK': {
    name: 'Senator Ninoy Aquino',
    regionID: 'PH-12',
    provinceID: 'PH-12-D',
  },
  'PH-13-A-YZD': {
    name: 'Buenavista',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-GMD': {
    name: 'Butuan',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-FHC': {
    name: 'Cabadbaran',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-SPI': {
    name: 'Carmen',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-JZO': {
    name: 'Jabonga',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-CTR': {
    name: 'Kitcharao',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-JAL': {
    name: 'Las Nieves',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-GOP': {
    name: 'Magallanes',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-YVG': {
    name: 'Nasipit',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-LQE': {
    name: 'Remedios T. Romualdez',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-AHQ': {
    name: 'Santiago',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-A-OYB': {
    name: 'Tubay',
    regionID: 'PH-13',
    provinceID: 'PH-13-A',
  },
  'PH-13-B-XRL': {
    name: 'Bunawan',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-WXX': {
    name: 'Esperanza',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-UNY': {
    name: 'La Paz',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-BES': {
    name: 'Loreto',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-GHF': {
    name: 'Prosperidad',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-OAH': {
    name: 'Rosario',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-BXE': {
    name: 'San Francisco',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-XSQ': {
    name: 'San Luis',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-GZP': {
    name: 'Santa Josefa',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-JWL': {
    name: 'Sibagat',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-WSL': {
    name: 'Talacogon',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-GFO': {
    name: 'Trento',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-B-MJM': {
    name: 'Veruela',
    regionID: 'PH-13',
    provinceID: 'PH-13-B',
  },
  'PH-13-E-QAP': {
    name: 'Basilisa',
    regionID: 'PH-13',
    provinceID: 'PH-13-E',
  },
  'PH-13-E-FWR': {
    name: 'Cagdianao',
    regionID: 'PH-13',
    provinceID: 'PH-13-E',
  },
  'PH-13-E-UWX': {
    name: 'Dinagat',
    regionID: 'PH-13',
    provinceID: 'PH-13-E',
  },
  'PH-13-E-STL': {
    name: 'Libjo',
    regionID: 'PH-13',
    provinceID: 'PH-13-E',
  },
  'PH-13-E-WVJ': {
    name: 'Loreto',
    regionID: 'PH-13',
    provinceID: 'PH-13-E',
  },
  'PH-13-E-OPN': {
    name: 'San Jose',
    regionID: 'PH-13',
    provinceID: 'PH-13-E',
  },
  'PH-13-E-IWN': {
    name: 'Tubajon',
    regionID: 'PH-13',
    provinceID: 'PH-13-E',
  },
  'PH-13-C-ESZ': {
    name: 'Alegria',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-ATJ': {
    name: 'Bacuag',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-EUY': {
    name: 'Burgos',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-HKS': {
    name: 'Claver',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-REC': {
    name: 'Dapa',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-XHS': {
    name: 'Del Carmen',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-AYX': {
    name: 'General Luna',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-MHZ': {
    name: 'Gigaquit',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-ZDM': {
    name: 'Mainit',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-GNH': {
    name: 'Malimono',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-USS': {
    name: 'Pilar',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-PCT': {
    name: 'Placer',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-XPP': {
    name: 'San Benito',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-ICG': {
    name: 'San Francisco',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-NJG': {
    name: 'San Isidro',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-RAK': {
    name: 'Santa Monica',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-USU': {
    name: 'Sison',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-OGB': {
    name: 'Socorro',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-DIM': {
    name: 'Surigao',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-IDA': {
    name: 'Tagana-an',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-C-VRI': {
    name: 'Tubod',
    regionID: 'PH-13',
    provinceID: 'PH-13-C',
  },
  'PH-13-D-ISF': {
    name: 'Barobo',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-YDA': {
    name: 'Bayabas',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-HYE': {
    name: 'Cagwait',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-YPP': {
    name: 'Cantilan',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-NPG': {
    name: 'Carmen',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-QDP': {
    name: 'Carrascal',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-DZG': {
    name: 'Cortes',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-VQI': {
    name: 'Hinatuan',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-TWD': {
    name: 'Lanuza',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-ZHQ': {
    name: 'Lianga',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-EKT': {
    name: 'Lingig',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-LHD': {
    name: 'Madrid',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-LPH': {
    name: 'Marihatag',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-URP': {
    name: 'San Agustin',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-JWS': {
    name: 'San Miguel',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-QSN': {
    name: 'Tagbina',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-13-D-BBE': {
    name: 'Tago',
    regionID: 'PH-13',
    provinceID: 'PH-13-D',
  },
  'PH-09-A-JAK': {
    name: 'Akbar',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-09-A-GDN': {
    name: 'Al-Barka',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-09-A-YCI': {
    name: 'Hadji Mohammad Ajul',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-09-A-QUO': {
    name: 'Hadji Muhtamad',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-09-A-BWH': {
    name: 'Lantawan',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-09-A-JIK': {
    name: 'Maluso',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-09-A-MMO': {
    name: 'Sumisip',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-09-A-NFJ': {
    name: 'Tabuan-Lasa',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-09-A-ZVA': {
    name: 'Tipo-Tipo',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-09-A-PCI': {
    name: 'Tuburan',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-09-A-GEE': {
    name: 'Ungkaya Pukan',
    regionID: 'PH-09',
    provinceID: 'PH-09-A',
  },
  'PH-14-A-YWC': {
    name: 'Bacolod-Kalawi',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-ZXZ': {
    name: 'Balabagan',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-YIX': {
    name: 'Balindong',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-QIJ': {
    name: 'Bayang',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-FIZ': {
    name: 'Binidayan',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-NAT': {
    name: 'Buadiposo-Buntong',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-AAB': {
    name: 'Bubong',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-OVT': {
    name: 'Bumbaran',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-NJF': {
    name: 'Butig',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-HLN': {
    name: 'Calanogas',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-WSO': {
    name: 'Ditsaan-Ramain',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-ZNJ': {
    name: 'Ganassi',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-OAW': {
    name: 'Kapai',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-BPL': {
    name: 'Kapatagan',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-KCZ': {
    name: 'Lumba-Bayabao',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-FJE': {
    name: 'Lumbaca-Unayan',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-MCZ': {
    name: 'Lumbatan',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-GCJ': {
    name: 'Lumbayanague',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-ELT': {
    name: 'Madalum',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-YCR': {
    name: 'Madamba',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-DVY': {
    name: 'Maguing',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-WKR': {
    name: 'Malabang',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-ETI': {
    name: 'Marantao',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-UMU': {
    name: 'Marogong',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-SGB': {
    name: 'Masiu',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-AYB': {
    name: 'Mulondo',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-NLM': {
    name: 'Pagayawan',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-QOH': {
    name: 'Piagapo',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-WPO': {
    name: 'Poona Bayabao',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-VHO': {
    name: 'Pualas',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-XJH': {
    name: 'Saguiaran',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-HVM': {
    name: 'Sultan Dumalondong',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-PXB': {
    name: 'Picong',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-EXA': {
    name: 'Tagoloan II',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-ESC': {
    name: 'Tamparan',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-NOF': {
    name: 'Taraka',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-JBP': {
    name: 'Tubaran',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-HTL': {
    name: 'Tugaya',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-14-A-TXP': {
    name: 'Wao',
    regionID: 'PH-14',
    provinceID: 'PH-14-A',
  },
  'PH-12-B-IMX': {
    name: 'Ampatuan',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-PAO': {
    name: 'Barira',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-DLM': {
    name: 'Buldon',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-GOY': {
    name: 'Buluan',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-ZKY': {
    name: 'Cotabato',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-PSC': {
    name: 'Datu Abdullah Sangki',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-PBO': {
    name: 'Datu Anggal Midtimbang',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-OHU': {
    name: 'Datu Blah T. Sinsuat',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-EVN': {
    name: 'Datu Hoffer Ampatuan',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-FIY': {
    name: 'Datu Montawal',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-IMQ': {
    name: 'Datu Odin Sinsuat',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-FWM': {
    name: 'Datu Paglas',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-CUL': {
    name: 'Datu Piang',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-WPD': {
    name: 'Datu Salibo',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-UUR': {
    name: 'Datu Saudi-Ampatuan',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-XYO': {
    name: 'Datu Unsay',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-YCE': {
    name: 'General Salipada K. Pendatun',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-UWT': {
    name: 'Guindulungan',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-OLU': {
    name: 'Kabuntalan',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-MJB': {
    name: 'Mamasapano',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-KOC': {
    name: 'Mangudadatu',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-LYD': {
    name: 'Matanog',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-TWL': {
    name: 'Northern Kabuntalan',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-YAD': {
    name: 'Pagalungan',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-FAN': {
    name: 'Paglat',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-AEG': {
    name: 'Pandag',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-SQX': {
    name: 'Parang',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-IBJ': {
    name: 'Rajah Buayan',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-UOT': {
    name: 'Shariff Aguak',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-AGW': {
    name: 'Shariff Saydona Mustapha',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-EUG': {
    name: 'South Upi',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-QPT': {
    name: 'Sultan Kudarat',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-CXN': {
    name: 'Sultan Mastura',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-HRL': {
    name: 'Sultan sa Barongis',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-LZM': {
    name: 'Sultan Sumagka',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-OFL': {
    name: 'Talayan',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-12-B-ROD': {
    name: 'Upi',
    regionID: 'PH-12',
    provinceID: 'PH-12-B',
  },
  'PH-14-B-NQW': {
    name: 'Banguingui',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-GJI': {
    name: 'Hadji Panglima Tahil',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-LSQ': {
    name: 'Indanan',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-ZWP': {
    name: 'Jolo',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-HQZ': {
    name: 'Kalingalan Caluang',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-TOE': {
    name: 'Lugus',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-SUY': {
    name: 'Luuk',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-NQZ': {
    name: 'Maimbung',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-GSJ': {
    name: 'Old Panamao',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-XXM': {
    name: 'Omar',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-EYU': {
    name: 'Pandami',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-ZEC': {
    name: 'Panglima Estino',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-SKZ': {
    name: 'Pangutaran',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-CEN': {
    name: 'Parang',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-CCN': {
    name: 'Pata',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-KUM': {
    name: 'Patikul',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-VUX': {
    name: 'Siasi',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-CZK': {
    name: 'Talipao',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-B-NPR': {
    name: 'Tapul',
    regionID: 'PH-14',
    provinceID: 'PH-14-B',
  },
  'PH-14-C-ILM': {
    name: 'Bongao',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
  'PH-14-C-XWH': {
    name: 'Languyan',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
  'PH-14-C-HPD': {
    name: 'Mapun',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
  'PH-14-C-POE': {
    name: 'Panglima Sugala',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
  'PH-14-C-EPO': {
    name: 'Sapa-Sapa',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
  'PH-14-C-KKY': {
    name: 'Sibutu',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
  'PH-14-C-ALF': {
    name: 'Simunul',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
  'PH-14-C-MZV': {
    name: 'Sitangkai',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
  'PH-14-C-KEY': {
    name: 'South Ubian',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
  'PH-14-C-CRY': {
    name: 'Tandubas',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
  'PH-14-C-IHP': {
    name: 'Turtle Islands',
    regionID: 'PH-14',
    provinceID: 'PH-14-C',
  },
};

export const PRODUCT_TYPE = {
  WALLPAPER_MURAL: 'Wallpaper & Mural',
};

export const HIDDEN_BRANDS = {
  KERAMIKAB_INC: 'Keramikab Inc.',
};

export const LIMITED = 'LIMITED';

export const ALL = 'ALL';

export const SHOP = 'SHOP';

export const PREV = 'PREV';

export const NEXT = 'NEXT';

export const ACTIVE_LOWER_CASED = 'active';

export const INCREMENTAL = 'INCREMENTAL';

export const DECREMENTAL = 'DECREMENTAL';

export const LEFT = 'Left';

export const MAX_SHOP_MERCHANT_LOGO_PER_ROW = 5;

export const MIN_SHOP_MERCHANT_LOGO_PER_ROW = 3;

export const PCNT = 'PCNT';

export const EMPIRE_URL = 'https://www.empiredesigns.ph/';

// Screen Size Breakpoints
export const SCREEN_SIZE = {
  PHONE: 425,
  PHABLET: 560,
  SMALL_TABLET: 640,
  TABLET: 992,
  LAPTOP: 1024
};
// Ribbon Carousel for Navigation Links Item Count
export const NUM_ITEMS = {
  PHONE: 1,
  PHABLET: 2,
  SMALL_TABLET: 3,
  TABLET: 4,
  LAPTOP: 5,
  DESKTOP: 7,
};