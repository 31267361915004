import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Fade from 'react-reveal/Fade';

import logoPlaceholder from '../../assets/img/logo-placeholder.png';
import FooterComponent from '../../components/FooterComponent';
import NavbarLPComponent from '../../components/LandingPage/NavbarLPComponent';

import './partnerspage.scss';

function PartnersPage() {
  return (
    <div>
      <NavbarLPComponent />
      <CookieConsent
        buttonStyle={{
          background: '#d1755b',
          padding: '5px 20px',
          color: '#ffffff',
          fontSize: '13px',
        }}
        buttonText="I Agree"
        location="bottom"
        style={{ background: '#33332B' }}
      >
        This website uses cookies to enhance the user experience.{' '}
        <a className="cookies-link" href="/terms-of-use#cookies">
          Learn More.
        </a>
      </CookieConsent>
      <Fade>
        <div className="mt-64 p-5 text-center">
          <h2 className="page-title">Who We Work With</h2>
          <p className="body-1-reg">
            Our vendors and suppliers to provide for your interior needs.
          </p>
        </div>
        <div className="container container-wide partners">
          <div className="row partners-row">
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Dwell (Eastwood)</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Dwell Megamall</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Hive by Restoration</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Store One Inc. (restoration, shangrila)</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Store One Rockwell</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Dimensione</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Kenneth Cobonpue</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Living Space Home Furnishing</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Arena Home (Diamond Concept Inc)</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Dexterton Corporation</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">La Vida Verde</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Murillo</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Scavolini</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Habitat Manila</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Modliving</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Vendel Custom Furniture</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Casa Consunji</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Creationslab Inc.</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Bluebird Hospitality</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Perch</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Camerich Furniture</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Mandaue - Shaw Blvd. Branch</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Match Furniture</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Lufu Design Center Corp</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Silverspirit Interiors</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Cubix Office</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Eigenpost</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Living Innovations Corp.</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">Taylor Living</p>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2 partner-item">
              <img alt="partner" src={logoPlaceholder} />
              <p className="body-1-m">JMMG Furniture Shop</p>
            </div>
          </div>
        </div>

        <FooterComponent />
      </Fade>
    </div>
  );
}

export default PartnersPage;
