import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import NavbarLPComponent from "../../../components/LandingPage/NavbarLPComponent";
import FooterComponent from "../../../components/FooterComponent";

import "./../successpage.scss";

function SuccessContactFormPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <NavbarLPComponent />
      <div className="mt-100 p-5 text-center success-page">
        <h1>Thank you</h1>
        <p>
          Your form submission has been received.
        </p>
        <Link
          to="/home"
          variant="primary"
          className="btn-petra btntext-13-sb p-13-55 mt-2 btn btn-primary"
        >
          Back to Home
        </Link>
      </div>
      <FooterComponent />
    </div>
  );
}

export default SuccessContactFormPage;
