import React from "react";
import { Button } from "react-bootstrap";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";

import check from "../../../assets/img/StyleQuiz/check.svg";
import "../stylequizcomponent.scss";
import "../../../App.scss";

function StyleQuiz2Component(props) {
  return (
    <div className="mt-75 container container-wide">
      <div className="row mb-50">
        <div className="col-md-12 text-center">
          <Fade top>
            <h3 className="mb-20">What made you want to try out GussyApp?</h3>
            <p>Select all that applies.</p>
          </Fade>
        </div>
      </div>
      <div className="row justify-content-center">
        <Flip delay={300} left cascade>
          <div className="col-md-8 text-center">
            <div className="d-inline-block p-1">
              <Button onClick={props.choice1Click} variant="light" className={"body-1-m choices-box text-center " + props.choice1Class}>
                <img src={check} alt="check" className="choice-check display-none"/>
                I would like a more budget-friendly approach
              </Button>
            </div>
            <div className="d-inline-block p-1">
              <Button onClick={props.choice2Click} variant="light" className={"body-1-m choices-box text-center " + props.choice2Class}>
                <img src={check} alt="check" className="choice-check display-none"/>
                I am redecorating
              </Button>
            </div>
            <div className="d-inline-block p-1">
              <Button onClick={props.choice3Click} variant="light" className={"body-1-m choices-box text-center " + props.choice3Class}>
                <img src={check} alt="check" className="choice-check display-none"/>
                My family is getting bigger
              </Button>
            </div>
            <div className="d-inline-block p-1">
              <Button onClick={props.choice4Click} variant="light" className={"body-1-m choices-box text-center " + props.choice4Class}>
                <img src={check} alt="check" className="choice-check display-none"/>
                I want to style one corner of my home
              </Button>
            </div>
            <div className="d-inline-block p-1">
              <Button onClick={props.choice5Click} variant="light" className={"body-1-m choices-box text-center " + props.choice5Class}>
                <img src={check} alt="check" className="choice-check display-none"/>
                I just want help with my floor plan and layout
              </Button>
            </div>
            <div className="d-inline-block p-1">
              <Button onClick={props.choice6Click} variant="light" className={"body-1-m choices-box text-center " + props.choice6Class}>
                <img src={check} alt="check" className="choice-check display-none"/>
                I want to make sure everything in my space looks put-together
              </Button>
            </div>
            <div className="d-inline-block p-1">
              <Button onClick={props.choice7Click} variant="light" className={"body-1-m choices-box text-center " + props.choice7Class}>
                <img src={check} alt="check" className="choice-check display-none"/>
                I’m moving to a bigger or smaller place
              </Button>
            </div>
            <div className="d-inline-block p-1">
              <Button onClick={props.choice8Click} variant="light" className={"body-1-m choices-box text-center " + props.choice8Class}>
                <img src={check} alt="check" className="choice-check display-none"/>
                Other
              </Button>
            </div>
          </div>
        </Flip>
      </div>
    </div>
  );
}

export default StyleQuiz2Component;