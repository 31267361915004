import React from 'react';
import Fade from 'react-reveal/Fade';

import countryImage from '../../assets/img/about/001-img.jpg';

import AboutPageComponents from '../../components/AboutPageComponent';
import HelmetComponent from '../../components/HelmetComponent';
import LayoutComponent from '../../components/LayoutComponent';
import ReadyComponent from '../../components/ReadyComponent';

import './aboutpage.scss';

function AboutPage() {
  return (
    <LayoutComponent>
      <HelmetComponent
        description="Gussy is an online platform that combines e-design with a curated marketplace to provide new ways for today’s homeowner to create their dream home."
        image={countryImage}
        title="A first in the country"
      />
      <Fade>
        <AboutPageComponents />
      </Fade>
      <ReadyComponent />
    </LayoutComponent>
  );
}

export default AboutPage;
