import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import placeholderImage from '../../../assets/img/about/gussy-footer.png';

import './getthatlookthumbnail.scss';

import SkeletonElement from '../../SkeletonComponents/SkeletonElements';

function GetThatLookThumbnail(props) {
  const { imgUrls, key, name } = props.data;
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkImage();
  }, []);

  const checkImage = () => {
    if (!imgUrls) {
      setImage(placeholderImage);
      setIsLoading(false);
      return;
    }
    var img = new Image();
    img.onload = () => {
      setImage(
        `https://res.cloudinary.com/symphco/image/fetch/q_80,f_auto,fl_lossy/${encodeURIComponent(
          imgUrls[0].imgUrl,
        )}`,
      );
      setIsLoading(false);
    };
    img.onerror = () => {
      setImage(imgUrls[0].imgUrl);
      setIsLoading(false);
    };
    img.src = `https://res.cloudinary.com/symphco/image/fetch/q_80,f_auto,fl_lossy/${encodeURIComponent(
      imgUrls[0].imgUrl,
    )}`;
  };

  const renderImage = isLoading ? (
    <div className="get-that-look-image-div">
      <SkeletonElement type="get-that-look-img" />
    </div>
  ) : (
    <div className="get-that-look-image-div">
      <div
        className="get-that-look-image"
        style={{
          backgroundImage: `url("${image}")`,
        }}
      />
    </div>
  );

  return (
    <div className="get-that-look-thumbnail">
      <Link
        className="product-link"
        to={{ pathname: `/shop-look/${key}`, state: { data: props.data } }}
      >
        {renderImage}
        <h5 className="product-title">{name}</h5>
      </Link>
    </div>
  );
}

export default GetThatLookThumbnail;
