import React, { useContext, useState, useEffect } from 'react';
import { Button, Carousel } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';

import { GUSSY_REDIRECT_URL } from '../../../config/constants';
import { AuthContext } from '../../../context';
import { fetchHomePage } from '../../../queries/contentfulCMS';
import './carousellpcomponent.scss';

const { Caption, Item } = Carousel;

export default () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const [homePage, setHomePage] = useState([]);

  const getHomePage = async () => {
    const data = await fetchHomePage();
    setHomePage(data);
  };

  useEffect(() => {
    getHomePage();
  }, []);

  const onClickStyleQuiz = async () => {
    if (authContext.user?.hasStyleQuiz) {
      const token = await authContext.getToken(authContext.user.id);
      window.location.href = `${GUSSY_REDIRECT_URL}/client-timeline.html?token=${token}`;
    } else {
      history.push(`/style-quiz`);
    }
  };

  return homePage.map((val) => {
    const { heroBanner, heroText, heroButton } = val;
    return (
      <div
        className="carousel-lp mt-50"
        key={heroText}
        style={{ backgroundImage: `url(${heroBanner.file.url})` }}
      >
        <Fade>
          <Carousel controls={false} fade={false}>
            <Item>
              <Caption>
                <div className="row">
                  <div className="col-md-12 col-lg-8 text-left">
                    <h1 className="h176 text-shadow">{heroText}</h1>
                  </div>
                  <div className="col-md-4 text-center d-flex align-items-center">
                    <Button
                      className="btntext-18-sb w-auto btn-white obsidian-font p-16-50 my-3"
                      onClick={onClickStyleQuiz}
                    >
                      {heroButton}
                    </Button>
                  </div>
                </div>
              </Caption>
            </Item>
          </Carousel>
        </Fade>
      </div>
    );
  });
};
