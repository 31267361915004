import React, { useContext, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";

import { fetchSignInMethodsForEmail } from "firebase/auth";
import { CITIES, GUSSY_REDIRECT_URL, PROVINCES } from "../../config/constants";
import { AuthContext } from "../../context";
import { sortObjEntriesByName } from "../../utils/helpers";
import { authFirebase } from "../Firebase";

const ErrMsg = ({ msg = "Required" }) => (
    <Form.Text className="text-error">{msg}</Form.Text>
);

const CreateAccountForm = () => {
    const [provinceId, setProvinceId] = useState("");
    const { register, handleSubmit, errors, getValues, setValue } = useForm();
    const authContext = useContext(AuthContext);

    const onSubmit = async (data) => {
        let providers = [];
        if (!authContext.isLoggedIn) {
            providers = await fetchSignInMethodsForEmail(
                authFirebase,
                data.email,
            );
        }

        if (providers.length === 0) {
            Swal.fire({
                title: "Creating your account",
                text: "Please wait",
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                icon: "info",
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
            });
            await authContext.signUp(
                data.email,
                PROVINCES[data.province].regionID,
                data.city,
                data.zipCode,
                data.password,
                data.province,
                data.mobilePhone,
                data.firstName,
                data.lastName,
            );
            Swal.fire("Account successfully created!", "", "success");
        } else {
            Swal.fire(
                "Email taken!",
                "Please use a different email address",
                "warning",
            );
        }
    };

    const loginRedirect = async () => {
        const id = uuidv4();
        localStorage.setItem("uniqueId", id);
        window.location.href = `${GUSSY_REDIRECT_URL}/index.html?uuid=${id}&redirect_url=${window.location.href}`;
    };

    const renderCities = Object.entries(CITIES)
        .sort(sortObjEntriesByName)
        .filter(([_key, city]) => {
            if (provinceId) {
                if (provinceId === city.provinceID) {
                    return (
                        <option key={key} value={key}>
                            {city.name}
                        </option>
                    );
                }
            } else {
                return (
                    <option key={key} value={key}>
                        {city.name}
                    </option>
                );
            }
            return true;
        })
        .map(([key, city]) => (
            <option key={key} value={key}>
                {city.name}
            </option>
        ));

    const renderProvinces = Object.entries(PROVINCES)
        .sort(sortObjEntriesByName)
        .map(([key, province]) => (
            <option key={key} value={key}>
                {province.name}
            </option>
        ));

    const onChangeProvince = () => {
        setValue("city", "");
        setProvinceId(getValues("province"));
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
                <h4>Create an account</h4>
                <p className="body-1-reg">
                    Already have an account?{" "}
                    <button
                        className="petra"
                        type="button"
                        onClick={() => loginRedirect()}
                    >
                        Log in
                    </button>
                </p>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                            name="firstName"
                            ref={register({ required: true })}
                            type="text"
                        />
                        {errors.firstName && <ErrMsg />}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                            name="lastName"
                            ref={register({ required: true })}
                            type="text"
                        />
                        {errors.lastName && <ErrMsg />}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Mobile Phone</Form.Label>
                        <Form.Control
                            name="mobilePhone"
                            ref={register({ required: true })}
                            type="text"
                        />
                        {errors.mobilePhone && <ErrMsg />}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            name="email"
                            ref={register({ required: true })}
                            type="email"
                        />
                        {errors.email && <ErrMsg />}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>State/Province</Form.Label>
                        <Form.Control
                            as="select"
                            name="province"
                            ref={register({ required: true })}
                            onChange={onChangeProvince}
                        >
                            {renderProvinces}
                        </Form.Control>
                        {errors.province && <ErrMsg />}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>City/Municipality</Form.Label>
                        <Form.Control
                            as="select"
                            name="city"
                            ref={register({ required: true })}
                        >
                            {renderCities}
                        </Form.Control>
                        {errors.city && <ErrMsg />}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                            name="zipCode"
                            ref={register({ required: true })}
                            type="text"
                        />
                        {errors.zipCode && <ErrMsg />}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            name="password"
                            ref={register({
                                required: "Required",
                                pattern: {
                                    value: /^[a-zA-Z0-9]{6,}$/i,
                                    message: "Must be at least 6 characters",
                                },
                            })}
                            type="password"
                        />
                        {errors.password && (
                            <ErrMsg msg={errors.password.message} />
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            name="confirmPassword"
                            ref={register({
                                required: true,
                                validate: (value) => {
                                    if (value === getValues().password) {
                                        return true;
                                    }
                                    return "The passwords do not match";
                                },
                            })}
                            type="password"
                        />
                        {errors.confirmPassword && (
                            <ErrMsg msg={errors.confirmPassword.message} />
                        )}
                    </Form.Group>
                </Form.Row>
            </div>
            <div className="d-flex mt-3 justify-content-end">
                <Button className="mr-1 btn-outline-petra" href="/cart">
                    Return to Cart
                </Button>
                <Button className="ml-1 btn-petra" type="submit">
                    Create Account
                </Button>
            </div>
        </Form>
    );
};

export default CreateAccountForm;
