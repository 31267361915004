import React, { Component } from 'react';
import Fade from "react-reveal/Fade";
import CookieConsent from 'react-cookie-consent';

import NavbarLPComponent from "../../components/LandingPage/NavbarLPComponent";
import FooterComponent from '../../components/FooterComponent';

import './privacypolicypage.scss'
class PrivacyPolicyPage extends Component {
    render() {
        return (
            <div>
                <NavbarLPComponent />
                <CookieConsent
                    location="bottom"
                    buttonText="I Agree"
                    style={{ background: "#33332B" }}
                    buttonStyle={{ background:"#d1755b", padding: "5px 20px", color: "#ffffff", fontSize: "13px" }}
                >
                    This website uses cookies to enhance the user experience. <a href="/terms-of-use#cookies" className="cookies-link">Learn More.</a>
                </CookieConsent>
                <Fade>
                    <div className="mt-64 py-5 container container-wide terms-page">
                        <div className="row text-center mb-50">
                            <div className="col-md-10 offset-1 text-left">
                                <h2 className="page-title">Privacy Policy</h2>
                                <p>
                                    This Privacy Policy describes how your personal information is collected, used, and shared when you visit <a href="https://gussy.design/">https://gussy.design/</a> or use and make a purchase from the Gussy Design service (the "Service").
                                </p>
                                <h5>
                                    Personal information we collect
                                </h5>
                                <p>
                                    <strong>Device information.</strong> When you visit the Service, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Service, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Service, and information about how you interact with the Service. We refer to this automatically-collected information as “Device Information.” 
                                </p>
                                <p>
                                    We collect Device Information using the following technologies:
                                    <ul>
                                        <li>
                                            “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
                                        </li>
                                        <li>
                                            “Log files” track actions occurring on the Service, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
                                        </li>
                                        <li>
                                            “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Service.
                                        </li>
                                    </ul>
                                </p>
                                <p>
                                    <strong>User information.</strong> We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our Service, register on the Service, fill out a form, and in connection with other activities, services, features or resources we make available on our Service. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Service anonymously. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Service-related activities.
                                </p>
                                <p>
                                    <strong>Order information.</strong> When you make a purchase or attempt to make a purchase through the Service, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.  We refer to this information as “Order Information.”
                                    <br /> <br />
                                    When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information, User, and Order Information.
                                </p>
                                <h5>
                                    How we handle your payments
                                </h5>
                                <p>
                                    We use Paymaya Payment Gateway for its online credit and debit card transactions; DragonPay for bank transfer and over-the-counter transactions.
                                    <br /> <br />
                                    For more information on Paymaya about its data use policy, please visit: <a href="https://www.paymaya.com/privacy">https://www.paymaya.com/privacy</a>.
                                    <br /> <br />
                                    For more information on DragonPay about its privacy policy, please visit: <a href="https://www.dragonpay.ph/privacy-policy">https://www.dragonpay.ph/privacy-policy</a>.
                                </p>

                                <h5>
                                    How we use your personal information
                                </h5>
                                <p>
                                    We use the information that we collect generally to:
                                    <ul>
                                        <li>
                                            fulfill any orders placed through the Service (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). 
                                        </li>
                                        <li>
                                            communicate with you;
                                        </li>
                                        <li>
                                            screen our orders for potential risk or fraud; and
                                        </li>
                                        <li>
                                            when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
                                        </li>
                                        <li>
                                            screen for potential risk and fraud (in particular, your IP address)
                                        </li>
                                        <li>
                                            improve and optimize our Service (for example, by generating analytics about how our customers browse and interact with the Service, and to assess the success of our marketing and advertising campaigns).
                                        </li>
                                    </ul>
                                </p>

                                <h5>
                                    How we protect your information
                                </h5>
                                <p>
                                    We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Service.
                                    <br /> <br />
                                    We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                                </p>

                                <h5>
                                    Sharing your personal information
                                </h5>
                                <p>
                                    We share your Personal Information with third parties to help us use your Personal Information.  For example, we use Google Analytics to help us understand how our customers use the Service--you can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>.  You can also opt-out of Google Analytics here:  <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
                                    <br /> <br />
                                    We may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful requests for information we receive, or to otherwise protect our rights.
                                    <br /> <br />
                                    We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.
                                </p>
                                <h5>
                                    Your rights to your personal information
                                </h5>
                                <p>
                                You have the right to access the Personal Information we hold about you, to port it to a new service, and to ask that your Personal Information be corrected, updated, or deleted. If you would like to exercise these rights, please contact us through the contact information below.
                                </p>
                                <h5>
                                    Data from social networks
                                </h5>
                                <p>
                                    If you are logged into your social media account and visit our social media presence, the operator of the social media portal will be able to attribute this visit to your user account. In some circumstances, however, your personal data may also be collected if you are not logged in or do not have an account with the respective social media portal. In such cases, your data may be collected using cookies, which are stored on your end device, or by recording your IP address.
                                    <br /> <br />
                                    Please also note that we are unable to track all processing operations performed on social media portals. Therefore, depending on the provider, the operators of social media portals may perform further processing operations. For further details, consult the terms and conditions of use and the data privacy information provided by the respective social media portals. We have provided corresponding links in the information below relating to the respective providers.
                                    <br /> <br />
                                    We have no influence over the retention period for the data that social network operators store for their own purposes. For specific information in this regard, consult information provided by the social networks’ operators directly (e.g. in their data privacy statement; see below).
                                    <br /> <br />
                                    For more information on Facebook about its data use policy, please visit: <a href="http://www.facebook.com/about/privacy">http://www.facebook.com/about/privacy</a>.
                                    <br /> <br />
                                    For more information on Google about its privacy policy, please visit: <a href="http://www.google.com/intl/policies/privacy">http://www.google.com/intl/policies/privacy</a>.
                                </p>
                                <h5>
                                    Facebook Pixel notice
                                </h5>
                                <p>
                                    With your permission, our website utilizes the Facebook Pixel service of Facebook Inc, 1601 S. California Ave, Palo Alto, CA 94304, USA (“Facebook”). This tool allows us to follow the actions of users after they are redirected to a provider’s website by clicking on a Facebook advertisement. We are thus able to record the efficacy of Facebook advertisements for statistical and market research purposes. Using this data also allows us to improve our advertising for a better experience to the users and also retarget you with further advertising on facebook and the use of Custom Audiences. Which you can find more information about and revoke your permission from here: <a href="http://www.google.com/intl/policies/privacy">https://www.facebook.com/ads/website_custom_audiences/</a>.
                                    <br /> <br />
                                    However, the collected data are saved and processed by Facebook. We are informing you on this matter according to our information at this time. Facebook is able to connect the data with your Facebook account and use the data for their own advertising purposes in accordance with Facebook’s Data use Policy. Facebook Pixel Tracking allows Facebook and it’s partners to show you advertisements on and outside Facebook. In addition a cookie will be saved onto your computer for these purposes.
                                </p>
                                <h5>
                                    Links to other sites
                                </h5>
                                <p>
                                    This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                                </p>
                                <h5>
                                    Changes to this privacy policy
                                </h5>
                                <p>
                                    Gussy Design has the discretion to update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
                                    <br /> <br />
                                </p>
                                <h5>
                                    Your acceptance of these terms
                                </h5>
                                <p>
                                    By using this Service, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Service. Your continued use of the Service following the posting of changes to this policy will be deemed your acceptance of those changes.
                                </p>
                                <h5>
                                    Contacting us
                                </h5>
                                <p>
                                    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href="mailto:hello@gussy.design">hello@gussy.design</a>.
                                </p>

                            </div>
                        </div>
                    </div>
                    <FooterComponent />
                </Fade>
            </div>
        );
    }
}

export default PrivacyPolicyPage