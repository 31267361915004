import React from 'react';
import ReactDOM from 'react-dom';
import 'react-bootstrap';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthProvider from './providers/auth';
import CartCountProvider from './providers/cartCount';
import SweetAlertProvider from './providers/sweetalert';
import ShopFiltersProvider from './providers/shopFilters';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';

if (process.env.NODE_ENV === 'production') {
  const trackingId = 'UA-174503950-1'; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const queryClient = new QueryClient();

ReactDOM.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <CartCountProvider>
        <SweetAlertProvider>
          <ShopFiltersProvider>
            <App />
          </ShopFiltersProvider>
        </SweetAlertProvider>
      </CartCountProvider>
    </QueryClientProvider>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
