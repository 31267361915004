export function calculatePercentageDiscount({ notForSaleTotal, subTotal, voucherValues }) {
  const { isVoucherAvailForSale, maxDiscountAmount, voucherDiscount } = voucherValues;
  const percentDiscountedAmount = isVoucherAvailForSale
    ? subTotal * (voucherDiscount / 100)
    : notForSaleTotal * (voucherDiscount / 100);
  const hasExceedMaxDiscount = maxDiscountAmount < percentDiscountedAmount;

  return hasExceedMaxDiscount ? maxDiscountAmount : percentDiscountedAmount;
}

export function calculateAmountDiscount({ notForSaleTotal, voucherValues }) {
  const { isVoucherAvailForSale, maxDiscountAmount, voucherDiscount } = voucherValues;
  const discountedAmout = isVoucherAvailForSale
    ? voucherDiscount
    : notForSaleTotal - voucherDiscount;
  const hasExceedMaxDiscount = maxDiscountAmount < voucherDiscount;

  return hasExceedMaxDiscount ? maxDiscountAmount : discountedAmout;
}
