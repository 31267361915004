import React, { Component } from "react";
import CookieConsent from 'react-cookie-consent';

import NavbarLPComponent from "../../components/LandingPage/NavbarLPComponent";
import FooterComponent from "../../components/FooterComponent";

import "./reviewspage.scss";
class ReviewsPage extends Component {
  render() {
    return (
      <div>
        <NavbarLPComponent />
        <CookieConsent
            location="bottom"
            buttonText="I Agree"
            style={{ background: "#33332B" }}
            buttonStyle={{ background:"#d1755b", padding: "5px 20px", color: "#ffffff", fontSize: "13px" }}
        >
            This website uses cookies to enhance the user experience. <a href="/terms-of-use#cookies" className="cookies-link">Learn More.</a>
        </CookieConsent>
        <div className="mt-64 p-5 text-center">
          <h1>Reviews</h1>
        </div>
        <FooterComponent />
      </div>
    );
  }
}

export default ReviewsPage;
