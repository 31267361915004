import React, { useState, useEffect } from 'react';
import { ShopFilterContext } from '../context';
import { FILTERS } from '../config/constants';

const ShopFilterProvider = (props) => {
  const [productKeys, setProductKeys] = useState([]);
  const [paginatedKeys, setPaginatedKeys] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [sortBy, setSortBy] = useState('DEFAULT');
  const [filters, setFilters] = useState(FILTERS);

  useEffect(() => {
    if (sessionStorage.getItem('shopFilters')) {
      setFilters(JSON.parse(sessionStorage.getItem('shopFilters')));
    }
    if (sessionStorage.getItem('shopPaginatedKeys')) {
      setPaginatedKeys(JSON.parse(sessionStorage.getItem('shopPaginatedKeys')));
    }
    if (sessionStorage.getItem('shopProductKeys')) {
      setProductKeys(JSON.parse(sessionStorage.getItem('shopProductKeys')));
    }
    if (sessionStorage.getItem('shopPage')) {
      setPageNum(JSON.parse(sessionStorage.getItem('shopPage')));
    }
  }, []);

  const hasFilters = () => {
    let has = false;
    Object.entries(filters).forEach(([key, val]) => {
      if ((key === 'SEARCH' && val !== '') || Object.keys(val).length > 0) {
        has = true;
        return;
      }
    });
    return has;
  };

  return (
    <ShopFilterContext.Provider
      value={{
        filters: filters,
        productKeys: productKeys,
        paginatedKeys: paginatedKeys,
        pageNum: pageNum,
        sortBy: sortBy,
        hasFilters: () => hasFilters(),
        setFilters: (newFilters) => {
          sessionStorage.setItem('shopFilters', JSON.stringify(newFilters));
          setFilters(newFilters);
        },
        storeKeys: (keys) => {
          setProductKeys(keys);
          sessionStorage.setItem('shopProductKeys', JSON.stringify(keys));
        },
        storePaginatedKeys: (keys) => {
          setPaginatedKeys(keys);
          sessionStorage.setItem('shopPaginatedKeys', JSON.stringify(keys));
        },
        storePageNum: (page) => {
          setPageNum(page);
          sessionStorage.setItem('shopPage', JSON.stringify(page));
        },
        storeSortBy: (sortByVal) => {
          setSortBy(sortByVal);
          sessionStorage.setItem('shopSortBy', JSON.stringify(sortBy));
        },
        clearFilters: () => {
          setFilters(FILTERS);
          sessionStorage.setItem('shopFilters', JSON.stringify(FILTERS));
        },
        clearKeys: () => {
          setProductKeys([]);
          sessionStorage.setItem('shopProductKeys', JSON.stringify([]));
        },
        clearPaginatedKeys: () => {
          setPaginatedKeys([]);
          sessionStorage.setItem('shopPaginatedKeys', JSON.stringify([]));
        },
        reset: () => {
          setPaginatedKeys([]);
          setProductKeys([]);
          setFilters(FILTERS);
          setPageNum(1);

          sessionStorage.setItem('shopPaginatedKeys', JSON.stringify([]));
          sessionStorage.setItem('shopProductKeys', JSON.stringify([]));
          sessionStorage.setItem('shopFilters', JSON.stringify(FILTERS));
          sessionStorage.setItem('shopPage', JSON.stringify(1));
          sessionStorage.setItem('shopSortBy', JSON.stringify('DEFAULT'));
        },
      }}
    >
      {props.children}
    </ShopFilterContext.Provider>
  );
};

export default ShopFilterProvider;
