import React, { useState, useEffect, Suspense } from 'react';
import Carousel from 'react-multi-carousel';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import Loading from '../../components/Loading';

import { paymentGateways } from '../../config/images';
import {
  fetchGetThatLook,
  fetchGussyFinds,
  fetchLastFurnitureByLimit,
  fetchMerchants,
  fetchOnSaleByLimit,
  fetchPopularProducts,
  fetchShopBanners,
} from '../../queries/shop';
import { cloudinaryfyMultipleFormats, cloudinaryImagesFromAssets } from '../../utils/helpers';
import { ACTIVE_CAPITALIZED, MAX_SHOP_MERCHANT_LOGO_PER_ROW, MIN_SHOP_MERCHANT_LOGO_PER_ROW } from '../../config/constants';

import HelmetComponent from '../../components/HelmetComponent';
import LayoutComponent from '../../components/LayoutComponent';
import GetThatLookCarousel from '../../components/ShopComponents/GetThatLookCarousel';
import ProductCarousel from '../../components/ShopComponents/ProductCarousel';
import SearchBar from '../../components/ShopComponents/SearchBar';
import ShopNav from '../../components/ShopComponents/ShopNav';
import Picture from '../../components/Picture';

import SkeletonElement from '../../components/SkeletonComponents/SkeletonElements';

function ShopPage() {
  const [onSaleProducts, setOnSaleProducts] = useState([]);
  const [newArrivalProducts, setNewArrivalProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const [getThatLook, setGetThatLook] = useState([]);
  const [gussyFinds, setGussyFinds] = useState([]);
  const [shopBanners, setShopBanners] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [isDoneFetching, setIsDoneFetching] = useState(true);

  useEffect(() => {
    getShopBanners();
    getGetThatLookProducts();
    getGussyFindsProducts();
    getPopularProducts();
    getOnSaleProducts();
    getNewArrivalProducts();
    getMerchants();
  }, []);

  const getGetThatLookProducts = async () => {
    const data = await fetchGetThatLook();

    setGetThatLook(data);
    setIsDoneFetching(false);
  };

  const getNewArrivalProducts = async () => {
    const data = await fetchLastFurnitureByLimit(10);

    setNewArrivalProducts(data);
    setIsDoneFetching(false);
  };

  const getOnSaleProducts = async () => {
    const data = await fetchOnSaleByLimit(10);

    setOnSaleProducts(data);
    setIsDoneFetching(false);
  };

  const getPopularProducts = async () => {
    const data = await fetchPopularProducts(10);

    setPopularProducts(data);
    setIsDoneFetching(false);
  };

  const getGussyFindsProducts = async () => {
    const data = await fetchGussyFinds();

    setGussyFinds(data);
    setIsDoneFetching(false);
  };

  const getShopBanners = async () => {
    const data = await fetchShopBanners();

    setShopBanners(data);
    setIsDoneFetching(false);
  };

  const getMerchants = async () => {
    const merchantsData = await fetchMerchants();
    const filteredMerchants =
      merchantsData &&
      Object.values(merchantsData).filter((merchant) => {
        return merchant?.logoUrl && merchant?.status === ACTIVE_CAPITALIZED;
      });
    const mutatedMerchants =
      filteredMerchants?.map((merchant) => ({ src: merchant.logoUrl, alt: merchant.name })) ?? [];

    setMerchants(mutatedMerchants);
    setIsDoneFetching(false);
  };

  const renderOnSaleProducts = () => {
    if (onSaleProducts.length > 0) {
      return (
        <ProductCarousel
          color="petralight"
          products={onSaleProducts}
          text="On Sale"
          seeAllLink="/shop/page/sale"
        />
      );
    }
  };

  const renderNewArrivalProducts = () => {
    if (newArrivalProducts.length > 0) {
      return (
        <ProductCarousel
          products={newArrivalProducts}
          text="New Arrivals"
          seeAllLink="/shop/page/new"
        />
      );
    }
  };

  const renderMostPopularProducts = () => {
    if (popularProducts.length === 10) {
      return <ProductCarousel products={popularProducts} text="Most Popular" />;
    }
  };

  const renderGussyFindsProducts = () => {
    if (gussyFinds.length > 5) {
      return <ProductCarousel color="petralight" products={gussyFinds} text="Gussy Finds" />;
    }
  };

  const renderGetThatLook = () => {
    const activeLook = getThatLook.filter((look) => look.isActive);
    if (activeLook.length > 0) {
      return (
        <GetThatLookCarousel
          getThatLook={activeLook}
          products={[]}
          text={isDoneFetching ? <SkeletonElement type="h2-size" /> : 'Get That Look'}
          subhead="Take inspiration from Gussy's real life projects."
        />
      );
    }
  };

  function renderImage(key, src, alt) {
    const marginClass = isMobile ? '' : 'mt-5';

    return (
      <img alt={alt} className={`col-md-2 col-sm-6 ${marginClass}`} key={alt + key} src={src} />
    );
  }

  const renderPartners = () => {
    if (isMobile) {
      const numLogosPerRow = Math.max(MIN_SHOP_MERCHANT_LOGO_PER_ROW, Math.floor(merchants.length / 2));
      const chunkedMerchantRows = [];
      for (let i = 0; i < merchants.length; i += numLogosPerRow) {
        chunkedMerchantRows.push(merchants.slice(i, i + numLogosPerRow));
      }
  
      return chunkedMerchantRows.map((row, idx) => {
        return (
          <div key={idx}>
            {row.map((merchant) => {
              return renderImage(idx, merchant.src, merchant.alt);
            })}
          </div>
        );
      });

    } else {
      const rows = [...Array(Math.ceil(merchants.length / MAX_SHOP_MERCHANT_LOGO_PER_ROW))];
      const chunkedMerchantRows = rows.map((_, idx) =>
        merchants.slice(
          idx * MAX_SHOP_MERCHANT_LOGO_PER_ROW,
          idx * MAX_SHOP_MERCHANT_LOGO_PER_ROW + MAX_SHOP_MERCHANT_LOGO_PER_ROW,
        ),
      );

      return chunkedMerchantRows.map((row, idx) => {
        return (
          <div key={idx}>
            {row.map((merchant) => {
              return renderImage(idx, merchant.src, merchant.alt);
            })}
          </div>
        );
      });
    }
  };

  const renderPaymentPartners = () => {
    return (
      <div>
        {paymentGateways.map((val) => {
          return (
            <img
              key={val.alt}
              className="col-md-2 col-sm-6 mt-3"
              src={cloudinaryImagesFromAssets(val.src)}
              alt={val.alt}
            />
          );
        })}
      </div>
    );
  };

  const paymentPartners = renderPaymentPartners();

  const renderCarouselItems = () => {
    return shopBanners.map((val, idx) => {
      return (
        <Link to={val.link} key={idx}>
          {isMobile ? (
            <Picture
              images={cloudinaryfyMultipleFormats(val.mobileImageUrl)}
              alt={val.name}
              classNames="w-100 mb-20"
            />
          ) : (
            <Picture
              images={cloudinaryfyMultipleFormats(val.desktopImageUrl)}
              alt={val.name}
              classNames="w-100 mb-20"
            />
          )}
        </Link>
      );
    });
  };

  const carouselItems = isDoneFetching ? (
    <SkeletonElement type="shop-banner" />
  ) : (
    renderCarouselItems()
  );

  // no. of items to be shown in carousel depending on breakpoint
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const navlist = isDoneFetching ? (
    <div className="shop-nav d-flex">
      <SkeletonElement type="navlist" />
    </div>
  ) : (
    <ShopNav />
  );

  const renderLast = isDoneFetching ? (
    <div className="container container-wide">
      <div className="mt-40 mb-20">
        <SkeletonElement type="etc" />
        <SkeletonElement type="etc" />
        <SkeletonElement type="etc" />
      </div>
    </div>
  ) : (
    <div className="bg-gray9 vendors text-center p-5 ">
      <h3>Our Vendors and Partners</h3>
      <div className="mt-20">
        <div className="container container-wide">{renderPartners()}</div>
      </div>
      <h3 className="mt-100">Payment Gateways</h3>
      <div className="mt-20">
        <div className="container container-wide payment-gateways">{paymentPartners}</div>
      </div>
    </div>
  );

  return (
    <Suspense fallback={<Loading />}>
      <LayoutComponent>
        <HelmetComponent
          title="Shop"
          description="We have partnered with a wide array of global and local brands to give you the convenience of online home shopping."
        />
        {navlist}
        <div className="mt-20">
          <div className="container container-wide">
            {isMobile && <SearchBar />}
            <Carousel
              autoPlay
              draggable
              infinite
              arrows={true}
              autoPlaySpeed={5000}
              focusOnSelect={false}
              responsive={responsive}
            >
              {carouselItems}
            </Carousel>
          </div>
          {renderGetThatLook()}
          {renderGussyFindsProducts()}
          {renderMostPopularProducts()}
          {renderOnSaleProducts()}
          {renderNewArrivalProducts()}
          {renderLast}
        </div>
      </LayoutComponent>
    </Suspense>
  );
}

export default ShopPage;
