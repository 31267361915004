import React from "react";
import Swal from "sweetalert2";
import { SweetAlertContext } from "../context";

const SweetAlertProvider = (props) => {
  const primeColor = "#D0765B";
  return (
    <SweetAlertContext.Provider
      value={{
        loadingLocked: (title = "", text = "") =>
          Swal.fire({
            title,
            text,
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            icon: "info",
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          }),
        locked: (title = "", text = "") =>
          Swal.fire({
            title,
            text,
            confirmButtonColor: primeColor,
            showConfirmButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            icon: "warning",
            customClass: {
              title: "swal-title",
            },
          }),
        normal: (title = "", text = "", icon = "") =>
          Swal.fire(title, text, icon),
      }}
    >
      {props.children}
    </SweetAlertContext.Provider>
  );
};

export default SweetAlertProvider;
