import capitalize from "lodash.capitalize";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { Accordion, Button, Col, Form } from "react-bootstrap";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  ACTIVE,
  COLOR_FILTERS,
  HIDDEN_BRANDS,
  STYLE_FILTERS,
} from "../../config/constants";
import "./shopfilter.scss";

function ShopFilterComponent(props) {
  const { categories, onChangeFilter, filters, onClickReset, merchants } =
    props;

  const filterEmptyFilters = (el) => {
    return el !== "";
  };

  const renderProductTypes = (productTypes) => {
    const x = Object.entries(productTypes || {}).map(([k, v]) => {
      return (
        <Form.Check
          key={k}
          custom
          type="checkbox"
          id={`${k}---${v.name}`}
          label={v.name}
          className="filter-item-custom"
          onChange={onChangeFilter}
          checked={filters["PRODUCT_TYPE"][`${k}---${v.name}`] || false}
          name="PRODUCT_TYPE"
        />
      );
    });
    return x;
  };

  const renderFilters = () => {
    const prod = [];
    const temp = [
      "-M9rVmeR06OMA_JmOSuC",
      "-MA47tiQlUWy49jtnw3x",
      "-MA48AUMaL_086Wqxsli",
    ];
    if (Object.keys(categories || {}).length > 0) {
      Object.entries(categories).forEach(([key, value]) => {
        if (!temp.includes(key)) {
          prod.push(filterComponent(value.name, key, value.productTypes));
        }
      });
      temp.reverse().forEach((key) => {
        if (categories[key]) {
          prod.unshift(
            filterComponent(
              categories[key].name,
              key,
              categories[key].productTypes,
            ),
          );
        }
      });
    }
    return prod;
  };

  const renderColorFilters = COLOR_FILTERS.map((color) => (
    <Form.Check
      custom
      type="checkbox"
      id={color}
      label={capitalize(color)}
      className="filter-item-custom"
      onChange={onChangeFilter}
      name="COLOR"
      checked={filters["COLOR"][color]}
      key={color}
    />
  ));

  const renderStyleFilters = STYLE_FILTERS.map((style) => (
    <Form.Check
      custom
      type="checkbox"
      id={style}
      label={capitalize(style)}
      className="filter-item-custom"
      onChange={onChangeFilter}
      name="STYLE"
      checked={filters["STYLE"][style] || false}
      key={style}
    />
  ));

  const filteredMerchants = Object.entries(merchants).filter(
    ([_, merchant]) => merchant.name !== HIDDEN_BRANDS.KERAMIKAB_INC,
  );

  const renderMerchantFilters = (filteredMerchants || {})
    .sort((a, b) => {
      const textA = a[1].name.toUpperCase();
      const textB = b[1].name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .map(([key, merchant]) => {
      if (
        merchant.status.toUpperCase() === ACTIVE &&
        merchant.name !== "Detalia Aurora"
      ) {
        return (
          <Form.Check
            custom
            type="checkbox"
            id={key}
            label={merchant.name}
            className="filter-item-custom"
            onChange={onChangeFilter}
            name="MERCHANT"
            checked={filters["MERCHANT"][key] || false}
            key={key}
          />
        );
      } else {
        return null;
      }
    });

  const filterComponent = (name, key, productTypes) => {
    let isDefaultOpen = false;
    if (productTypes) {
      Object.keys(filters["PRODUCT_TYPE"]).forEach((elem) => {
        if (Object.keys(productTypes).includes(elem)) {
          isDefaultOpen = true;
        }
      });
    }

    return (
      <Accordion
        className="filter-accordion"
        key={key}
        defaultActiveKey={isDefaultOpen ? key : ""}
      >
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey={key}
          className="filter-toggle"
        >
          {name}
          <ExpandMoreIcon />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={key}>
          <div key={key}>{renderProductTypes(productTypes)}</div>
        </Accordion.Collapse>
      </Accordion>
    );
  };

  return (
    <>
      <div className="filter-header d-flex justify-content-between align-items-center">
        <h4>Filter</h4>
        <Button variant="link" onClick={onClickReset}>
          Reset
        </Button>
      </div>
      {renderFilters()}
      <Accordion
        className="filter-accordion"
        defaultActiveKey={
          !isEmpty(Object.values(filters["PRICE"]).filter(filterEmptyFilters))
            ? "priceFilter"
            : ""
        }
      >
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="priceFilter"
          className="filter-toggle"
        >
          Price
          <ExpandMoreIcon />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="priceFilter">
          <Form.Group className="mb-1">
            <Form.Row className="align-items-end">
              <Col>
                <Form.Control
                  size="sm"
                  type="number"
                  placeholder="Min Price"
                  onChange={onChangeFilter}
                  name="PRICE"
                  id="min"
                  value={filters["PRICE"]?.min || ""}
                />
              </Col>
              <span className="body-1-reg smoke">-</span>
              <Col>
                <Form.Control
                  size="sm"
                  type="number"
                  placeholder="Max Price"
                  onChange={onChangeFilter}
                  name="PRICE"
                  id="max"
                  value={filters["PRICE"]?.max || ""}
                />
              </Col>
            </Form.Row>
          </Form.Group>
        </Accordion.Collapse>
      </Accordion>
      <Accordion
        className="filter-accordion"
        defaultActiveKey={
          !isEmpty(
            Object.values(filters["WIDTH"]).filter(filterEmptyFilters),
          ) ||
          !isEmpty(
            Object.values(filters["DEPTH"]).filter(filterEmptyFilters),
          ) ||
          !isEmpty(Object.values(filters["HEIGHT"]).filter(filterEmptyFilters))
            ? "dimensionsFilter"
            : ""
        }
      >
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="dimensionsFilter"
          className="filter-toggle"
        >
          Dimensions
          <ExpandMoreIcon />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="dimensionsFilter">
          <>
            <Form.Group>
              <Form.Label className="label2">Width (cm)</Form.Label>
              <Form.Row className="align-items-end">
                <Col>
                  <Form.Control
                    size="sm"
                    type="number"
                    placeholder="Min Width"
                    onChange={onChangeFilter}
                    name="WIDTH"
                    id="min"
                    value={filters["WIDTH"]?.min || ""}
                  />
                </Col>
                <span className="body-1-reg smoke">-</span>
                <Col>
                  <Form.Control
                    size="sm"
                    type="number"
                    placeholder="Max Width"
                    onChange={onChangeFilter}
                    name="WIDTH"
                    id="max"
                    value={filters["WIDTH"]?.max || ""}
                  />
                </Col>
              </Form.Row>
            </Form.Group>
            <Form.Group>
              <Form.Label className="label2">Depth (cm)</Form.Label>
              <Form.Row className="align-items-end">
                <Col>
                  <Form.Control
                    size="sm"
                    type="number"
                    placeholder="Min Depth"
                    onChange={onChangeFilter}
                    name="DEPTH"
                    id="min"
                    value={filters["DEPTH"]?.min || ""}
                  />
                </Col>
                <span className="body-1-reg smoke">-</span>
                <Col>
                  <Form.Control
                    size="sm"
                    type="number"
                    placeholder="Max Depth"
                    onChange={onChangeFilter}
                    name="DEPTH"
                    id="max"
                    value={filters["DEPTH"]?.max || ""}
                  />
                </Col>
              </Form.Row>
            </Form.Group>
            <Form.Group>
              <Form.Label className="label2">Height (cm)</Form.Label>
              <Form.Row className="align-items-end">
                <Col>
                  <Form.Control
                    size="sm"
                    type="number"
                    placeholder="Min Height"
                    onChange={onChangeFilter}
                    name="HEIGHT"
                    id="min"
                    value={filters["HEIGHT"]?.min || ""}
                  />
                </Col>
                <span className="body-1-reg smoke">-</span>
                <Col>
                  <Form.Control
                    size="sm"
                    type="number"
                    placeholder="Max Height"
                    onChange={onChangeFilter}
                    name="HEIGHT"
                    id="max"
                    value={filters["HEIGHT"]?.max || ""}
                  />
                </Col>
              </Form.Row>
            </Form.Group>
          </>
        </Accordion.Collapse>
      </Accordion>
      <Accordion
        className="filter-accordion"
        defaultActiveKey={
          Object.values(filters["COLOR"]).includes(true) ? "colorFilter" : ""
        }
      >
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="colorFilter"
          className="filter-toggle"
        >
          Color
          <ExpandMoreIcon />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="colorFilter">
          <div key="colorFilters">{renderColorFilters}</div>
        </Accordion.Collapse>
      </Accordion>
      <Accordion
        className="filter-accordion"
        defaultActiveKey={
          Object.values(filters["STYLE"]).includes(true) ? "styleFilter" : ""
        }
      >
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="styleFilter"
          className="filter-toggle"
        >
          Style
          <ExpandMoreIcon />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="styleFilter">
          <div key="styleFilters">{renderStyleFilters}</div>
        </Accordion.Collapse>
      </Accordion>
      <Accordion
        className="filter-accordion"
        defaultActiveKey={
          Object.values(filters["MERCHANT"]).includes(true)
            ? "merchantFilter"
            : ""
        }
      >
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="merchantFilter"
          className="filter-toggle"
        >
          Brand
          <ExpandMoreIcon />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="merchantFilter">
          <div key="merchantFilters">{renderMerchantFilters}</div>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
}

export default ShopFilterComponent;
