import uniq from 'lodash.uniq';
import chunk from 'lodash.chunk';
import shuffle from 'lodash.shuffle';
import {
  dbFirebase,
  storageFirebase,
  firestore,
  authFirebase,
  functionsFirebase,
} from '../components/Firebase';
import { httpsCallable } from 'firebase/functions';
import { collection, query, where, getDocs, getDoc, limit, doc } from 'firebase/firestore/lite';
import {
  ref as refDB,
  query as queryDB,
  orderByKey,
  orderByValue,
  limitToLast,
  get,
  update,
  remove,
  set,
} from 'firebase/database';
import { getDownloadURL, ref } from 'firebase/storage';

const productsCollection = collection(firestore, 'products');

export const fetchProductDetails = async (key) => {
  try {
    const snap = await get(refDB(dbFirebase, `furnitures/${key}`));
    if (snap.val()) {
      return { ...snap.val(), id: snap.key };
    } else {
      return snap.val();
    }
  } catch {
    return [];
  }
};

export const fetchProductKeys = async (filters) => {
  try {
    let results = {
      PRODUCT_TYPE: {},
      PRICE: {},
      WIDTH: {},
      DEPTH: {},
      HEIGHT: {},
      COLOR: {},
      SALE: {},
      STYLE: {},
      SEARCH: '',
      NEW: {},
      MERCHANT: {},
      CATEGORY_ID: '',
      PRODUCT_TYPE_ID: '',
    };
    let hasFilter = false;
    let keys = [];
    let searchTermFilters = [];
    const selectedFilters = [];
    const promises = Object.entries(filters || {}).map(([key, filter]) => {
      switch (key) {
        case 'PRODUCT_TYPE':
          keys = Object.keys(filter || {}).filter((x) => filter[x] === true);
          if (keys.length !== 0) {
            hasFilter = true;
            selectedFilters.push(key);
            return fetchByProductType(keys, (res) => {
              results[key] = res;
            });
          }
          break;

        case 'MERCHANT':
          keys = Object.keys(filter || {}).filter((x) => filter[x] === true);
          if (keys.length !== 0) {
            hasFilter = true;
            selectedFilters.push(key);
            return fetchByMerchant(keys, (res) => {
              results[key] = res;
            });
          }
          break;
        case 'COLOR':
          keys = Object.keys(filter || {}).filter((x) => filter[x] === true);
          if (keys.length !== 0) {
            hasFilter = true;
            selectedFilters.push(key);
            return fetchByColorTags(keys, (res) => {
              results[key] = res;
            });
          }
          break;
        case 'STYLE':
          keys = Object.keys(filter || {}).filter((x) => filter[x] === true);
          if (keys.length !== 0) {
            hasFilter = true;
            selectedFilters.push(key);
            searchTermFilters = [...searchTermFilters, ...keys];
            return fetchBySearchTags(searchTermFilters, (res) => {
              results[key] = res;
            });
          }
          break;
        case 'PRICE':
          if (filter.min || filter.max) {
            hasFilter = true;
            selectedFilters.push(key);
            return fetchByPriceRange(parseFloat(filter.min), parseFloat(filter.max), (res) => {
              results[key] = res;
            });
          }
          break;
        case 'DEPTH':
          if (filter.min || filter.max) {
            hasFilter = true;
            selectedFilters.push(key);
            return fetchByDepthRange(parseFloat(filter.min), parseFloat(filter.max), (res) => {
              results[key] = res;
            });
          }
          break;
        case 'WIDTH':
          if (filter.min || filter.max) {
            hasFilter = true;
            selectedFilters.push(key);
            return fetchByWidthRange(parseFloat(filter.min), parseFloat(filter.max), (res) => {
              results[key] = res;
            });
          }
          break;
        case 'HEIGHT':
          if (filter.min || filter.max) {
            hasFilter = true;
            selectedFilters.push(key);
            return fetchByHeightRange(parseFloat(filter.min), parseFloat(filter.max), (res) => {
              results[key] = res;
            });
          }
          break;
        case 'SEARCH':
          if (filter) {
            hasFilter = true;
            selectedFilters.push(key);
            searchTermFilters.push(filter.toLowerCase());
            return fetchByNameTags(searchTermFilters, (res) => {
              results[key] = res;
            });
          }
          break;
        case 'SALE':
          if (filter) {
            hasFilter = true;
            selectedFilters.push(key);
            return fetchOnSale((res) => {
              results[key] = res;
            });
          }
          break;
        case 'NEW':
          if (filter) {
            hasFilter = true;
            selectedFilters.push(key);
            return fetchNewArrival((res) => {
              results[key] = res;
            });
          }
          break;
        default:
          break;
      }
    });
    if (hasFilter) {
      return Promise.all(promises).then(() => {
        // returns an array of keys per filter
        const furnKeysPerFilter = Object.entries(results || {})
          .filter(([key, val]) => selectedFilters.includes(key))
          .map(([key, val]) => val);

        // returns the common keys given the multiple arrays
        const commonKeys = furnKeysPerFilter.shift().filter(function (v) {
          return furnKeysPerFilter.every(function (a) {
            return a.indexOf(v) !== -1;
          });
        });
        return uniq(commonKeys);
      });
    } else {
      await fetchAll((res) => {
        results = sortKeys(res);
      });
    }
    return results;
  } catch {
    return [];
  }
};

export const fetchByPriceRange = async (min, max, res) => {
  const q = query(
    productsCollection,
    where('price', '<=', max || 9999999),
    where('price', '>=', min || 0),
  );

  return await getDocs(q)
    .then((snap) => res(snap.docs.map((doc) => doc.id)))
    .catch((err) => console.error(err));
};

export const fetchByWidthRange = async (min, max, res) => {
  const q = query(
    productsCollection,
    where('width', '<=', max || 9999999),
    where('width', '>=', min || 0),
  );

  return await getDocs(q)
    .then((snap) => res(snap.docs.map((doc) => doc.id)))
    .catch((err) => console.error(err));
};

export const fetchByHeightRange = async (min, max, res) => {
  const q = query(
    productsCollection,
    where('height', '<=', max || 9999999),
    where('height', '>=', min || 0),
  );

  return await getDocs(q)
    .then((snap) => res(snap.docs.map((doc) => doc.id)))
    .catch((err) => console.error(err));
};

export const fetchByDepthRange = async (min, max, res) => {
  const q = query(
    productsCollection,
    where('depth', '<=', max || 9999999),
    where('depth', '>=', min || 0),
  );

  return await getDocs(q)
    .then((snap) => res(snap.docs.map((doc) => doc.id)))
    .catch((err) => console.error(err));
};

export const fetchByProductType = async (names, res) => {
  const tmpKeys = await fetchByProductTypeName(names);
  const keys = [...new Set(tmpKeys)];

  const prodKeys = [];
  const promises = keys.map((key) =>
    get(refDB(dbFirebase, `productTypeFurnitures/${key}`)).then((snap) => {
      if (snap.val()) {
        prodKeys.push(...Object.keys(snap.val()));
      }
    }),
  );
  return Promise.all(promises).then(() => res(prodKeys));
};

export const fetchByProductTypeName = async (keys) => {
  const names = keys.map((key) => key.split('---')[1]);
  const prodKeys = [];

  await Promise.all(
    names.map((name) =>
      get(refDB(dbFirebase, `productTypesByName/${name.trim().replace(/^-/g, '')}`)).then(
        (snap) => {
          if (snap.val()) {
            prodKeys.push(...Object.keys(snap.val() || {}));
          }
        },
      ),
    ),
  );

  return prodKeys;
};

export const fetchByColorTags = async (keys, res) => {
  const tmpKeys = [];
  const tmp = chunk(keys, 10);
  const promises = tmp.map((x) => {
    const q = query(productsCollection, where('colorTags', 'array-contains-any', x));

    return getDocs(q)
      .then((snap) => {
        tmpKeys.push(...snap.docs.map((doc) => doc.id));
      })
      .catch((err) => console.error(err));
  });
  return Promise.all(promises).then(() => {
    res(tmpKeys);
  });
};

export const fetchByNameTags = async (searchTags, res) => {
  const tags = searchTags.map((tag) => tag.trim().split(' ')).flat();
  let newTags = [];

  tags.forEach((tag) => {
    if (tag.slice(-1) === 's') {
      newTags.push(tag.slice(0, -1));
    } else {
      newTags.push(`${tag}s`);
    }
    newTags.push(tag);
  });

  const q = query(
    productsCollection,
    where('nameTags', 'array-contains-any', newTags),
    where('active', '==', true),
  );

  return await getDocs(q)
    .then((snap) => {
      const data = sortByNameTagRelevance(
        snap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        }),
        newTags,
      );
      let products;

      if (res) {
        products = data.map((product) => product.id);
      } else {
        products = uniq(data);
      }

      return fetchBySearchTags(newTags, res, products);
    })
    .catch((err) => console.error(err));
};

export const fetchBySearchTags = async (searchTags, res, nameResults) => {
  const tags = searchTags.map((tag) => tag.trim().split(' ')).flat();
  let newTags = [];

  tags.forEach((tag) => {
    if (tag.slice(-1) === 's') {
      newTags.push(tag.slice(0, -1));
    } else {
      newTags.push(`${tag}s`);
    }
    newTags.push(tag);
  });

  const q = query(
    productsCollection,
    where('searchTags', 'array-contains-any', uniq(newTags)),
    where('active', '==', true),
  );

  return await getDocs(q)
    .then((snap) => {
      if (nameResults) {
        if (res) {
          const resIds = nameResults.concat(snap.docs.map((doc) => doc.id));
          const results = uniq(resIds);

          res(results);
        } else {
          const products = snap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });

          return uniq(nameResults.concat(products));
        }
      } else {
        if (res) {
          res(snap.docs.map((doc) => doc.id));
        } else {
          const products = snap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          return uniq(products);
        }
      }
    })
    .catch((err) => console.error(err));
};

export const fetchOnSale = async (res) => {
  const q = query(productsCollection, where('isOnSale', '==', true), where('active', '==', true));

  return await getDocs(q)
    .then((snap) => res(snap.docs.map((doc) => doc.id)))
    .catch((err) => console.error(err));
};

export const fetchNewArrival = async (res) => {
  const prodKeys = [];
  const q = queryDB(refDB(dbFirebase, 'furnitures'), orderByKey(), limitToLast(20 * 5));
  await get(q).then((snap) => {
    if (snap.val()) {
      Object.keys(snap.val()).forEach((value) => {
        prodKeys.push(value);
      });
    }
  });
  return res(prodKeys);
};

// this fetches n onsale furniture in firestore
export const fetchOnSaleByLimit = async (lim) => {
  const q = query(
    productsCollection,
    where('isOnSale', '==', true),
    where('active', '==', true),
    limit(lim),
  );

  return await getDocs(q)
    .then((snap) =>
      snap.docs.map((doc) => {
        return {
          key: doc.id,
          data: doc.data(),
        };
      }),
    )
    .catch((err) => console.error(err));
};

// this fetches last n furniture in firebase rdb
export const fetchLastFurnitureByLimit = async (limit) => {
  const q = queryDB(refDB(dbFirebase, 'furnitures'), orderByKey(), limitToLast(limit));
  return await get(q).then((snap) => {
    const products = [];
    for (const [key, value] of Object.entries(snap.val())) {
      products.push({
        key: key,
        data: value,
      });
    }
    return products;
  });
};

const fetchProductsByKeys = async (productKeys) => {
  const promises = productKeys.map((key) => {
    return get(refDB(dbFirebase, `furnitures/${key}`)).then((snap) => {
      if (snap.exists()) {
        return {
          key: key,
          data: snap.val(),
        };
      }
    });
  });

  return promises;
};

export const fetchGussyFinds = async () => {
  const productKeys = await get(refDB(dbFirebase, `shopGussyFinds`)).then((snap) =>
    Object.keys(snap.val()),
  );
  const promises = await fetchProductsByKeys(productKeys);

  return Promise.all(promises).then((products) => products.filter((i) => i));
};

export const fetchGetThatLookByKey = async (key) => {
  const getThatLook = await get(refDB(dbFirebase, `shopGetThatLook/${key}`)).then((snap) =>
    snap.val(),
  );

  return getThatLook;
};

export const fetchGetThatLook = async () => {
  const getThatLook = await get(refDB(dbFirebase, 'shopGetThatLook')).then((snap) => snap.val());

  const getThatLookArr = [];
  const getThatLookKeys = Object.keys(getThatLook);
  Object.values(getThatLook).forEach((val, ndx) => {
    getThatLookArr.push({
      key: getThatLookKeys[ndx],
      ...val,
    });
  });

  return getThatLookArr;
};

export const fetchPopularProducts = async (limit) => {
  const productKeys = await fetchProductKeysByPopularity(limit);
  const promises = await fetchProductsByKeys(productKeys);

  return Promise.all(promises).then((products) => products.filter((i) => i));
};

const fetchProductKeysByPopularity = async (limit) => {
  return get(refDB(dbFirebase, 'productKeysByPopularity')).then((snap) =>
    Object.entries(snap.val())
      .sort((a, b) => (a[1] < b[1] ? 1 : b[1] < a[1] ? -1 : 0))
      .map((item) => item[0])
      .slice(0, limit),
  );
};

export const fetchAll = async (res) => {
  return get(refDB(dbFirebase, 'furnitureKeys')).then((snap) => res(Object.keys(snap.val() || {})));
};

export const fetchProducts = async (keys) => {
  try {
    const results = [];
    const promises = keys.map((key) => {
      return get(refDB(dbFirebase, `furnitures/${key}`)).then((snap) => {
        if (snap.val() && snap.val().status === 'ACTIVE' && snap.val().active) {
          results.push({ ...snap.val(), key: snap.key });
        } else if (snap.val()) {
          httpsCallable(
            functionsFirebase,
            'removeProductInShop',
          )({
            categoryId: snap.val().categoryId,
            productType: snap.val().productType,
            user: snap.val().user,
            id: snap.key,
          });
        }
      });
    });
    return Promise.all(promises).then(() => {
      return results;
    });
  } catch {
    return [];
  }
};

export const fetchByMerchant = async (keys, res) => {
  const prodKeys = [];

  const promises = keys.map((key) =>
    get(refDB(dbFirebase, `merchantFurnitures/${key}`)).then((snap) =>
      prodKeys.push(...Object.keys(snap.val() || {})),
    ),
  );
  return Promise.all(promises).then(() => res(prodKeys));
};

export const fetchMerchants = async () => {
  const res = await httpsCallable(functionsFirebase, 'fetchMerchants')();
  return res.data;
};

export const setProductImageUrl = async () => {
  const snap = await get(refDB(dbFirebase, `furnitures`));
  const products = snap.val();
  const promises = Object.keys(products).map(async (key) => {
    const product = products[key];
    if (product.fileName && !product.imgUrl) {
      const url = await getDownloadURL(
        ref(storageFirebase, `furnitures/${product.fileName}`),
      ).catch((err) => console.error(err));
      if (url) {
        return update(refDB(dbFirebase, `furnitures/${key}`), { imgUrl: url });
      } else {
        return [];
      }
    } else {
      return [];
    }
  });

  return Promise.all(promises);
};
export const setCartItems = async (items) => {
  if (authFirebase.currentUser) {
    await set(refDB(dbFirebase, `cart/${authFirebase.currentUser.uid}`), { ...items });
  } else {
    localStorage.setItem('cartItems', JSON.stringify(items));
  }
};

export const moveLocalCartItemsToDb = async () => {
  let items = {};
  if (authFirebase.currentUser) {
    const cartFromDbSnap = await get(refDB(dbFirebase, `cart/${authFirebase.currentUser.uid}`));

    const cartItems = JSON.parse(localStorage.getItem('cartItems'));

    if (cartFromDbSnap.val()) {
      items = { ...items, ...cartFromDbSnap.val() };
    }
    if (Object.keys(cartItems || {}).length > 0) {
      items = { ...items, ...cartItems };
    }
    await set(refDB(dbFirebase, `cart/${authFirebase.currentUser.uid}`), { ...items }).then(
      () => {},
    );
    localStorage.removeItem('cartItems');
  }
};

export const fetchCartIds = async () => {
  let productQty = {};
  if (authFirebase.currentUser) {
    const snap = await get(refDB(dbFirebase, `cart/${authFirebase.currentUser.uid}`));
    productQty = snap.val();
  } else {
    productQty = JSON.parse(localStorage.getItem('cartItems')) || {};
  }
  return productQty || {};
};

export const fetchCartItems = async () => {
  let productQty = {};
  if (authFirebase.currentUser) {
    const snap = await get(refDB(dbFirebase, `cart/${authFirebase.currentUser.uid}`));
    productQty = snap.val();
  } else {
    productQty = JSON.parse(localStorage.getItem('cartItems')) || {};
  }

  const keys = Object.keys(productQty || {});
  const res = await fetchProducts(keys);
  const products = {};

  const promises = res.map((x) => {
    let wallpaperUnit = '';
    return fetchProductTypeName(x.productType).then((productTypeName) => {
      if (productTypeName.toUpperCase() === 'WALLPAPER') {
        wallpaperUnit = ` /${x.wallpaperUnit || 'sqm'}`;
      }
      products[x.key] = {
        ...x,
        qty: +productQty[x.key],
        total: +productQty[x.key] * (x.isOnSale ? +x.salePrice : +x.price),
        wallpaperUnit,
      };
    });
  });

  return Promise.all(promises).then(() => products);
};

const calcDeliveryFee = (rate, total) => {
  total = total || 0;
  if (rate && (rate.isServicable || !rate.isRemoved) && !rate.isFree) {
    return rate.deliveryFee + total;
  } else {
    return 0 + total;
  }
};

export const fetchDeliveryRates = (merchantKeys, cityId) => {
  const deliveryRates = {};
  const deliveryFeePerMerchant = {};
  let totalDeliveryFee = 0;
  const promises = merchantKeys.map((key) =>
    get(refDB(dbFirebase, `deliveryFees/${key}/${cityId}`)).then((snap) => {
      const val = snap.val();
      if (!(key in deliveryRates)) {
        deliveryRates[key] = {};
      }
      if (!(key in deliveryFeePerMerchant)) {
        totalDeliveryFee = calcDeliveryFee(val, totalDeliveryFee);
      }
      deliveryFeePerMerchant[key] = calcDeliveryFee(val, 0);

      deliveryRates[key][snap.key] = val;
    }),
  );

  return Promise.all(promises).then(() => {
    return [deliveryRates, deliveryFeePerMerchant, totalDeliveryFee];
  });
};

export const clearCart = async (uid) => {
  localStorage.removeItem('cartItems');
  return remove(refDB(dbFirebase, `cart/${uid}`));
};

export const fetchPayment = async (transId) => {
  const paymentRes = await httpsCallable(
    functionsFirebase,
    'fetchPayment',
  )({
    paymentId: transId,
  });
  return paymentRes.data;
};

export const fetchMerchantLogo = async (ref) => {
  const res = await httpsCallable(
    functionsFirebase,
    'fetchStorageUrl',
  )({
    ref,
  });
  return res.data;
};

export const fetchProductTypeName = async (id) => {
  return get(refDB(dbFirebase, `productTypes/${id}/name`)).then((snap) => snap.val());
};

export const fetchRelatedProducts = async (selectedProductId) => {
  const docRef = doc(firestore, 'products', selectedProductId);

  const selectedProduct = await getDoc(docRef).then((snap) => snap.data());

  const ids = await get(
    refDB(dbFirebase, `productTypeFurnitures/${selectedProduct.productType}`),
  ).then((snap) => shuffle(Object.keys(snap.val() || {})));

  const promises = ids
    .filter((id) => id !== selectedProductId)
    .map((id) =>
      getDoc(doc(firestore, 'products', id)).then((snap) => {
        if (snap.data()?.status === 'ACTIVE' && snap.data()?.active === true) {
          return { ...snap.data(), id: snap.id };
        } else if (snap.data() !== undefined) {
          httpsCallable(
            functionsFirebase,
            'removeProductInShop',
          )({
            categoryId: snap.data().categoryId,
            productType: snap.data().productType,
            user: snap.data().user,
            id: snap.key,
          });
          return false;
        }
      }),
    );

  let products = await Promise.all(promises).then((res) => res.filter(Boolean));
  products = sortByTagsSimilarity(products, selectedProduct);
  products = products.slice(0, 12);

  return products;
};

const sortByTagsSimilarity = (products, selectedProduct) => {
  products.forEach((item, index) => {
    let points = 0;

    if (item.supplierId === selectedProduct.supplierId) {
      points += 200;
    }
    (item.searchTags || []).forEach((tag) => {
      if (selectedProduct.searchTags.includes(tag)) {
        points += 10;
      }
    });
    products[index].points = points;
  });
  return products.sort((a, b) => b.points - a.points);
};

export const getCategoryProductTypes = async () => {
  const snap = await get(refDB(dbFirebase, 'categoryProductTypes'));
  return snap.val();
};

export const getProductTypeImages = async (productTypeKeys) => {
  const imagesObject = {};
  await Promise.all(
    productTypeKeys.map((prodKey) =>
      get(refDB(dbFirebase, `productTypes/${prodKey}/imageUrl`)).then((snap) => {
        if (snap.val()) {
          imagesObject[prodKey] = snap.val();
        }
      }),
    ),
  );
  return imagesObject;
};

export const getProductTypesByCategory = async (categoryKey) => {
  const snap = await get(refDB(dbFirebase, `categoryProductTypes/${categoryKey}`));
  return snap.val();
};

export const sortKeys = async (keys) => {
  if (!keys) return [];

  const sortedKeys = [];
  const q = queryDB(refDB(dbFirebase, `orderedProductKeys`), orderByValue());
  const snaps = await get(q);

  snaps.forEach((snap) => {
    if (keys.includes(snap.key)) {
      sortedKeys.unshift(snap.key);
      keys = keys.filter((key) => key !== snap.key);
    }
  });

  return [...sortedKeys, ...keys];
};

export const fetchShopBanners = async () => {
  return get(refDB(dbFirebase, `shopBanners`)).then((snap) => Object.values(snap.val() || {}));
};

export const fetchReviewBanners = async () => {
  return await get(refDB(dbFirebase, `homeFooterBanners`)).then((snap) =>
    Object.values(snap.val() || {}),
  );
};

export const sortByNameTagRelevance = (products, tags) => {
  return products.sort((prodA, prodB) => {
    const relevanceLengthA = prodA.nameTags.filter((nameTag) => tags.includes(nameTag)).length;
    const relevanceLengthB = prodB.nameTags.filter((nameTag) => tags.includes(nameTag)).length;

    return relevanceLengthA < relevanceLengthB ? 1 : -1;
  });
};
