import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import '../../App.scss';

function ModalComponent(props) {
  // eslint-disable-next-line react/prop-types
  const { onHide, show, header, message } = props;
  return (
    <div>
      <Modal centered show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button className="btn-copper" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalComponent;
