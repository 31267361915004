import { Suspense, lazy } from 'react';
import { useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router';
import NavbarLPComponent from '../../../components/LandingPage/NavbarLPComponent';
import CookieConsent from 'react-cookie-consent/build';
import FooterComponent from '../../../components/FooterComponent';
import { fetchGussyExperienceFeedback, fetchProjects } from '../../../queries/contentfulCMS';
import Loading from '../../../components/Loading';

const IndividualProject = lazy(() => import('../../../components/IndividualProjectComponent'));

export default function IndividualProjectPage() {
  const { id } = useParams();
  const history = useHistory();


  const { data: projects, isLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: fetchProjects,
  });

  if (isLoading) {
    return <Loading />;
  }

  const currentData = projects.find((val, index) => {
    return val.slug === id;
  });

  const currentProject = {
    name: currentData.projectTitle,
    description: currentData.projectDetails,
    
    afterInteriorCover: currentData.afterInteriorCoverPhoto?.file?.url,
    beforeInteriorCover: currentData.beforeInteriorCoverPhoto?.file?.url,
    schematicDesignCover: currentData.schematicDesignCoverPhoto?.file?.url,
    mainCover: currentData.mainCoverPhoto?.file?.url,

    otherImagesAfter: currentData.afterInteriorOtherPhotos?.map(photo => photo.file?.url) || [],
    otherImagesBefore: currentData.beforeInteriorOtherPhotos?.map(photo => photo.file?.url) || [],
    otherImagesSchematic: currentData.schematicDesignOtherPhotos?.map(photo => photo.file?.url) || []
};


  const handleOnClickGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <NavbarLPComponent />
      <CookieConsent
        location="bottom"
        buttonText="I Agree"
        style={{ background: '$obsidian' }}
        buttonStyle={{
          background: '$petra',
          padding: '5px 20px',
          color: 'white',
          fontSize: '13px',
        }}
      >
        This website uses cookies to enhance the user experience.{' '}
        <a href="/terms-of-service#cookies" className="cookies-link">
          Learn More.
        </a>
      </CookieConsent>
      <div className="mt-64 pt-5 mx-5">
        <div className="row text-center justify-content-center">
          <div className="col">
            <Suspense fallback={<Loading />}>
              <h2 className="page-title mb-3">Featured Projects</h2>
              <p className="body-1-reg">See the transformation from 2D to the actual spaces.</p>
              <IndividualProject currentProject={currentProject} projectNumber={id} />
            </Suspense>
          </div>
        </div>
        <button type="button" className="btn btn-outline-dark my-3 back-button" onClick={handleOnClickGoBack}>
          &lt; Back
        </button>
      </div>
      <FooterComponent />
    </>
  );
}
