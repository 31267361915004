import React, { Component, Suspense, lazy } from 'react';
import { withRouter } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import CookieConsent from 'react-cookie-consent';
import ChoosePackageComponent from '../../components/ChoosePackageComponent';
import NavbarLPComponent from '../../components/LandingPage/NavbarLPComponent';
import FooterComponent from '../../components/FooterComponent';
import HelmetComponent from '../../components/HelmetComponent';
import './projectspage.scss';
import '../../components/ProjectsPageComponent/featuredprojectscomponent.scss';
import SkeletonElement from '../../components/SkeletonComponents/SkeletonElements';

const FeaturedProjectsComponent = lazy(() => import('../../components/ProjectsPageComponent'));

class ProjectsPage extends Component {
  render() {
    const { history } = this.props;
    const skeletonDisplay = (
      <>
        <SkeletonElement type="h2-size" />
        <SkeletonElement type="text-paragraph" />

        <div className="container square-bg">
          <div className="row justify-content-center">
            <div className=" col-md-5 featured-badge featured-before img-fluid py-3">
              <SkeletonElement type="featured-before-schematic" />
            </div>

            <div className=" col-md-5 featured-badge featured-schematic img-fluid py-3">
              <SkeletonElement type="featured-before-schematic" />
            </div>

            <div className="col-md-10   featured-badge featured-after -after img-fluid py-3 ">
              <SkeletonElement type="featured-after" />
            </div>
          </div>
        </div>
      </>
    );

    const handleOnClickGoBack = () => {
      history.goBack();
    };

    return (
      <div>
        <HelmetComponent
          title="Featured Projects"
          description="Have access to licensed and experienced interior designers trained by Empire Designs, a premier Traditional Interior Design firm in the country."
        />
        <NavbarLPComponent />
        <CookieConsent
          location="bottom"
          buttonText="I Agree"
          style={{ background: '#33332B' }}
          buttonStyle={{
            background: '#d1755b',
            padding: '5px 20px',
            color: '#ffffff',
            fontSize: '13px',
          }}
        >
          This website uses cookies to enhance the user experience.{' '}
          <a href="/terms-of-service#cookies" className="cookies-link w-100">
            Learn More.
          </a>
        </CookieConsent>
        <Fade cascade>
          <div className="mt-64 pt-5 mx-3">
            <div className="row text-center justify-content-center">
              <div className="col-md-12">
                {' '}
                <Suspense fallback={skeletonDisplay}>
                  <h2 className="page-title mb-3">Featured Projects</h2>
                  <p className="body-1-reg">See the transformation from 2D to the actual spaces.</p>
                  <FeaturedProjectsComponent />
                </Suspense>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-outline-dark m-3"
              onClick={handleOnClickGoBack}
            >
              Back
            </button>
          </div>

          <div className="mt-2">
            <ChoosePackageComponent />
          </div>

          <FooterComponent />
        </Fade>
      </div>
    );
  }
}

export default withRouter(ProjectsPage);
