import React from 'react';

import LoadingGif from '../../assets/img/gussyloading.gif';

function Loading() {
  return (
    <div className="mt-64 p-5 text-center width-full loading-div">
      <img alt="Loading..." className="loading-img" src={LoadingGif} />
    </div>
  );
}

export default Loading;
