import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Fade from 'react-reveal/Fade';

import FaqsComponent from '../../components/FaqsComponent';
import FooterComponent from '../../components/FooterComponent';
import HelmetComponent from '../../components/HelmetComponent';
import NavbarLPComponent from '../../components/LandingPage/NavbarLPComponent';

function FaqsPage() {
  return (
    <div>
      <HelmetComponent
        description="Helping homeowners get the best advice through our online design platform"
        title="FAQs"
      />
      <NavbarLPComponent />
      <CookieConsent
        buttonStyle={{
          background: '#d1755b',
          padding: '5px 20px',
          color: '#ffffff',
          fontSize: '13px',
        }}
        buttonText="I Agree"
        location="bottom"
        style={{ background: '#33332B' }}
      >
        This website uses cookies to enhance the user experience.{' '}
        <a className="cookies-link" href="/terms-of-use#cookies">
          Learn More.
        </a>
      </CookieConsent>
      <Fade>
        <div className="mt-64 p-5 container">
          <div className="row text-center">
            <div className="col-md-12">
              <h2 className="page-title">FAQ</h2>
              <p className="body-1-reg">Let&apos;s get you answers.</p>
            </div>
          </div>
          <FaqsComponent />
        </div>
        <FooterComponent />
      </Fade>
    </div>
  );
}

export default FaqsPage;
