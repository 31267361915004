import React from "react";
import { Button } from "react-bootstrap";
import Fade from "react-reveal/Fade";

import NavbarComponent from "../../NavbarComponent";
import confirmEmail from "../../../assets/img/StyleQuiz/confirm-email.svg";

import "../stylequizcomponent.scss";

function StyleQuiz8Component(props) {
  return (
    <div>
      <NavbarComponent display="none" />
      <div className="mt-64 p-5">
        <Fade duration={1000} cascade>
          <div className="row text-center justify-content-center mb-50">
            <div>
              <img src={confirmEmail} alt=""></img>
            </div>
            <div className="col-md-12 mb-30">
              <h2 className="bold">Confirm your account.</h2>
            </div>
            <div className="col-md-5 col-sm-12 d-flex flex-column align-items-center stylequiz-end">
              <p className="mb-4">
                Your account has been successfully created and your style profile
                saved. To complete the process, we sent an email to{" "}
                <b>{props.email || "sample@email.com"}</b> to confirm your
                account
              </p>
              <p className="mb-40">
                Can't find the email? Please check your spam folder.
              </p>
              <Button
                onClick={() => (window.location.href = "/home")}
                className="btn-dark p-13-55 btntext-13-sb width-fit-content"
              >
                Back to home page
              </Button>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default StyleQuiz8Component;
