// Login icons
import GoogleLogo from "../assets/img/google-logo.svg";
import FbLogo from "../assets/img/fb-logo.svg";

export const socialMediaLogos = {
  googleLogo: GoogleLogo,
  fbLogo: FbLogo,
};

export const merchants = [
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/taylor-living.jpg`,
    alt: "Taylor Living",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/c-and-c.jpg`,
    alt: "C and C",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/prizmic-and-brill.jpg`,
    alt: "Prizmic and Brill",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/home-and-beyond.jpg`,
    alt: "Home and Beyond",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/neumuri.jpg`,
    alt: "Neumuri",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/mav-details.jpg`,
    alt: "MAV Details",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/home-cartel.jpg`,
    alt: "Home Cartel",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/decoliving.jpg`,
    alt: "Deco Living",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/shadows-and-patterns.jpg`,
    alt: "Shadows and Patterns",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/design-source.jpg`,
    alt: "Design Source",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/global-views.jpg`,
    alt: "Global Views",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/room-for-tots.jpg`,
    alt: "Room For Tots",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/commune.jpg`,
    alt: "Commune",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/harver-hill.jpg`,
    alt: "Harver Hill",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/merchants/doze-days.jpg`,
    alt: "Doze Days",
  },
];

export const paymentGateways = [
  {
    src: `${process.env.PUBLIC_URL}/assets/img/payment-gateways/paymaya.png`,
    alt: "Paymaya",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/img/payment-gateways/dragonpay.png`,
    alt: "Dragonpay",
  },
];

