import { createClient } from "contentful";
import { toFloatPrice } from "../utils/helpers";

const {
    NODE_ENV,
    REACT_APP_CONTENTFUL_PREVIEW_CONFIG,
    REACT_APP_CONTENTFUL_DELIVERY_CONFIG,
} = process.env;

const contentfulConfig =
    NODE_ENV === "development"
        ? JSON.parse(REACT_APP_CONTENTFUL_PREVIEW_CONFIG)
        : JSON.parse(REACT_APP_CONTENTFUL_DELIVERY_CONFIG);

const client = createClient(contentfulConfig);

export const fetchPricingPackages = async () => {
    try {
        const entries = await client.getEntries({
            content_type: "pricingPackage",
            select: "fields",
        });

        const sanitizedEntries = entries.items.map((item) => {
            const packageImage = item.fields.packageImage.fields;
            return { ...item.fields, packageImage };
        });

        return sanitizedEntries.sort(
            (a, b) =>
                toFloatPrice(a.packagePrice) - toFloatPrice(b.packagePrice),
        );
    } catch (err) {
        console.error(`Contentful error fetching pricing packages: ${err}`);
        return [];
    }
};

export const fetchAboutPage = async () => {
    try {
        const entries = await client.getEntries({
            content_type: "aboutPage",
            select: "fields",
        });
        const sanitizedEntries = entries.items.map((item) => {
            const featuredDesignPhoto = item.fields.featuredDesignPhoto.fields;
            const featuredDesignImage = item.fields.featuredDesignImage.fields;
            const dataTableImage = item.fields.dataTableImage.fields;
            return {
                ...item.fields,
                featuredDesignPhoto,
                featuredDesignImage,
                dataTableImage,
            };
        });

        return sanitizedEntries;
    } catch (err) {
        console.error(`Error fetching Contentful data: ${err}`);
    }
};

export const fetchDataModelItems = async () => {
    try {
        const entries = await client.getEntries({
            content_type: "dataModelItems",
            select: "fields",
            order: "fields.itemOrder",
        });
        const sanitizedEntries = entries.items.map((item) => {
            return { ...item.fields };
        });

        return sanitizedEntries;
    } catch (err) {
        console.error(`Error fetching Table data: ${err}`);
    }
};

export const fetchHomePage = async () => {
    try {
        const entries = await client.getEntries({
            content_type: "homePage",
            select: "fields",
        });

        const sanitizedEntries = entries.items.map((item) => {
            const heroBanner = item.fields.heroBanner.fields;
            const schematicInterior = item.fields.schematicInterior.fields;
            const actualInterior = item.fields.actualInterior.fields;
            const stepsLogo = item.fields.stepsLogo.fields;
            const step1 = item.fields.step1.fields;
            const step2 = item.fields.step2.fields;
            const step3 = item.fields.step3.fields;
            const step4 = item.fields.step4.fields;
            const featuredPhoto = item.fields.featuredPhoto.fields;
            const cartLogo = item.fields.cartLogo.fields;
            const heroBanner2 = item.fields.heroBanner2.fields;
            const phoneLogo = item.fields.phoneLogo.fields;
            return {
                ...item.fields,
                heroBanner,
                schematicInterior,
                actualInterior,
                stepsLogo,
                step1,
                step2,
                step3,
                step4,
                featuredPhoto,
                cartLogo,
                heroBanner2,
                phoneLogo,
            };
        });

        return sanitizedEntries;
    } catch (err) {
        console.error(`Contentful error fetching : ${err}`);
        return [];
    }
};

export const fetchGussyExperienceFeedback = async () => {
    try {
        const entries = await client.getEntries({
            content_type: "gussyExperienceFeedback",
            select: "fields",
        });

        const sanitizedEntries = entries.items.map((item) => {
            const actualInterior = item.fields.actualInterior.fields;
            const relatedProject = item.fields.relatedProject ? item.fields.relatedProject.fields : {};
            return { ...item.fields, actualInterior, relatedProject };
        });

        return sanitizedEntries;
    } catch (err) {
        console.error(`Contentful error fetching : ${err}`);
        return [];
    }
};

export const fetchProjects = async () => {
    try {
        const entries = await client.getEntries({
            content_type: "projects",
            select: "fields",
        });

        const sanitizedEntries = entries.items.map((item) => {
            const mainCoverPhoto =
                item.fields.mainCoverPhoto.fields;
            const afterInteriorCoverPhoto =
                item.fields.afterInteriorCoverPhoto.fields;
            const beforeInteriorCoverPhoto =
                item.fields.beforeInteriorCoverPhoto.fields;
            const schematicDesignCoverPhoto =
                item.fields.schematicDesignCoverPhoto.fields;

            const afterInteriorOtherPhotos = item.fields.afterInteriorOtherPhotos?.map(photo => photo.fields) || [];
            const beforeInteriorOtherPhotos = item.fields.beforeInteriorOtherPhotos?.map(photo => photo.fields) || [];
            const schematicDesignOtherPhotos = item.fields.schematicDesignOtherPhotos?.map(photo => photo.fields) || [];

            return {
                ...item.fields,
                afterInteriorCoverPhoto,
                beforeInteriorCoverPhoto,
                schematicDesignCoverPhoto,
                afterInteriorOtherPhotos,
                beforeInteriorOtherPhotos,
                schematicDesignOtherPhotos,
                mainCoverPhoto
            };
        });

        return sanitizedEntries;
    } catch (err) {
        console.error(`Contentful error fetching : ${err}`);
        return [];
    }
};

