import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SaleTag from '../SaleTag';
import { toMoney, cloudinaryfy } from '../../../utils/helpers';
import { fetchRelatedProducts } from '../../../queries/shop';
import { WALLPAPER_IDS } from '../../../config/constants';

const RelatedProductsComponent = ({ product }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    let mounted = true;
    setProducts([]);
    if (mounted) {
      getRelatedProducts();
    }
    return () => (mounted = false);
  }, [product.id]);

  const getRelatedProducts = async () => {
    const res = await fetchRelatedProducts(product.id);
    setProducts(res);
  };

  const isWallpaper = (productType, wallpaperUnit) => {
    if (WALLPAPER_IDS.includes(productType)) {
      if (wallpaperUnit) {
        return ` /${wallpaperUnit.toLowerCase()}`;
      } else {
        return ' /sqm';
      }
    }
    return '';
  };

  const displayPrice = ({
    isOnSale,
    salePrice,
    wallpaperUnit,
    price,
    saleDiscount,
    productType,
  }) => {
    if (isOnSale) {
      return (
        <p className="product-price">
          {toMoney(salePrice)}
          {isWallpaper(productType, wallpaperUnit).toLowerCase()}
          <br />
          <span className="original-price">
            {toMoney(price)}
            {isWallpaper(productType, wallpaperUnit).toLowerCase()}
          </span>
          <span className="discount">{saleDiscount || 0}%</span>
        </p>
      );
    } else {
      return (
        <p className="product-price">
          {toMoney(price)}
          {isWallpaper(productType, wallpaperUnit).toLowerCase()}
        </p>
      );
    }
  };

  const renderProducts = products.map((item) => (
    <div className="col-4 col-md-4 col-lg-2" key={item.id}>
      <div className="product-thumbnail">
        <Link to={`/shop/item-details?id=${item.id}`} className="product-link">
          <div
            className="product-image"
            style={{
              backgroundImage: `url("${cloudinaryfy(item.imgUrl, 150)}")`,
            }}
          />
          <h5 className="product-title">{item.name}</h5>
          {displayPrice(item)}
        </Link>
        {item.isOnSale ? <SaleTag /> : <></>}
      </div>
    </div>
  ));

  return (
    <div className="container-fluid">
      <br />
      <br />
      <br />
      <br />
      <h4>Related Products: </h4>
      <br />
      <div className="row pl-0 pl-md-4">{renderProducts}</div>
    </div>
  );
};

export default RelatedProductsComponent;
