import React from "react";
import { Form } from "react-bootstrap";
import {
  DEFAULT,
  H_TO_L,
  L_TO_H,
  NEW,
  SALE,
} from "../../../config/constants"

function SortSelect(props) {
  return (
    <div className="d-flex">
      <div className="mr-3 mt-1">Sort by </div>
      <Form.Group>
        <Form.Control
          custom
          as="select"
          size="sm"
          onChange={props.onChange}
          value={props.value}
        >
          <option value={DEFAULT}>Default</option>
          <option value={L_TO_H}>Price (Low to High)</option>
          <option value={H_TO_L}>Price (High to Low)</option>
          <option value={SALE}>Sale</option>
          <option value={NEW}>New</option>
        </Form.Control>
      </Form.Group>
    </div>
  );
}

export default SortSelect;
