import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Nav,
  Row,
  Tab,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import LayoutComponent from '../../components/LayoutComponent';
import Voucher from '../../components/Voucher';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RemoveIcon from '@material-ui/icons/Remove';

import placeholderImage from '../../assets/img/about/gussy-footer.png';
import { AuthContext, CartCountContext, SweetAlertContext } from '../../context';
import {
  calculateAmountDiscount,
  calculatePercentageDiscount,
} from '../../utils/calculations/discount';
import { totalCartItems } from '../../utils/calculations/totalCartItems';
import { makeUID, sortObjEntriesByName, toMoney } from '../../utils/helpers';

import { CITIES, PCNT, PROVINCES } from '../../config/constants';
import { fetchCartItems, fetchDeliveryRates } from '../../queries/shop';

import { checkoutDragonPay, checkoutPaymaya } from '../../queries/payments';

import Loading from '../../components/Loading';

import { push, ref, remove, set, update } from 'firebase/database';
import { httpsCallable } from 'firebase/functions';
import { dbFirebase, fireStamp, functionsFirebase } from '../../components/Firebase';
import './checkout.scss';

const ErrMsg = ({ msg = 'Required' }) => <Form.Text className="text-error">{msg}</Form.Text>;

function CheckoutPage() {
  const [activeTab, setActiveTab] = useState('tab-information');
  const [formData, setFormData] = useState({});
  const [products, setProducts] = useState({});
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [deliveryRatesPerMerchant, setDeliveryRatesPerMerchat] = useState({});
  const [provinceId, setProvinceId] = useState('');
  const [notServicable, setNotServicable] = useState(false);
  const [voucher, setVoucher] = useState(null);
  const [deliveryDetails, setDeliveryDetails] = useState({});
  const [cartItems, setCartItems] = useState({ notForSaleItems: [], forSaleItems: [] });
  const [cartItemsCount, setCartItemsCount] = useState(0);

  const { register, handleSubmit, errors, setValue, getValues } = useForm();

  const authContext = useContext(AuthContext);
  const cartCountContext = useContext(CartCountContext);
  const sa = useContext(SweetAlertContext);

  let total = 0;
  let grandTotalPurchasePrice = 0;
  let isDisabled = cartItemsCount < 1;

  useEffect(() => {
    window.scrollTo(0, 0);
    getCartItems();
  }, []);

  useEffect(() => {
    setCartItemsCount(Object.keys(products).length || 0);

    setCartItems({
      forSaleItems: Object.values(products).filter((product) => product?.isOnSale),
      notForSaleItems: Object.values(products).filter((product) => !product?.isOnSale),
    });
  }, [products]);

  useEffect(() => {
    if (authContext.isLoggedIn) {
      let city = authContext.user.city;
      let province = authContext.user.province;

      if (authContext.user.deliveryDetails) {
        setDeliveryDetails(authContext.user.deliveryDetails || {});
        Object.entries(authContext.user.deliveryDetails || {}).forEach(([key, val]) => {
          if (key === 'city') {
            city = val;
          } else if (key === 'province') {
            province = val;
          } else {
            setValue(key, val);
          }
        });
      }
      setProvinceId(province);
      setValue('province', province);
      setValue('city', city);
    }
  }, [authContext.isLoggedIn]);

  const onChangeProvince = () => {
    setValue('city', '');
    setProvinceId(getValues('province'));
  };

  const getCartItems = async () => {
    const res = await fetchCartItems();
    checkOutOfStock(res);
  };

  const checkOutOfStock = async (items) => {
    const tmp = [];
    const prodLocal = JSON.parse(localStorage.getItem('cartItems')) || {};
    const promises = Object.entries(items).map(([key, val]) => {
      if (val.status !== 'ACTIVE') {
        tmp.push(val.name);
        delete items[key];
        if (authContext.isLoggedIn) {
          return remove(ref(dbFirebase, `cart/${authContext.user.id}/${key}`));
        } else {
          delete prodLocal[key];
          return [];
        }
      }
      return [];
    });
    if (tmp.length > 0) {
      sa.locked('These items went out of stock while you were away:', tmp.join(', '), 'warning');
      Promise.all(promises).then(() => {
        setProducts(items);
        localStorage.setItem('cartItems', JSON.stringify(prodLocal));
      });
    } else {
      setProducts(items);
    }
  };

  const checkIfServicable = (city, items) => {
    setNotServicable(false);
    if (Object.keys(items).length === 0) {
      setNotServicable(true);
    } else {
      Object.values(items).forEach((item) => {
        Object.values(item).forEach((val) => {
          if (!val || val.isRemoved || val.isServicable === false) {
            setNotServicable(true);
          }
        });
      });
    }
  };

  const onSubmit = async (data) => {
    setDeliveryDetails(data);
    await getDeliveryRates(data);
    handleActiveTab('tab-review');
  };

  const getDeliveryRates = async (data) => {
    const merchantIds = Object.values(products).map((item) => Object.keys(item.user)[0]);
    const merchantNames = Object.values(products).map((item) => item.supplier);
    const res = await fetchDeliveryRates(merchantIds, data.city);
    setFormData({ merchantIds, merchantNames, ...data });
    checkIfServicable(data.city, res[0]);
    setDeliveryRatesPerMerchat(res[1]);
    setDeliveryFee(res[2]);
  };

  const setDetails = async () =>
    new Promise(async (resolve) => {
      const tmp = {};
      const itemsByMerchant = {};
      const productsByMerchant = {};
      const paymayaItems = [];
      Object.entries(products).forEach(([key, item]) => {
        const merchantId = Object.keys(item.user)[0];
        if (!(merchantId in productsByMerchant)) {
          productsByMerchant[merchantId] = {};
        }

        const srp = item.isOnSale ? item.salePrice : item.price;
        const commission = item.isOnSale ? item.saleCommission : item.commission;
        const purchasePrice = item.isOnSale ? item.salePurchasePrice || 0 : item.purchasePrice;

        tmp[key] = {
          imgUrl: item.imgUrl ?? '',
          fileName: item.fileName,
          id: key,
          purchasePrice,
          qty: item.qty,
          srp,
          commission,
          name: item.name,
        };

        productsByMerchant[merchantId][key] = {
          ...tmp[key],
        };

        paymayaItems.push({
          name: item.name,
          quantity: item.qty,
          code: item.sku,
          amount: {
            value: srp,
          },
          totalAmount: {
            value: srp * item.qty,
          },
        });
      });

      for (const id of formData.merchantIds) {
        const merchant = await httpsCallable(functionsFirebase, 'fetchUser')({ id });
        itemsByMerchant[id] = {
          deliveryFee: deliveryRatesPerMerchant[id],
          id,
          name: merchant.data.name,
          email: merchant.data.email,
          products: productsByMerchant[id],
          totalPurchasePrice: Object.values(productsByMerchant[id]).reduce((sum, item) => {
            const purchasePrice = item.isOnSale ? item.salePurchasePrice || 0 : item.purchasePrice;
            return sum + purchasePrice * item.qty;
          }, 0),
          totalSRP: Object.values(productsByMerchant[id]).reduce((sum, item) => {
            return sum + item.srp * item.qty;
          }, 0),
        };
      }
      let userDetails = {};
      if (!authContext.isLoggedIn) {
        const uid = makeUID(28);
        httpsCallable(
          functionsFirebase,
          'createTemporaryUser',
        )({
          email: formData.email,
          region: CITIES[formData.city].regionID,
          city: formData.city,
          zipCode: formData.zip,
          password: formData.password,
          province: formData.province,
          mobilePhone: formData.mobilePhone,
          firstName: formData.firstName,
          lastName: formData.lastName,
          uid,
        });
        userDetails = {
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          id: uid,
        };
      } else {
        userDetails = authContext.user;
      }

      delete formData.password;
      setFormData({ ...formData });
      resolve({ itemsByMerchant, tmp, userDetails, paymayaItems });
    });

  const checkout = async (paymentMethod) => {
    sa.loadingLocked('Please wait', 'Redirecting you to our payment provider');
    const transactionId = uuidv4();
    const unconfirmedOrdersRef = push(ref(dbFirebase, `unconfirmedOrders`));
    const dbRefKey = unconfirmedOrdersRef.key;
    const city = CITIES[formData.city];
    const { itemsByMerchant, tmp, userDetails, paymayaItems } = await setDetails();

    await update(ref(dbFirebase, `users/${userDetails.id}`), { deliveryDetails });

    set(unconfirmedOrdersRef, {
      ...formData,
      region: city.regionID,
      products: tmp,
      dateOrdered: fireStamp,
      paymentMethod,
      paymentStatus: 'PENDING',
      totalDeliveryFees: deliveryFee,
      status: 'ORDERED',
      clientName: userDetails.name,
      clientEmail: userDetails.email,
      clientId: userDetails.id,
      contactPersonName: `${formData.contactPerson.firstName} ${formData.contactPerson.lastName}`,
      grandTotalSRP: total,
      grandTotalPurchasePrice,
      grandTotal,
      transactionId,
      itemsByMerchant,
      discount,
      hasVoucher: voucher ? true : false,
      voucher: voucher
        ? {
            id: voucher.key,
            code: voucher.code,
            discount: voucher.discount,
            discountType: voucher.discountType,
            maxDiscountAmount: voucher.maxDiscountAmount,
            name: voucher.name,
          }
        : {},
    }).then(() => {
      if (paymentMethod === 'DRAGONPAY') {
        checkoutDragonPay(
          transactionId,
          grandTotal.toFixed(2),
          'PHP',
          'Gussy Purchase Order',
          userDetails.email,
          'PRODUCT_PURCHASE_SHOP',
          dbRefKey,
        );
      } else {
        checkoutPaymaya(
          paymayaItems,
          transactionId,
          total,
          deliveryFee,
          grandTotal,
          'PRODUCT_PURCHASE_SHOP',
          dbRefKey,
          userDetails.id,
          discount,
        );
      }
    });
  };

  const handleRemoveItem = async (e) => {
    const { id } = e.target;
    if (authContext.isLoggedIn) {
      await update(ref(dbFirebase, `cart/${authContext.user.id}`), { [id]: null }).then(() => {
        delete products[id];
        cartCountContext.decCount();
        setProducts({ ...products });
      });
    } else {
      delete products[id];
      cartCountContext.decCount();
      setProducts({ ...products });
    }
  };

  const onChangeQuantity = (e) => {
    const { value, id: txtId } = e.target;
    const { name, id: btnId } = e.currentTarget;

    const id = btnId || txtId;
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || {};
    const price = products[id].isOnSale ? products[id].salePrice : products[id].price;

    if (name === 'inc') {
      cartItems[id] = products[id].qty + 1;
      setProducts({
        ...products,
        [id]: {
          ...products[id],
          qty: products[id].qty + 1,
          total: (products[id].qty + 1) * price,
        },
      });
    } else if (name === 'dec') {
      if (products[id].qty > 1) {
        cartItems[id] = products[id].qty - 1;
        setProducts({
          ...products,
          [id]: {
            ...products[id],
            qty: products[id].qty - 1,
            total: (products[id].qty - 1) * price,
          },
        });
      }
    } else if (value) {
      cartItems[id] = parseInt(value);
      setProducts({
        ...products,
        [id]: {
          ...products[id],
          qty: parseInt(value),
          total: parseInt(value) * price,
        },
      });
    }
  };

  const handleQuote = async () => {
    sa.loadingLocked('Please wait', 'Processing your order');
    const transactionId = uuidv4();
    const city = CITIES[formData.city];
    const { itemsByMerchant, tmp, userDetails } = await setDetails();

    await update(ref(dbFirebase, `users/${userDetails.id}`), { deliveryDetails });

    const response = await httpsCallable(
      functionsFirebase,
      'requestQuote',
    )({
      ...formData,
      region: city.regionID,
      products: tmp,
      dateOrdered: fireStamp,
      totalDeliveryFees: deliveryFee,
      status: 'ORDERED',
      clientName: userDetails.name,
      clientEmail: userDetails.email,
      clientId: userDetails.id,
      contactPersonName: `${formData.contactPerson.firstName} ${formData.contactPerson.lastName}`,
      grandTotalSRP: total,
      grandTotalPurchasePrice,
      grandTotal,
      transactionId,
      itemsByMerchant,
      isQuoted: true,
      discount,
      hasVoucher: voucher ? true : false,
      voucher: voucher
        ? {
            id: voucher.key,
            code: voucher.code,
            discount: voucher.discount,
            discountType: voucher.discountType,
            maxDiscountAmount: voucher.maxDiscountAmount,
            name: voucher.name,
          }
        : {},
    });

    if (response.data) {
      window.location.href = `${window.location.origin}/success-checkout?order_id=${response.data}&quoted=true&uid=${userDetails.id}`;
    }
  };

  const calculateTotal = () => {
    const { forSaleItems, notForSaleItems } = cartItems;
    const notForSaleTotal = totalCartItems(notForSaleItems);
    const forSaleTotal = totalCartItems(forSaleItems);
    const subTotal = notForSaleTotal + forSaleTotal;

    if (voucher) {
      return calculateDiscountedTotalWithVoucher({
        notForSaleTotal,
        subTotal,
        usedVoucher: voucher,
      });
    }

    return { discount: 0, grandTotal: subTotal + deliveryFee };
  };

  const calculateDiscountedTotalWithVoucher = ({ notForSaleTotal, subTotal, usedVoucher }) => {
    const {
      applyOnSaleItems,
      discount: voucherDiscount,
      discountType,
      maxDiscountAmount,
    } = usedVoucher;

    const voucherValues = {
      isVoucherAvailForSale: applyOnSaleItems,
      maxDiscountAmount,
      voucherDiscount,
    };

    const calculateDiscountParams = {
      notForSaleTotal,
      subTotal,
      voucherValues,
    };

    const isPercentageDiscount = discountType === PCNT;

    const discountTotal = isPercentageDiscount
      ? calculatePercentageDiscount(calculateDiscountParams)
      : calculateAmountDiscount(calculateDiscountParams);

    return { discount: discountTotal, grandTotal: subTotal + deliveryFee - discountTotal };
  };

  const renderCities = Object.entries(CITIES)
    .sort(sortObjEntriesByName)
    .map(([key, city]) => {
      if (provinceId) {
        if (provinceId === city.provinceID) {
          return (
            <option value={key} key={key}>
              {city.name}
            </option>
          );
        }
      } else {
        return (
          <option value={key} key={key}>
            {city.name}
          </option>
        );
      }
    });

  const renderProvinces = Object.entries(PROVINCES)
    .sort(sortObjEntriesByName)
    .map(([key, province]) => (
      <option value={key} key={key}>
        {province.name}
      </option>
    ));

  const renderProducts = Object.entries(products || {}).map(([key, item]) => {
    const purchasePrice = item.isOnSale ? item.salePurchasePrice || 0 : item.purchasePrice;
    grandTotalPurchasePrice += purchasePrice * item.qty;
    total += item.total;
    return (
      <div className="cart-item" key={key}>
        <div className="cart-item-imagewrapper">
          <div
            className="product-image"
            style={{
              backgroundImage: item.imgUrl
                ? `url(https://res.cloudinary.com/symphco/image/fetch/q_100,f_auto,w_100,fl_lossy/${encodeURIComponent(
                    item.imgUrl,
                  )})`
                : `url(${placeholderImage})`,
            }}
          />
        </div>
        <div className="cart-item-info">
          <div className="cart-item-details">
            <h6 className="cart-item-title">{item.name}</h6>
            <p className="cart-item-brand">{item.supplier}</p>
          </div>
          <div className="cart-item-price">
            <p>
              {toMoney(item.isOnSale ? item.salePrice : item.price)}
              {item.wallpaperUnit.toLowerCase()}
            </p>
          </div>
          <div className="cart-item-quantity">
            <InputGroup className="increment-input">
              <InputGroup.Prepend>
                <Button variant="link" size="sm" onClick={onChangeQuantity} name="dec" id={key}>
                  <RemoveIcon style={{ fontSize: 20 }} />
                </Button>
              </InputGroup.Prepend>
              <FormControl
                aria-describedby="quantity"
                size="sm"
                type="number"
                onChange={onChangeQuantity}
                value={item.qty}
                id={key}
              />
              <InputGroup.Append>
                <Button variant="link" size="sm" onClick={onChangeQuantity} name="inc" id={key}>
                  <AddIcon style={{ fontSize: 20 }} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <Button variant="link" className="remove-item" onClick={handleRemoveItem} id={key}>
              Remove
            </Button>
          </div>
          <div className="cart-item-total d-none d-lg-flex">
            <p>{toMoney(item.total)}</p>
          </div>
        </div>
      </div>
    );
  });

  const handleActiveTab = (tab) => setActiveTab(tab);

  const getVoucher = (response) => {
    if (response) {
      setVoucher({ ...response });
    } else {
      setVoucher(null);
    }
  };

  const renderVoucher = () => {
    if (voucher) {
      return (
        <div className="cart-breakdown-item">
          <p className="body-1-sb smoke">Discount</p>
          <p>-{toMoney(discount)}</p>
        </div>
      );
    }
  };

  const { discount, grandTotal } = calculateTotal();

  return (
    <LayoutComponent>
      <Tab.Container
        defaultActiveKey="tab-information"
        activeKey={activeTab}
        onSelect={(x) => setActiveTab(x)}
      >
        <Container className="mt-64 pt-5">
          <Row>
            <Col sm={12} className="pb-4">
              <h2 className="mb-0">Checkout</h2>
              <Nav variant="pills" className="checkout-tabs">
                <Nav.Item>
                  <Nav.Link href="/cart">Cart</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link disabled>
                    <ChevronRightIcon style={{ fontSize: 18 }} className="smoke" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab-information">Information</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link disabled>
                    <ChevronRightIcon style={{ fontSize: 18 }} className="smoke" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab-review" disabled={activeTab === 'tab-information'}>
                    Review
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link disabled>
                    <ChevronRightIcon style={{ fontSize: 18 }} className="smoke" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab-payment" disabled={activeTab !== 'tab-payment'}>
                    Payment
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="tab-information">
                  <Row>
                    {!authContext.isLoggedIn && <Loading />}
                    {authContext.isLoggedIn && (
                      <>
                        <Col lg={6}>
                          <div>
                            <h4>Customer</h4>
                            <p className="body-1-reg">
                              {authContext.user.name}
                              <br />
                              {authContext.user.email}
                              <br />
                              {authContext.user.mobilePhone || ''}
                              <br />
                            </p>
                          </div>
                          <div className="mb-3 mb-lg-0 mt-auto">
                            <h4>Contact Person Upon Delivery</h4>

                            <Form.Row>
                              <Form.Group as={Col} md="6">
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                  ref={register({
                                    required: true,
                                  })}
                                  type="text"
                                  name="contactPerson.firstName"
                                />
                                {errors.contactPerson?.firstName && <ErrMsg />}
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                  ref={register({
                                    required: true,
                                  })}
                                  type="text"
                                  name="contactPerson.lastName"
                                />
                                {errors.contactPerson?.lastName && <ErrMsg />}
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} md="6">
                                <Form.Label>Mobile Phone</Form.Label>
                                <Form.Control
                                  ref={register({
                                    required: true,
                                  })}
                                  type="text"
                                  name="contactPerson.mobilePhone"
                                />
                                {errors.contactPerson?.mobilePhone && <ErrMsg />}
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                  ref={register({
                                    required: true,
                                  })}
                                  type="email"
                                  name="contactPerson.emailAddress"
                                />
                                {errors.contactPerson?.emailAddress && <ErrMsg />}
                              </Form.Group>
                            </Form.Row>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <Form>
                            <h4>Delivery Address</h4>
                            <Form.Row>
                              <Form.Group as={Col} md="6">
                                <Form.Label>State/Province</Form.Label>
                                <Form.Control
                                  ref={register({
                                    required: true,
                                  })}
                                  as="select"
                                  name="province"
                                  onChange={onChangeProvince}
                                >
                                  {renderProvinces}
                                </Form.Control>
                                {errors.province && <ErrMsg />}
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <Form.Label>City/Municipality</Form.Label>
                                <Form.Control
                                  ref={register({
                                    required: true,
                                  })}
                                  as="select"
                                  name="city"
                                >
                                  {renderCities}
                                </Form.Control>
                                {errors.city && <ErrMsg />}
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} md="6">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control
                                  ref={register({
                                    required: true,
                                  })}
                                  type="text"
                                  name="zip"
                                />
                                <Form.Text className="text-muted">
                                  <a
                                    href="http://zip-codes.philsite.net/"
                                    target="_blank"
                                    className="text-muted"
                                    rel="noopener noreferrer"
                                  >
                                    <HelpOutlineIcon
                                      style={{
                                        fontSize: 16,
                                      }}
                                    />{' '}
                                    Need help?
                                  </a>
                                </Form.Text>
                                {errors.zip && <ErrMsg />}
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <Form.Label>Barangay</Form.Label>
                                <Form.Control
                                  ref={register({
                                    required: true,
                                  })}
                                  type="text"
                                  name="barangay"
                                />
                                {errors.barangay && <ErrMsg />}
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} md="12">
                                <Form.Label>
                                  House/Unit/Flr, Building, Subdivision, Street, etc.
                                </Form.Label>
                                <Form.Control
                                  ref={register({
                                    required: true,
                                  })}
                                  type="text"
                                  name="address"
                                />
                                {errors.address && <ErrMsg />}
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} md="12">
                                <Form.Label>Landmarks / Other Notes (Optional)</Form.Label>
                                <Form.Control ref={register()} type="text" name="landmark" />
                                {errors.landmark && <ErrMsg />}
                              </Form.Group>
                            </Form.Row>
                          </Form>
                        </Col>

                        <Col lg={12}>
                          <hr />
                        </Col>
                        <div className="col-12 col-md-6 col-lg-5 ml-auto">
                          <div className="d-flex mt-3">
                            <Button className="w-100 mr-1 btn-outline-petra" href="/cart">
                              Return to Cart
                            </Button>
                            <Button
                              className="w-100 ml-1 btn-petra"
                              onClick={handleSubmit(onSubmit)}
                            >
                              Proceed
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="tab-review">
                  <Row>
                    <Col lg={12}>
                      <div className="cart-item cart-header d-none d-lg-flex">
                        <div className="cart-item-imagewrapper">
                          <span>Product</span>
                        </div>
                        <div className="cart-item-info">
                          <div className="cart-item-details"></div>
                          <div className="cart-item-price">
                            <span>Price</span>
                          </div>
                          <div className="cart-item-quantity mt-0 text-center">
                            <span>Quantity</span>
                          </div>
                          <div className="cart-item-total d-none d-lg-flex">
                            <span>Total</span>
                          </div>
                        </div>
                      </div>
                      {renderProducts}
                    </Col>
                  </Row>

                  <div className="row justify-content-end pt-4 cart-breakdown">
                    <div className="col-12 col-md-6 col-lg-5">
                      <Voucher
                        getVoucher={getVoucher}
                        cartItems={cartItems}
                        clientId={authContext.user.id}
                        totalAmount={total}
                      />
                      <div className="cart-breakdown-item">
                        <p className="body-1-sb smoke">Subtotal</p>
                        <p>{toMoney(total)}</p>
                      </div>
                      <div className="cart-breakdown-item">
                        <p className="body-1-sb smoke">Shipping</p>
                        <p>{notServicable ? '*To Follow' : toMoney(deliveryFee)}</p>
                      </div>
                      {renderVoucher()}
                      {!notServicable && (
                        <div className="cart-breakdown-item">
                          <p className="body-1-sb">Total</p>
                          <h5 className="petra">{toMoney(grandTotal)}</h5>
                        </div>
                      )}
                      {notServicable && (
                        <div className="disclaimer-div">
                          <span>
                            *Kindly give us 2-3 business days to give you a quote for shipping. Your
                            approval for the cost of items and shipping is required before you're
                            charged for the total order.
                          </span>
                        </div>
                      )}

                      <div className="d-flex mt-2">
                        <Button
                          className="w-100 mr-1 btn-outline-petra"
                          onClick={() => handleActiveTab('tab-information')}
                        >
                          Back
                        </Button>
                        {!notServicable ? (
                          <Button
                            className="w-100 ml-1 btn-petra"
                            disabled={isDisabled}
                            onClick={() => handleActiveTab('tab-payment')}
                          >
                            Proceed to Payment
                          </Button>
                        ) : (
                          <Button
                            className="w-100 ml-1 btn-petra"
                            disabled={isDisabled}
                            onClick={handleQuote}
                          >
                            Get a Quote
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab-payment">
                  <div className="row justify-content-between">
                    <div className="col-12 col-lg-7 mb-4 mb-lg-0">
                      <h4 className="mb-3">Select Payment Method</h4>
                      <ListGroup className="mb-2">
                        <ListGroup.Item
                          action
                          href="#"
                          className="d-flex align-items-center justify-content-between"
                          onClick={() => checkout('PAYMAYA')}
                        >
                          <div className="d-flex align-items-center">
                            <CreditCardIcon className="mr-2 petra" />
                            <span className="body-1-reg">Credit Card / Debit Card</span>
                          </div>
                          <ChevronRightIcon className="smoke" />
                        </ListGroup.Item>
                      </ListGroup>

                      <ListGroup>
                        <ListGroup.Item
                          action
                          onClick={() => checkout('DRAGONPAY')}
                          href="#"
                          className="d-flex align-items-center justify-content-between"
                        >
                          <div className="d-flex align-items-center">
                            <AccountBalanceIcon className="mr-2 petra" />
                            <span className="body-1-reg">Bank Transfer / Over the Counter</span>
                          </div>
                          <ChevronRightIcon className="smoke" />
                        </ListGroup.Item>
                      </ListGroup>
                    </div>

                    <div className="col-12 col-lg-5 pl-lg-5">
                      <div className="p-4 bg-gray6">
                        <h4 className="mb-2">Order Summary</h4>
                        <div className="row justify-content-end cart-breakdown">
                          <div className="col-12">
                            <div className="cart-breakdown-item">
                              <p className="body-1-sb smoke">Subtotal</p>
                              <p>{toMoney(total)}</p>
                            </div>
                            <div className="cart-breakdown-item">
                              <p className="body-1-sb smoke">Shipping</p>
                              <p>{notServicable ? '*To Follow' : toMoney(deliveryFee)}</p>
                            </div>
                            {renderVoucher()}
                            {!notServicable && (
                              <div className="cart-breakdown-item">
                                <p className="body-1-sb">Total</p>
                                <h5 className="petra">{toMoney(grandTotal)}</h5>
                              </div>
                            )}
                            {notServicable && (
                              <div className="disclaimer-div">
                                <span>
                                  *Kindly give us 2-3 business days to give you a quote for
                                  shipping. Your approval for the cost of items and shipping is
                                  required before you're charged for the total order.
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <Button
                        className="mt-4 px-4 btn-outline-petra"
                        onClick={() => handleActiveTab('tab-review')}
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Container>
      </Tab.Container>
    </LayoutComponent>
  );
}

export default CheckoutPage;
