import React, { useEffect, useState, useContext } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import LayoutComponent from '../../components/LayoutComponent';
import CheckoutLoginModal from '../../components/ShopComponents/CheckoutLoginModal';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import routes from '../../config/routes';
import { ACTIVE, DECREMENTAL, INCREMENTAL } from '../../config/constants';

import placeholderImage from '../../assets/img/about/gussy-footer.png';

import { fetchCartItems, setCartItems } from '../../queries/shop';
import { dbFirebase, authFirebase } from '../../components/Firebase';
import { remove, ref, update } from 'firebase/database';
import { CartCountContext, SweetAlertContext, AuthContext } from '../../context';
import { cloudinaryfy, toMoney } from '../../utils/helpers';

import './shoppingcart.scss';

function ShoppingCartPage() {
  const [products, setProducts] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const cartCountContext = useContext(CartCountContext);
  const sa = useContext(SweetAlertContext);
  const authContext = useContext(AuthContext);
  const history = useHistory();

  let total = 0;
  let isDisabled = cartItemsCount < 1;

  useEffect(() => {
    getCartItems();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (!isFetching) {
      const tmp = {};
      Object.entries(products).forEach(([key, val]) => {
        tmp[key] = val.qty;
      });
      setCartItems(tmp);
    }
  }, [products]);

  useEffect(() => {
    setCartItemsCount(Object.keys(products).length || 0);
  }, [products]);

  const getCartItems = async () => {
    const res = await fetchCartItems();
    setIsFetching(false);
    await checkOutOfStock(res);
  };

  const checkOutOfStock = async (items) => {
    const tmp = [];
    const prodLocal = JSON.parse(localStorage.getItem('cartItems')) || {};
    const promises = Object.entries(items).map(([key, val]) => {
      if (val.status !== ACTIVE) {
        tmp.push(val.name);
        delete items[key];
        if (authContext.isLoggedIn) {
          return remove(ref(dbFirebase, `cart/${authContext.user.id}/${key}`));
        } else {
          delete prodLocal[key];
          return [];
        }
      }
      return [];
    });
    if (tmp.length > 0) {
      sa.locked('These items went out of stock while you were away:', tmp.join(', '), 'warning');
      Promise.all(promises).then(() => {
        setProducts(items);
        localStorage.setItem('cartItems', JSON.stringify(prodLocal));
      });
    } else {
      setProducts(items);
    }
  };

  const handleRemoveItem = async (e) => {
    const { id } = e.target;
    if (authFirebase.currentUser) {
      await update(ref(dbFirebase, `cart/${authFirebase.currentUser.uid}`), { [id]: null }).then(
        () => {
          delete products[id];
          cartCountContext.decCount();
          setProducts({ ...products });
        },
      );
    } else {
      delete products[id];
      cartCountContext.decCount();
      setProducts({ ...products });
    }
  };

  const toggleModal = () => setIsOpen(!isOpen);

  const onCheckoutClick = () => {
    if (authContext.isLoggedIn) {
      history.push(routes.CHECKOUT);
    } else {
      toggleModal();
    }
  };

  const onChangeQuantity = (e) => {
    const { value, id: txtId } = e.target;
    const { name, id: btnId } = e.currentTarget;

    const id = btnId || txtId;
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || {};
    const price = products[id].isOnSale ? products[id].salePrice : products[id].price;

    if (name === INCREMENTAL) {
      cartItems[id] = products[id].qty + 1;
      setProducts({
        ...products,
        [id]: {
          ...products[id],
          qty: products[id].qty + 1,
          total: (products[id].qty + 1) * price,
        },
      });
    } else if (name === DECREMENTAL) {
      if (products[id].qty > 1) {
        cartItems[id] = products[id].qty - 1;
        setProducts({
          ...products,
          [id]: {
            ...products[id],
            qty: products[id].qty - 1,
            total: (products[id].qty - 1) * price,
          },
        });
      }
    } else if (value) {
      cartItems[id] = parseInt(value);
      setProducts({
        ...products,
        [id]: {
          ...products[id],
          qty: parseInt(value),
          total: parseInt(value) * price,
        },
      });
    }
  };

  const renderProducts = Object.values(products).map((item) => {
    const productRedirectUrl = routes.PRODUCT_DETAIL.replace(/KEY/, item.key);
    total += +item.total;

    return (
      <div className="cart-item" key={item.key}>
        <div className="cart-item-imagewrapper">
          <div
            className="product-image"
            style={{
              backgroundImage: item.imgUrl
                ? `url(${cloudinaryfy(item.imgUrl, 100, 100)})`
                : `url(${placeholderImage})`,
            }}
          />
        </div>
        <div className="cart-item-info">
          <div className="cart-item-details">
            <Link to={productRedirectUrl} className="cart-item-title">
              {item.name}
            </Link>

            <p className="cart-item-brand">{item.supplier}</p>
          </div>
          <div className="cart-item-price">
            <p>
              {toMoney(item.isOnSale ? item.salePrice : item.price)}
              {item.wallpaperUnit.toLowerCase()}
            </p>
          </div>
          <div className="cart-item-quantity">
            <InputGroup className="increment-input">
              <InputGroup.Prepend>
                <Button
                  variant="link"
                  size="sm"
                  id={item.key}
                  name={DECREMENTAL}
                  onClick={onChangeQuantity}
                >
                  <RemoveIcon style={{ fontSize: 20 }} />
                </Button>
              </InputGroup.Prepend>
              <FormControl
                aria-describedby="quantity"
                size="sm"
                type="number"
                value={item.qty}
                id={item.key}
                onChange={onChangeQuantity}
              />
              <InputGroup.Append>
                <Button
                  variant="link"
                  size="sm"
                  id={item.key}
                  name={INCREMENTAL}
                  onClick={onChangeQuantity}
                >
                  <AddIcon style={{ fontSize: 20 }} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <Button variant="link" className="remove-item" onClick={handleRemoveItem} id={item.key}>
              Remove
            </Button>
          </div>
          <div className="cart-item-total d-none d-lg-flex">
            <p>{toMoney(item.total)}</p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <LayoutComponent>
      <CheckoutLoginModal isOpen={isOpen} toggleModal={toggleModal} />
      <div className="container mt-64 pt-5">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">Shopping Cart</h2>
            <div className="cart-item cart-header d-none d-lg-flex">
              <div className="cart-item-imagewrapper">
                <span>Product</span>
              </div>
              <div className="cart-item-info">
                <div className="cart-item-details"></div>
                <div className="cart-item-price">
                  <span>Price</span>
                </div>
                <div className="cart-item-quantity mt-0 text-center">
                  <span>Quantity</span>
                </div>
                <div className="cart-item-total d-none d-lg-flex">
                  <span>Total</span>
                </div>
              </div>
            </div>
            {renderProducts}
          </div>
        </div>

        <div className="row justify-content-end pt-4 cart-breakdown">
          <div className="col-12 col-md-6 col-lg-5">
            <div className="cart-breakdown-item">
              <p className="body-1-sb">Subtotal</p>
              <h5 className="petra">{toMoney(total)}</h5>
            </div>

            <div className="d-flex mt-2">
              <Button href="/shop" className="w-100 mr-1 btn-outline-petra">
                Continue Shopping
              </Button>
              <Button
                className="w-100 ml-1 btn-petra"
                disabled={isDisabled}
                onClick={onCheckoutClick}
              >
                Checkout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
}

export default ShoppingCartPage;
