import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useQuery } from 'react-query';
import Carousel from 'react-multi-carousel';
import ReactStars from 'react-stars';
import { useHistory } from 'react-router';
import gussyLogo from '../../../assets/img/landing/gussy-logo.svg';
import { fetchGussyExperienceFeedback } from '../../../queries/contentfulCMS';
import Loading from '../../Loading';
import './reviewBanner.scss';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function ReviewBannerComponent() {
  const history = useHistory();

  const { data: gussyExperienceFeedback, isLoading } = useQuery({
    queryKey: ['experienceList'],
    queryFn: fetchGussyExperienceFeedback,
  });

  if (isLoading) {
    return <Loading />;
  }

  const gussyExperienceFeedbackList = gussyExperienceFeedback?.map((val, index) => {
    const projectNumber = index + 1;
    const { bannerDescription, gussyFeedback, feedbackAuthor, actualInterior, relatedProject } = val;
    const handleOnClickRedirectToProject = () => {
      const path = `/projects/${projectNumber}`;
      history.push(path);
    };

    return (
      <div className="container-fluid feedback-container" key={feedbackAuthor}>
        <div className="row mb-10">
          <div className="col-lg-6 col-md-10 offset-lg-1 text-center m-auto">
            <div className="banner-title-container">
              <h2 className="banner-title">
                The&nbsp;
                <img alt="logo" className="gussy-logo" src={gussyLogo} />
                &nbsp;Experience
              </h2>
            </div>
            <div className="body-1-sb my-4 gussy-logo">
              <span className="banner-description">
                {documentToReactComponents(bannerDescription)}
              </span>
            </div>
            <div className="feedback-star my-5">
              <ReactStars color2="#000000" count={5} size={40} value={5} />
            </div>
            <div className="gussy-feedback">
              <div>
                <span>{documentToReactComponents(gussyFeedback)}</span>
              </div>
              <div>
                <p>
                  <i>{feedbackAuthor}</i>
                </p>
              </div>
            </div>
            {relatedProject.slug && <Link to={`/projects/${relatedProject.slug}`}>
              <Button
                className="btn-petra project-design btntext-13-sb p-12-30 smmb-20"
              >
                See the design
              </Button>
            </Link>}
            <div>
            </div>
          </div>
          <div className="col-lg-6 h-100">
            <div className="interior-img">
              <img alt="img" src={actualInterior.file.url} />
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <Carousel
      arrows
      autoPlay
      draggable
      infinite
      autoPlaySpeed={5000}
      removeArrowOnDeviceType={['tablet', 'mobile']}
      responsive={responsive}
    >
      {gussyExperienceFeedbackList}
    </Carousel>
  );
}

export default ReviewBannerComponent;
