import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import ProductThumbnail from '../ProductThumbnailComponent';
import GetThatLookThumbnail from '../GetThatLookThumbnail';

import RightArrow from '../../../assets/img/rightArrow.svg';
import LeftArrow from '../../../assets/img/leftArrow.svg';

// no. of items to be shown in carousel depending on breakpoint
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3,
    partialVisibilityGutter: 20,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 115,
  },
};

const ProductCarousel = (props) => {
  const { color, text, getThatLook, products, seeAllLink } = props;

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button className="carousel-arrow right" onClick={() => onClick()}>
        <img src={RightArrow} alt="right arrow" />
      </button>
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button className="carousel-arrow left" onClick={() => onClick()}>
        <img src={LeftArrow} alt="left arrow" />
      </button>
    );
  };

  const renderProducts = () => {
    if (getThatLook && getThatLook.length > 0) {
      return getThatLook.map((look) => {
        const { isActive, key } = look;
        if (isActive) {
          return <GetThatLookThumbnail data={look} key={key} />;
        } else {
          return <></>;
        }
      });
    } else {
      return products
        .filter((product) => product.data.active)
        .map((product) => {
          const { data, key } = product;

          if (data.name && data.price) {
            return (
              <ProductThumbnail
                {...data}
                id={key}
                isWallpaper={() => {
                  return '';
                }}
                discount={data.saleDiscount || 0}
                key={key}
                productTypeId={data.productType}
                src={data.imgUrl}
              />
            );
          } else {
            return null;
          }
        });
    }
  };

  const productData = renderProducts();
  const renderLink = seeAllLink ? (
    <a href={seeAllLink}>
      See More
      <ArrowForwardIosIcon style={{ fontSize: 14 }} />
    </a>
  ) : (
    ''
  );

  return (
    <div className={`product-div ${color ? color : ''}`}>
      <div className="d-flex w-100 products-header">
        <h3>{text}</h3>
        {renderLink}
      </div>
      <div className="product-carousel-div">
        <div className="product-carousel-inner-div">
          <Carousel
            infinite
            partialVisible
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            responsive={responsive}
          >
            {productData}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
