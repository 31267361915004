import React, { useState, useEffect } from 'react';

import { fetchHomePage } from '../../../queries/contentfulCMS';

function BannerLPComponent() {
  const [homePage, setHomePage] = useState([]);

  const getHomePage = async () => {
    const data = await fetchHomePage();
    setHomePage(data);
  };

  useEffect(() => {
    getHomePage();
  }, []);

  return homePage.map((val) => {
    const {
      heroBanner2Text,
      heroBanner2,
      subHeading1Title,
      subHeading1Description,
      subHeading2Title,
      subHeading2Description,
      subHeading3Title,
      subHeading3Description,
      phoneLogo,
      phoneTextBanner,
      textDescriptionButton,
    } = val;

    return (
      <div className="banner-div w-100" key={val}>
        <div className="container-fluid pr-0 pl-0 float-container">
          <div className="float-text">
            <h2 className="white-font f60">{heroBanner2Text}</h2>
          </div>
          <div>
            <img alt="banner" className="banner-img h100p" src={heroBanner2.file.url} />
          </div>
        </div>

        <div className="py-5 px-4 container">
          <div className="row">
            <div className="col-md-4">
              <div className="px-2 mr20">
                <p className="body-1-sb mb-5">{subHeading1Title}</p>
                <p className="body-1-reg pb-3 mb-20 float-home">{subHeading1Description}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="px-2 mr20">
                <p className="body-1-sb mb-5">{subHeading2Title}</p>
                <p className="body-1-reg pb-3 mb-20 float-heart">{subHeading2Description}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="px-2 mr20">
                <p className="body-1-sb mb-5">{subHeading3Title}</p>
                <p className="body-1-reg pb-3 mb-20 float-money">{subHeading3Description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-gray9 text-center py-5">
          <div className="row mb-3">
            <div className="col-md-12">
              <img alt=" " className="phone-lp pl-3" height="60px" src={phoneLogo.file.url} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 smmt-20 text-center">
              <h3 className="f-semibold">{phoneTextBanner}</h3>
              <a
                className="body-1-reg brand-orange text-underline"
                href="https://calendly.com/gussydesign/consultation"
              >
                <u>{textDescriptionButton}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  });
}

export default BannerLPComponent;
