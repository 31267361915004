import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import LandingPage from './pages/LandingPage';
import AboutPage from './pages/AboutPage';
import PricingPage from './pages/PricingPage';
import ProjectsPage from './pages/ProjectsPage';
import PartnersPage from './pages/PartnersPage';
import StyleQuizPage from './pages/StyleQuizPage';
import FaqsPage from './pages/FaqsPage';
import ContactUsPage from './pages/ContactUsPage';
import ReviewsPage from './pages/ReviewsPage';
import SignUpPage from './pages/SignUpPage';
import LocationsPage from './pages/LocationsPage';
import FinancingPage from './pages/FinancingPage';
import GiftCardsPage from './pages/GiftCardsPage';
import ReferFriendsPage from './pages/ReferFriendsPage';
import HelpCenterPage from './pages/HelpCenterPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import NotFoundPage from './pages/NotFoundPage';
import ShopGetThatLookPage from './pages/ShopGetThatLookPage';
import ShopNewPage from './pages/ShopNewPage';
import ShopPage from './pages/ShopPage';
import SuccessContactFormPage from './pages/SuccessPage/SuccessContactForm';
import SuccessCheckoutPage from './pages/SuccessPage/SuccessCheckout';
import PendingCheckoutPage from './pages/SuccessPage/PendingCheckout';
import ProductDetailsPage from './components/ShopComponents/ProductDetailsComponent';
import ShoppingCartPage from './pages/ShoppingCartPage';
import CheckoutPage from './pages/CheckoutPage';
import CheckoutPackagePage from './pages/CheckoutPackagePage';
import FailedPayment from './pages/FailedPayment';
import CancelledPayment from './pages/CancelledPayment';
import IndividualProjectPage from './pages/ProjectsPage/components/IndividualProjectPage';
import './App.scss';
import { authFirebase, dbFirebase } from './components/Firebase';
import { onAuthStateChanged, signOut, signInWithCustomToken } from 'firebase/auth';
import { ref, get, set } from 'firebase/database';
import { AuthContext, CartCountContext } from './context';
import { moveLocalCartItemsToDb } from './queries/shop';
import { removeParam } from './utils/helpers';

const App = () => {
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartCountContext);
  const params = queryString.parse(window.location.search);

  useEffect(() => {
    authObserver();
  }, []);

  const setUserData = async (userData, firebaseUserId) => {
    authContext.setUser({ ...userData, id: firebaseUserId });
  };

  const authObserver = async () => {
    try {
      if (params.token) {
        await signOut(authFirebase);
        await signInWithCustomToken(authFirebase, params.token);
        localStorage.removeItem('uniqueId');
        window.history.replaceState({}, 'Gussy', removeParam('token', window.location.href));
      }
      onAuthStateChanged(authFirebase, async (firebaseUser) => {
        if (firebaseUser) {
          let userData = await get(ref(dbFirebase, `users/${firebaseUser.uid}`)).then((snap) => {
            return snap.val();
          });
          if (!userData) {
            userData = {
              name: firebaseUser.displayName,
              roles: { client: true },
              status: 'Active',
              email: firebaseUser.email,
            };
            await set(ref(dbFirebase, `users/${firebaseUser.uid}`), userData).then(() => {
              setUserData(userData, firebaseUser.uid);
            });
          } else {
            setUserData(userData, firebaseUser.uid);
          }
          await moveLocalCartItemsToDb();
          authContext.setIsLoggedIn(true);
        }
        cartContext.initCount();
        authContext.setIsLoggingIn(false);
      });
    } catch (error) {
      cartContext.initCount();
      authContext.setIsLoggedIn(false);
      authContext.setIsLoggingIn(false);
    }
  };

  return (
    <Router>
      {!authContext.isLoggingIn && (
        <div>
          <Switch>
            <Route path="/home" exact component={LandingPage} />
            <Route path="/about" exact component={AboutPage} />
            <Route path="/pricing" exact component={PricingPage} />
            <Route path="/projects" exact component={ProjectsPage} />
            <Route path="/projects/:id" component={IndividualProjectPage} />
            <Route path="/partners" exact component={PartnersPage} />
            <Route path="/reviews" exact component={ReviewsPage} />
            <Route path="/signup" exact component={SignUpPage} />
            <Route path="/faqs" exact component={FaqsPage} />
            <Route path="/contact-us" exact component={ContactUsPage} />
            <Route path="/style-quiz" exact component={StyleQuizPage} />
            <Route path="/shop-look/:id" component={ShopGetThatLookPage} />
            <Route path="/shop/item-details" exact component={ProductDetailsPage} />
            <Route path="/shop/page/:page" exact component={ShopPage} />
            <Route path="/shop/:categoryId" exact component={ShopPage} />
            <Route path="/shop/:categoryId/:productTypeId" exact component={ShopPage} />
            <Route path="/shop-home" exact component={ShopNewPage} />
            <Route path="/shop" exact component={ShopPage} />
            <Route path="/cart" exact component={ShoppingCartPage} />
            <Route path="/checkout" exact component={CheckoutPage} />
            <Route path="/locations" exact component={LocationsPage} />
            <Route path="/financing" exact component={FinancingPage} />
            <Route path="/gift-cards" exact component={GiftCardsPage} />
            <Route path="/refer-friends" exact component={ReferFriendsPage} />
            <Route path="/help-center" exact component={HelpCenterPage} />
            <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />
            <Route path="/terms-of-use" exact component={TermsOfServicePage} />
            <Route path="/success" exact component={SuccessContactFormPage} />
            <Route path="/success-checkout" exact component={SuccessCheckoutPage} />
            <Route path="/pending-checkout" exact component={PendingCheckoutPage} />
            <Route path="/checkout-package" exact component={CheckoutPackagePage} />
            <Route path="/failed-payment" exact component={FailedPayment} />
            <Route path="/cancelled-payment" exact component={CancelledPayment} />
            <Redirect from="/" to="/home" />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      )}
    </Router>
  );
};

export default App;
