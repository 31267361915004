import React, { useEffect, useContext, useMemo, useState } from 'react';
import { Button, Dropdown, NavItem, NavLink, Carousel } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import ProductTypesModal from '../ProductTypesModal';

import { ShopFilterContext } from '../../../context';
import { getCategoryProductTypes } from '../../../queries/shop';
import { NUM_ITEMS, SCREEN_SIZE } from '../../../config/constants.js';
import LeftArrow from '../../../assets/img/leftArrow.svg';
import RightArrow from '../../../assets/img/rightArrow.svg';

const ShopNav = () => {
  const filterContext = useContext(ShopFilterContext);
  const [categories, setCategories] = useState({});
  const [isFetchingCategories, setIsFetchingCategories] = useState(false);
  const [isProductTypeShow, setIsProductTypeShow] = useState(false);
  const [productTypes, setProductTypes] = useState({});
  const [categoryName, setCategoryName] = useState();
  const [categoryKey, setCategoryKey] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  let NUM_ITEMS_PER_CAROUSEL = NUM_ITEMS.DESKTOP;

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setIsFetchingCategories(true);
    const data = await getCategoryProductTypes();
    setCategories(data);
    setIsFetchingCategories(false);
  };

  const renderProductTypes = (thisProductTypes, thisCategoryKey) => {
    return Object.entries(thisProductTypes || {})
      .sort((a, b) => a[1].order - b[1].order)
      .map(([k, v]) => {
        return (
          <Link
            key={k}
            to={{ pathname: `/shop/${thisCategoryKey}/${k}` }}
            className="dropdown-item"
          >
            {v.name}
          </Link>
        );
      });
  };

  const toggleProductTypeModal = (thisProductTypes, thisCategoryName, thisCategoryKey) => {
    if (thisProductTypes && thisCategoryName && thisCategoryKey) {
      setProductTypes(thisProductTypes);
      setCategoryName(thisCategoryName);
      setCategoryKey(thisCategoryKey);
    } else {
      setProductTypes({});
      setCategoryName(null);
      setCategoryKey(null);
    }
    setIsProductTypeShow(!isProductTypeShow);
  };

  const resetProdTypeFilter = () => {
    filterContext.setFilters({
      ...filterContext.filters,
      PRODUCT_TYPE_ID: {},
    });
  };

  const updateNumItemsPerCarousel = (e) => {
    const screenWidth = window.innerWidth;
  
    switch (true) {
      case screenWidth <= SCREEN_SIZE.PHONE:
        NUM_ITEMS_PER_CAROUSEL = NUM_ITEMS.PHONE;
        break;
      case screenWidth <= SCREEN_SIZE.PHABLET:
        NUM_ITEMS_PER_CAROUSEL = NUM_ITEMS.PHABLET;
        break;
      case screenWidth <= SCREEN_SIZE.SMALL_TABLET:
        NUM_ITEMS_PER_CAROUSEL = NUM_ITEMS.SMALL_TABLET;
        break;
      case screenWidth <= SCREEN_SIZE.TABLET:
        NUM_ITEMS_PER_CAROUSEL = NUM_ITEMS.TABLET;
        break;
      case screenWidth <= SCREEN_SIZE.LAPTOP:
        NUM_ITEMS_PER_CAROUSEL = NUM_ITEMS.LAPTOP;
        break;
      default:
        NUM_ITEMS_PER_CAROUSEL = NUM_ITEMS.DESKTOP;
        break;
    }
  };

  //Determine Screen Size
  const mediaQuery = window.matchMedia('(max-width: 1920px)');
  mediaQuery.addEventListener('change', updateNumItemsPerCarousel);
  updateNumItemsPerCarousel(mediaQuery);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  const navLinks = useMemo(() => {
    return Object.values(categories)
      .sort((a, b) => a.order - b.order)
      .map((category) => {
        const { key, productTypes: thisProdTypes, name } = category;
        if (isMobile) {
          return (
            <div key={key}>
              <div className="shop-nav-mob">
                <Button
                  onClick={() => toggleProductTypeModal(thisProdTypes, name, key)}
                  className="dropdown-toggle btn btn-primary"
                >
                  {name}
                </Button>
              </div>
            </div>
          );
        } else {
          return (
            <div key={key}>
              <div>
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink} className="dropdown-toggle">
                    {name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Link
                      to={{ pathname: `/shop/${key}` }}
                      onClick={resetProdTypeFilter}
                      className="dropdown-item"
                    >
                      All {name}
                    </Link>
                    {renderProductTypes(thisProdTypes, key)}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          );
        }
      });
  }, [categories]);

  const carouselPageCount = Math.ceil(navLinks.length / NUM_ITEMS_PER_CAROUSEL);

  const navLinkCarousel = (
    <Carousel interval={null} activeIndex={activeIndex} onSelect={handleSelect} controls={false}>
      {Array.from({ length: carouselPageCount }, (_, index) => (
        <Carousel.Item key={index}>
          <div className="shop-item d-flex">
            {navLinks.slice(index * NUM_ITEMS_PER_CAROUSEL, (index + 1) * NUM_ITEMS_PER_CAROUSEL)}
          </div>
        </Carousel.Item>
      ))}
      <div>
        {activeIndex > 0 && (
          <button className="carousel-arrow left" onClick={() => setActiveIndex(activeIndex - 1)}>
            <img src={LeftArrow} alt="left arrow" />
          </button>
        )}  
        {activeIndex < carouselPageCount - 1 && (
          <button className="carousel-arrow right"  onClick={() => setActiveIndex(activeIndex + 1)}>
            <img src={RightArrow} alt="right arrow" />
          </button>
        )}
      </div>
    </Carousel>
  );

  return (
    <>
      <div className="shop-nav d-flex">
        {navLinkCarousel}
        {isFetchingCategories ? (
          <></>
        ) : (
          <>
            <Link
              to={{ pathname: '/shop/page/new' }}
              className="dropdown-toggle btn btn-primary font-weight-bold"
            >
              NEW
            </Link>
            <Link
              to={{ pathname: '/shop/page/sale' }}
              className="dropdown-toggle btn btn-primary font-weight-bold petra-font"
            >
              SALE
            </Link>
          </>
        )}
      </div>
      {isMobile && (
        <ProductTypesModal
          categoryKey={categoryKey}
          categoryName={categoryName}
          productTypes={productTypes}
          show={isProductTypeShow}
          toggleModal={toggleProductTypeModal}
        />
      )}
    </>
  );
};

export default ShopNav;
