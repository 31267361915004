import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import GetThatLookThumbnail from '../GetThatLookThumbnail';

import LeftArrow from '../../../assets/img/leftArrow.svg';
import RightArrow from '../../../assets/img/rightArrow.svg';

// no. of items to be shown in carousel depending on breakpoint
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
    slidesToSlide: 1,
  },
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 20,
    slidesToSlide: 3,
  },
};

const GetThatLookCarousel = (props) => {
  const { color, getThatLook, subhead, text } = props;

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button className="carousel-arrow right" onClick={onClick}>
        <img src={RightArrow} alt="right arrow" />
      </button>
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button className="carousel-arrow left" onClick={onClick}>
        <img src={LeftArrow} alt="left arrow" />
      </button>
    );
  };

  const productData =
    getThatLook &&
    getThatLook.length > 0 &&
    getThatLook.map((look) => {
      const { isActive, key } = look;
      if (isActive) {
        return <GetThatLookThumbnail data={look} key={key} />;
      } else {
        return null;
      }
    });

  return (
    <div className={`product-div ${color ? color : ''}`}>
      <div className="d-flex flex-column mb-3 w-100 products-header">
        <h3>{text}</h3>
        <p className="ml-3 ml-lg-0">{subhead}</p>
      </div>
      <div className="product-carousel-div">
        <div className="product-carousel-inner-div">
          <Carousel
            infinite
            partialVisible
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            responsive={responsive}
          >
            {productData}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default GetThatLookCarousel;
