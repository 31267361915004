import React from "react";
import { Button } from "react-bootstrap";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";

import check from "../../../assets/img/StyleQuiz/check.svg";
import "../stylequizcomponent.scss";
import "../../../App.scss";

function StyleQuiz3Component(props) {
  return (
    <div>
      <div className="mt-75 container container-wide">
        <div className="row mb-50">
          <div className="col-md-12 text-center">
            <Fade top>
              <h3 className="mb-20">What would you categorize your project as?</h3>
              <p>Select which one applies.</p>
            </Fade>
          </div>
        </div>
        <div className="row justify-content-center">
          <Flip delay={300} left cascade>
            <div className="col-md-8 text-center">
              <div className="d-inline-block p-1">
                <Button onClick={props.choice1Click} variant="light" className={"body-1-m choices-box text-center " + props.choice1Class}>
                  <img src={check} alt="check" className="choice-check display-none" />
                  New construction / clean slate
                  </Button>
              </div>
              <div className="d-inline-block p-1">
                <Button onClick={props.choice2Click} variant="light" className={"body-1-m choices-box text-center " + props.choice2Class}>
                  <img src={check} alt="check" className="choice-check display-none" />
                  I have some furniture
              </Button>
              </div>
              <div className="d-inline-block p-1">
                <Button onClick={props.choice3Click} variant="light" className={"body-1-m choices-box text-center " + props.choice3Class}>
                  <img src={check} alt="check" className="choice-check display-none" />
                  I have a lot of furniture and want to add some final touches
              </Button>
              </div>
            </div>
          </Flip>
        </div>
      </div>
    </div>
  );
}

export default StyleQuiz3Component;