import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { Spinner } from 'react-bootstrap';
import { useDebounce } from 'use-lodash-debounce';
import { dbFirebase } from '../Firebase';
import { ref, query, orderByChild, equalTo, get } from 'firebase/database';

import { timestampToDate, toMoney } from '../../utils/helpers';
import { ACTIVE, ALL, INACTIVE, LIMITED, SHOP } from '../../config/constants';
import './voucher.scss';

const Vouchers = ({ getVoucher: returnVoucher, cartItems, clientId, totalAmount }) => {
  const [voucherCode, setVoucherCode] = useState('');
  const [isValid, setIsValid] = useState(null);
  const [isInvalid, setIsInvalid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const debouncedInput = useDebounce(voucherCode, 500);
  const invalidMsg = 'Invalid voucher code';

  const onChangeInput = (e) => setVoucherCode(e.target.value.toUpperCase().trim());

  useEffect(() => {
    setIsValid(false);
    setIsInvalid(false);
    fetchVoucher();
  }, [debouncedInput, totalAmount]);

  const fetchVoucher = async () => {
    if (voucherCode === '' || voucherCode === null) {
      setIsValid(null);
      setIsInvalid(null);
      returnVoucher(null);
    } else {
      setLoading(true);
      const q = query(ref(dbFirebase, 'vouchers'), orderByChild('code'), equalTo(voucherCode));

      const snap = await get(q).then((s) => s.val());

      if (snap) {
        const filteredSnapshot = Object.entries(snap).filter(([_, data]) => data.status === ACTIVE);
        const [snapshot] = filteredSnapshot;
        const voucherKey = snapshot.find(Boolean);
        const voucherData = { key: voucherKey, ...snap[voucherKey] };

        const valid = await checkValidity(voucherData);

        if (valid) {
          setIsValid(true);
          setIsInvalid(false);
          setMsg(voucherData.name);
          returnVoucher(voucherData);
        } else {
          setIsValid(false);
          setIsInvalid(true);
          returnVoucher(null);
        }
      } else {
        setIsValid(false);
        setIsInvalid(true);
        setMsg(invalidMsg);
        returnVoucher(null);
      }
    }
    setLoading(false);
  };

  const checkValidity = async (voucher) => {
    const {
      applyOnSaleItems,
      endDateTime,
      key,
      level,
      minAmount,
      quantity,
      quantityType,
      reusable,
      startDateTime,
      status,
    } = voucher;

    const dateTimeNow = Math.round(new Date().getTime());
    const notForSaleItemsCount = cartItems.notForSaleItems?.length ?? 0;

    const isValidDate = startDateTime <= dateTimeNow && endDateTime >= dateTimeNow;
    const isStillNotAvailable = startDateTime > dateTimeNow;
    const isInvalidAmount = totalAmount < (minAmount ?? 0);
    const isVoucherLimited = quantityType === LIMITED;
    const isInactive = status === INACTIVE;
    const hasInvalidQty = isVoucherLimited ? quantity <= 0 : false;
    const hasInvalidLevel = ![ALL, SHOP].includes(level);
    const hasNoForSaleItems = notForSaleItemsCount === 0;
    const isUsed = await checkIfVoucherWasUsed(key);

    if (isUsed && !reusable) {
      setMsg('You already used this voucher code');
      return false;
    }

    if (isInactive) {
      setMsg('This voucher is unavailable');
      return false;
    }

    if (isStillNotAvailable) {
      setMsg(`This voucher is only available starting on ${timestampToDate(startDateTime)}`);
      return false;
    }

    if (!isValidDate) {
      setMsg('This voucher has already expired');
      return false;
    }

    if (hasInvalidQty) {
      setMsg('This voucher has ran out');
      return false;
    }

    if (hasInvalidLevel) {
      setMsg('This voucher is only available for Gussy Projects');
      return false;
    }

    if (isInvalidAmount) {
      setMsg(`This voucher needs a minimum amount of ${toMoney(minAmount)}`);
      return false;
    }

    if (!applyOnSaleItems && hasNoForSaleItems) {
      setMsg('Voucher cannot be used on sale items');
      return false;
    }

    return true;
  };

  const checkIfVoucherWasUsed = async (voucherId) =>
    get(ref(dbFirebase, `userVoucherUsage/${voucherId}/${clientId}`)).then((snap) => snap.exists());

  const loader = loading ? (
    <Spinner animation="border" id="loader" role="status" size="sm" variant="secondary">
      <span className="sr-only">Loading...</span>
    </Spinner>
  ) : (
    ''
  );

  return (
    <FormGroup row>
      <Label for="voucher" id="label" size="lg" sm={4}>
        Voucher Code
      </Label>
      <Col sm={8}>
        <Input
          id="voucher"
          invalid={isInvalid}
          name="voucher"
          onChange={onChangeInput}
          type="text"
          value={voucherCode}
          valid={isValid}
        />
        <FormFeedback valid={isValid}>{msg}</FormFeedback>
        {loader}
      </Col>
    </FormGroup>
  );
};

export default Vouchers;
