import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import LayoutComponent from '../../components/LayoutComponent';

import './termsofservicepage.scss';
class TermsOfServicePage extends Component {
  componentDidMount(){
    const element = document.getElementById(window.location.hash.substring(1));
    if(element) {
      window.scrollTo(0, element.offsetTop);
    }
  }
  render() {
    return (
      <div>
        <LayoutComponent>
          <Fade>
            <div className="mt-64 py-5 container container-wide terms-page">
              <div className="row text-center mb-50">
                <div className="col-md-10 offset-1 text-left">
                  <h2 className="page-title">Terms of Use</h2>
                  <h5 className="pt-5"> OVERVIEW </h5>
                  <p>
                    The website Gussy.Design (Website) is operated by
                    GussyDesign, Inc. (Gussy). It is Gussy’s aim to make your
                    browsing experience through the Website a pleasant,
                    enjoyable and orderly experience. Thus, Gussy is providing
                    these (“Terms of Use” or “Terms”) to inform you of the
                    specific parameters, restrictions and guidelines by which
                    the Website can be used.
                    <br /> <br />
                    By visiting the Website and/ or purchasing something from
                    us, you agree to be bound by these Terms of Use, including
                    those additional terms and conditions and policies
                    referenced herein and/or available by hyperlink. These Terms
                    of Use apply to all users of the Website, including without
                    limitation browsers, vendors, customers, merchants, and/ or
                    advertisers or contributors of content.
                    <br /> <br />
                    If you do not agree to all or any of the terms and
                    conditions in these Terms of Use, then you may not access
                    the Website or avail of any of the services offered.
                    <br /> <br />
                    Any new features or tools which are added to the Website
                    shall also be subject to the Terms of Use. The most current
                    version of the Terms of Use can be viewed at any time on the
                    Website. We, however, reserve the right to update, change or
                    replace any provision of these Terms of Use by posting
                    notices of updates and/or changes to our Website. Your
                    continued use of or access of the Website following the
                    posting of any notice of changes constitutes an express
                    acceptance of those changes.
                    <br /> <br />
                    GUSSY website is an online e-commerce platform that sells
                    products and services to you.
                  </p>
                  <h5>SECTION 1 - CAPACITY</h5>
                  <p>
                    The services offered in the Website are not intended for use
                    by anyone under the age of majority, and which is eighteen
                    (18) years of age in the Philippines. By accessing the
                    Website and availing of any of its services, you represent
                    that you are at least the age of majority in your place of
                    residence.
                  </p>
                  <h5>SECTION 2 - GENERAL CONDITIONS</h5>
                  <p>
                    2.1
                    <span className="pl-3">
                      Gussy reserves the right to refuse to provide any service
                      to anyone for any reason at any time.
                    </span>
                    <br /> <br />
                    2.2
                    <span className="pl-3">
                      You understand that your content and non-personal
                      information (not including credit card information), may
                      be transferred unencrypted and involve (a) transmissions
                      over various networks; and (b) changes to conform and
                      adapt to technical requirements of connecting networks or
                      devices. However, rest assured that all credit card
                      information is always encrypted during transfer over
                      networks.
                    </span>
                    <br /> <br />
                    2.3
                    <span className="pl-3">
                      You shall not reproduce, duplicate, copy, sell, resell or
                      exploit any portion of the Website, the services offered,
                      the use and access to and around the Website and its
                      services or otherwise use the same for any other illegal
                      or immoral purpose.
                    </span>
                    <br /> <br />
                    2.4
                    <span className="pl-3">
                      Neither may you create frames around Gussy’s Web pages or
                      use other techniques that alter in any way the visual
                      presentation or appearance of Gussy’s Website without
                      prior approval and express written permission.
                    </span>
                    <br /> <br />
                    2.5
                    <span className="pl-3">
                      You shall not use the Website or any of the products or
                      services offered and sold therein for any illegal, immoral
                      or unauthorized purpose.
                    </span>
                    <br /> <br />
                    2.6
                    <span className="pl-3">
                      Neither shall you transmit any worms or viruses or any
                      code of a destructive nature intended to disrupt the
                      Website’s operations, extract confidential information or
                      otherwise for any illegal, immoral or illegal purposes.
                    </span>
                    <br /> <br />
                    2.7
                    <span className="pl-3">
                      Any breach or violation of any of the Terms of Use will
                      result in an immediate and permanent ban on availing of
                      the services, without prejudice to any other right or
                      remedy available under law or equity.
                    </span>
                    <br /> <br />
                    2.8
                    <span className="pl-3">
                      These Terms of Use are effective and shall continue to be
                      in effect unless otherwise terminated or modified by
                      Gussy. The effectivity date of any revised Terms of Use
                      shall be stated on the version of the Terms of Use being
                      currently viewed.
                    </span>
                  </p>

                  <h5>
                    SECTION 3 - ACCURACY, COMPLETENESS, AND TIMELINESS OF
                    INFORMATION
                  </h5>
                  <p>
                    Some of the materials in this Website are provided for
                    general information only and can contain or reflect the
                    personal preferences and opinions of the Website’s owners,
                    content managers, editors, etc. You are still encouraged to
                    consult other sources to be able to arrive at the most
                    accurate, complete and informed opinion.
                    <br /> <br />
                    Gussy reserves the right to modify the contents of this
                    Website at any time and that it is the user’s responsibility
                    to monitor changes to the Website. However, Gussy has no
                    obligation to update any information on the Website.
                    <br /> <br />
                  </p>

                  <h5>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</h5>
                  <p>
                    4.1
                    <span className="pl-3">
                      Prices as well as any promotional services for products
                      offered on the Website are subject to change without
                      notice.
                    </span>
                    <br /> <br />
                    4.2
                    <span className="pl-3">
                      Gussy reserves the right at any time to modify or
                      discontinue any product or service being offered (or any
                      part or content thereof) without notice at any time.
                    </span>
                    <br /> <br />
                    4.3
                    <span className="pl-3">
                      Gussy shall not be liable to you or to any third-party for
                      any modification, price change, suspension or
                      discontinuance of any product or service being offered.
                    </span>
                  </p>

                  <h5>SECTION 5 - PRODUCTS OR SERVICES</h5>
                  <p>
                    5.1
                    <span className="pl-3">
                      Certain products or services may be available exclusively
                      online through the Website. These products or services
                      have limited quantities and are not subject to any
                      reservations except as otherwise provided in any
                      promotional mechanics or offer. All purchases are subject
                      to Gussy’s Return or Exchange Policy.
                    </span>
                    <br /> <br />
                    5.2
                    <span className="pl-3">
                      Gussy has made every effort to display and describe all
                      products via images and with descriptions which are as
                      accurate as possible. However, your view of colors and
                      images of products that appear in the Website may be
                      affected by the monitor display of your computer screen,
                      smart phone or tablet. All descriptions or images of
                      products are subject to change at any time without notice,
                      at Gussy’s sole discretion.
                    </span>
                    <br /> <br />
                    5.3
                    <span className="pl-3">
                      Gussy reserves the right, but is not in any way obligated,
                      to: (i) limit the sales of products or Services to any
                      person, geographic region or jurisdiction; (ii) to limit
                      the quantities or even discontinue any products or
                      services that are offered.
                    </span>
                    <br /> <br />
                    Gussy reserves the right to exercise these prerogatives,
                    together with all other rights provided in these Terms of
                    Use on a case-by-case basis.
                    <br /> <br />
                    5.4
                    <span className="pl-3">
                      It is understood that any offer for any product or service
                      made on the Website is void where it is prohibited.
                    </span>
                    <br /> <br />
                    5.5
                    <span className="pl-3">
                      Gussy’s aim is to sell to final users or consumers. For
                      this purpose, Gussy reserves the right to reject or limit
                      orders and/or quantities that, in Gussy’s sole judgment,
                      appear to be placed by dealers, resellers or distributors.
                      Thus, Gussy reserves the right in its sole discretion, to
                      refuse any order which is placed or to limit or cancel
                      quantities purchased per person, per household or per
                      order. These restrictions may be placed on orders under
                      the same customer account, the same credit card, and/or
                      orders that use the same billing and/or shipping address
                      or any other circumstance which arouses Gussy’s suspicion.
                    </span>
                    <br /> <br />
                    In the event that Gussy makes a change or cancels an order,
                    Gussy will promptly notify you through the e-mail and/or
                    billing address/phone number provided at the time the order
                    was made and in accordance with these Terms of Use.
                    <br /> <br />
                    5.6
                    <span className="pl-3">
                      The extent of Gussy’s warranty is subject to its Return
                      and Exchange Policy provided in connection with the
                      products or services. To the fullest extent permitted by
                      applicable law, Gussy disclaims all other warranties and
                      representations, whether express or implied, including,
                      but not limited to, any implied warranties of
                      merchantability, fitness for a particular purpose, and any
                      warranties arising out of course of dealing or usage of
                      trade.
                    </span>
                    <br /> <br />
                    5.7
                    <span className="pl-3">
                      Gussy shall endeavor to deliver and fulfill services or
                      products ordered within fourteen (14) business days for
                      orders within Metro Manila and Greater Manila Area only.
                      However, you accept that Gussy’s compliance is also
                      dependent on the availability of shippers/transporters,
                      location of the purchaser and other factors. For this
                      purpose, Gussy shall provide timely updates regarding the
                      status of any orders. Further, delivery charges/rates
                      indicated will apply.
                    </span>
                  </p>

                  <h5>
                    SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION
                  </h5>
                  <p>
                    6.1
                    <span className="pl-3">
                      You agree to provide current, complete and accurate
                      purchase and account information for all purchases made
                      through Gussy’s Website. To ensure that all transactions
                      are completed and fulfilled, you shall promptly update
                      your account and other information, including your email
                      address and credit card numbers and expiration dates.
                    </span>
                    <br /> <br />
                    6.2
                    <span className="pl-3">
                      For more details, please also review Gussy’s Returns and
                      Exchange Policy.
                    </span>
                  </p>

                  <h5>SECTION 7 - OPTIONAL TOOLS</h5>
                  <p>
                    7.1
                    <span className="pl-3">
                      Gussy may provide you with access to third-party tools.
                      Note, however, that Gussy does not monitor nor have any
                      control nor input over these third-party tools and that
                      these are being provided merely for your convenience and
                      reference.
                    </span>
                    <br /> <br />
                    7.2
                    <span className="pl-3">
                      You acknowledge and agree that Gussy provides access to
                      such tools on an “as-is-where-is” and “as available” basis
                      without any warranties, representations or conditions of
                      any kind and without any specific or formal endorsement.
                      Gussy disavows any liability whatsoever arising from or
                      relating to your use of these optional third-party tools.
                    </span>
                    <br /> <br />
                    7.3
                    <span className="pl-3">
                      Your use of optional tools offered through the Website is
                      entirely at your own risk and discretion. Thus, you should
                      ensure that you are familiar with and approve of the terms
                      on which these tools are provided by the relevant
                      third-party provider(s).
                    </span>
                    <br /> <br />
                    7.4
                    <span className="pl-3">
                      Gussy may also offer new services and/or features through
                      the Website (including, the release of new tools and
                      resources). Such new features and/or services shall also
                      be subject to these Terms of Use.
                    </span>
                  </p>

                  <h5>SECTION 8 - THIRD-PARTY LINKS</h5>
                  <p>
                    8.1
                    <span className="pl-3">
                      Certain content, products and services available via the
                      Website may include materials from third-parties.
                    </span>
                    <br /> <br />
                    8.2
                    <span className="pl-3">
                      These third-party links on the Website may direct you to
                      third-party websites that are not affiliated with us.
                      Gussy is not responsible for examining or evaluating the
                      content or accuracy and neither does Gussy make any
                      endorsement or warranty for any information, products or
                      transaction with any third-party materials or websites, or
                      for any other materials, products, or services of
                      third-parties.
                    </span>
                    <br /> <br />
                    8.3
                    <span className="pl-3">
                      Gussy shall not be liable for any inconvenience,
                      inaccuracy, harm or damage incurred in connection with the
                      purchase or use of goods, services, resources, content, or
                      any other transactions made in connection with any
                      third-party websites. For your security, please review the
                      third-party's policies and practices and ensure that you
                      understand and agree to them before you enter into any
                      transaction. Complaints, claims, concerns, or questions
                      regarding third-party products should be directed to the
                      third-party.
                    </span>
                  </p>

                  <h5>
                    SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
                  </h5>
                  <p>
                    9.1
                    <span className="pl-3">
                      Gussy values comments, suggestions or feedback regarding
                      your experience with any service or product offered or
                      with the Website. Responsible and constructive criticism
                      or suggestions are highly appreciated and will help Gussy
                      improve the services and products offered.
                    </span>
                    <br /> <br />
                    9.2
                    <span className="pl-3">
                      If, at Gussy’s request, you send certain specific
                      submissions (for example contest entries) or even without
                      a request from Gussy you send creative ideas, suggestions,
                      proposals, plans, or other materials, whether online, by
                      email, by postal mail, or otherwise (collectively,
                      'comments'), you agree that Gussy may, at any time,
                      without restriction, edit, copy, publish, distribute,
                      translate and otherwise use in any medium any comments
                      that you forward to Gussy. Gussy is and shall be under no
                      obligation (1) to maintain any comments in confidence; (2)
                      to pay compensation for any comments; or (3) to respond to
                      any comments.
                    </span>
                    <br /> <br />
                    9.3
                    <span className="pl-3">
                      Gussy reserves the right to monitor, edit or remove
                      content that Gussy determines, in its sole discretion, to
                      be unlawful, offensive, threatening, libelous, defamatory,
                      pornographic, obscene or otherwise objectionable or
                      violates any party’s intellectual property or these Terms
                      of Use. Gussy shall also be entitled to pursue any
                      available right or remedy under law or equity, as may be
                      warranted under the circumstances.
                    </span>
                    <br /> <br />
                    9.4
                    <span className="pl-3">
                      You shall ensure that your comments or submissions will
                      not: (i) violate any right of any third-party, including
                      copyright, trademark, privacy, personality or other
                      personal or any proprietary rights; (ii) contain libelous
                      or otherwise unlawful, abusive or obscene material; (iii)
                      contain any computer virus or other malware that could in
                      any way affect the operation of the services or Website.
                    </span>
                    <br /> <br />
                    9.5
                    <span className="pl-3">
                      The use of a false e-mail address or pretending to be
                      someone other than yourself, or otherwise intending to
                      mislead Gussy or any third-parties as to the origin of any
                      comments is prohibited and shall be dealt with
                      accordingly.
                    </span>
                    <br /> <br />
                    9.6
                    <span className="pl-3">
                      Gussy shall not be responsible and assumes no liability
                      for any comments posted by any third-party.
                    </span>
                  </p>

                  <h5>SECTION 10 - PERSONAL INFORMATION</h5>
                  <p>
                    Your submission of personal information through the store is
                    governed by Gussy’ Privacy Policy.{' '}
                    <a href="/privacy-policy">Click Here</a> to view our Privacy
                    Policy.
                  </p>

                  <h5>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</h5>
                  <p>
                    11.1
                    <span className="pl-3">
                      Gussy aims to provide you with the most correct and
                      accurate information regarding the products or services
                      offered in the Website. Occasionally, however, there may
                      be information on the Website that contains inadvertent
                      typographical errors, inaccuracies or omissions relating
                      to product descriptions, pricing, promotions, offers,
                      product shipping charges, transit times and availability.
                      Gussy reserves the right to correct any unintentional
                      errors, inaccuracies or omissions, and to change or update
                      information or cancel orders if any information in any
                      services or on any related Website is inaccurate at any
                      time without prior notice (including after you have
                      submitted your order).
                    </span>
                    <br /> <br />
                    11.2
                    <span className="pl-3">
                      Gussy undertakes, as soon as practicable, to update, amend
                      or clarify information regarding the services/products or
                      on any related information on the Website, including
                      without limitation, pricing information.
                    </span>
                  </p>

                  <h5>SECTION 12 - PROHIBITED USES</h5>
                  <p>
                    In addition to other prohibitions as set forth in these
                    Terms of Use, it is also prohibited to use the Website or
                    its content: (a) for any unlawful purpose; (b) to solicit
                    others to perform or participate in any unlawful acts; (c)
                    to violate any domestic or international rules, laws, or
                    local ordinances; (d) to infringe upon or violate Gussy’s
                    intellectual property rights or the intellectual property
                    rights of others; (e) to harass, abuse, insult, harm,
                    defame, slander, disparage, intimidate, or discriminate
                    based on gender, sexual orientation, religion, ethnicity,
                    race, age, national origin, or disability; (f) to submit
                    false or misleading information; (g) to upload or transmit
                    viruses or any other type of malicious code that will or may
                    be used in any way that will affect the functionality or
                    operation of the service or the Website, other websites, or
                    the Internet; (h) to collect or track the personal
                    information of others; (i) to spam, phish, pharm, pretext,
                    spider, crawl, or scrape; (j) for any obscene or immoral
                    purpose; or (k) to interfere with or circumvent the security
                    features of the service or the Website, other websites, or
                    the Internet.
                    <br /> <br />
                    Gussy reserves the right to terminate your use of the
                    service or the Website for violating any of the prohibited
                    uses, as well as to avail of any other right or remedy under
                    law or equity.
                  </p>

                  <h5>SECTION 13 - LICENSE</h5>
                  <p>
                    13.1
                    <span className="pl-3">
                      Unless otherwise stated, Gussy and/or its licensors own
                      the intellectual property rights for all material on
                      relating to Gussy and the Website. All intellectual
                      property rights are reserved. You may view and/or print
                      pages from{' '}
                      <a href="https://www.gussy.design">
                        https://www.gussy.design
                      </a>{' '}
                      for your own personal use subject to restrictions set in
                      these Terms of Use.r order).
                    </span>
                    <br /> <br />
                    13.2
                    <span className="pl-3">
                      No user may: (i) republish material from{' '}
                      <a href="https://www.gussy.design">
                        https://www.gussy.design
                      </a>
                      ; (ii) sell, rent out or sub-license material from{' '}
                      <a href="https://www.gussy.design">
                        https://www.gussy.design
                      </a>
                      ; (iii) reproduce, duplicate or copy material from{' '}
                      <a href="https://www.gussy.design">
                        https://www.gussy.design
                      </a>
                      ; (iv) redistribute content from Gussy Design (unless
                      content is specifically made for redistribution); or (v)
                      any other analogous acts aimed at unlawfully appropriating
                      or utilizing Gussy’s intellectual property without Gussy’s
                      consent.
                    </span>
                  </p>

                  <div id="cookies" className="anchor"></div>
                  <h5>SECTION 14 - COOKIES</h5>
                  <p>
                    14.1
                    <span className="pl-3">
                      Most of today’s interactive web sites use cookies which
                      retrieves user details for each visit and which are aimed
                      at creating a more meaningful and relevant customer
                      experience for all users. Similarly, Gussy employs the use
                      of cookies and by using Gussy's website, you consent to
                      the use of cookies in accordance with Gussy's Privacy
                      Policy.
                    </span>
                    <br />
                    <br />
                    14.2
                    <span className="pl-3">
                      Note that some of Gussy’s affiliate/ advertising partners
                      may also use cookies. Please review the applicable privacy
                      policies for your information.
                    </span>
                  </p>

                  <h5>
                    SECTION 15 - DISCLAIMER OF WARRANTIES; LIMITATION OF
                    LIABILITY
                  </h5>
                  <p>
                    15.1
                    <span className="pl-3">
                      While we aim to give Gussy’s users the most hassle-free
                      experience, Gussy does not discount the possibility that
                      factors beyond its control may sometimes affect the
                      continuity, availability or functionality of the Website.
                      Thus, Gussy does not guarantee, represent or warrant that
                      your use of the service and the Website will always be
                      uninterrupted, timely, secure or error-free.
                    </span>
                    <br />
                    <br />
                    15.2
                    <span className="pl-3">
                      You accept and understand that from time to time Gussy may
                      remove any service, product, feature or link for
                      indefinite periods of time or cancel the service at any
                      time, without notice.
                    </span>
                    <br /> <br />
                    15.3
                    <span className="pl-3">
                      Except as otherwise provided in the Return and Exchange
                      Policy and these Terms of Use, all products and services
                      delivered to you purchased from the Website are provided
                      on an “as-is-where-is” and 'as available' basis, without
                      any representation, warranties or conditions of any kind,
                      either express or implied, including all implied
                      warranties or conditions of merchantability, merchantable
                      quality, fitness for a particular purpose, durability,
                      title, and non-infringement.
                    </span>
                    <br /> <br />
                    15.4
                    <span className="pl-3">
                      In no case shall Gussy, Gussy’s directors, officers,
                      employees, affiliates, agents, contractors, interns,
                      suppliers, service providers or licensors be liable for
                      any injury, loss, claim, or any direct, indirect,
                      incidental, punitive, special, or consequential damages of
                      any kind, including, without limitation lost profits, lost
                      revenue, lost savings, loss of data, replacement costs, or
                      any similar damages, whether based in contract, tort
                      (including negligence), strict liability or otherwise,
                      arising from your use of any of the service or any
                      products procured using the service and through the
                      Website, or for any other claim related in any way to your
                      use of the service or any product, including, but not
                      limited to, any errors or omissions in any content, or any
                      loss or damage of any kind incurred as a result of the use
                      of the service or any content (or product) posted,
                      transmitted, or otherwise made available via the service,
                      even if advised of their possibility.
                    </span>
                  </p>

                  <h5>SECTION 16 - INDEMNIFICATION</h5>
                  <p>
                    You shall indemnify, defend and hold harmless Gussy and
                    Gussy’s parent, subsidiaries, affiliates, partners,
                    officers, directors, agents, contractors, licensors, service
                    providers, subcontractors, suppliers, interns and employees,
                    harmless from any claim or demand, including reasonable
                    attorneys’ fees, made by any third-party due to or arising
                    out of your breach of these Terms of Use or the documents
                    incorporated by reference, or your violation of any law or
                    the rights of a third-party.
                  </p>

                  <div id="returnpolicy" className="anchor"></div>

                  <h5>SECTION 17 - RETURN AND EXCHANGE POLICY</h5>
                  <p>
                    Without prejudice to Gussy’s limitation of liability
                    provided in Sec. 15.3 of these Terms of Use, please note the
                    terms and conditions of Gussy’s Return and Exchange Policy:
                    <br /> <br />
                    a.
                    <span className="pl-3">
                      Except as otherwise may be provided under law and these
                      Terms of Use, Gussy’s limitation of liability shall be
                      limited to replacing any damaged, defective or erroneous
                      service or product procured through the Website. If there
                      is no replacement available, Gussy shall offer a store
                      credit equivalent to the amount of the damaged, defective
                      or erroneous item and which can be used to avail of any
                      other service or product from the Website. It is
                      understood, however, that should the credit be
                      insufficient to cover the amount of the replacement, you
                      shall shoulder any balance.
                    </span>
                    <br /> <br />
                    b.
                    <span className="pl-3">
                      When allowed, Products that will qualify for Returns and
                      Exchanges must not have been used, with the boxes,
                      packaging, stickers and other marks still attached and as
                      close to the condition when it was shipped.
                    </span>
                    <br /> <br />
                    c.
                    <span className="pl-3">
                      All customers are encouraged to carefully check and
                      inspect any item upon its delivery. Should you or your
                      authorized representative refuse or fail to inspect the
                      concerned item for any prominent, obvious and apparent
                      damage, our delivery personnel shall expressly note in the
                      delivery receipt that you "received without inspection".
                      In this case, you agree that Gussy and its Merchant will
                      no longer be liable for any claim to replace the damaged
                      item. This provision will not apply if the item delivered
                      was not the correct item ordered and Gussy shall still
                      honor the claim to exchange the item for the correct one.
                    </span>
                    <br /> <br />
                    d.
                    <span className="pl-3">
                      Claims for Return or Exchange which are not barred under
                      (c) above must be made within a reasonable time from its
                      delivery but not to exceed forty-eight (48) hours from its
                      delivery. Otherwise, it is conclusively established that
                      the service or product is correct and free from defects or
                      damage.
                    </span>
                    <br /> <br />
                    e.
                    <span className="pl-3">
                      For qualified Returns or Exchanges, Gussy shall get in
                      touch with you for the details of your Return or Exchange.
                    </span>
                    <br /> <br />
                    f.
                    <span className="pl-3">
                      Any issues involving products or services acquired from
                      third-parties, other than merchants, found on the Website
                      shall be governed by the concerned third-party’s terms and
                      policies.
                    </span>
                  </p>

                  <h5>SECTION 18 – FORCE MAJEURE</h5>
                  <p>
                    Gussy shall not be liable if and to the extent that its
                    delay or failure to perform its obligations is exclusively
                    caused by <i>force majeure</i> and without its fault or
                    negligence either prior, during or subsequent to the
                    happening of the <i>force majeure</i>.
                    <br />
                    <br />
                    <i>“Force majeure”</i> means an unforeseeable event, or even
                    if foreseeable is inevitable, and is outside the control of
                    any Party and which effectively prevents the performance of
                    Gussy’ obligations. <i>Force majeure</i> under this Section
                    shall include but not be limited to wars, revolutions,
                    fires, floods, epidemics, pandemics, quarantine restrictions
                    and other analogous or similar circumstances.
                  </p>

                  <h5>SECTION 19 – MISCELLANEOUS PROVISIONS</h5>
                  <p>
                    19.1
                    <span className="pl-3">
                      Cumulative Remedies. – Any right or remedy conferred
                      herein upon Gussy shall not be exclusive of any other
                      right or remedy; but each right or remedy shall be
                      cumulative of every other right or remedy available to
                      Gussy.
                    </span>
                    <br />
                    <br />
                    19.2
                    <span className="pl-3">
                      Governing Law. – These Terms of Use shall be governed,
                      construed and interpreted in accordance with the laws of
                      the Republic of the Philippines, without giving effect to
                      principles of conflicts of law. All actions or proceedings
                      arising out of or in connection with these Terms of Use
                      shall be brought exclusively before the proper courts
                      within Metro Manila.
                    </span>
                    <br />
                    <br />
                    19.3
                    <span className="pl-3">
                      Severability. – In the event that any provision of these
                      Terms of Use is determined to be unlawful, void or
                      unenforceable, the unenforceable portion shall be deemed
                      to be severed from these Terms of Use and shall not affect
                      the validity and enforceability of any other remaining
                      provisions.
                    </span>
                    <br />
                    <br />
                    19.4
                    <span className="pl-3">
                      Non-waiver. – The failure of Gussy to insist upon the
                      strict performance of any of the provisions of these Terms
                      of Use shall not be deemed a relinquishment or waiver of
                      any subsequent breach or default of the terms and
                      conditions and covenants hereof, and which terms and
                      conditions shall continue to be in full force and effect.
                      No waiver by Gussy of any of their rights under this
                      Agreement shall be deemed to have been made unless
                      expressed in writing and signed.
                    </span>
                    <br />
                    <br />
                    19.5
                    <span className="pl-3">
                      Entire Agreement. – These Terms of Use and any policies or
                      operating rules posted on the Website or in respect to the
                      service constitutes the entire agreement and understanding
                      between you and Gussy and governs your use of the services
                      and Website, superseding any prior or contemporaneous
                      agreements, communications and proposals, whether oral or
                      written (including, but not limited to, any prior versions
                      of the Terms of Use).
                    </span>
                  </p>

                  <h5>SECTION 20 - CONTACT INFORMATION</h5>
                  <p>
                    Questions about the Terms of Use may be sent to us at{' '}
                    <a href="mailto:hello@gussy.design">hello@gussy.design</a>.
                    We shall be more than happy to accommodate your questions or
                    concerns.
                  </p>
                </div>
              </div>
            </div>
          </Fade>
        </LayoutComponent>
      </div>
    );
  }
}

export default TermsOfServicePage;
