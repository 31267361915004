import React, { useState } from 'react';
import Swal from 'sweetalert2';

import { AuthContext } from '../context';
import { authFirebase, functionsFirebase } from '../components/Firebase';
import { httpsCallable } from 'firebase/functions';
import { signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';

const AuthProvider = (props) => {
  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(true);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        isLoggingIn,
        setUser: (x) => setUser(x),
        setIsLoggedIn: (status) => setIsLoggedIn(status),
        setIsLoggingIn: (status) => setIsLoggingIn(status),
        logout: () =>
          signOut(authFirebase).then(async () => {
            localStorage.removeItem('uniqueId');
            localStorage.removeItem('cartItems');
            setIsLoggedIn(false);
            setUser({});
            window.location.href = '/home';
          }),
        getToken: async (uid) => {
          const token = await httpsCallable(functionsFirebase, 'getSignInToken')({ uid });
          return token.data;
        },
        signIn: async (email, password) => {
          const promise = await signInWithEmailAndPassword(authFirebase, email, password);
          promise.catch((e) => {
            Swal.fire('Invalid email and/or password.', '', 'error');
          });
        },
        signUp: async (
          email,
          region,
          city,
          zipCode,
          password,
          province,
          mobilePhone,
          firstName,
          lastName,
        ) =>
          new Promise(async (resolve, reject) => {
            const name = `${firstName} ${lastName}`;
            const newUserDetails = {
              name,
              email,
              region,
              city,
              province,
              zipCode,
              roles: { client: true },
              status: 'Active',
              mobilePhone,
              firstName,
              lastName,
            };

            // deletes null values in newUserDetails object
            Object.keys(newUserDetails).forEach(
              (key) => newUserDetails[key] == null && delete newUserDetails[key],
            );

            const newUser = await createUserWithEmailAndPassword(
              authFirebase,
              email,
              password,
            ).catch((error) => console.error(error.message));

            await httpsCallable(
              functionsFirebase,
              'saveUser',
            )({
              id: newUser.user.uid,
              values: newUserDetails,
            });

            setUser({ ...newUserDetails, id: newUser.user.uid });
            setIsLoggedIn(true);

            await httpsCallable(
              functionsFirebase,
              'verifyEmail',
            )({
              email,
              name,
            });

            resolve({ ...newUserDetails, id: newUser.user.uid });
          }),
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
