import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import FooterComponent from '../../components/FooterComponent';
import NavbarLPComponent from '../../components/LandingPage/NavbarLPComponent';

function FailedPayment(props) {
  const [transId, setTransId] = useState();
  // eslint-disable-next-line react/prop-types
  const { location } = props;
  // eslint-disable-next-line react/prop-types
  const params = queryString.parse(location.search);

  useEffect(() => {
    const init = async () => {
      if (params.trans_id) {
        setTransId(params.trans_id);
      }
    };

    init();
    window.scrollTo(0, 0);
  }, [params.trans_id]);

  return (
    <div>
      <NavbarLPComponent />
      <div className="mt-100 p-5 text-center success-page">
        <div className="container">
          <Row className="flex-column align-items-center">
            <Col lg={6}>
              <h3 className="mb-3">Payment Failed</h3>
              {transId && <p>Transaction # {transId}</p>}
              <p>You can try again by going back to your cart and checking out.</p>
            </Col>
            <Link
              className="btn-petra btntext-13-sb p-13-55 mt-2 btn btn-primary"
              to="/cart"
              variant="primary"
            >
              Back to Cart
            </Link>
          </Row>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default FailedPayment;
