import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';

import CreateAccountForm from '../../components/CreateAccountForm';
import FooterComponent from '../../components/FooterComponent';
import NavbarLPComponent from '../../components/LandingPage/NavbarLPComponent';
import Loading from '../../components/Loading';
import { GUSSY_REDIRECT_URL } from '../../config/constants';
import { AuthContext } from '../../context';
import './checkout.scss';

function CheckoutPackagePage(props) {
  // eslint-disable-next-line react/prop-types
  const { location } = props;
  // eslint-disable-next-line react/prop-types
  const params = queryString.parse(location.search);
  const [activeTab, setActiveTab] = useState('tab-information');
  const authContext = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const redirectToAppCheckout = async () => {
      if (authContext.isLoggedIn) {
        const token = await authContext.getToken(authContext.user.id);
        window.location.href = `${GUSSY_REDIRECT_URL}/client-timeline.html?package=${params.id}&token=${token}`;
      }
    };

    redirectToAppCheckout();
  }, [authContext, params.id]);

  return (
    <div className="no-focus-outline">
      <NavbarLPComponent />
      <CookieConsent
        buttonStyle={{
          background: '#d1755b',
          padding: '5px 20px',
          color: '#ffffff',
          fontSize: '13px',
        }}
        buttonText="I Agree"
        location="bottom"
        style={{ background: '#33332B' }}
      >
        This website uses cookies to enhance the user experience.{' '}
        <a className="cookies-link" href="/terms-of-use#cookies">
          Learn More.
        </a>
      </CookieConsent>

      <Tab.Container
        activeKey={activeTab}
        defaultActiveKey="tab-information"
        onSelect={(x) => setActiveTab(x)}
      >
        <Container className="mt-64 pt-5">
          {!authContext.isLoggedIn && (
            <Row>
              <Col className="pb-4" sm={12}>
                <h2 className="mb-0">Checkout</h2>
                <Nav className="checkout-tabs" variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="tab-information">Account Information</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="tab-information">
                    <Row>
                      <Col lg={6}>{!authContext.isLoggedIn && <CreateAccountForm />}</Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          )}
          {authContext.isLoggedIn && <Loading />}
        </Container>
      </Tab.Container>
      <FooterComponent />
    </div>
  );
}

export default CheckoutPackagePage;
