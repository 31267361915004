import chunk from "lodash.chunk";
import isEmpty from "lodash/isEmpty";
import React, { useContext, useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  FormControl,
  InputGroup,
  Modal,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useDebounce } from "use-lodash-debounce";

import HelmetComponent from "../../components/HelmetComponent";
import LayoutComponent from "../../components/LayoutComponent";
import Loading from "../../components/Loading";
import ProductThumbnail from "../../components/ShopComponents/ProductThumbnailComponent";
import ShopNav from "../../components/ShopComponents/ShopNav";
import SortSelect from "../../components/ShopComponents/SortSelect";
import ShopFilterComponent from "../../components/ShopFilterComponent";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";

import {
  COLOR_FILTERS,
  DEFAULT,
  FILTERS,
  H_TO_L,
  INACTIVE,
  L_TO_H,
  NEW,
  SALE,
  STYLE_FILTERS,
  URL_NAV_PARAMS,
} from "../../config/constants";
import { ShopFilterContext } from "../../context";

import {
  fetchMerchants,
  fetchProductKeys,
  fetchProducts,
  getCategoryProductTypes,
  getProductTypesByCategory,
  sortKeys,
} from "../../queries/shop";

import {
  getURLQueryParam,
  getURLQueryParamAllowNull,
  getURLQueryParamNumberOnly,
  isURLParamAvailable,
  setURLQueryParam,
} from "../../utils/helpers";

function ShopPage(props) {
  const { location, match } = props;

  const pageSize = 20;
  const inactiveKeys = [];
  const inactiveNames = [];

  const filterContext = useContext(ShopFilterContext);

  const [categories, setCategories] = useState({});
  const [categoryProductTypes, setCategoryProductTypes] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSortBy, setCurrentSortBy] = useState("DEFAULT");
  const [firstLoad, setFirstLoad] = useState(true);
  const [isCategoryFiltersSet, setIsCategoryFiltersSet] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingCategories, setIsFetchingCategories] = useState(true);
  const [merchants, setMerchants] = useState({});
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [productResponses, setProductResponses] = useState([]);
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState(false);

  const debouncedPagination = useDebounce(currentPage, 200);

  const debouncedFilters = useDebounce(filterContext.filters, 400);

  const urlParams = useParams();
  const urlPageParam = match.params.page ?? null;
  const page = urlPageParam
    ? urlPageParam
    : (location.state && location.state.page) || null;
  const category =
    (urlParams && urlParams.categoryId) ||
    (location.state && location.state.fromBreadCrumb
      ? location.state.fromBreadCrumb
      : null)
      ? urlParams.categoryId
      : !isEmpty(filterContext.filters.CATEGORY_ID)
      ? filterContext.filters.CATEGORY_ID
      : null;
  const prodType =
    (urlParams && urlParams.productTypeId) ||
    (location.state && location.state.fromBreadCrumb
      ? location.state.fromBreadCrumb
      : null) ||
    (urlParams && urlParams.categoryId)
      ? urlParams.productTypeId
      : !isEmpty(filterContext.filters.PRODUCT_TYPE_ID)
      ? filterContext.filters.PRODUCT_TYPE_ID
      : null;
  let breadCrumbs;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    //always check if they enter int
    let currentPageInt = getURLQueryParamNumberOnly(URL_NAV_PARAMS.PAGE);
    if (currentPageInt) {
      setCurrentPage(currentPageInt);
    } else {
      setCurrentPage(filterContext.pageNum);
    }
    let sortBy = getURLQueryParam(URL_NAV_PARAMS.SORT);
    if (sortBy && isSortStrValid(sortBy)) {
      setCurrentSortBy(sortBy);
    } else {
      setCurrentSortBy(filterContext.sortBy);
    }

    getCategories();
    getMerchants();
    sortKeys();
    filterByParams();
  }, [page, category, prodType]);

  useEffect(() => {
    if (isCategoryFiltersSet) {
      getKeys();
    }
  }, [debouncedFilters]);

  useEffect(() => {
    if (productResponses.length > 0) {
      sortProducts();
      setIsFetching(true);
    }
  }, [currentSortBy, productResponses]);

  useEffect(() => {
    if (paginatedProducts.length > 0 && isFetching) {
      getProducts();
    } else if (paginatedProducts.length === 0 && isFetching) {
      setFirstLoad(false);
    } else {
      setIsFetching(false);
    }
  }, [debouncedPagination, paginatedProducts]);

  const setCategoryFilters = async (
    categoryId,
    productTypeId,
    productTypeIds,
  ) => {
    let searchText = "";
    if (location.state) {
      searchText = location.state.search ?? "";
    }

    // sets page number and sort by to defaulr if it is not from product details
    // or if there's a previous search, category, or product type
    if (
      !(location.state && location.state.fromProductDetails) &&
      ((location.state && location.state.search) ||
        !isEmpty(categoryId) ||
        !isEmpty(productTypeId) ||
        !isEmpty(productTypeIds))
    ) {
      filterContext.storePageNum(1);
      filterContext.storeSortBy(DEFAULT);
      setCurrentPage(1);
      //set params to default
      setURLQueryParam(URL_NAV_PARAMS.PAGE, 1);
      setURLQueryParam(URL_NAV_PARAMS.SORT, DEFAULT);
    }

    let tempFilter = {};
    if (location.state && location.state.fromProductDetails) {
      tempFilter = {
        ...filterContext.filters,
      };
      location.state.fromProductDetails = false;
    } else {
      tempFilter = {
        ...FILTERS,
        SEARCH: searchText,
        CATEGORY_ID: categoryId ? categoryId : {},
        PRODUCT_TYPE_ID: productTypeId ? productTypeId : {},
        PRODUCT_TYPE: productTypeIds ? productTypeIds : {},
      };
    }

    if (isURLParamAvailable()) {
      tempFilter = setFilterFromURL(tempFilter);
    }
    //for safeguard
    if (tempFilter) {
      filterContext.setFilters(tempFilter);
    }
  };

  const setFilterFromURL = (tempFilter) => {
    let filter = tempFilter;

    //SEARCH
    let search = getURLQueryParamAllowNull(URL_NAV_PARAMS.SEARCH);
    if (search) {
      filter.SEARCH = search;
    }

    //COLOR
    let color = getURLQueryParamAllowNull(URL_NAV_PARAMS.COLOR);
    if (color) {
      // split by comma,
      let colorArray = color.replace(/ /g, "").split(",");
      colorArray.forEach((val) => {
        //will add checker if in COLOR_FILTERS
        if (COLOR_FILTERS.includes(val)) {
          filter.COLOR[val] = true;
        }
      });
    }

    //STYLE
    let style = getURLQueryParamAllowNull(URL_NAV_PARAMS.STYLE);
    if (style) {
      // remove extra spaces if has,  split by comma,
      let styleArray = style.replace(/ /g, "").split(",");
      styleArray.forEach((val) => {
        //will add checker if in STYLE_FILTERS
        if (STYLE_FILTERS.includes(val)) {
          filter.STYLE[val] = true;
        }
      });
    }

    //MERCHANT BRAND
    let brand = getURLQueryParamAllowNull(URL_NAV_PARAMS.BRAND);
    if (brand) {
      // split by comma,
      let brandArray = brand.replace(/ /g, "").split(",");
      brandArray.forEach((val) => {
        //will add checker if in merchants
        filter.MERCHANT[val] = true;
      });
    }

    //height DIMENSION
    let height_min = getURLQueryParamNumberOnly(URL_NAV_PARAMS.HEIGHT_MIN);
    if (height_min) {
      filter.HEIGHT["min"] = height_min;
    }
    let height_max = getURLQueryParamNumberOnly(URL_NAV_PARAMS.HEIGHT_MAX);
    if (height_max) {
      filter.HEIGHT["max"] = height_max;
    }

    //width DIMENSION
    let width_min = getURLQueryParamNumberOnly(URL_NAV_PARAMS.WIDTH_MIN);
    if (width_min) {
      filter.WIDTH["min"] = width_min;
    }
    let width_max = getURLQueryParamNumberOnly(URL_NAV_PARAMS.WIDTH_MAX);
    if (width_max) {
      filter.WIDTH["max"] = width_max;
    }

    //DEPTH DIMENSION
    let depth_min = getURLQueryParamNumberOnly(URL_NAV_PARAMS.DEPTH_MIN);
    if (depth_min) {
      filter.DEPTH["min"] = depth_min;
    }
    let depth_max = getURLQueryParamNumberOnly(URL_NAV_PARAMS.DEPTH_MAX);
    if (depth_max) {
      filter.DEPTH["max"] = depth_max;
    }

    //PRICE
    let price_min = getURLQueryParamNumberOnly(URL_NAV_PARAMS.PRICE_MIN);
    if (price_min) {
      filter.PRICE["min"] = price_min;
    }
    let price_max = getURLQueryParamNumberOnly(URL_NAV_PARAMS.PRICE_MAX);
    if (price_max) {
      filter.PRICE["max"] = price_max;
    }

    // return modified filter
    return filter;
  };

  // validation for sort
  const isSortStrValid = (sortStr) => {
    let sortValidStrings = [
      DEFAULT,
      FILTERS,
      H_TO_L,
      INACTIVE,
      L_TO_H,
      NEW,
      SALE,
    ];
    return sortValidStrings.includes(sortStr);
  };

  const triggerNoProductsFoundDisplay = () => {
    setProducts([]);
    setFirstLoad(false);
    setIsFetching(false);
  };

  const setNewAndSaleFilter = (newValue, saleValue) => {
    filterContext.setFilters({
      ...FILTERS,
      NEW: newValue,
      SALE: saleValue,
    });
  };

  const filterByParams = async () => {
    if (page) {
      if (page === "new") {
        setNewAndSaleFilter(true, false);
      } else if (page === "sale") {
        setNewAndSaleFilter(false, true);
      } else {
        triggerNoProductsFoundDisplay();
      }
    } else if (category) {
      const categoryProdTypes = await getProductTypesByCategory(category);
      setCategoryProductTypes(categoryProdTypes);
      const prodTypes = {};
      if (prodType && categoryProdTypes.productTypes[prodType]) {
        setCategoryFilters(category, prodType, {
          [`${prodType}---${categoryProdTypes.productTypes[prodType].name}`]: true,
        });
      } else if (categoryProdTypes) {
        Object.values(categoryProdTypes?.productTypes || {}).forEach((val) => {
          if (prodType) {
            if (val.key === prodType) {
              prodTypes[`${val.key}---${val.name}`] = true;
            }
          } else {
            prodTypes[`${val.key}---${val.name}`] = true;
          }
        });
        setCategoryFilters(category, prodType, prodTypes);
      } else {
        triggerNoProductsFoundDisplay();
      }
    } else {
      setCategoryFilters({});
    }

    setIsCategoryFiltersSet(true);
  };

  const getKeys = async () => {
    setIsFetching(true);
    setProducts([]);
    const productKeysResponse = await fetchProductKeys(filterContext.filters);
    if (productKeysResponse.length === 0) {
      setIsFetching(false);
      setFirstLoad(false);
      setProductResponses([]);
      setPaginatedProducts([]);
    } else {
      const productsResponse = await fetchProducts(productKeysResponse);
      setProductResponses(productsResponse);
    }
  };

  const sortProducts = () => {
    let sortedProducts = [...productResponses];

    if (currentSortBy === L_TO_H) {
      sortedProducts.sort((a, b) => {
        return parseFloat(a.price) - parseFloat(b.price);
      });
    } else if (currentSortBy === H_TO_L) {
      sortedProducts.sort((a, b) => {
        return parseFloat(b.price) - parseFloat(a.price);
      });
    } else if (currentSortBy === SALE) {
      sortedProducts
        .sort((a, b) => {
          return parseFloat(b.saleDiscount) - parseFloat(a.saleDiscount);
        })
        .sort((a, b) => {
          return a.isOnSale === b.isOnSale ? 0 : a.isOnSale ? -1 : 1;
        });
    } else if (currentSortBy === NEW) {
      sortedProducts.sort((a, b) => {
        return b.key > a.key ? 1 : -1;
      });
    } else if (currentSortBy === DEFAULT) {
      sortedProducts = [...productResponses];
    }

    const chunkedProds = chunk(sortedProducts, pageSize);
    setPaginatedProducts(chunkedProds);

    if (chunkedProds.length === 0) {
      setIsFetching(false);
    }
  };

  const getProducts = async () => {
    // always check if undefined
    let product = paginatedProducts[currentPage - 1];
    // if it does make it an empty array
    product = product ? product : [];
    setProducts(product);
    setIsFetching(false);
    setFirstLoad(false);
  };

  const onClickReset = (event) => {
    event.preventDefault();
    filterContext.reset();
    props.history.push({
      search: "",
    });
  };

  const onChangeFilter = (event) => {
    filterContext.storePageNum(1);
    filterContext.storeSortBy(DEFAULT);
    setCurrentPage(1);
    setCurrentSortBy(DEFAULT);

    const { id, name, value } = event.target;
    let queryParam = name;
    let queryValue = value;

    function handleCheckboxFilter(params) {
      queryParam = params;
      queryValue = addOrRemoveInUrlQueryParam(params, id);

      const state = filterContext.filters[name][id] || false;

      filterContext.setFilters({
        ...filterContext.filters,
        [name]: { ...filterContext.filters[name], [id]: !state },
      });
    }

    switch (name) {
      case "MERCHANT":
        handleCheckboxFilter(URL_NAV_PARAMS.BRAND);
        break;
      case "STYLE":
        handleCheckboxFilter(URL_NAV_PARAMS.STYLE);
        break;
      case "PRODUCT_TYPE":
        handleCheckboxFilter(URL_NAV_PARAMS.PRODUCT_TYPE);
        break;
      case "COLOR":
        handleCheckboxFilter(URL_NAV_PARAMS.COLOR);
        break;
      case "PRICE":
      case "DEPTH":
      case "WIDTH":
      case "HEIGHT":
        queryParam = name + "_" + id;
        filterContext.setFilters({
          ...filterContext.filters,
          [name]: { ...filterContext.filters[name], [id]: value },
        });
        break;
      case "CATEGORY_ID":
      case "SEARCH":
        filterContext.setFilters({
          ...FILTERS,
          [name]: value,
        });
        break;
      default:
        break;
    }
    setURLQueryParam(queryParam.toLowerCase(), queryValue);
    setURLQueryParam(URL_NAV_PARAMS.PAGE, 1);
    setURLQueryParam(URL_NAV_PARAMS.SORT, DEFAULT);
  };

  //add Param if none and remove if exists
  const addOrRemoveInUrlQueryParam = (name, value) => {
    let param = name.toLowerCase();
    let queryArray = [];
    //COLOR
    let query = getURLQueryParamAllowNull(param);
    if (query) {
      // split by comma,
      queryArray = query.replace(/ /g, "").split(",");

      const index = queryArray.indexOf(value);
      if (index > -1) {
        //remove
        queryArray.splice(index, 1);
      } else {
        //add
        queryArray.push(value);
      }
    } else {
      //add
      queryArray.push(value);
    }
    return queryArray.join(",");
  };

  const getCategories = async () => {
    setIsFetchingCategories(true);
    const data = await getCategoryProductTypes();
    setCategories(data);
    setIsFetchingCategories(false);
  };

  const getMerchants = async () => {
    const fetchedMerchants = await fetchMerchants();
    setMerchants(fetchedMerchants);

    Object.entries(fetchedMerchants || {}).map(([key, merchant]) => {
      if (merchant.status.toUpperCase() === INACTIVE) {
        inactiveKeys.push(key);
        inactiveNames.push(merchant.name);
      }
    });
  };

  const onChangePagination = (n) => {
    //setting url page param
    setURLQueryParam(URL_NAV_PARAMS.PAGE, n);
    setIsFetching(true);
    setProducts([]);
    setCurrentPage(n);
    filterContext.storePageNum(n);
    sessionStorage.setItem("prevPage", n);
    window.scrollTo(0, 0);
  };

  const onChangeSortSelect = (e) => {
    setSortValue(e.target.value);
  };

  //make function reusable
  const setSortValue = (sortValue) => {
    setURLQueryParam(URL_NAV_PARAMS.SORT, sortValue);
    filterContext.storeSortBy(sortValue);
    setCurrentSortBy(sortValue);
    setCurrentPage(1);
  };

  const isWallpaper = (categoryId, productTypeId, wallpaperUnit) => {
    if (
      categoryId &&
      productTypeId &&
      categories[categoryId]?.productTypes[
        productTypeId
      ]?.name.toUpperCase() === "WALLPAPER"
    ) {
      return ` /${wallpaperUnit || "sqm"}`;
    }
    return "";
  };

  const renderProducts = products.map((product) => {
    if (
      !inactiveKeys.includes(product.supplierId) ||
      !inactiveNames.includes(product.supplier)
    ) {
      return (
        <div className="col-6 col-md-6 col-lg-3" key={product.key}>
          <ProductThumbnail
            src={product.imgUrl}
            isOnSale={product.isOnSale}
            salePrice={product.salePrice}
            price={product.price}
            name={product.name}
            id={product.key}
            productTypeId={product.productType}
            categoryId={product.categoryId}
            wallpaperUnit={product.wallpaperUnit}
            isWallpaper={isWallpaper}
            discount={product.saleDiscount || 0}
          />
        </div>
      );
    } else {
      return null;
    }
  });

  const renderBreadCrumbs = () => {
    const isCategoryActive = prodType ? false : true;
    const isAllProductsActive = category || page ? false : true;
    return (
      <Breadcrumb className="shop-breadcrumb">
        <Breadcrumb.Item href="/shop-home">Home</Breadcrumb.Item>
        <li
          className={`breadcrumb-item ${isAllProductsActive ? "active" : ""}`}
        >
          {isAllProductsActive ? (
            "All Products"
          ) : (
            <Link to={{ pathname: "/shop", state: { fromBreadCrumb: true } }}>
              All Products
            </Link>
          )}
        </li>
        {category && categoryProductTypes && (
          <li className={`breadcrumb-item ${isCategoryActive ? "active" : ""}`}>
            {isCategoryActive ? (
              categoryProductTypes.name
            ) : (
              <Link
                to={{
                  pathname: `/shop/${category}`,
                  state: { fromBreadCrumb: true },
                }}
              >
                {categoryProductTypes.name}
              </Link>
            )}
          </li>
        )}
        {page && (
          <li className="breadcrumb-item active">
            {page.charAt(0).toUpperCase() + page.slice(1)}
          </li>
        )}
        {categoryProductTypes && prodType && (
          <li className="breadcrumb-item active">
            {prodType && categoryProductTypes.productTypes[prodType]
              ? categoryProductTypes.productTypes[prodType].name
              : ""}
          </li>
        )}
      </Breadcrumb>
    );
  };

  breadCrumbs = renderBreadCrumbs();

  const renderPagination = () => {
    if (paginatedProducts.length > 1 && !isFetching) {
      return (
        <div className="col-12 d-flex justify-content-center">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={pageSize}
            totalItemsCount={productResponses.length - 1}
            onChange={onChangePagination}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      );
    }
  };

  const pagination = renderPagination();

  const resultPlaceholder = () => {
    let placeholder = (
      <div className="mt-64 p-5 text-center width-full">
        <h3>Loading...</h3>
      </div>
    );
    if (!isFetching && products.length === 0 && !firstLoad) {
      placeholder = (
        <div className="mt-64 p-5 text-center width-full">
          <h3>Coming Soon.</h3>
        </div>
      );
    } else if (!isFetching && products.length > 0) {
      placeholder = renderProducts;
    }

    return placeholder;
  };

  return (
    <LayoutComponent>
      <HelmetComponent
        title="Shop"
        description="We have partnered with a wide array of global and local brands to give you the convenience of online home shopping."
      />
      {isFetchingCategories && isFetching ? (
        <Loading />
      ) : (
        <>
          <ShopNav />
          <div className="container pt-5 shop-container">
            <div className="shop-header">
              <div className="shop-header-text">
                <h2>Shop for your dream home</h2>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mt-3 mb-4 search-bar-div">
                <InputGroup className="mb-3 shop-search-bar">
                  <FormControl
                    aria-label="Search products"
                    placeholder="Search products"
                    className="f14"
                    onChange={onChangeFilter}
                    name="SEARCH"
                    value={filterContext.filters["SEARCH"]}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <SearchIcon style={{ fontSize: 20 }} className="smoke" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>

                <Button
                  block
                  variant="outline-secondary"
                  onClick={handleShow}
                  className="f14 d-md-none"
                >
                  <FilterListIcon style={{ fontSize: 20 }} /> Filter
                </Button>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 d-flex">
                <div className="shop-filter-container d-none d-md-block">
                  <ShopFilterComponent
                    categories={{}}
                    onChangeFilter={onChangeFilter}
                    filters={filterContext.filters}
                    onClickReset={onClickReset}
                    merchants={merchants}
                  />
                </div>
                <div className="container-fluid">
                  <div className="d-flex justify-content-between">
                    {breadCrumbs}
                    <div>
                      <SortSelect
                        value={currentSortBy}
                        onChange={onChangeSortSelect}
                      />
                    </div>
                  </div>
                  <div className="row pl-0 pl-md-4">
                    {resultPlaceholder()}
                    {pagination}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal show={show} onHide={handleClose} className="filter-modal">
        <Modal.Body>
          <Button variant="link" onClick={handleClose} className="modal-back">
            <ArrowBackIosIcon style={{ fontSize: 20 }} /> Back
          </Button>
          <ShopFilterComponent
            categories={{}}
            onChangeFilter={onChangeFilter}
            filters={filterContext.filters}
            onClickReset={onClickReset}
            merchants={merchants}
          />
        </Modal.Body>
      </Modal>
    </LayoutComponent>
  );
}

export default ShopPage;
