import { GUSSY_NETLIFY_URL } from '../config/constants';
import placeholderImage from '../assets/img/about/gussy-footer.png';

const imgFormats = ['avif', 'webp', 'jpg'];

export const makeUID = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const toMoney = (val) => {
  return `₱${new Intl.NumberFormat('en-PH', { currency: 'PHP' }).format(val)}`;
};

export const cloudinaryImagesFromAssets = (img, size) => {
  const cloudinarySize = size ? `w_${size},` : '';
  return `https://res.cloudinary.com/symphco/image/fetch/${cloudinarySize}q_80/${GUSSY_NETLIFY_URL}${img}`;
};

export const cloudinaryImagesFromAssetsMultipleFormats = (img, size) => {
  const images = {};
  const cloudinarySize = size ? `w_${size},` : '';
  imgFormats.forEach((val) => {
    images[
      val
    ] = `https://res.cloudinary.com/symphco/image/fetch/q_80,${cloudinarySize}f_${val}/${GUSSY_NETLIFY_URL}${img}`;
  });
  return images;
};

export const removeParam = (key, sourceURL) => {
  var rtn = sourceURL.split('?')[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    params_arr = queryString.split('&');
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + '?' + params_arr.join('&');
  }
  return rtn;
};

export const cloudinaryfy = (src, width, quality) => {
  const cloudinarySize = width ? `w_${width},` : '';
  const cloudinaryQuality = quality ? `q_${quality}` : 'q_80';

  if (!src) src = `${GUSSY_NETLIFY_URL}${placeholderImage}`;
  const encodedURI = encodeURIComponent(src);

  return `https://res.cloudinary.com/symphco/image/fetch/${cloudinaryQuality},f_auto,${cloudinarySize}fl_lossy/${encodedURI}`;
};

export const cloudinaryfyMultipleFormats = (src, width) => {
  const cloudinarySize = width ? `w_${width},` : '';
  const images = {};
  if (!src) {
    src = `${GUSSY_NETLIFY_URL}${placeholderImage}`;
  }

  imgFormats.forEach((val) => {
    images[
      val
    ] = `https://res.cloudinary.com/symphco/image/fetch/q_80${cloudinarySize},f_${val},fl_lossy/${encodeURIComponent(
      src,
    )}`;
  });

  return images;
};

export const timestampToDate = (timestamp) => {
  const date = new Date(timestamp);

  return date.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const sortObjEntriesByName = (a, b) =>
  a[1].name > b[1].name ? 1 : b[1].name > a[1].name ? -1 : 0;

export const sortObjEntriesByFullname = (a, b) =>
  a[1].fullname > b[1].fullname ? 1 : b[1].fullname > a[1].fullname ? -1 : 0;

export const getURLQueryParam = (param) => {
  let rx = new RegExp('[?&]' + param + '=([^&]+).*$');
  let returnVal = window.location.search.match(rx);
  return returnVal === null ? '' : decodeURIComponent(returnVal[1].replace(/\+/g, ' '));
};

export const getURLQueryParamAllowNull = (param) => {
  let rx = new RegExp('[?&]' + param + '=([^&]+).*$');
  let returnVal = window.location.search.match(rx);
  return returnVal === null ? returnVal : decodeURIComponent(returnVal[1].replace(/\+/g, ' '));
};

export const setURLQueryParam = (param, value) => {
  if (window.history.pushState) {
    let newurl = process.env.PUBLIC_URL + window.location.pathname + window.location.search;
    let re = new RegExp('([?&])' + param + '=.*?(&|$)', 'i');
    let separator = newurl.indexOf('?') !== -1 ? '&' : '?';
    if (newurl.match(re)) {
      newurl = newurl.replace(re, '$1' + param + '=' + value + '$2');
    } else {
      newurl = newurl + separator + param + '=' + value;
    }
    window.history.pushState({ path: newurl }, '', newurl);
  }
};

export const isURLParamAvailable = () => {
  return window.location.search !== '';
};

export const getURLQueryParamNumberOnly = (param) => {
  let c = getURLQueryParam(param);
  if (c) {
    c = c.replace(/[^\d.-]/g, '');
    if (c && c !== '') {
      return parseInt(c);
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const shuffleProducts = (products) => {
  var currentIndex = products.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = products[currentIndex];
    products[currentIndex] = products[randomIndex];
    products[randomIndex] = temporaryValue;
  }

  return products;
};

export const getLastWord = (s) => s.split(' ').pop().toUpperCase();

export const isEven = (n) => (n + 1) % 2 == 0;

export const toFloatPrice = (x) => parseFloat(x.replaceAll(',',''));
