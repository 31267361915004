import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";
import { v4 as uuidv4 } from "uuid";

import StyleQuizComponent from "../../components/StyleQuiz/StyleQuizComponent";
import StyleQuiz1Component from "../../components/StyleQuiz/StyleQuiz1Component";
import StyleQuiz2Component from "../../components/StyleQuiz/StyleQuiz2Component";
import StyleQuiz3Component from "../../components/StyleQuiz/StyleQuiz3Component";
import StyleQuiz5Component from "../../components/StyleQuiz/StyleQuiz5Component";
import StyleQuiz5bComponent from "../../components/StyleQuiz/StyleQuiz5bComponent";
import StyleQuiz5dComponent from "../../components/StyleQuiz/StyleQuiz5dComponent";
import StyleQuiz5eComponent from "../../components/StyleQuiz/StyleQuiz5eComponent";
import StyleQuiz5cComponent from "../../components/StyleQuiz/StyleQuiz5cComponent";
import StyleQuiz6Component from "../../components/StyleQuiz/StyleQuiz6Component";
import StyleQuiz7Component from "../../components/StyleQuiz/StyleQuiz7Component";
import StyleQuiz8Component from "../../components/StyleQuiz/StyleQuiz8Component";

import NavbarComponent from "../../components/NavbarComponent";
import FooterSQComponent from "../../components/FooterSQComponent";
import ModalComponent from "../../components/ModalComponent";
import { CITIES, GUSSY_REDIRECT_URL } from "../../config/constants.js";

import {
  dbFirebase,
  authFirebase,
  functionsFirebase,
  GoogleProvider,
  FacebookProvider,
} from "../../components/Firebase";
import { httpsCallable } from "firebase/functions";
import { ref, get, update } from "firebase/database";
import {
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
} from "firebase/auth";

import remove from "lodash/remove";
import indexOf from "lodash/indexOf";
import "./stylequizpage.scss";
import Swal from "sweetalert2";

const roomsWorkingOn = []; //for the question which room are you working on?
const whyTryOut = []; //for the question why try out gussyapp
const bedRoomSQ = [];
const diningSQ = [];
const livingSQ = [];
const chosenPhotos = [];
const styleQuiz = {
  boho: 0,
  bold: 0,
  industrial: 0,
  minimalist: 0,
  modern: 0,
  rustic: 0,
  scandinavian: 0,
  traditional: 0,
  transitional: 0,
};
var result = [];
const pages = [
  "StyleQuizComponent",
  "StyleQuiz1Component",
  "StyleQuiz2Component",
  "StyleQuiz3Component",
  "StyleQuiz5Component",
  "StyleQuiz5bComponent",
  "StyleQuiz5dComponent",
  "StyleQuiz5eComponent",
  "StyleQuiz5cComponent",
  "StyleQuiz6Component",
  "StyleQuiz7Component",
  "StyleQuiz8Component",
];

class StyleQuizPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      modalTitle: "",
      modalMsg: "",
      back: "",
      active: pages[0],
      next: pages[1],
      progress: 0,
      roomsWorkingOn,
      whyTryOut,
      category: "",
      budget: "",
      chosenPhotos: "",
      name: "",
      region: "none",
      city: "none",
      province: "",
      email: "",
      password: "",
      confirmPassword: "",
      isDisabled: false,
      isLoggedIn: false,
      uid: null,
    };
  }

  async componentDidMount() {
    onAuthStateChanged(authFirebase, async (firebaseUser) => {
      if (firebaseUser) {
        pages.splice(10, 2);
        const userData = await get(
          ref(dbFirebase, `users/${firebaseUser.uid}`),
        ).then((snap) => snap.val());

        if (userData) {
          this.setState({
            isLoggedIn: true,
            uid: firebaseUser.uid,
            email: userData.email,
            province: userData.province,
            name: userData.name,
            region: userData.region,
            city: userData.city,
          });
        }
      }
    });
  }

  signUpThirdParty = async (method) => {
    let provider;
    switch (method) {
      case "GOOGLE":
        provider = GoogleProvider;
        break;
      case "FACEBOOK":
        provider = FacebookProvider;
        break;
      default:
        break;
    }

    const res = await signInWithPopup(authFirebase, provider).catch((error) =>
      console.error(error),
    );

    const { isNewUser } = getAdditionalUserInfo(res);
    const isUserAlreadyRegisteredPreviously = !isNewUser;

    if (isUserAlreadyRegisteredPreviously) {
      Swal.fire(
        "Another account has already been created using this email",
        "",
        "error",
      );

      await signOut(authFirebase);

      return;
    }

    this.computeStyleQuiz();

    const stylequiz = {
      rooms: JSON.stringify(this.state.roomsWorkingOn),
      tryout: JSON.stringify(this.state.whyTryOut),
      result: JSON.stringify(result),
      category: this.state.category,
      budget: this.state.budget,
      chosenPhotos: JSON.stringify(this.state.chosenPhotos),
    };

    await update(ref(dbFirebase, `users/${res.user.uid}`), {
      name: res.user.displayName,
      email: res.user.email,
      region: this.state.region,
      city: this.state.city,
      province: this.state.province,
      roles: {
        client: true,
      },
      status: "Active",
      stylequiz,
      hasStyleQuiz: true,
    });

    const token = await httpsCallable(
      functionsFirebase,
      "getSignInToken",
    )({
      uid: res.user.uid,
    });

    window.location.href = `${GUSSY_REDIRECT_URL}/client-timeline.html?token=${token.data}`;
  };

  logoutBothSites() {
    signOut(authFirebase).then(() => {
      window.location.href = `${GUSSY_REDIRECT_URL}?from_gussy_design=true`;
    });
  }

  async loginRedirect() {
    const id = uuidv4();
    localStorage.setItem("uniqueId", id);
    window.location.href = `${GUSSY_REDIRECT_URL}/index.html?uuid=${id}&redirect_url=${window.location.href}`;
  }

  //function for next buttons
  handleNextPageClick() {
    var activeIndex = indexOf(pages, this.state.active);
    var nextIndex = indexOf(pages, this.state.next);
    var progress = (100 / (pages.length - 1)) * nextIndex;

    if (this.state.isLoggedIn && activeIndex === 9) {
      this.saveUserData();
      this.setState({
        ...this.state,
        progress,
      });
    } else {
      this.setState({
        ...this.state,
        progress,
        back: pages[activeIndex],
        active: pages[nextIndex],
        next: pages[nextIndex + 1],
      });
    }
  }

  //function for back buttons
  handleBackPageClick() {
    var backIndex = indexOf(pages, this.state.back);
    var activeIndex = indexOf(pages, this.state.active);
    var progress = (100 / (pages.length - 1)) * backIndex;
    this.setState({
      ...this.state,
      progress,
      back: pages[backIndex - 1],
      active: pages[backIndex],
      next: pages[activeIndex],
      isDisabled: false,
    });
  }

  //handles the picked choices for questions that can have multiple answers; img is for chosenImages
  handleArrayChoices(array, choice, imgName) {
    if (array.includes(choice)) {
      remove(array, function (n) {
        return n === choice;
      });
    } else {
      array.push(choice);
    }
    if (imgName) {
      if (chosenPhotos.includes(imgName)) {
        remove(chosenPhotos, function (n) {
          return n === imgName;
        });
      } else {
        chosenPhotos.push(imgName);
      }
      this.setState({
        chosenPhotos,
      });
    }
    this.setState({
      array,
    });
  }

  // function returns the class 'choices-active' if choice is picked (for arrays)
  handleArrayClass(array, param) {
    if (array.includes(param)) {
      return "choices-active";
    }
  }

  handleArrayImgClass(array, param) {
    if (chosenPhotos.includes(param)) {
      return "img-choices-active";
    }
  }

  //handles the picked choices for questions that can have multiple answers
  handleSingleChoice(choiceState, choice) {
    if (choiceState === "category") {
      this.setState({
        category: choice,
      });
    }
  }

  // function returns the class 'choices-active' if choice is picked (for single choices)
  handleSingleClass(choiceState, choice) {
    if (choiceState === "category") {
      if (this.state.category === choice) {
        return "choices-active";
      }
    }
  }

  handleChangeConfirmPassword = (e) => {
    this.setState({ confirmPassword: e.target.value.trim() });
  };

  changeDisabledState = (disabled) => {
    this.setState({ isDisabled: disabled });
  };

  handleChangeCity = (e) => {
    const province = CITIES[e.target.value].provinceID;
    this.setState({ city: e.target.value, province });
  };

  handleChangeRegion = (e) => {
    this.setState({ region: e.target.value, city: "none" });
  };

  handleChangeBudgetValue = (e) => {
    this.setState({ budget: e.target.value });
  };

  handleChangeNameValue = (e) => {
    this.setState({ name: e.target.value });
  };

  handleChangeEmailValue = (e) => {
    this.setState({ email: e.target.value.trim() });
  };

  handleChangePasswordValue = (e) => {
    this.setState({ password: e.target.value.trim() });
  };

  toggleModal(header, msg) {
    this.setState({
      ...this.state,
      modalShow: true,
      modalHeader: header,
      modalMsg: msg,
    });
  }

  hideModal() {
    this.setState({ modalShow: false });
  }

  computeStyleQuiz = (e) => {
    for (var i = 0; i < Object.entries(styleQuiz).length; i++) {
      if (bedRoomSQ.includes(Object.keys(styleQuiz)[i])) {
        styleQuiz[Object.keys(styleQuiz)[i]] += 1;
      }
      if (livingSQ.includes(Object.keys(styleQuiz)[i])) {
        styleQuiz[Object.keys(styleQuiz)[i]] += 1;
      }
      if (diningSQ.includes(Object.keys(styleQuiz)[i])) {
        styleQuiz[Object.keys(styleQuiz)[i]] += 1;
      }
    }
    this.getSQResult();
  };

  getSQResult = (e) => {
    let arr = Object.values(styleQuiz);
    let max = Math.max(...arr);

    result = Object.keys(styleQuiz).filter(function (key) {
      return styleQuiz[key] === max;
    });
  };

  saveUserData = async () => {
    const self = this;
    this.computeStyleQuiz();

    const stylequiz = {
      rooms: JSON.stringify(this.state.roomsWorkingOn),
      tryout: JSON.stringify(this.state.whyTryOut),
      result: JSON.stringify(result),
      category: this.state.category,
      budget: this.state.budget,
      chosenPhotos: JSON.stringify(this.state.chosenPhotos),
    };

    if (authFirebase.currentUser) {
      this.toggleModal("", "Saving your style quiz result...");
      const token = await httpsCallable(
        functionsFirebase,
        "getSignInToken",
      )({
        uid: this.state.uid,
      });
      update(ref(dbFirebase, `users/${this.state.uid}`), {
        stylequiz,
        hasStyleQuiz: true,
      }).then(() => {
        window.location.href = `${GUSSY_REDIRECT_URL}/client-projects.html?token=${token.data}`;
      });
    } else {
      this.toggleModal("", "Saving your account and style quiz result...");

      createUserWithEmailAndPassword(
        authFirebase,
        this.state.email,
        this.state.password,
      )
        .then(async (snap) => {
          await update(ref(dbFirebase, `users/${snap.user.uid}`), {
            name: this.state.name,
            email: this.state.email,
            region: this.state.region,
            city: this.state.city,
            province: this.state.province,
            roles: {
              client: true,
            },
            status: "Active",
            stylequiz,
            hasStyleQuiz: true,
          }).catch((error) => {
            console.error(error);
          });

          const token = await httpsCallable(
            functionsFirebase,
            "getSignInToken",
          )({
            uid: snap.user.uid,
          }).catch((error) => {
            console.error(error);
          });

          window.location.href = `${GUSSY_REDIRECT_URL}/client-projects.html?token=${token.data}`;
        })
        .catch(function (error) {
          self.hideModal();
          self.toggleModal("Oops!", error.message);
          console.error(error);
        });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (
      !this.state.email ||
      !this.state.password ||
      !this.state.confirmPassword
    ) {
      return this.toggleModal(
        "Oops!",
        "You have to enter your complete information.",
      );
    }
    if (this.state.password !== this.state.confirmPassword) {
      return this.toggleModal("Oops!", "Passwords did not match.");
    }

    this.saveUserData();
  };

  render() {
    return (
      <div>
        <CookieConsent
          location="bottom"
          buttonText="I Agree"
          style={{ background: "#33332B" }}
          buttonStyle={{
            background: "#d1755b",
            padding: "5px 20px",
            color: "#ffffff",
            fontSize: "13px",
          }}
        >
          This website uses cookies to enhance the user experience.{" "}
          <a href="/terms-of-use#cookies" className="cookies-link">
            Learn More.
          </a>
        </CookieConsent>
        <ModalComponent
          show={this.state.modalShow}
          onHide={() => this.hideModal()}
          header={this.state.modalHeader}
          message={this.state.modalMsg}
        />

        {this.state.active === "StyleQuiz8Component" && (
          <StyleQuiz8Component email={this.state.email} />
        )}

        {!["StyleQuizComponent", "StyleQuiz8Component"].includes(
          this.state.active,
        ) && (
          <NavbarComponent
            width={this.state.progress + "%"}
            back={() => this.handleBackPageClick()}
          />
        )}

        {this.state.active === "StyleQuiz7Component" && (
          <StyleQuiz7Component
            emailValue={this.state.email}
            handleEmailChange={this.handleChangeEmailValue}
            passwordValue={this.state.password}
            handlePasswordChange={this.handleChangePasswordValue}
            confirmPassword={this.state.confirmPassword}
            onChangeConfirmPassword={this.handleChangeConfirmPassword}
            handleSubmit={this.handleSubmit}
            signUpThirdParty={this.signUpThirdParty}
          />
        )}

        {this.state.active === "StyleQuiz6Component" && (
          <StyleQuiz6Component
            name={this.state.name}
            city={this.state.city}
            region={this.state.region}
            onChangeName={this.handleChangeNameValue}
            onChangeCity={this.handleChangeCity}
            onChangeRegion={this.handleChangeRegion}
            changeDisabledState={this.changeDisabledState}
          />
        )}

        {this.state.active === "StyleQuiz5cComponent" && (
          <StyleQuiz5cComponent
            choice1Click={() =>
              this.handleArrayChoices(livingSQ, "bold", "bold-living-5c")
            }
            choice1Class={this.handleArrayImgClass(livingSQ, "bold-living-5c")}
            choice2Click={() =>
              this.handleArrayChoices(
                livingSQ,
                "minimalist",
                "minimalist-living-5c",
              )
            }
            choice2Class={this.handleArrayImgClass(
              livingSQ,
              "minimalist-living-5c",
            )}
            choice3Click={() =>
              this.handleArrayChoices(livingSQ, "rustic", "rustic-living-1-5c")
            }
            choice3Class={this.handleArrayImgClass(
              livingSQ,
              "rustic-living-1-5c",
            )}
            choice4Click={() =>
              this.handleArrayChoices(livingSQ, "modern", "modern-living-1-5c")
            }
            choice4Class={this.handleArrayImgClass(
              livingSQ,
              "modern-living-1-5c",
            )}
            choice5Click={() =>
              this.handleArrayChoices(livingSQ, "modern", "modern-living-2-5c")
            }
            choice5Class={this.handleArrayImgClass(
              livingSQ,
              "modern-living-2-5c",
            )}
            choice6Click={() =>
              this.handleArrayChoices(livingSQ, "rustic", "rustic-living-2-5c")
            }
            choice6Class={this.handleArrayImgClass(
              livingSQ,
              "rustic-living-2-5c",
            )}
            choice7Click={() =>
              this.handleArrayChoices(bedRoomSQ, "boho", "boho-bedroom-5c")
            }
            choice7Class={this.handleArrayImgClass(
              bedRoomSQ,
              "boho-bedroom-5c",
            )}
            choice8Click={() =>
              this.handleArrayChoices(
                livingSQ,
                "transitional",
                "transitional-living-5c",
              )
            }
            choice8Class={this.handleArrayImgClass(
              livingSQ,
              "transitional-living-5c",
            )}
          />
        )}
        {this.state.active === "StyleQuiz5eComponent" && (
          <StyleQuiz5eComponent
            choice1Click={() =>
              this.handleArrayChoices(
                diningSQ,
                "transitional",
                "transitional-dining-5e",
              )
            }
            choice1Class={this.handleArrayImgClass(
              diningSQ,
              "transitional-dining-5e",
            )}
            choice2Click={() =>
              this.handleArrayChoices(
                bedRoomSQ,
                "minimalist",
                "minimalist-bedroom-5e",
              )
            }
            choice2Class={this.handleArrayImgClass(
              bedRoomSQ,
              "minimalist-bedroom-5e",
            )}
            choice3Click={() =>
              this.handleArrayChoices(bedRoomSQ, "modern", "modern-bedroom-5e")
            }
            choice3Class={this.handleArrayImgClass(
              bedRoomSQ,
              "modern-bedroom-5e",
            )}
            choice4Click={() =>
              this.handleArrayChoices(diningSQ, "rustic", "rustic-dining-5e")
            }
            choice4Class={this.handleArrayImgClass(
              diningSQ,
              "rustic-dining-5e",
            )}
            choice5Click={() =>
              this.handleArrayChoices(bedRoomSQ, "boho", "boho-bedroom-5e")
            }
            choice5Class={this.handleArrayImgClass(
              bedRoomSQ,
              "boho-bedroom-5e",
            )}
            choice6Click={() =>
              this.handleArrayChoices(livingSQ, "bold", "bold-living-5e")
            }
            choice6Class={this.handleArrayImgClass(livingSQ, "bold-living-5e")}
            choice7Click={() =>
              this.handleArrayChoices(livingSQ, "boho", "boho-living-5e")
            }
            choice7Class={this.handleArrayImgClass(livingSQ, "boho-living-5e")}
            choice8Click={() =>
              this.handleArrayChoices(
                livingSQ,
                "transitional",
                "transitional-living-5e",
              )
            }
            choice8Class={this.handleArrayImgClass(
              livingSQ,
              "transitional-living-5e",
            )}
          />
        )}
        {this.state.active === "StyleQuiz5dComponent" && (
          <StyleQuiz5dComponent
            choice1Click={() =>
              this.handleArrayChoices(livingSQ, "modern", "modern-living-5d")
            }
            choice1Class={this.handleArrayImgClass(
              livingSQ,
              "modern-living-5d",
            )}
            choice2Click={() =>
              this.handleArrayChoices(livingSQ, "rustic", "rustic-living-5d")
            }
            choice2Class={this.handleArrayImgClass(
              livingSQ,
              "rustic-living-5d",
            )}
            choice3Click={() =>
              this.handleArrayChoices(
                bedRoomSQ,
                "transitional",
                "transitional-bedroom-5d",
              )
            }
            choice3Class={this.handleArrayImgClass(
              bedRoomSQ,
              "transitional-bedroom-5d",
            )}
            choice4Click={() =>
              this.handleArrayChoices(bedRoomSQ, "boho", "boho-bedroom-5d")
            }
            choice4Class={this.handleArrayImgClass(
              bedRoomSQ,
              "boho-bedroom-5d",
            )}
            choice5Click={() =>
              this.handleArrayChoices(
                bedRoomSQ,
                "minimalist",
                "minimalist-bedroom-5d",
              )
            }
            choice5Class={this.handleArrayImgClass(
              bedRoomSQ,
              "minimalist-bedroom-5d",
            )}
            choice6Click={() =>
              this.handleArrayChoices(livingSQ, "bold", "bold-living-5d")
            }
            choice6Class={this.handleArrayImgClass(livingSQ, "bold-living-5d")}
            choice7Click={() =>
              this.handleArrayChoices(bedRoomSQ, "rustic", "rustic-bedroom-5d")
            }
            choice7Class={this.handleArrayImgClass(
              bedRoomSQ,
              "rustic-bedroom-5d",
            )}
            choice8Click={() =>
              this.handleArrayChoices(
                livingSQ,
                "minimalist",
                "minimalist-living-5d",
              )
            }
            choice8Class={this.handleArrayImgClass(
              livingSQ,
              "minimalist-living-5d",
            )}
          />
        )}
        {this.state.active === "StyleQuiz5bComponent" && (
          <StyleQuiz5bComponent
            choice1Click={() =>
              this.handleArrayChoices(livingSQ, "boho", "boho-living-5b")
            }
            choice1Class={this.handleArrayImgClass(livingSQ, "boho-living-5b")}
            choice2Click={() =>
              this.handleArrayChoices(livingSQ, "bold", "bold-living-5b")
            }
            choice2Class={this.handleArrayImgClass(livingSQ, "bold-living-5b")}
            choice3Click={() =>
              this.handleArrayChoices(livingSQ, "modern", "modern-living-5b")
            }
            choice3Class={this.handleArrayImgClass(
              livingSQ,
              "modern-living-5b",
            )}
            choice4Click={() =>
              this.handleArrayChoices(
                livingSQ,
                "minimalist",
                "minimalist-living-5b",
              )
            }
            choice4Class={this.handleArrayImgClass(
              livingSQ,
              "minimalist-living-5b",
            )}
            choice5Click={() =>
              this.handleArrayChoices(diningSQ, "rustic", "rustic-dining-5b")
            }
            choice5Class={this.handleArrayImgClass(
              diningSQ,
              "rustic-dining-5b",
            )}
            choice6Click={() =>
              this.handleArrayChoices(
                diningSQ,
                "transitional",
                "transitional-kitchen-5b",
              )
            }
            choice6Class={this.handleArrayImgClass(
              diningSQ,
              "transitional-kitchen-5b",
            )}
            choice7Click={() =>
              this.handleArrayChoices(
                bedRoomSQ,
                "minimalist",
                "minimalist-bedroom-5b",
              )
            }
            choice7Class={this.handleArrayImgClass(
              bedRoomSQ,
              "minimalist-bedroom-5b",
            )}
            choice8Click={() =>
              this.handleArrayChoices(
                diningSQ,
                "transitional",
                "transitional-dining-5b",
              )
            }
            choice8Class={this.handleArrayImgClass(
              diningSQ,
              "transitional-dining-5b",
            )}
          />
        )}
        {this.state.active === "StyleQuiz5Component" && (
          <StyleQuiz5Component
            choice1Click={() =>
              this.handleArrayChoices(livingSQ, "boho", "boho-living-5")
            }
            choice1Class={this.handleArrayImgClass(livingSQ, "boho-living-5")}
            choice2Click={() =>
              this.handleArrayChoices(bedRoomSQ, "bold", "bold-bedroom-5")
            }
            choice2Class={this.handleArrayImgClass(bedRoomSQ, "bold-bedroom-5")}
            choice3Click={() =>
              this.handleArrayChoices(bedRoomSQ, "rustic", "rustic-bedroom-5")
            }
            choice3Class={this.handleArrayImgClass(
              bedRoomSQ,
              "rustic-bedroom-5",
            )}
            choice4Click={() =>
              this.handleArrayChoices(
                livingSQ,
                "transitional",
                "transitional-living-5",
              )
            }
            choice4Class={this.handleArrayImgClass(
              livingSQ,
              "transitional-living-5",
            )}
            choice5Click={() =>
              this.handleArrayChoices(bedRoomSQ, "modern", "modern-bedroom-5")
            }
            choice5Class={this.handleArrayImgClass(
              bedRoomSQ,
              "modern-bedroom-5",
            )}
            choice6Click={() =>
              this.handleArrayChoices(livingSQ, "bold", "bold-living-5")
            }
            choice6Class={this.handleArrayImgClass(livingSQ, "bold-living-5")}
            choice7Click={() =>
              this.handleArrayChoices(diningSQ, "modern", "modern-dining-5")
            }
            choice7Class={this.handleArrayImgClass(diningSQ, "modern-dining-5")}
            choice8Click={() =>
              this.handleArrayChoices(bedRoomSQ, "boho", "boho-bedroom-5")
            }
            choice8Class={this.handleArrayImgClass(bedRoomSQ, "boho-bedroom-5")}
          />
        )}

        {this.state.active === "StyleQuiz3Component" && (
          <StyleQuiz3Component
            choice1Click={() => this.handleSingleChoice("category", "1")}
            choice1Class={this.handleSingleClass("category", "1")}
            choice2Click={() => this.handleSingleChoice("category", "2")}
            choice2Class={this.handleSingleClass("category", "2")}
            choice3Click={() => this.handleSingleChoice("category", "3")}
            choice3Class={this.handleSingleClass("category", "3")}
          />
        )}

        {this.state.active === "StyleQuiz2Component" && (
          <StyleQuiz2Component
            choice1Click={() => this.handleArrayChoices(whyTryOut, "1")}
            choice1Class={this.handleArrayClass(whyTryOut, "1")}
            choice2Click={() => this.handleArrayChoices(whyTryOut, "2")}
            choice2Class={this.handleArrayClass(whyTryOut, "2")}
            choice3Click={() => this.handleArrayChoices(whyTryOut, "3")}
            choice3Class={this.handleArrayClass(whyTryOut, "3")}
            choice4Click={() => this.handleArrayChoices(whyTryOut, "4")}
            choice4Class={this.handleArrayClass(whyTryOut, "4")}
            choice5Click={() => this.handleArrayChoices(whyTryOut, "5")}
            choice5Class={this.handleArrayClass(whyTryOut, "5")}
            choice6Click={() => this.handleArrayChoices(whyTryOut, "6")}
            choice6Class={this.handleArrayClass(whyTryOut, "6")}
            choice7Click={() => this.handleArrayChoices(whyTryOut, "7")}
            choice7Class={this.handleArrayClass(whyTryOut, "7")}
            choice8Click={() => this.handleArrayChoices(whyTryOut, "8")}
            choice8Class={this.handleArrayClass(whyTryOut, "8")}
          />
        )}

        {this.state.active === "StyleQuiz1Component" && (
          <StyleQuiz1Component
            livingRoomClick={() =>
              this.handleArrayChoices(roomsWorkingOn, "Living")
            }
            livingClass={this.handleArrayClass(roomsWorkingOn, "Living")}
            bedroomClick={() =>
              this.handleArrayChoices(roomsWorkingOn, "Bedroom")
            }
            bedroomClass={this.handleArrayClass(roomsWorkingOn, "Bedroom")}
            diningClick={() =>
              this.handleArrayChoices(roomsWorkingOn, "Dining")
            }
            diningClass={this.handleArrayClass(roomsWorkingOn, "Dining")}
            nurseryClick={() =>
              this.handleArrayChoices(roomsWorkingOn, "Nursery")
            }
            nurseryClass={this.handleArrayClass(roomsWorkingOn, "Nursery")}
            entertainmentClick={() =>
              this.handleArrayChoices(roomsWorkingOn, "Entertainment")
            }
            entertainmentClass={this.handleArrayClass(
              roomsWorkingOn,
              "Entertainment",
            )}
            homeOfficeClick={() =>
              this.handleArrayChoices(roomsWorkingOn, "HomeOffice")
            }
            homeOfficeClass={this.handleArrayClass(
              roomsWorkingOn,
              "HomeOffice",
            )}
            studioClick={() =>
              this.handleArrayChoices(roomsWorkingOn, "Studio")
            }
            studioClass={this.handleArrayClass(roomsWorkingOn, "Studio")}
            notSureClick={() =>
              this.handleArrayChoices(roomsWorkingOn, "NotSure")
            }
            notSureClass={this.handleArrayClass(roomsWorkingOn, "NotSure")}
          />
        )}

        {this.state.active === "StyleQuizComponent" && (
          <StyleQuizComponent
            handleNextPageClick={() => this.handleNextPageClick()}
            isLoggedIn={this.state.isLoggedIn}
            name={this.state.name}
            logoutBothSites={this.logoutBothSites}
            loginRedirect={this.loginRedirect}
          />
        )}

        {![
          "StyleQuizComponent",
          "StyleQuiz8Component",
          "StyleQuiz7Component",
        ].includes(this.state.active) && (
          <FooterSQComponent
            next={() => this.handleNextPageClick()}
            disabled={this.state.isDisabled}
          />
        )}
      </div>
    );
  }
}

export default StyleQuizPage;
