/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

import { EMPIRE_URL } from "../../config/constants";
import {
    fetchAboutPage,
    fetchDataModelItems,
} from "../../queries/contentfulCMS";

function AboutPageComponents() {
    const [aboutPage, setAboutPage] = useState([]);
    const [dataModelItems, setDataModelItems] = useState([]);

    const getAboutPage = async () => {
        const data = await fetchAboutPage();
        setAboutPage(data);
    };

    const getdataModelItems = async () => {
        const data = await fetchDataModelItems();
        setDataModelItems(data);
    };

    useEffect(() => {
        getAboutPage();
        getdataModelItems();
    }, []);

    return (
        <>
            {aboutPage.map((val, index) => (
                <div key={index}>
                    <div className="mt-64 p-5 container">
                        <div className="row align-items-start">
                            <div className="col-md-6 stick-md-top">
                                <img
                                    alt="About"
                                    className="w-100"
                                    src={val.featuredDesignPhoto.file.url}
                                />
                            </div>
                            <div className="col-md-6">
                                <h2 className="page-title mt-4 mt-md-0">
                                    {val.introText}
                                </h2>
                                <div className="body-1-reg">
                                    <ReactMarkdown className="rich-text">
                                        {val.subText}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 container">
                        <div className="row">
                            <div className="col-md-12 vulcanite comparison-table">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th width="55%;">{/* empty */}</th>
                                            <th className="text-center petra">
                                                {val.dataTable}
                                            </th>
                                            <th className="text-center">
                                                <img
                                                    alt="logo"
                                                    className="pricing-gussy-logo"
                                                    src={
                                                        val.dataTableImage.file
                                                            .url
                                                    }
                                                    width="40%"
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataModelItems.map((value, item) => (
                                            <tr key={item}>
                                                <td>{value.tableTitle}</td>
                                                <td className="text-center first-check">
                                                    <ReactMarkdown>
                                                        {
                                                            value.traditionalDesignColumn
                                                        }
                                                    </ReactMarkdown>
                                                </td>
                                                <td className="text-center second-check">
                                                    <ReactMarkdown>
                                                        {value.gussyDesignColumn}
                                                    </ReactMarkdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="nextLevelSection mt-50">
                        <div className="container">
                            <div className="row row align-items-center">
                                <div className="col-md-6">
                                    <h2 className="smmt-40">
                                        {val.heroBanner}
                                    </h2>
                                    <Button
                                        className="btn-black btntext-13-sb white-font inq-now-btn mtb-30"
                                        href={EMPIRE_URL}
                                    >
                                        {val.heroButton}
                                    </Button>
                                </div>
                                <div className="col-md-6">
                                    <div className="half-full">
                                        <div
                                            className="img-right"
                                            style={{
                                                backgroundImage: `url(${val.featuredDesignImage.file.url})`,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

export default AboutPageComponents;
