import React, { useContext, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import SearchIcon from "@material-ui/icons/Search";

import { ShopFilterContext } from "../../../context";

const SearchBar = () => {
  const history = useHistory();
  const filterContext = useContext(ShopFilterContext);
  const [searchText, setSearchText] = useState('')

  const onSearchChange = (event) => {
    const { value } = event.target;
    setSearchText(value);
    filterContext.setFilters({
      ...filterContext.filters,
      "SEARCH": value,
    });
  }

  const onSearchClick = () => {
    history.push("/shop");
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      history.push({
        pathname: '/shop',
        state: { search: searchText }
      });
    }
  }

  return (  
    <InputGroup className="mb-3 shop-search-bar">
      <FormControl
        aria-label="Search products"
        placeholder="Search products"
        className="f14"
        name="SEARCH"
        onChange={onSearchChange}
        onKeyDown={handleKeyDown}
      />
      <InputGroup.Append>
        <InputGroup.Text
          onClick={onSearchClick}>
          <SearchIcon style={{ fontSize: 20 }} className="smoke" />
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
}

export default SearchBar
