import React from "react";
import { Button } from "react-bootstrap";
import Fade from "react-reveal/Fade";

import NavbarComponent from "../../NavbarComponent";

function StyleQuiz1Component(props) {
  const action = () => {
    if (props.isLoggedIn) {
      return (
        <p className="body-2-reg smoke-font">
          Not <b>{props.name}</b>?<br />
          <a
            className="body-2-sb petra-font link-hover-copper"
            href="#"
            onClick={props.logoutBothSites}
          >
            Log out
          </a>
        </p>
      );
    } else {
      return (
        <p className="body-2-reg smoke-font">
          Do you already have an account?
          <br />
          <a
            className="body-2-sb petra-font link-hover-copper"
            href="#"
            onClick={() => props.loginRedirect()}
          >
            Log in
          </a>
        </p>
      );
    }
  };
  return (
    <div>
      <NavbarComponent display="none" />
      <div className="mt-64 p-5">
        <Fade duration={1000} cascade>
          <div className="row text-center justify-content-center mb-50">
            <div className="col-md-12 mb-30">
              <h2>Hello. Welcome to Gussy.</h2>
            </div>
            <div className="col-md-5 col-sm-12 d-flex flex-column align-items-center">
              <p className="mb-40">
                At Gussy, we believe your home should reflect you, no matter
                what your budget or style.
                <br />
                <br />
                Take our fun interior design style quiz to reveal your unique
                style and we’ll match you with the perfect Gussy designer.
              </p>
              <Button
                onClick={props.handleNextPageClick}
                className="btn-dark p-16-50 btntext-18-sb width-fit-content"
              >
                Start the style quiz
              </Button>
            </div>
          </div>
          <div className="row text-center justify-content-center">
            <div className="col-md-6">{action()}</div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default StyleQuiz1Component;
