import React from "react";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";

import imgcheck from "../../../assets/img/StyleQuiz/img-check.svg";
import image1 from "../../../assets/img/StyleQuiz/BOHO/Boho54.jpg";
import image2 from "../../../assets/img/StyleQuiz/BOLD/Bold11.jpg";
import image3 from "../../../assets/img/StyleQuiz/RH MODERN/Modern59.jpg";
import image4 from "../../../assets/img/StyleQuiz/MINIMALIST/Minimalist55.jpg";
import image5 from "../../../assets/img/StyleQuiz/RUSTIC/Rustic19.jpg";
import image6 from "../../../assets/img/StyleQuiz/TRANSITIONAL/Transitional22.jpg";
import image7 from "../../../assets/img/StyleQuiz/MINIMALIST/Minimalist54.jpg";
import image8 from "../../../assets/img/StyleQuiz/TRANSITIONAL/transitional-dining.jpeg";
import { cloudinaryImagesFromAssets } from "../../../utils/helpers";
import "../stylequizcomponent.scss";
import "../../../App.scss";

function StyleQuiz5bComponent(props) {
  return (
    <div>
      <div className="mt-75 container-wide">
        <div className="row mb-50 justify-content-center">
          <div className="col-md-8 text-center">
            <Fade top>
              <h3 className="mb-20">
                Here are more photos to help us figure out your design style!
              </h3>
              <p>Pick as many as you like.</p>
            </Fade>
          </div>
        </div>
        <div className="row justify-content-center">
          <Flip delay={300} left cascade>
            <div className="col-md-12 text-center no-focus-outline">
              <div className="d-inline-block p-1">
                <div
                  onClick={props.choice1Click}
                  className={"img-choices " + props.choice1Class}
                >
                  <img
                    src={imgcheck}
                    alt="check"
                    className="choice-img-check display-none"
                  />
                  <img
                    src={cloudinaryImagesFromAssets(image1)}
                    alt=""
                    className="choice-img"
                  />
                </div>
              </div>
              <div className="d-inline-block p-1">
                <div
                  onClick={props.choice2Click}
                  className={"img-choices " + props.choice2Class}
                >
                  <img
                    src={imgcheck}
                    alt="check"
                    className="choice-img-check display-none"
                  />
                  <img
                    src={cloudinaryImagesFromAssets(image2)}
                    alt=""
                    className="choice-img"
                  />
                </div>
              </div>
              <div className="d-inline-block p-1">
                <div
                  onClick={props.choice3Click}
                  className={"img-choices " + props.choice3Class}
                >
                  <img
                    src={imgcheck}
                    alt="check"
                    className="choice-img-check display-none"
                  />
                  <img
                    src={cloudinaryImagesFromAssets(image3)}
                    alt=""
                    className="choice-img"
                  />
                </div>
              </div>
              <div className="d-inline-block p-1">
                <div
                  onClick={props.choice4Click}
                  className={"img-choices " + props.choice4Class}
                >
                  <img
                    src={imgcheck}
                    alt="check"
                    className="choice-img-check display-none"
                  />
                  <img
                    src={cloudinaryImagesFromAssets(image4)}
                    alt=""
                    className="choice-img"
                  />
                </div>
              </div>
              <div className="d-inline-block p-1">
                <div
                  onClick={props.choice5Click}
                  className={"img-choices " + props.choice5Class}
                >
                  <img
                    src={imgcheck}
                    alt="check"
                    className="choice-img-check display-none"
                  />
                  <img
                    src={cloudinaryImagesFromAssets(image5)}
                    alt=""
                    className="choice-img"
                  />
                </div>
              </div>
              <div className="d-inline-block p-1">
                <div
                  onClick={props.choice6Click}
                  className={"img-choices " + props.choice6Class}
                >
                  <img
                    src={imgcheck}
                    alt="check"
                    className="choice-img-check display-none"
                  />
                  <img
                    src={cloudinaryImagesFromAssets(image6)}
                    alt=""
                    className="choice-img"
                  />
                </div>
              </div>
              <div className="d-inline-block p-1">
                <div
                  onClick={props.choice7Click}
                  className={"img-choices " + props.choice7Class}
                >
                  <img
                    src={imgcheck}
                    alt="check"
                    className="choice-img-check display-none"
                  />
                  <img
                    src={cloudinaryImagesFromAssets(image7)}
                    alt=""
                    className="choice-img"
                  />
                </div>
              </div>
              <div className="d-inline-block p-1">
                <div
                  onClick={props.choice8Click}
                  className={"img-choices " + props.choice8Class}
                >
                  <img
                    src={imgcheck}
                    alt="check"
                    className="choice-img-check display-none"
                  />
                  <img
                    src={cloudinaryImagesFromAssets(image8)}
                    alt=""
                    className="choice-img"
                  />
                </div>
              </div>
            </div>
          </Flip>
        </div>
      </div>
    </div>
  );
}

export default StyleQuiz5bComponent;
