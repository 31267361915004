import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

import backSVG from '../../assets/img/StyleQuiz/back.svg';
import logo from '../../assets/img/gussy-logo.png';

import './navbarcomponent.scss';

function NavbarComponent(props) {
  // eslint-disable-next-line react/prop-types
  const { display, back, width } = props;
  return (
    <div>
      <Navbar className="nav-stylequiz" expand="lg">
        <Nav.Link className="body-1-m smoke-font" style={{ display }} onClick={back}>
          <img alt="" src={backSVG} />
          &nbsp;&nbsp;Back
        </Nav.Link>
        <Navbar.Brand className="brand mx-auto" href="/home">
          <img alt="logo" src={logo} width="80px" />
        </Navbar.Brand>
      </Navbar>
      <div className="loading" style={{ display }}>
        <div className="loading-bar" style={{ width }} />
      </div>
    </div>
  );
}

export default NavbarComponent;
