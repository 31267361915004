import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import queryString from "query-string";

import NavbarLPComponent from "../../../components/LandingPage/NavbarLPComponent";
import FooterComponent from "../../../components/FooterComponent";
import { clearCart, fetchPayment } from "../../../queries/shop";
import { CartCountContext } from "../../../context";

import "./../successpage.scss";

function PendingCheckoutPage(props) {
  const { location } = props;
  const params = queryString.parse(location.search);
  const cartCountContext = useContext(CartCountContext);
  useEffect(() => {
    refreshCart();
    window.scrollTo(0, 0);
  }, []);

  const refreshCart = async () => {
    const payment = await fetchPayment(params.trans_id);
    if (payment) {
      clearCart(params.uid);
      cartCountContext.setCount(0);
    }
  };

  const show = () => {
    if (params.type === "PROJECT_PURCHASE_SHOP") {
      return newProjectPurchased;
    } else {
      return shopPurchased;
    }
  };

  const newProjectPurchased = (
    <>
      <p>We've sent you an email to verify your email address</p>
    </>
  );

  const shopPurchased = (
    <>
      <Col lg={6}>
        <p className="text-left">
          Transaction # <strong>{params.trans_id}</strong> <br />
          Reference # <strong>{params.ref_no}</strong> <br />
          Provider <strong>{params.provider}</strong>
        </p>
      </Col>
      <Col lg={6}>
        <p>
          We’ll email you an order confirmation with details of your purchase
          once your payment is verified and confirmed.
        </p>
      </Col>
    </>
  );

  return (
    <div>
      <NavbarLPComponent />
      <div className="mt-100 p-5 text-center success-page">
        <div className="container">
          <Row className="flex-column align-items-center">
            <Col lg={6}>
              <h3 className="mb-3">Payment pending</h3>
            </Col>
            {show()}
            <Link
              to="/shop"
              variant="primary"
              className="btn-petra btntext-13-sb p-13-55 mt-2 btn btn-primary"
            >
              Continue Shopping
            </Link>
          </Row>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default PendingCheckoutPage;
