import React, { useContext } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { isBrowser } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import logo from "../../../assets/img/gussy-logo2.png";
import { GUSSY_REDIRECT_URL } from "../../../config/constants";
import { AuthContext, CartCountContext } from "../../../context";
import SearchBar from "../../ShopComponents/SearchBar";
import "./navbarlpcomponent.scss";

import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

const HAS_SEARCH_BAR = ["/shop-home", "/shop-look", "/shop/"];

function NavbarLPComponent() {
    const history = useHistory();
    const cartCountContext = useContext(CartCountContext);
    const authContext = useContext(AuthContext);

    const logout = async () => {
        await authContext.logout();
        history.push("/home");
    };

    const handleLoginClick = async () => {
        const isCheckout = ["checkout", "style-quiz"].some((el) =>
            window.location.pathname.includes(el),
        );

        const id = uuidv4();

        localStorage.setItem("uniqueId", id);

        const redirectUrl = isCheckout
            ? `&redirect_url=${window.location.href}`
            : "";

        window.location.href = `${GUSSY_REDIRECT_URL}/index.html?uuid=${id}&from_gussy_design=true${redirectUrl}`;
    };

    const isSearchBarVisible =
        isBrowser &&
        HAS_SEARCH_BAR.some((path) => window.location.pathname.includes(path));
    const navSearchBar = isSearchBarVisible && (
        <Nav className="ml-auto mr-3">
            <SearchBar />
        </Nav>
    );

    return (
        <div>
            <Navbar
                bg="light"
                className="mb-50"
                expand="lg"
                fixed="top"
                id="lp-navbar"
                style={{ transition: "0.5s ease" }}
            >
                <Navbar.Brand className="nav-brand" href="/home">
                    <img alt="logo" height="32px" src={logo} />
                </Navbar.Brand>
                <Nav className="ml-auto mr-3 d-block d-lg-none">
                    <Nav.Link href="/cart">
                        <span className="smoke">
                            <ShoppingCartOutlinedIcon
                                style={{ fontSize: 21 }}
                            />{" "}
                            {cartCountContext.count}
                        </span>
                    </Nav.Link>
                </Nav>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown
                            className="lp-dropdown nav-link-left body-1-m"
                            id="basic-nav-dropdown"
                            title="E-Design"
                        >
                            <NavDropdown.Item href="/home">
                                Home
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/pricing">
                                Pricing
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/projects">
                                Projects
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link
                            className="nav-link-left body-1-m"
                            href="/shop-home"
                        >
                            Shop
                        </Nav.Link>
                        <Nav.Link
                            className="nav-link-left body-1-m"
                            href="/about"
                        >
                            About
                        </Nav.Link>
                        <Nav.Link
                            className="nav-link-left body-1-m"
                            href="/contact-us"
                        >
                            Contact
                        </Nav.Link>
                    </Nav>
                    {navSearchBar}
                    {cartCountContext.count !== 0 && (
                        <Nav className="mr-3 d-none d-lg-block">
                            <Nav.Link href="/cart">
                                <span className="smoke">
                                    <ShoppingCartOutlinedIcon
                                        style={{ fontSize: 21 }}
                                    />{" "}
                                    {cartCountContext.count}
                                </span>
                            </Nav.Link>
                        </Nav>
                    )}

                    {!authContext.isLoggingIn && (
                        <div>
                            {Object.keys(authContext.user).length !== 0 ? (
                                <Nav>
                                    <Nav.Link href="https://app.gussy.design/">
                                        <span id="username">
                                            {authContext.user.name}
                                        </span>
                                    </Nav.Link>
                                    <Nav.Link className="nav-divider" href="#">
                                        |
                                    </Nav.Link>
                                    <Nav.Link href="#" onClick={logout}>
                                        Logout
                                    </Nav.Link>
                                </Nav>
                            ) : (
                                <Nav>
                                    <Nav.Link
                                        href={"#"}
                                        onClick={handleLoginClick}
                                    >
                                        Login
                                    </Nav.Link>
                                    <Nav.Link
                                        href={"#"}
                                        onClick={handleLoginClick}
                                        className="nav-divider"
                                    >
                                        |
                                    </Nav.Link>
                                    <Nav.Link href="/style-quiz">
                                        Signup
                                    </Nav.Link>
                                </Nav>
                            )}
                        </div>
                    )}
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default NavbarLPComponent;
