import React from "react";
import { Button } from "react-bootstrap";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";

import check from "../../../assets/img/StyleQuiz/check.svg";
import "../stylequizcomponent.scss";
import "../../../App.scss";

function StyleQuiz1Component(props) {
    return (
      <div>
        <div className="mt-75 container container-wide text-center">
          <div className="row mb-40">
            <div className="col-md-12">
              <Fade top>
                <h3 className="mb-20">Which rooms are you considering for a makeover?</h3>
                <p>Pick as many as you like.</p>
              </Fade>
            </div>
          </div>
          <div className="row justify-content-center">
            <Flip delay={300} left cascade>
            <div className="col-md-8 text-center">
              <div className="d-inline-block p-1">
                <Button onClick={props.livingRoomClick} variant="light" className={"body-1-m choices-box text-center " + props.livingClass}>
                  <img src={check} alt="check" className="choice-check display-none"/>
                  Living Room
                </Button>
              </div>
              <div className="d-inline-block p-1">
                <Button onClick={props.bedroomClick} variantvariant="light" className={"body-1-m choices-box text-center " + props.bedroomClass}>
                  <img src={check} alt="check" className="choice-check display-none"/>
                  Bedroom
                </Button>
              </div>
              <div className="d-inline-block p-1">
                <Button onClick={props.diningClick} variant="light" className={"body-1-m choices-box text-center " + props.diningClass}>
                  <img src={check} alt="check" className="choice-check display-none"/>
                  Dining Room
                </Button>
              </div>
              <div className="d-inline-block p-1">
                <Button onClick={props.nurseryClick} variant="light" className={"body-1-m choices-box text-center " + props.nurseryClass}>
                  <img src={check} alt="check" className="choice-check display-none"/>
                  Nursery / Playroom
                </Button>
              </div>
              <div className="d-inline-block p-1">
                <Button onClick={props.entertainmentClick} variant="light" className={"body-1-m choices-box text-center " + props.entertainmentClass}>
                  <img src={check} alt="check" className="choice-check display-none"/>
                  Entertainment Room
                </Button>
              </div>
              <div className="d-inline-block p-1">
                <Button onClick={props.homeOfficeClick} variant="light" className={"body-1-m choices-box text-center " + props.homeOfficeClass}>
                  <img src={check} alt="check" className="choice-check display-none"/>
                  Home Office
                </Button>
              </div>
              <div className="d-inline-block p-1">
                <Button onClick={props.studioClick} variant="light" className={"body-1-m choices-box text-center " + props.studioClass}>
                  <img src={check} alt="check" className="choice-check display-none"/>
                  Studio
                </Button>
              </div>
              <div className="d-inline-block p-1">
                <Button onClick={props.notSureClick} variant="light" className={"body-1-m choices-box text-center " + props.notSureClass}>
                  <img src={check} alt="check" className="choice-check display-none"/>
                  Not sure yet
                </Button>
              </div>
              </div>
              </Flip>
            </div>
        </div>
      </div>
    );
  }
  
  export default StyleQuiz1Component;