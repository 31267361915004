import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { getDatabase, serverTimestamp } from "firebase/database";
import { getFirestore } from "firebase/firestore/lite";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const { NODE_ENV, REACT_APP_FIREBASE_CONFIG, REACT_APP_FIREBASE_TEST_CONFIG } =
  process.env;

const firebaseConfig =
  NODE_ENV === "development"
    ? JSON.parse(REACT_APP_FIREBASE_TEST_CONFIG)
    : JSON.parse(REACT_APP_FIREBASE_CONFIG);

const appFirebase = initializeApp(firebaseConfig);

export const GoogleProvider = new GoogleAuthProvider();
export const FacebookProvider = new FacebookAuthProvider();
export const dbFirebase = getDatabase(appFirebase);
export const authFirebase = getAuth(appFirebase);
export const storageFirebase = getStorage(appFirebase);
export const functionsFirebase = getFunctions(appFirebase, "asia-northeast1");
export const firestore = getFirestore(appFirebase);
export const fireStamp = serverTimestamp();
